import axios from "axios"
// ----------------------------------------------------------------------

const axiosInstance = axios.create({
  withCredentials: true,
  headers: { "Content-Type": "application/json" },
  baseURL: process.env.REACT_APP_BACKEND_URI,
})

axiosInstance.interceptors.response.use(
  (response) => response,
  (error) => Promise.reject(`${error.response && JSON.stringify(error.response.data)}` || "Something went wrong")
)

export default axiosInstance

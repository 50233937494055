import { Consent } from "../../../@types/consent"

/* eslint-disable */
const consentEN: Consent = {
  lang: "en",
  text: `
<br><h2 class="code-line" data-line-start=0 data-line-end=1 ><a id="SPEAKUITY_MH2_0"></a>SPEAKUITY MH2</h2>
<p class="has-line-data" data-line-start="1" data-line-end="3"> <br>
Collection of vocal data and health data to develop vocal biomarkers* relating to physio-psychological distress</p>
<p class="has-line-data" data-line-start="4" data-line-end="6">* Words or groups of words topped with an asterisk are included in the glossary<br>
 </p>
<br><h4 class="code-line" data-line-start=6 data-line-end=7 ><a id="Your_contacts_6"></a>Your contacts:</h4>
<hr>
<br>
<p class="has-line-data" data-line-start="8" data-line-end="9"><strong>Research promoter</strong>* and data controller:</p>
<blockquote>
<p class="has-line-data" data-line-start="9" data-line-end="13">AKUITY CARE<br>
23 rue Léon Jost 75017 Paris<br>
+ 33 6 10 69 47 54<br>
<a href="mailto:contact@akuity.care">contact@akuity.care</a></p>
</blockquote>
<br>
<p class="has-line-data" data-line-start="14" data-line-end="15"><strong>Investigator*coordinator</strong> of the research:</p>
<blockquote>
<p class="has-line-data" data-line-start="15" data-line-end="19">Dr. Guy Fagherazzi<br>
Luxembourg Institute of Health<br>
+ 33 1 55 25 01 90<br>
<a href="mailto:guy.fagherazzi@lih.lu">guy.fagherazzi@lih.lu</a></p>
</blockquote>
<br>
<p class="has-line-data" data-line-start="20" data-line-end="21">Data Protection Officer <strong>(DPO)</strong>* of the promoter:</p>
<blockquote>
<p class="has-line-data" data-line-start="21" data-line-end="23">Jean-Marie Nazarenko<br>
<a href="mailto:dpo@akuity.care">dpo@akuity.care</a></p>
</blockquote>
<br>
<p class="has-line-data" data-line-start="25" data-line-end="26">CNIL- Commission nationale de l’Informatique et des libertés:</p>
<blockquote>
<p class="has-line-data" data-line-start="26" data-line-end="29">CNIL / Service des Plaintes<br>
3 Place de Fontenoy TSA 80715<br>
75334 PARIS CEDEX 07</p>
</blockquote>
<hr>
<p class="has-line-data" data-line-start="31" data-line-end="32"> </p>
<br><h4 class="code-line" data-line-start=32 data-line-end=33 ><a id="Can_you_participate_in_this_Research_32"></a>Can you participate in this Research?</h4>
<p class="has-line-data" data-line-start="34" data-line-end="35">To participate in this Research, you must validate all of the following conditions:</p>
<ul>
<li class="has-line-data" data-line-start="35" data-line-end="36">be of legal age (&gt;=18 years old)</li>
<li class="has-line-data" data-line-start="36" data-line-end="37">be a user of a terminal connected to the internet and equipped with a microphone (smartphone, tablet or computer) for filling out and sending digital questionnaires and voice capture</li>
<li class="has-line-data" data-line-start="37" data-line-end="38">speak one of the following languages fluently: French, German, English or Spanish</li>
<li class="has-line-data" data-line-start="38" data-line-end="39">not be deprived of liberty, under guardianship or curatorship</li>
<li class="has-line-data" data-line-start="39" data-line-end="40">be sufficiently proficient in French, German, English, Spanish or reading to read a text of a few lines or answer simple questionnaires in these languages</li>
<li class="has-line-data" data-line-start="40" data-line-end="41">not have severe speech difficulties or very impaired speech</li>
</ul>
<p class="has-line-data" data-line-start="43" data-line-end="44"> </p>
<br>
<br><h4 class="code-line" data-line-start=44 data-line-end=45 ><a id="Summary__44"></a>Summary :</h4>
<table class="table table-striped table-bordered">
<thead>
<tr>
<th></th>
<th style="text-align:right">Page</th>
</tr>
</thead>
<tbody>
<tr>
<td><strong>PART 1: RESEARCH INFORMATION</strong></td>
<td style="text-align:right">3</td>
</tr>
<tr>
<td>Why is this Research being carried out?</td>
<td style="text-align:right">3</td>
</tr>
<tr>
<td>What does Research consist of?</td>
<td style="text-align:right">3</td>
</tr>
<tr>
<td>How does the Research take place?</td>
<td style="text-align:right">4</td>
</tr>
<tr>
<td>What are the expected benefits and risks linked to Research?</td>
<td style="text-align:right">4</td>
</tr>
<tr>
<td>What compensation is provided?</td>
<td style="text-align:right">4</td>
</tr>
<tr>
<td>What will happen in the event of premature termination of the Research and after the Research?</td>
<td style="text-align:right">4</td>
</tr>
<tr>
<td>How long will your data be kept?</td>
<td style="text-align:right">5</td>
</tr>
<tr>
<td>Legislative and regulatory provisions</td>
<td style="text-align:right">5</td>
</tr>
<tr>
<td><strong>PART 2: INFORMATION ON THE PARTICIPANT’S RIGHTS AND ON THE MANAGEMENT OF COLLECTED DATA</strong></td>
<td style="text-align:right">6</td>
</tr>
<tr>
<td>What are your rights ?</td>
<td style="text-align:right">6</td>
</tr>
<tr>
<td>How will your personal data* be processed as part of the research?</td>
<td style="text-align:right">6</td>
</tr>
<tr>
<td>What is the legal basis and purpose of processing your personal data?</td>
<td style="text-align:right">7</td>
</tr>
<tr>
<td>How will the confidentiality of your data be ensured?</td>
<td style="text-align:right">8</td>
</tr>
<tr>
<td>Who will have access to your data as part of the Research?</td>
<td style="text-align:right">8</td>
</tr>
<tr>
<td>What are your rights relating to your personal data?</td>
<td style="text-align:right">9</td>
</tr>
<tr>
<td>How to exercise your rights?</td>
<td style="text-align:right">9</td>
</tr>
<tr>
<td>Can your encoded data be reused?</td>
<td style="text-align:right">9</td>
</tr>
<tr>
<td><strong>PART 3: GLOSSARY</strong></td>
<td style="text-align:right">11</td>
</tr>
</tbody>
</table>
<p class="has-line-data" data-line-start="67" data-line-end="68"> </p>
<br>
<br><h2 class="code-line" data-line-start=69 data-line-end=70 ><a id="PART_1_RESEARCH_INFORMATION_69"></a>PART 1: RESEARCH INFORMATION</h2>
<p class="has-line-data" data-line-start="71" data-line-end="72">Dear Sir or Madam,</p>
<p class="has-line-data" data-line-start="73" data-line-end="74">As part of your use of the voqia application or the <a href="https://www.yourvoice.care/">https://www.yourvoice.care/</a> website, you are invited to take part in research aimed at collecting data to develop voice biomarkers* for physio-psychological distress.</p>
<p class="has-line-data" data-line-start="75" data-line-end="76">You are free to participate or not. You can take the time necessary to read the information below, discuss with your loved ones and ask all your questions to the point of contact coordinating the research, called an investigator*. After having obtained satisfactory answers to your questions and having had sufficient time to think, you can then decide whether you agree to participate in the research or not.</p>
<p class="has-line-data" data-line-start="77" data-line-end="78"> </p>
<br><h3 class="code-line" data-line-start=78 data-line-end=79 ><a id="Why_is_this_Research_being_carried_out_78"></a>Why is this Research being carried out?</h5>
<p class="has-line-data" data-line-start="80" data-line-end="82">AKUITY CARE, the promoter* of this Research, plans to develop an AI (Artificial Intelligence*) solution allowing, from an analysis of the voice, to evaluate certain parameters defined as markers of physio-psychological distress .<br>
The AI* algorithms that would ultimately be integrated into such a solution are called <strong>vocal biomarkers</strong>*. They give an assessment of a particular condition based on a voice sample.</p>
<p class="has-line-data" data-line-start="83" data-line-end="84">To develop them, you must train them by providing them, a large number of times:</p>
<ul>
<li class="has-line-data" data-line-start="84" data-line-end="85">on the one hand, data on the state of individuals,</li>
<li class="has-line-data" data-line-start="85" data-line-end="87">on the other hand, samples of their voice.</li>
</ul>
<p class="has-line-data" data-line-start="87" data-line-end="88">By giving him the answer that is expected from him, we gradually teach an algorithm to find this answer on its own, that is to say to deduce the state of an individual based on the parameters of distress. physio-psychological defined above, solely from his voice.</p>
<p class="has-line-data" data-line-start="89" data-line-end="90">The purpose of this research is to collect data from you and other users of the voqia application and the <a href="https://www.yourvoice.care/">https://www.yourvoice.care/</a> website in order to develop and train voice biomarkers*.</p>
<p class="has-line-data" data-line-start="91" data-line-end="92"> </p>
<br><h3 class="code-line" data-line-start=92 data-line-end=93 ><a id="What_does_Research_consist_of_92"></a>What does Research consist of?</h5>
<p class="has-line-data" data-line-start="94" data-line-end="95">This Research targets several parameters relating to physio-psychological distress:</p>
<ul>
<li class="has-line-data" data-line-start="96" data-line-end="97">depressive symptoms</li>
<li class="has-line-data" data-line-start="97" data-line-end="98">anxiety disorders</li>
<li class="has-line-data" data-line-start="98" data-line-end="99">symptoms of fatigue</li>
<li class="has-line-data" data-line-start="99" data-line-end="100">the stress</li>
<li class="has-line-data" data-line-start="100" data-line-end="101">pain</li>
</ul>
<p class="has-line-data" data-line-start="103" data-line-end="105">The research will last 2 years and should allow the participation of several thousand users of the voqia application and the website <a href="https://www.yourvoice.care/">https://www.yourvoice.care/</a>.<br>
It is financed by the promoter*.</p>
<p class="has-line-data" data-line-start="106" data-line-end="107"> </p>
<br><h3 class="code-line" data-line-start=108 data-line-end=109 ><a id="How_does_the_Research_take_place_108"></a>How does the Research take place?</h5>
<p class="has-line-data" data-line-start="110" data-line-end="111">Your participation in the Research will consist, if you consent, of providing the data targeted by the Research, namely:</p>
<ul>
<li class="has-line-data" data-line-start="111" data-line-end="112"><strong>information on your profile, your habits and your state of health:</strong> you will provide them by answering questionnaires in the voqia application, either when you first use the application or when you use it subsequently, or on the <a href="https://www.yourvoice.care/">https://www.yourvoice.care/</a> website if you participate in the Research via this channel</li>
<li class="has-line-data" data-line-start="112" data-line-end="114"><strong>samples of your voice:</strong> you will provide them by using the “Journaling” and “Relaxation” features of the voqia application using the connected device on which you are using the application, or on the <a href="https://www.yourvoice.care/">https://www.yourvoice.care/</a> website if you are participating in the Research via this channel</li>
</ul>
<p class="has-line-data" data-line-start="114" data-line-end="115">If you have any questions about the Research, you can find this document in the “Use of data” section of the voqia application or contact its manufacturer, the promoter* of the Research, using the contact details given on the first page. This document is automatically downloaded to your device when you confirm that you want to read it.</p>
<p class="has-line-data" data-line-start="116" data-line-end="117">The data that will be requested from you in the Research has been chosen because it is all presumed to be important for learning the AI* algorithms that the Research aims to develop.</p>
<p class="has-line-data" data-line-start="118" data-line-end="119"> </p>
<br><h3 class="code-line" data-line-start=119 data-line-end=120 ><a id="What_are_the_expected_benefits_and_risks_linked_to_Research_119"></a>What are the expected benefits and risks linked to Research?</h5>
<p class="has-line-data" data-line-start="121" data-line-end="122">This research is qualified as “Research not involving humans*” (RNIPH) because it collects prospective data without aiming at the development of biological or medical knowledge, only the development of an internal tool by its promoter*, in occurrence of vocal biomarkers* associated with physio-psychological distress, without seeking to evaluate their effectiveness.</p>
<p class="has-line-data" data-line-start="123" data-line-end="124">The questionnaires and vocal exercises requested are very simple and pose no risk to you.</p>
<p class="has-line-data" data-line-start="125" data-line-end="126">The Research could produce long-term benefits, if the vocal biomarkers developed from the data collected are subsequently evaluated in another study and if they prove sufficiently effective. They could then, for example, make it possible to assess the clinical state of patients suffering from depression using their voice, therefore easily and remotely. Thus, medical interventions could be more appropriate: at the right time, with the right patients and based on information on their recent condition.</p>
<p class="has-line-data" data-line-start="127" data-line-end="128"> </p>
<br><h3 class="code-line" data-line-start=128 data-line-end=129 ><a id="What_compensation_is_provided_128"></a>What compensation is provided?</h5>
<p class="has-line-data" data-line-start="130" data-line-end="131">This Research is carried out during your use of the voqia application or the internet site <a href="https://www.yourvoice.care/">https://www.yourvoice.care/</a>. It does not incur any costs for you. No compensation is provided for your participation in this Research.</p>
<p class="has-line-data" data-line-start="132" data-line-end="133"> </p>
<br><h3 class="code-line" data-line-start=133 data-line-end=134 ><a id="What_will_happen_if_the_Research_ends_prematurely_and_after_the_Research_133"></a>What will happen if the Research ends prematurely and after the Research?</h5>
<p class="has-line-data" data-line-start="135" data-line-end="137">Premature termination of the Search will have no impact on your use of the voqia application  or the internet site <a href="https://www.yourvoice.care/">https://www.yourvoice.care/</a>.<br>
During and after the Research, the data collected will be used to develop vocal biomarkers relating to physio-psychological distress.</p>
<p class="has-line-data" data-line-start="138" data-line-end="139"> </p>
<br><h3 class="code-line" data-line-start=139 data-line-end=140 ><a id="How_long_will_your_data_be_kept_139"></a>How long will your data be kept?</h5>
<p class="has-line-data" data-line-start="140" data-line-end="141">In accordance with the regulations, your data will be kept until two years after the last publication of the results of the Research or, in the absence of publication, until the signing of the final report at the end of the Research.</p>
<p class="has-line-data" data-line-start="142" data-line-end="143">They will be archived for a period of 15 years.</p>
<p class="has-line-data" data-line-start="144" data-line-end="145"> </p>
<br><h3 class="code-line" data-line-start=145 data-line-end=146 ><a id="Legislative_and_regulatory_provisions_145"></a>Legislative and regulatory provisions</h5>
<p class="has-line-data" data-line-start="146" data-line-end="147">In accordance with regulations, this Research has been declared to the Health Data Hub.</p>
<p class="has-line-data" data-line-start="148" data-line-end="149">The processing of your personal data in the context of research complies with a Reference Methodology (MR)* MR-004 established by the Commission Nationale Informatique et Libertés (CNIL).</p>
<p class="has-line-data" data-line-start="150" data-line-end="151"> </p>
<br><h2 class="code-line" data-line-start=151 data-line-end=152 ><a id="PART_2_INFORMATION_ON_THE_PARTICIPANTS_RIGHTS_AND_ON_THE_MANAGEMENT_OF_COLLECTED_DATA_151"></a>PART 2: INFORMATION ON THE PARTICIPANT’S RIGHTS AND ON THE MANAGEMENT OF COLLECTED DATA</h2>
<p class="has-line-data" data-line-start="153" data-line-end="154"> </p>
<br><h3 class="code-line" data-line-start=154 data-line-end=155 ><a id="What_are_your_rights__154"></a>What are your rights ?</h5>
<p class="has-line-data" data-line-start="156" data-line-end="157">Your participation in the Research is free and voluntary: you are free to accept or refuse to participate in this research and you can interrupt your participation at any time without having to give a reason and without incurring any liability or prejudice as a result. Simply report it to the promoter whose contact details are given on the first page, or to their Data Protection Officer (DPO)*. Their contact details can be found on the first page of this document.</p>
<p class="has-line-data" data-line-start="159" data-line-end="160">We invite you to ask AKUITY CARE any questions you may have about this Research, using the following email address: <a href="mailto:contact@akuity.care">contact@akuity.care</a></p>
<p class="has-line-data" data-line-start="161" data-line-end="162">You have the right to obtain communication, during or at the end of the research, of the data which concerns and which is held by the promoter*.</p>
<p class="has-line-data" data-line-start="163" data-line-end="164"> </p>
<br><h3 class="code-line" data-line-start=164 data-line-end=165 ><a id="How_will_your_personal_data_be_processed_as_part_of_the_research_164"></a>How will your personal data* be processed as part of the research?</h5>
<p class="has-line-data" data-line-start="165" data-line-end="166">If you agree to participate in research, your personal data, including your health data, will be processed* by the promoter, as the data controller.</p>
<p class="has-line-data" data-line-start="167" data-line-end="168">The following data will be collected and processed under appropriate security conditions:</p>
<table class="table table-striped table-bordered">
<thead>
<tr>
<th style="text-align:left">What data?</th>
<th style="text-align:left">How ?</th>
<th style="text-align:center">From whom ?</th>
</tr>
</thead>
<tbody>
<tr>
<td style="text-align:left">Voice recordings</td>
<td style="text-align:left">1) In the voqia application : a) free recordings made in the “Journaling” module of the app b) recordings of vocalisations (sounds “AAAaa” sustained for the time of an exhalation) in the “Relaxation” module of the application. - 2) On the <a href="https://www.yourvoice.care/">https://www.yourvoice.care/</a> website: a) recording of the reading of a short text b) recording of a vocalise (“AAAaa” sound sustained for the time of an exhalation) c) recording of the free description of an image d) recording of the free response to the question “How are you today?” e) recording of 3 inhalations/exhalations through the mouth f) recording of 3 coughs</td>
<td style="text-align:center">Yourself</td>
</tr>
<tr>
<td style="text-align:left">Assessment of depressive symptoms (by a questionnaire)</td>
<td style="text-align:left">Multiple Choice Quiz in 9 questions</td>
<td style="text-align:center">Yourself</td>
</tr>
<tr>
<td style="text-align:left">Assessment of anxiety disorders (via a questionnaire)</td>
<td style="text-align:left">Multiple Choice Quiz in 7 questions</td>
<td style="text-align:center">Yourself</td>
</tr>
<tr>
<td style="text-align:left">Assessment of fatigue symptoms (via a questionnaire)</td>
<td style="text-align:left">Multiple Choice Quiz in 9 questions</td>
<td style="text-align:center">Yourself</td>
</tr>
<tr>
<td style="text-align:left">Stress assessment (using a scale)</td>
<td style="text-align:left">Drop-down menu (0 to 10)</td>
<td style="text-align:center">Yourself</td>
</tr>
<tr>
<td style="text-align:left">Pain assessment (using a scale)</td>
<td style="text-align:left">Drop-down menu (0 to 10)</td>
<td style="text-align:center">Yourself</td>
</tr>
<tr>
<td style="text-align:left">Known diagnoses of depression, generalized anxiety disorder, schizophrenia, bipolar disorder, COPD, asthma, respiratory infections in the last 3 months and currently, current ENT* symptoms, persistent symptoms of Covid-19, respiratory allergy, pulmonary fibrosis, cancer (+ information relating to cancer if applicable)</td>
<td style="text-align:left">Yes / No</td>
<td style="text-align:center">Yourself</td>
</tr>
<tr>
<td style="text-align:left">Other known comorbidities (to be indicated in a list)</td>
<td style="text-align:left">Drop-down menu</td>
<td style="text-align:center">Yourself</td>
</tr>
<tr>
<td style="text-align:left">Current treatments (to be indicated in a list)</td>
<td style="text-align:left">Drop-down menu</td>
<td style="text-align:center">Yourself</td>
</tr>
<tr>
<td style="text-align:left">Unique code</td>
<td style="text-align:left">Alpha-numeric characters</td>
<td style="text-align:center">Automatic</td>
</tr>
<tr>
<td style="text-align:left">Mother tongue</td>
<td style="text-align:left">Drop-down menu</td>
<td style="text-align:center">Yourself</td>
</tr>
<tr>
<td style="text-align:left">Year of birth</td>
<td style="text-align:left">Drop-down menu</td>
<td style="text-align:center">Yourself</td>
</tr>
<tr>
<td style="text-align:left">Sex</td>
<td style="text-align:left">Multiple Choice Quiz</td>
<td style="text-align:center">Yourself</td>
</tr>
<tr>
<td style="text-align:left">Size</td>
<td style="text-align:left">Drop-down menu</td>
<td style="text-align:center">Yourself</td>
</tr>
<tr>
<td style="text-align:left">Weight</td>
<td style="text-align:left">Drop-down menu</td>
<td style="text-align:center">Yourself</td>
</tr>
<tr>
<td style="text-align:left">Current consumption of tobacco or other smoked substances (frequency, quantity by form, age)</td>
<td style="text-align:left">Multiple choice questionnaires and drop-down menu</td>
<td style="text-align:center">Yourself</td>
</tr>
<tr>
<td style="text-align:left">Historical consumption of tobacco or other smoked substances (frequency, quantity by form, duration, date of cessation)</td>
<td style="text-align:left">Multiple Choice Quizzes and Drop-Down Menu</td>
<td style="text-align:center">Yourself</td>
</tr>
<tr>
<td style="text-align:left">Current pregnancy (and stage if applicable)</td>
<td style="text-align:left">Yes / No (and drop-down menu)</td>
<td style="text-align:center">Yourself</td>
</tr>
<tr>
<td style="text-align:left">Data relating to the conduct of research</td>
<td style="text-align:left">Start and end date and time of each screen for quizzes and voice exercises</td>
<td style="text-align:center">Automatic (from the hardware used for Research)</td>
</tr>
</tbody>
</table>
<p class="has-line-data" data-line-start="191" data-line-end="192">These data were chosen because they are all presumed to be important for learning the AI* algorithms that the Research aims to develop.</p>
<p class="has-line-data" data-line-start="193" data-line-end="194"> </p>
<br><h3 class="code-line" data-line-start=194 data-line-end=195 ><a id="What_is_the_legal_basis_and_purpose_of_processing_your_personal_data_194"></a>What is the legal basis and purpose of processing your personal data?</h5>
<p class="has-line-data" data-line-start="195" data-line-end="199">The processing of your personal data is necessary to carry out the research, that is to say the development of the aforementioned vocal biomarkers. In this context, the sub-purposes are as follows: develop the register of participants, ensure the security of the data, test their quality and respond to requests to exercise participants’ rights.<br>
Thus, the processing of your personal data is authorized because it responds to a legitimate interest pursued by AKUITY CARE for scientific research purposes in accordance with articles 6.1 f) and 9.2. j) GDPR*.<br>
The legitimate interest pursued is the collection of data for the purposes of developing the vocal biomarkers described above.<br>
The data controller must implement appropriate measures to guarantee your rights and freedoms, in particular only the collection of data strictly necessary for research.</p>
<p class="has-line-data" data-line-start="200" data-line-end="201"> </p>
<br><h3 class="code-line" data-line-start=201 data-line-end=202 ><a id="How_will_the_confidentiality_of_your_data_be_ensured_201"></a>How will the confidentiality of your data be ensured?</h5>
<p class="has-line-data" data-line-start="203" data-line-end="205">Your personal data will be treated confidentially, in accordance with the French Data Protection Act of 6 January 1978, as amended, and with the General Data Protection Regulation (GDPR*).<br>
Your data will be encrypted*, i.e. you will be identified by a code number for the purposes of the research, without any “unencrypted” mention of the only potential directly identifying data collected in the Research, i.e. the email address associated with your account on the voqia application or your use of the <a href="https://www.yourvoice.care/">https://www.yourvoice.care/</a> website. A third party data host will keep the list of matches between this code and your email address. AKUITY CARE, in its capacity as promoter*, will not have contractual access to your email address, except in cases provided for by the GDPR*.</p>
<p class="has-line-data" data-line-start="206" data-line-end="207">Vocal recordings are, for some, exercises in free expression. Even if the nature of the application (emotional logbook and cardiac coherence tool) will not encourage you to do so, please be careful to <strong>not disclose any identifying information (such as your name or contact details) in your recordings</strong> . Such information will require us to destroy your data and will not permit your participation in the Research.</p>
<p class="has-line-data" data-line-start="208" data-line-end="209">Finally, these data will be processed in a computerized database presenting all the required technical and organizational security measures and in a confidential manner, respecting professional secrecy.</p>
<p class="has-line-data" data-line-start="210" data-line-end="211"> </p>
<br><h3 class="code-line" data-line-start=211 data-line-end=212 ><a id="Who_will_have_access_to_your_data_as_part_of_Research_211"></a>Who will have access to your data as part of Research?</h5>
<p class="has-line-data" data-line-start="213" data-line-end="214">Your potentially identifying information, i.e. your email address, will only be known by:</p>
<ul>
<li class="has-line-data" data-line-start="214" data-line-end="215">the company AZ Network, as a third-party health data host chosen by AKUITY CARE in its capacity as promoter to store the list of correspondence between email addresses and identification codes of Research participants (this list is therefore stored separately from other Research data, in particular participants’ health data, which is stored by the company OVHCloud, another health data host)</li>
<li class="has-line-data" data-line-start="215" data-line-end="216">the DPO (Data Protection Officer*) of the promoter* if you contact him <a href="mailto:dpo@akuity.care">dpo@akuity.care</a></li>
<li class="has-line-data" data-line-start="216" data-line-end="218">in the event of a dispute, the authorized personnel of the promoter’s insurance organization*.</li>
</ul>
<p class="has-line-data" data-line-start="218" data-line-end="219">These people are subject to professional secrecy.</p>
<p class="has-line-data" data-line-start="220" data-line-end="221">Your encoded data will be accessible to the following people:</p>
<ul>
<li class="has-line-data" data-line-start="221" data-line-end="222">promoter*</li>
<li class="has-line-data" data-line-start="222" data-line-end="224">the company OVHCloud, as a third-party health data host chosen by AKUITY CARE in its capacity as promoter to store all Research data with the exception of the correspondence list between email addresses and access codes identification of participants</li>
</ul>
<p class="has-line-data" data-line-start="224" data-line-end="225">These people, subject to professional secrecy, will have access to your coded data within the framework of their function and in compliance with regulations.</p>
<p class="has-line-data" data-line-start="226" data-line-end="227"> </p>
<br><h3 class="code-line" data-line-start=227 data-line-end=228 ><a id="What_are_your_rights_relating_to_your_personal_data_227"></a>What are your rights relating to your personal data?</h5>
<p class="has-line-data" data-line-start="229" data-line-end="231">You have the right to access your data, through the Data Protection Officer (DPO)* of the Research promoter or investigator*, and request that it be rectified or completed.<br>
You can also request the limitation of the processing of your data (i.e. ask the promoter to temporarily freeze the use of your data).</p>
<p class="has-line-data" data-line-start="232" data-line-end="234">Even if you agree to participate in the Research, you may at any time object to the processing of your data for the purposes of carrying out the Research. In this case, no additional information about you will be collected.<br>
You can also exercise your right to erasure of data already collected.</p>
<p class="has-line-data" data-line-start="235" data-line-end="236"> </p>
<br><h3 class="code-line" data-line-start=236 data-line-end=237 ><a id="How_to_exercise_your_rights_236"></a>How to exercise your rights?</h5>
<p class="has-line-data" data-line-start="237" data-line-end="239">You can exercise your rights at any time and without having to justify yourself.<br>
As the promoter* does not have access to your identity, it is recommended that you first contact its Data Protection Officer (DPO)*, using the contact details available in this note on the first page.</p>
<p class="has-line-data" data-line-start="240" data-line-end="241">In this case, your identity (first name, last name) will be made accessible to the DPO* of the promoter*.</p>
<p class="has-line-data" data-line-start="242" data-line-end="243">In the event that you are unable to exercise your rights, you also have the right to file a complaint concerning the processing of your personal data with the National Commission for Informatics and Liberties (CNIL), which is the competent supervisory authority in France in matters of data protection:</p>
<ul>
<li class="has-line-data" data-line-start="243" data-line-end="244">Via the online complaint teleservice</li>
<li class="has-line-data" data-line-start="244" data-line-end="246">By post by writing to: CNIL - Complaints Service - 3 Place de Fontenoy - TSA 80715 - 75334 PARIS CEDEX 07.</li>
</ul>
<p class="has-line-data" data-line-start="246" data-line-end="247"> </p>
<br><h3 class="code-line" data-line-start=247 data-line-end=248 ><a id="Can_your_encoded_data_be_reused_247"></a>Can your encoded data be reused?</h5>
<p class="has-line-data" data-line-start="248" data-line-end="249">Your data may be reused in other research aimed at the development of vocal biomarkers*. This will make it possible, for example, to evaluate the performance of vocal biomarkers* developed from the data collected in this Research by combining them with data from similar research (or vice versa).</p>
<p class="has-line-data" data-line-start="250" data-line-end="252">To obtain information on the new processing(s) of your data, the promoter* has set up a dynamic website (“transparency portal”) which you can consult regularly and prior to implementation. implementation of each new processing, at the following address: <a href="http://www.akuity.care/transparence/nv92j2">www.akuity.care/transparence/nv92j2</a>.<br>
This page will detail all the mandatory notices of the GDPR*, in particular, for each project concerned, the identity of the data controller and the purposes pursued justifying the reuse of your data. The site will be updated with each new Search reusing your data.</p>
<p class="has-line-data" data-line-start="253" data-line-end="255">With this information, you will be able to choose to exercise your rights of access, rectification, limitation, opposition or deletion of your data. The display of projects on the website page constitutes information for this reuse of data and therefore, it is not planned to send you an individual letter of additional information for each research project.<br>
The opposition procedures for each research project will be indicated on this website.</p>
<p class="has-line-data" data-line-start="256" data-line-end="257"> </p>
<br><h2 class="code-line" data-line-start=257 data-line-end=258 ><a id="PART_3_GLOSSARY_257"></a>PART 3: GLOSSARY</h2>
<ul>
<li class="has-line-data" data-line-start="259" data-line-end="260"><strong>Voice biomarker</strong>: AI algorithm providing an estimate of a clinical criterion in an individual based on a sample of their voice.</li>
<li class="has-line-data" data-line-start="260" data-line-end="261"><strong>Coding of your data</strong>: unique identification of your data in the Research database, by a non-significant code, i.e. not allowing you to be personally identified (alphanumeric code) .</li>
<li class="has-line-data" data-line-start="261" data-line-end="262"><strong>Personal data</strong>: any information relating to an identified or identifiable natural person, the processing of which is governed, in Europe, by the GDPR.</li>
<li class="has-line-data" data-line-start="262" data-line-end="263"><strong>DPO (Data Protection Officer)</strong>: person responsible for the protection of personal data within an organization.</li>
<li class="has-line-data" data-line-start="263" data-line-end="264"><strong>AI</strong> (Artificial Intelligence): construction of computer programs automatically carrying out defined tasks, these <strong>programs being called “algorithms”.</strong></li>
<li class="has-line-data" data-line-start="264" data-line-end="265"><strong>Investigator</strong>: Natural person responsible for monitoring and directing the research.</li>
<li class="has-line-data" data-line-start="265" data-line-end="266"><strong>Reference methodology (MR)</strong>: Simplified procedure governing access to health data for research promoters.</li>
<li class="has-line-data" data-line-start="266" data-line-end="267"><strong>Promoter</strong>: Natural or legal person responsible for the research, who ensures its management and who verifies that its financing is planned.</li>
<li class="has-line-data" data-line-start="267" data-line-end="268"><strong>Research not involving humans (RNIPH)</strong>: research outside the scope of research involving humans (RIPH), the latter being organized and carried out on human beings with a view to developing biological or medical (article L.1121-1 of the Public Health Code)</li>
<li class="has-line-data" data-line-start="268" data-line-end="269"><strong>GDPR</strong> (General Data Protection Regulation): Regulation (EU) 2016/679 of the European Parliament and of the Council of April 27, 2016 on the protection of individuals with regard to the processing of personal data personal and the free circulation of this data</li>
<li class="has-line-data" data-line-start="269" data-line-end="270"><strong>ENT symptoms</strong>: ear, nose and throat symptoms (example: nasal or sinus congestion)</li>
<li class="has-line-data" data-line-start="270" data-line-end="271"><strong>Processing of personal data</strong>: processing of personal data is an operation, or set of operations, relating to personal data, whatever the process used (collection, recording, organization, conservation, adaptation, modification, extraction, consultation, use, communication by transmission or dissemination or any other form of making available, reconciliation).</li>
</ul>
<hr>
`,

}
/* eslint-enable */
export default consentEN

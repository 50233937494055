import { MedicalQuestion } from "../../../../@types/medicalQuestion"

const generalFR: MedicalQuestion[] = [
  {
    id: "lang",
    name: "Language",
    type: "select",
    value: [
      { value: "french_france$", label: "Français (France)" },
      { value: "arabic", label: "Arabe" },
      { value: "bengali", label: "Bengali" },
      { value: "chinese_mandarin_wu", label: "Chinois (mandarin, wu...)" },
      { value: "czech", label: "Tchèque" },
      { value: "dutch", label: "Hollandais" },
      { value: "english_american", label: "Anglais (américain)" },
      { value: "english_british", label: "Anglais (britannique)" },
      { value: "french_africa", label: "Français (Africa)" },
      { value: "french_belgium", label: "Français (Belgique)" },
      { value: "french_france", label: "Français (France)" },
      { value: "french_quebec", label: "Français (Quebec)" },
      { value: "french_switzerland", label: "Français (Suisse)" },
      { value: "finnish", label: "Finlandais" },
      { value: "german_germany", label: "Allemand (Allemagne)" },
      { value: "german_swiss", label: "Allemand (Suisse)" },
      { value: "greek", label: "Grec" },
      { value: "hindi", label: "Hindi" },
      { value: "hungarian", label: "Hongrois" },
      { value: "italian", label: "Italien" },
      { value: "japanese", label: "Japonais" },
      { value: "korean", label: "Coréen" },
      { value: "norwegian", label: "Norvégien" },
      { value: "portuguese", label: "Portugais" },
      { value: "polish", label: "Polonais" },
      { value: "romanian", label: "Roumain" },
      { value: "russian", label: "Russe" },
      { value: "spanish", label: "Espagnol" },
      { value: "swedish", label: "Suédois" },
      { value: "turkish", label: "Turc" },
      { value: "other", label: "Autre" },
    ],
    description: "Quelle est votre langue maternelle ?",
    parent: "general_info",
    order: 1,
    lang: "fr",
    required: true,
  },
  {
    id: "age",
    name: "Age",
    type: "number",
    minValue: 1930,
    maxValue: 2011,
    description: "Année de naissance",
    parent: "general_info",
    order: 2,
    lang: "fr",
    required: true,
  },
  {
    id: "sex",
    name: "Gender",
    type: "categoricalShort",
    value: [
      { label: "Masculin", value: "male" },
      { label: "Féminin", value: "female" },
      { label: "Non-binaire", value: "non-binary" },
    ],
    description: "Sexe",
    parent: "general_info",
    order: 3,
    lang: "fr",
    required: true,
  },
  {
    id: "height",
    name: "Height",
    type: "number",
    minValue: 100,
    maxValue: 220,
    description: "Taille (cm)",
    parent: "general_info",
    order: 4,
    lang: "fr",
    required: true,
  },
  {
    id: "weight",
    name: "Weight",
    type: "number",
    minValue: 40,
    maxValue: 200,
    description: "Poids (kg)",
    parent: "general_info",
    order: 5,
    lang: "fr",
    required: true,
  },
  {
    id: "c_smo",
    name: "Current tobacco smoking",
    type: "categoricalShort",
    value: [
      { label: "Tous les jours", value: "daily" },
      { label: "Moins d’une fois par jour", value: "less-than-daily" },
      { label: "Pas du tout", value: "not-at-all" },
    ],
    description:
      "Êtes-vous actuellement fumeur(euse) (tabac ou autres substances, quelle que soit la forme y compris cigarette électronique) ?",
    parent: "general_info",
    order: 6,
    lang: "fr",
    required: true,
  },
  {
    id: "c_smo_quant",
    name: "Current smoking quantity",
    type: "number",
    minValue: 0,
    maxValue: 500,
    description: "En moyenne, quelle quantité de cigarettes (ou équivalents) fumez-vous chaque semaine ?",
    info:
      "Quelques équivalences pour rappel :\n" +
      "- 1 paquet de cigarettes = 20 cigarettes\n" +
      "- 1 cigare équivaut à environ 15 cigarettes\n" +
      "- 1 cigarillo équivaut à environ 3 cigarettes\n" +
      "- 1 joint équivaut à environ 3 cigarettes\n" +
      "- 1 heure de narguilé équivaut à environ 5 paquets de cigarettes\n" +
      "- 1 flacon de 10mL de e-liquide équivaut à environ 10 paquets de cigarettes",
    parent: "general_info",
    order: 7,
    lang: "fr",
    dependsOn: {
      id: "c_smo",
      values: ["daily", "less-than-daily"],
    },
    required: false,
  },
  {
    id: "c_dur",
    name: "Current duration smoking",
    type: "number",
    minValue: 1,
    maxValue: 60,
    description: "Depuis combien d'années fumez-vous ?",
    parent: "general_info",
    order: 8,
    lang: "fr",
    dependsOn: {
      id: "c_smo",
      values: ["daily", "less-than-daily"],
    },
    required: false,
  },
  {
    id: "p_smo",
    name: "Past tobacco smoking",
    type: "categoricalShort",
    value: [
      { label: "Tous les jours", value: "daily" },
      { label: "Moins d’une fois par jour", value: "less-than-daily" },
      { label: "Pas du tout", value: "not-at-all" },
    ],
    description: "Avez-vous fumé dans le passé (tabac ou autres substances, quelle que soit la forme y compris cigarette électronique) ?",
    parent: "general_info",
    order: 9,
    lang: "fr",
    dependsOn: {
      id: "c_smo",
      values: ["not-at-all"],
    },
    required: false,
  },
  {
    id: "p_smo_quant",
    name: "Current smoking quantity",
    type: "number",
    minValue: 1,
    maxValue: 500,
    description: "En moyenne, quelle quantité de cigarettes (ou équivalents) fumiez-vous alors chaque semaine ?",
    info:
      "Quelques équivalences pour rappel :\n" +
      "- 1 paquet de cigarettes = 20 cigarettes\n" +
      "- 1 cigare équivaut à environ 15 cigarettes\n" +
      "- 1 cigarillo équivaut à environ 3 cigarettes\n" +
      "- 1 joint équivaut à environ 3 cigarettes\n" +
      "- 1 heure de narguilé équivaut à environ 5 paquets de cigarettes\n" +
      "- 1 flacon de 10mL de e-liquide équivaut à environ 10 paquets de cigarettes",
    parent: "general_info",
    order: 10,
    lang: "fr",
    dependsOn: {
      id: "p_smo",
      values: ["daily", "less-than-daily"],
    },
    required: false,
  },
  {
    id: "p_dur",
    name: "Past duration smoking",
    type: "number",
    minValue: 1,
    maxValue: 60,
    description: "Pendant combien d'années avez-vous fumé ?",
    parent: "general_info",
    order: 23,
    lang: "fr",
    dependsOn: {
      id: "p_smo",
      values: ["daily", "less-than-daily"],
    },
    required: false,
  },
  {
    id: "p_dur_stop",
    name: "Past duration smoking stop",
    type: "number",
    minValue: 0,
    maxValue: 60,
    description: "Depuis combien d'années avez-vous arrêté de fumer ?",
    parent: "general_info",
    order: 24,
    lang: "fr",
    dependsOn: {
      id: "p_smo",
      values: ["daily", "less-than-daily"],
    },
    required: false,
  },
  {
    id: "alc_freq",
    name: "Alcohol frequency",
    type: "categoricalShort",
    value: [
      { label: "Tous les jours", value: "daily" },
      { label: "5 ou 6 fois par semaine", value: "5-6-a-week" },
      { label: "3 ou 4 fois par semaine", value: "3-4-a-week" },
      { label: "2 fois par semaine", value: "twice-a-week" },
      { label: "1 fois par semaine", value: "once-a-week" },
      { label: "2 ou 3 fois par mois", value: "2-3-a-month" },
      { label: "1 fois par mois", value: "once-a-month" },
      { label: "3 à 11 fois au cours des 12 derniers mois", value: "3-11-a-year" },
      { label: "1 ou 2 fois au cours des 12 derniers mois", value: "1-2-a-year" },
      { label: "jamais", value: "never" },
    ],
    description: "Sur les 12 derniers mois en moyenne, à quelle fréquence avez-vous consommé de l'alcool ?",
    info: "toute forme d'alcool et quelle que soit la quantité",
    parent: "general_info",
    order: 13,
    lang: "fr",
    required: true,
  },
  {
    id: "alc_quant",
    name: "Alcool quantity ",
    type: "number",
    minValue: 0,
    maxValue: 20,
    description: "Et sur ces jours de consommation, combien de verres standards avez-vous consommé en moyenne ?",
    info: "(1 verre standard = par exemple 25cL de bière, 10cL de vin, 2.5cL de pastis, 2.5cl de whisky)",
    parent: "general_info",
    order: 14,
    lang: "fr",
    dependsOn: {
      id: "alc_freq",
      values: [
        "daily",
        "5-6-a-week",
        "3-4-a-week",
        "twice-a-week",
        "once-a-week",
        "2-3-a-month",
        "once-a-month",
        "3-11-a-year",
        "1-2-a-year",
      ],
    },
    required: false,
  },
]

export default generalFR

import { Categorical, MedicalQuestion } from "../../../../@types/medicalQuestion"

const category: Categorical[] = [
  { label: "1", value: "1" },
  { label: "2", value: "2" },
  { label: "3", value: "3" },
  { label: "4", value: "4" },
  { label: "5", value: "5" },
  { label: "6", value: "6" },
  { label: "7", value: "7" },
]

const fssEN: MedicalQuestion[] = [
  {
    id: "fss_intro",
    name: "intro",
    value:
      "Choose a number from 1 (strongly disagree) to 7 (strongly agree) that indicates your degree of agreement " +
      "with the following statements. Please answer the questions with reference to how you have been feeling on " +
      "average over the last week.",
    type: "intro",
    lang: "en",
    parent: "fss",
    required: false,
  },
  {
    id: "fss_1",
    name: "Fatigue Severity Scale",
    type: "categoricalLong",
    value: category,
    labelStart: "Strongly disagree",
    labelEnd: "Strongly agree",
    description: "My motivation is lower when I am fatigued.",
    parent: "fss",
    order: 1,
    lang: "en",
    required: false,
  },
  {
    id: "fss_2",
    name: "Fatigue Severity Scale",
    type: "categoricalLong",
    value: category,
    labelStart: "Strongly disagree",
    labelEnd: "Strongly agree",
    description: "Exercise brings on my fatigue",
    parent: "fss",
    order: 2,
    lang: "en",
    required: false,
  },
  {
    id: "fss_3",
    name: "Fatigue Severity Scale",
    type: "categoricalLong",
    value: category,
    labelStart: "Strongly disagree",
    labelEnd: "Strongly agree",
    description: "I am easily fatigued",
    parent: "fss",
    order: 3,
    lang: "en",
    required: false,
  },
  {
    id: "fss_4",
    name: "Fatigue Severity Scale",
    type: "categoricalLong",
    value: category,
    labelStart: "Strongly disagree",
    labelEnd: "Strongly agree",
    description: "Fatigue interferes with my physical functioning",
    parent: "fss",
    order: 4,
    lang: "en",
    required: false,
  },
  {
    id: "fss_5",
    name: "Fatigue Severity Scale",
    type: "categoricalLong",
    value: category,
    labelStart: "Strongly disagree",
    labelEnd: "Strongly agree",
    description: "Fatigue causes frequent problems for me",
    parent: "fss",
    order: 5,
    lang: "en",
    required: false,
  },
  {
    id: "fss_6",
    name: "Fatigue Severity Scale",
    type: "categoricalLong",
    value: category,
    labelStart: "Strongly disagree",
    labelEnd: "Strongly agree",
    description: "My fatigue prevents sustained physical functioning",
    parent: "fss",
    order: 6,
    lang: "en",
    required: false,
  },
  {
    id: "fss_7",
    name: "Fatigue Severity Scale",
    type: "categoricalLong",
    value: category,
    labelStart: "Strongly disagree",
    labelEnd: "Strongly agree",
    description: "Fatigue interferes with carrying out certain duties and responsibilities",
    parent: "fss",
    order: 7,
    lang: "en",
    required: false,
  },
  {
    id: "fss_8",
    name: "Fatigue Severity Scale",
    type: "categoricalLong",
    value: category,
    labelStart: "Strongly disagree",
    labelEnd: "Strongly agree",
    description: "Fatigue is among my three most disabling symptoms",
    parent: "fss",
    order: 8,
    lang: "en",
    required: false,
  },
  {
    id: "fss_9",
    name: "Fatigue Severity Scale",
    type: "categoricalLong",
    value: category,
    labelStart: "Strongly disagree",
    labelEnd: "Strongly agree",
    description: "Fatigue interferes with my work, family, or social life.",
    parent: "fss",
    order: 9,
    lang: "en",
    required: false,
  },
]
export default fssEN

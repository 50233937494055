import { MedicalQuestion } from "../../../../@types/medicalQuestion"

const generalDE: MedicalQuestion[] = [
  {
    id: "lang",
    name: "Language",
    type: "select",
    value: [
      { value: "german_germany$", label: "Deutsch (Deutschland)" },
      { value: "arabic", label: "Arabisch" },
      { value: "bengali", label: "Bengali" },
      { value: "chinese_mandarin_wu", label: "Chinesisch (mandarin, wu...)" },
      { value: "czech", label: "Tschechisch" },
      { value: "dutch", label: "Holländisch" },
      { value: "english_american", label: "Englisch (amerikanisch)" },
      { value: "english_british", label: "Englisch (britisch)" },
      { value: "french_france", label: "Französisch (Frankreich)" },
      { value: "french_africa", label: "Französisch (Afrikanisch)" },
      { value: "french_belgium", label: "Französisch (Belgisch)" },
      { value: "french_quebec", label: "Französisch (Quebec)" },
      { value: "french_switzerland", label: "Französisch (Schweiz)" },
      { value: "finnish", label: "Finnisch" },
      { value: "german_germany", label: "Deutsch (Deutschland)" },
      { value: "german_swiss", label: "Deutsch (Schweiz)" },
      { value: "greek", label: "Griechisch" },
      { value: "hindi", label: "Hindi" },
      { value: "hungarian", label: "Ungarisch" },
      { value: "italian", label: "Italienisch" },
      { value: "japanese", label: "Japanisch" },
      { value: "korean", label: "Koreanisch" },
      { value: "norwegian", label: "Norwegisch" },
      { value: "portuguese", label: "Portugiesisch" },
      { value: "polish", label: "Polnisch" },
      { value: "romanian", label: "Rumänisch" },
      { value: "russian", label: "Russisch" },
      { value: "spanish", label: "Spanisch" },
      { value: "swedish", label: "Schwedisch" },
      { value: "turkish", label: "Türkisch" },
      { value: "other", label: "Andere" },
    ],
    description: "Was ist Ihre Muttersprache?",
    parent: "general_info",
    order: 1,
    lang: "de",
    required: true,
  },
  {
    id: "age",
    name: "Age",
    type: "number",
    minValue: 1930,
    maxValue: 2010,
    description: "Geburtsjahr",
    parent: "general_info",
    order: 2,
    lang: "de",
    required: true,
  },
  {
    id: "sex",
    name: "Gender",
    type: "categoricalShort",
    value: [
      { label: "Männlich", value: "male" },
      { label: "Weiblich", value: "female" },
      { label: "Nicht-binär", value: "non-binary" },
    ],
    description: "Geschlecht",
    parent: "general_info",
    order: 3,
    lang: "de",
    required: true,
  },
  {
    id: "height",
    name: "Height",
    type: "number",
    minValue: 100,
    maxValue: 220,
    description: "Größe (cm)",
    parent: "general_info",
    order: 4,
    lang: "de",
    required: true,
  },
  {
    id: "weight",
    name: "Weight",
    type: "number",
    minValue: 40,
    maxValue: 200,
    description: "Gewicht (kg)",
    parent: "general_info",
    order: 5,
    lang: "de",
    required: true,
  },
  {
    id: "c_smo",
    name: "Current tobacco smoking",
    type: "categoricalShort",
    value: [
      { label: "Täglich", value: "daily" },
      { label: "Weniger als täglich", value: "less-than-daily" },
      { label: "Überhaupt nicht", value: "not-at-all" },
    ],
    description:
      "Sind Sie derzeit Raucher/in (Tabak oder andere Substanzen, in jeglicher Form, einschließlich " + "elektronischer Zigaretten) ?",
    parent: "general_info",
    order: 6,
    lang: "de",
    required: true,
  },
  {
    id: "c_smo_view",
    name: "Current smoking overview question",
    type: "headerText",
    headerStyle: "subtitle1",
    description: "Wie viel von jeder der unten aufgeführten Formen rauchen Sie im Durchschnitt ?",
    parent: "general_info",
    order: 7,
    lang: "de",
    dependsOn: {
      id: "c_smo",
      values: ["daily", "less-than-daily"],
    },
    required: false,
  },
  {
    id: "c_smo_c",
    name: "Current smoking cigarettes quantity",
    type: "number",
    minValue: 0,
    maxValue: 150,
    description: "Zigaretten pro Tag (zur Erinnerung: 1 Schachtel = 20 Zigaretten) ?",
    parent: "general_info",
    order: 8,
    lang: "de",
    dependsOn: {
      id: "c_smo",
      values: ["daily", "less-than-daily"],
    },
    required: false,
  },
  {
    id: "c_smo_cig",
    name: "Current smoking cigarettes quantity",
    type: "number",
    minValue: 0,
    maxValue: 100,
    description: "Zigarren pro Woche ?",
    parent: "general_info",
    order: 9,
    lang: "de",
    dependsOn: {
      id: "c_smo",
      values: ["daily", "less-than-daily"],
    },
    required: false,
  },
  {
    id: "c_smo_ril",
    name: "Current smoking cigarillos quantity",
    type: "number",
    minValue: 0,
    maxValue: 150,
    description: "Zigarillos pro Woche ?",
    parent: "general_info",
    order: 10,
    lang: "de",
    dependsOn: {
      id: "c_smo",
      values: ["daily", "less-than-daily"],
    },
    required: false,
  },
  {
    id: "c_smo_joi",
    name: "Current smoking joints quantity",
    type: "number",
    minValue: 0,
    maxValue: 150,
    description: "Joints pro Woche ?",
    parent: "general_info",
    order: 11,
    lang: "de",
    dependsOn: {
      id: "c_smo",
      values: ["daily", "less-than-daily"],
    },
    required: false,
  },
  {
    id: "c_smo_nar",
    name: "Current smoking water pipes quantity",
    type: "number",
    minValue: 0,
    maxValue: 10,
    description: "Wasserpfeife / Shisha pro Woche ?",
    parent: "general_info",
    order: 12,
    lang: "de",
    dependsOn: {
      id: "c_smo",
      values: ["daily", "less-than-daily"],
    },
    required: false,
  },
  {
    id: "c_smo_ele",
    name: "Current smoking electronic cigarettes quantity",
    type: "number",
    minValue: 0,
    maxValue: 5,
    description: "Elektronische Zigaretten, in 10mL-Flaschen E-Liquid pro Woche ?",
    parent: "general_info",
    order: 13,
    lang: "de",
    dependsOn: {
      id: "c_smo",
      values: ["daily", "less-than-daily"],
    },
    required: false,
  },
  {
    id: "c_dur",
    name: "Current duration smoking",
    type: "number",
    minValue: 1,
    maxValue: 60,
    description: "Seit wie vielen Jahren rauchen Sie ?",
    parent: "general_info",
    order: 14,
    lang: "de",
    dependsOn: {
      id: "c_smo",
      values: ["daily", "less-than-daily"],
    },
    required: false,
  },
  {
    id: "p_smo",
    name: "Past tobacco smoking",
    type: "categoricalShort",
    value: [
      { label: "Täglich", value: "daily" },
      { label: "Weniger als täglich", value: "less-than-daily" },
      { label: "Überhaupt nicht", value: "not-at-all" },
    ],
    description:
      "Haben Sie in der Vergangenheit geraucht (Tabak oder andere Substanzen, in jeglicher Form, " +
      "einschließlich elektronischer Zigaretten)?",
    parent: "general_info",
    order: 15,
    lang: "de",
    required: true,
  },
  {
    id: "p_smo_view",
    name: "Past smoking overview question",
    type: "headerText",
    headerStyle: "subtitle1",
    description: "Wie viel von jeder der folgenden Formen haben Sie damals durchschnittlich geraucht ?",
    parent: "general_info",
    order: 16,
    lang: "de",
    dependsOn: {
      id: "p_smo",
      values: ["daily", "less-than-daily"],
    },
    required: false,
  },
  {
    id: "p_smo_c",
    name: "Past smoking cigarettes quantity",
    type: "number",
    minValue: 0,
    maxValue: 150,
    description: "Zigaretten pro Tag (zur Erinnerung: 1 Schachtel = 20 Zigaretten) ?",
    parent: "general_info",
    order: 17,
    lang: "de",
    dependsOn: {
      id: "p_smo",
      values: ["daily", "less-than-daily"],
    },
    required: false,
  },
  {
    id: "p_smo_cig",
    name: "Past smoking cigarettes quantity",
    type: "number",
    minValue: 0,
    maxValue: 100,
    description: "Zigarren pro Woche ?",
    parent: "general_info",
    order: 18,
    lang: "de",
    dependsOn: {
      id: "p_smo",
      values: ["daily", "less-than-daily"],
    },
    required: false,
  },
  {
    id: "p_smo_ril",
    name: "Past smoking cigarillos quantity",
    type: "number",
    minValue: 0,
    maxValue: 150,
    description: "Zigarillos pro Woche ?",
    parent: "general_info",
    order: 19,
    lang: "de",
    dependsOn: {
      id: "p_smo",
      values: ["daily", "less-than-daily"],
    },
    required: false,
  },
  {
    id: "p_smo_joi",
    name: "Past smoking joints quantity",
    type: "number",
    minValue: 0,
    maxValue: 150,
    description: "Joints pro Woche geraucht ?",
    parent: "general_info",
    order: 20,
    lang: "de",
    dependsOn: {
      id: "p_smo",
      values: ["daily", "less-than-daily"],
    },
    required: false,
  },
  {
    id: "p_smo_nar",
    name: "Past smoking water pipes quantity",
    type: "number",
    minValue: 0,
    maxValue: 10,
    description: "Wasserpfeife / Shisha in Stunden pro Woche ?",
    parent: "general_info",
    order: 21,
    lang: "de",
    dependsOn: {
      id: "p_smo",
      values: ["daily", "less-than-daily"],
    },
    required: false,
  },
  {
    id: "p_smo_ele",
    name: "Past smoking electronic cigarettes quantity",
    type: "number",
    minValue: 0,
    maxValue: 5,
    description: "Elektronische Zigaretten, in 10mL-Flaschen E-Liquid pro Woche ?",
    parent: "general_info",
    order: 22,
    lang: "de",
    dependsOn: {
      id: "p_smo",
      values: ["daily", "less-than-daily"],
    },
    required: false,
  },
  {
    id: "p_dur",
    name: "Past duration smoking",
    type: "number",
    minValue: 1,
    maxValue: 60,
    description: "Wie viele Jahre lang haben Sie geraucht ?",
    parent: "general_info",
    order: 23,
    lang: "de",
    dependsOn: {
      id: "p_smo",
      values: ["daily", "less-than-daily"],
    },
    required: false,
  },
  {
    id: "p_dur_stop",
    name: "Past duration smoking stop",
    type: "number",
    minValue: 0,
    maxValue: 60,
    description: "Vor wie vielen Jahren haben Sie mit dem Rauchen aufgehört ?",
    parent: "general_info",
    order: 24,
    lang: "de",
    dependsOn: {
      id: "p_smo",
      values: ["daily", "less-than-daily"],
    },
    required: false,
  },
]

export default generalDE

import { Categorical, MedicalQuestion } from "../../../../@types/medicalQuestion"

const category: Categorical[] = [
  {
    label: "ja",
    value: "yes",
  },
  {
    label: "nein",
    value: "no",
  },
]

const cofoundingDE: MedicalQuestion[] = [
  {
    id: "preg",
    name: "Pregnancy to date",
    type: "categoricalShort",
    value: category,
    description: "Sind Sie zurzeit Schwanger ?",
    parent: "cofounding",
    order: 1,
    lang: "de",
    required: true,
  },
  {
    id: "preg_d",
    name: "Pregnancy month",
    type: "number",
    minValue: 1,
    maxValue: 9,
    description: "In welchem Schwangerschaftsmonat befinden Sie sich ?",
    parent: "cofounding",
    order: 2,
    lang: "de",
    dependsOn: {
      id: "preg",
      values: ["yes"],
    },
    required: false,
  },
  {
    id: "pain",
    name: "Pain scale",
    type: "categoricalLong",
    value: [
      { label: "0", value: "0" },
      { label: "1", value: "1" },
      { label: "2", value: "2" },
      { label: "3", value: "3" },
      { label: "4", value: "4" },
      { label: "5", value: "5" },
      { label: "6", value: "6" },
      { label: "7", value: "7" },
      { label: "8", value: "8" },
      { label: "9", value: "9" },
      { label: "10", value: "10" },
    ],
    labelStart: "Kein Schmerz",
    labelEnd: "Stärkste vorstellbare Schmerzen",
    description: "Wie stark sind augenblicklich Ihre Schmerzen? (von 0=Kein Schmerz bis 10=Stärkste vorstellbare Schmerzen)",
    parent: "cofounding",
    order: 3,
    lang: "de",
    required: false,
  },
  {
    id: "stress",
    name: "Stress scale",
    type: "categoricalLong",
    value: [
      { label: "0", value: "0" },
      { label: "1", value: "1" },
      { label: "2", value: "2" },
      { label: "3", value: "3" },
      { label: "4", value: "4" },
      { label: "5", value: "5" },
      { label: "6", value: "6" },
      { label: "7", value: "7" },
      { label: "8", value: "8" },
      { label: "9", value: "9" },
      { label: "10", value: "10" },
    ],
    labelStart: "Kein Stress",
    labelEnd: "Stärkste vorstellbare Stress",
    description: "Wie stark ist augenblicklich Ihr Stress? (von 0=Kein Stress bis 10=Stärkster vorstellbarer Stress)",
    parent: "cofounding",
    order: 4,
    lang: "de",
    required: false,
  },
  {
    id: "depr",
    name: "Depression",
    type: "categoricalShort",
    value: category,
    description: "Wurde bei Ihnen eine Depression diagnostiziert ?",
    parent: "cofounding",
    order: 5,
    lang: "de",
    required: true,
  },
  {
    id: "anx",
    name: "Generalized Anxiety Disorder question",
    type: "categoricalShort",
    value: category,
    description: "Wurde bei Ihnen eine generalisierte Angststörung diagnostiziert ?",
    parent: "cofounding",
    order: 6,
    lang: "de",
    required: true,
  },
  {
    id: "schiz",
    name: "Schizophrenia diagnosis",
    type: "categoricalShort",
    value: category,
    description: "Wurde bei Ihnen Schizophrenie diagnostiziert ?",
    parent: "cofounding",
    order: 7,
    lang: "de",
    required: true,
  },
  {
    id: "bipol",
    name: "Bipolar disorder diagnosis",
    type: "categoricalShort",
    value: category,
    description: "Wurde bei Ihnen eine bipolare Störung diagnostiziert ?",
    parent: "cofounding",
    order: 8,
    lang: "de",
    required: true,
  },
  {
    id: "copd",
    name: "Chronic obstructive pulmonary disease",
    type: "categoricalShort",
    value: category,
    description: "Wurde bei Ihnen eine Chronisch obstruktive Lungenerkrankung (COPD) diagnostiziert ?",
    parent: "cofounding",
    order: 9,
    lang: "de",
    required: true,
  },
  {
    id: "asth",
    name: "Asthma",
    type: "categoricalShort",
    value: category,
    description: "Wurde bei Ihnen Asthma diagnostiziert ?",
    parent: "cofounding",
    order: 10,
    lang: "de",
    required: true,
  },
  {
    id: "cov19",
    name: "covid-19",
    type: "categoricalShort",
    value: category,
    description: "Wurde bei Ihnen in den letzten drei Monaten COVID-19 diagnostiziert ?",
    parent: "cofounding",
    order: 11,
    lang: "de",
    required: true,
  },
  {
    id: "l_cov19",
    name: "Long covid-19",
    type: "categoricalShort",
    value: category,
    description: "Haben Sie anhaltende Covid-19-Symptome (Long-Covid) ?",
    parent: "cofounding",
    order: 12,
    lang: "de",
    required: true,
  },
  {
    id: "infect_dis",
    name: "Infectious disease",
    type: "multi",
    value: [
      { label: "Grippe", value: "influenza" },
      { label: "Lungenentzündung", value: "pneumonia" },
      { label: "Bronchitis", value: "bronchitis" },
      { label: "Andere", value: "other" },
    ],
    description: "Wurde bei Ihnen in den letzten 3 Monaten eine andere Infektionskrankheit der Atemwege diagnostiziert ?",
    parent: "cofounding",
    order: 13,
    lang: "de",
    required: false,
  },
  {
    id: "allergie_resp",
    name: "Allergie respiratoire",
    type: "categoricalShort",
    value: category,
    description: "Wurde bei Ihnen eine Atemwegsallergie diagnostiziert ?",
    parent: "cofounding",
    order: 14,
    lang: "de",
    required: true,
  },
  {
    id: "fib_pul",
    name: "Fibrose pulmonaire",
    type: "categoricalShort",
    value: category,
    description: "Wurde bei Ihnen Lungenfibrose diagnostiziert ?",
    parent: "cofounding",
    order: 15,
    lang: "de",
    required: true,
  },
  {
    id: "comorb",
    name: "Comorbidities",
    type: "multi",
    value: [
      { label: "Infarkt/Herzinfarkt", value: "infarction/heart-attack" },
      { label: "Kongestive Herzinsuffizienz", value: "congestive-heart-failure" },
      { label: "Koronare Herzerkrankung", value: "coronary-heart-disease" },
      { label: "Angina pectoris", value: "angina-pectoris" },
      { label: "Hypertonie (Bluthochdruck)", value: "hypertension" },
      { label: "Diabetes", value: "diabetes" },
      { label: "Schilddrüsenerkrankung", value: "thyroidic-disease" },
      { label: "Chronisches Nierenleiden", value: "chronic-kidney-disease" },
      { label: "Allergie (z. B. Milben, Pollen, Lebensmittel, Haustiere usw.)", value: "allergy" },
      { label: "Emphysem", value: "emphysema" },
      { label: "Malaria", value: "malaria" },
      { label: "Tuberkulose", value: "tuberculosis" },
      { label: "Zika", value: "zika" },
      { label: "Gicht", value: "gout" },
      { label: "Hepatitis", value: "hepatitis" },
      { label: "Morbus Crohn", value: "crohn-disease" },
      { label: "Ulzeröse Kolitis", value: "ulcerative-colitis" },
      { label: "Rheumatoide Arthritis oder systemischer Lupus (SLE)", value: "rheumatoid arthritis or systemic lupus (sle)" },
      { label: "Multiple Sklerose", value: "multiple-sclerosis" },
      { label: "Epilepsie", value: "epilepsy" },
      { label: "Amyotropher Lat. Sklerose (ALS)", value: "amyotrophic-lat-sclerosis" },
      { label: "Narkolepsie", value: "narcolepsy" },
      { label: "Parkinson-Krankheit", value: "parkinson-disease" },
      { label: "Schlaganfall", value: "stroke" },
      { label: "Migräne", value: "migraine" },
      { label: "Kehlkopfentzündung (Laryngitis)", value: "laryngitis" },
      { label: "Nicht kanzeröse Stimmbandläsion (Polyp, Knötchen, Zyste)", value: "non-cancerous-vocal-cord-lesion" },
      { label: "Kehlkopf-Reflux", value: "laryngopharyngeal-reflux" },
      { label: "Leukoplakie", value: "leukoplakia" },
    ],
    description: "Wurden bei Ihnen jemals eine oder mehrere der folgenden Krankheiten diagnostiziert ?",
    parent: "cofounding",
    order: 16,
    lang: "de",
    required: false,
  },
  {
    id: "med",
    name: "Medication",
    type: "multi",
    value: [
      { label: "Paracetamol", value: "paracetamol" },
      { label: "Ibuprofen", value: "ibuprofen" },
      { label: "Kodein", value: "codeine" },
      { label: "Morphin oder von Morphin abgeleitete Arzneimittel", value: "morphine-or-morphine-derived-drugs" },
      { label: "Medikamente gegen Cholesterin", value: "medication-for-cholesterol" },
      { label: "Medikamente (Tabletten oder Insulin) für Diabetes", value: "medication-diabetes" },
      { label: "Medikamente gegen Bluthochdruck", value: "medication-hypertension" },
      {
        label: "Arzneimittel auf der Grundlage des Angiotensin-konvertierenden Enzyms (ACE)",
        value: "angiotensin-converting-enzyme-medications",
      },
      { label: "Antibiotika", value: "antibiotics" },
      { label: "Medikamente zur Behandlung von Angstzuständen oder Depressionen", value: "medication-anxiety-or-depression" },
      { label: "Anti-Reflux-Medikamente", value: "anti-reflux-medication" },
      { label: "Arzneimittel gegen Schilddrüsenprobleme", value: "treatment-thyroid" },
      {
        label: "Kortikosteroide wie Prednison oder Budesonid (Entocort EC)",
        value: "corticosteroids",
      },
      { label: "Muskelrelaxantien", value: "muscle-relaxants" },
      { label: "Abschwellende Mittel", value: "decongestants" },
      { label: "Diuretika", value: "diuretics" },
      { label: "Antidiarrhoika (z. B. Lsoperamid (Imodium A-D))", value: "antidiarrheal-agents" },
      { label: "Antihistaminika", value: "antihistamines" },
      { label: "Anticholinergika", value: "anticholinergics" },
      {
        label: "Hohe Dosen von Vitamin C (mehr als fünf Gramm pro Tag)",
        value: "high-doses-of-vitamin-c",
      },
      { label: "Antikoagulanzien", value: "anticoagulants" },
      { label: "Medikamente gegen Halsschmerzen", value: "medication-throatpain" },
      { label: "Hormonbehandlung (Verhütung,Menopause...)", value: "hormonal-treatment" },
    ],
    description: "Nehmen Sie derzeit mindestens 3 Mal pro Woche eines der folgenden Medikamente ein ?",
    parent: "cofounding",
    order: 17,
    lang: "de",
    required: false,
  },
]

export default cofoundingDE

import consents from "./consent"
import tasksRecording from "./tasks"
import { Project } from "../../@types/project"
import cofounding from "./questions/cofounding"
import general from "./questions/general"
import PHQ from "./questions/phq"
import GAD from "./questions/gad"
import fss from "./questions/fss"

const project: Project = {
  projectID: "21c7f674-41f0-4904-ab4b-a2c73ddb7919",
  projectName: "Speakuity MH (Demo)",
  projectLocation: "Demo",
  projectCreated: "03/07/2023",
  projectType: "oneshot",
  defaultLang: "fr",
  projectLang: ["en", "fr", "de"],
  voiceRecordings: tasksRecording,
  questionnaires: [
    { questionnaire: general, optional: false },
    { questionnaire: PHQ, optional: false },
    { questionnaire: GAD, optional: false },
    { questionnaire: fss, optional: false },
    { questionnaire: cofounding, optional: false },
  ],
  diseaseQuestions: [],
  consents: consents,
  isPatientAnsweringDiseaseQuestions: false,
  showOptionToStartWithNonDefaultTasks: true,
  isStartWithQuestionnaires: false,
  isAskPhoneNumber: false,
  showQuestionnaireIdFinalPage: true,
  showVocalTaskInstructions: false,
  showResult: false,
  showAdmin: true,
  versionData: "1",
  transparenceID: "77kjk9",
}

export default project

import { createContext, ReactNode, useState } from "react"
import { AuthContextType, LoginDTO, Token } from "../@types/auth"
import useLocalStorage from "../hooks/useLocalStorage"
import axios from "axios"
import { getAuthorized } from "../fetchers/fetchers"

const AuthContext = createContext<AuthContextType | null>(null)

type AuthProviderProps = {
  children: ReactNode
}

const AuthProvider = ({ children }: AuthProviderProps) => {
  const [token, setToken] = useLocalStorage<Token | null>("akuity-token", null)
  const [isAuthenticated, setIsAuthenticated] = useLocalStorage<boolean>("akuity-authenticated", false)
  const [authError, setAuthError] = useState<string>("")
  const [projectId, setProjectId] = useLocalStorage<string>("akuity-project-id", "")

  const login = async (params: LoginDTO) => {
    const formData = new FormData()
    formData.append("username", params.username)
    formData.append("password", params.password)
    setAuthError("")
    return await axios
      .post<Token>(process.env.REACT_APP_BACKEND_PATH_LOGIN, formData)
      .then((response) => {
        if (response.data) {
          setToken(response.data)
          setIsAuthenticated(true)
          setAuthError("")
          getAuthorized<string>(process.env.REACT_APP_BACKEND_PATH_PROJECT, response.data.access_token).then((response) => {
            setProjectId(response)
          })
        }
      })
      .catch((err) => {
        if (err.response && err.response.data && err.response.data.detail) {
          setAuthError(err.response.data.detail)
        }
      })
  }

  const logout = async () => {
    setToken(null)
    setIsAuthenticated(false)
  }

  return <AuthContext.Provider value={{ isAuthenticated, authError, token, login, logout, projectId }}>{children}</AuthContext.Provider>
}

export { AuthContext, AuthProvider }

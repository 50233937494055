import { useLocation, Outlet } from "react-router-dom"
// @mui
import { Stack } from "@mui/material"
// components
//
import MainHeader from "./MainHeader"
import { styled } from "@mui/material/styles"

// ----------------------------------------------------------------------

export default function MainLayout() {
  const { pathname } = useLocation()

  const RootStyle = styled("div")(({ theme }) => ({
    paddingTop: theme.spacing(8),
    [theme.breakpoints.up("md")]: {
      paddingTop: theme.spacing(11),
    },
  }))
  return (
    <Stack sx={{ minHeight: 1 }}>
      <MainHeader />
      <RootStyle>
        <Outlet />
      </RootStyle>
    </Stack>
  )
}

import { Categorical, MedicalQuestion } from "../../../../@types/medicalQuestion"

const category: Categorical[] = [
  {
    label: "Not at all",
    value: "not-at-all",
  },
  {
    label: "A little",
    value: "a-little",
  },
  {
    label: "Moderately",
    value: "moderately",
  },
  {
    label: "A lot",
    value: "a-lot",
  },
  {
    label: "Extremely",
    value: "extremely",
  },
]

const vqEN: MedicalQuestion[] = [
  {
    id: "vq_intro",
    name: "intro",
    value:
      "The following sentences express feelings about the consequences of a respiratory disease. For each sentence, tick the intensity" +
      " that best reflects your feeling at this moment (from “not at all” to “extremely”). There are no wrong answers. " +
      "Each one is personal.",
    type: "intro",
    lang: "en",
    parent: "vq",
    required: false,
  },
  {
    id: "vq_1",
    name: "Respiratory quality of life VQ11",
    type: "categoricalShort",
    value: category,
    description: "I suffer from breathlessness",
    parent: "vq",
    order: 1,
    lang: "en",
    required: true,
  },
  {
    id: "vq_2",
    name: "Respiratory quality of life VQ11",
    type: "categoricalShort",
    value: category,
    description: "I am worried about my respiratory condition",
    parent: "vq",
    order: 2,
    lang: "en",
    required: true,
  },
  {
    id: "vq_3",
    name: "Respiratory quality of life VQ11",
    type: "categoricalShort",
    value: category,
    description: "I feel my entourage (family, friends, etc.) misunderstands me",
    parent: "vq",
    order: 3,
    lang: "en",
    required: true,
  },
  {
    id: "vq_4",
    name: "Respiratory quality of life VQ11",
    type: "categoricalShort",
    value: category,
    description: "My respiratory condition prevents me from moving about as easily as I would like",
    parent: "vq",
    order: 4,
    lang: "en",
    required: true,
  },
  {
    id: "vq_5",
    name: "Respiratory quality of life VQ11",
    type: "categoricalShort",
    value: category,
    description: "I feel sleepy during the day",
    parent: "vq",
    order: 5,
    lang: "en",
    required: true,
  },
  {
    id: "vq_6",
    name: "Respiratory quality of life VQ11",
    type: "categoricalShort",
    value: category,
    description: "I feel unable to achieve my objectives",
    parent: "vq",
    order: 6,
    lang: "en",
    required: true,
  },
  {
    id: "vq_7",
    name: "Respiratory quality of life VQ11",
    type: "categoricalShort",
    value: category,
    description: "I quickly get tired when doing day-to-day activities",
    parent: "vq",
    order: 7,
    lang: "en",
    required: true,
  },
  {
    id: "vq_8",
    name: "Respiratory quality of life VQ11",
    type: "categoricalShort",
    value: category,
    description: "Physically, I am dissatisfied with what I can do",
    parent: "vq",
    order: 8,
    lang: "en",
    required: true,
  },
  {
    id: "vq_9",
    name: "Respiratory quality of life VQ11",
    type: "categoricalShort",
    value: category,
    description: "My respiratory disease disrupts my social life",
    parent: "vq",
    order: 9,
    lang: "en",
    required: true,
  },
  {
    id: "vq_10",
    name: "Respiratory quality of life VQ11",
    type: "categoricalShort",
    value: category,
    description: "I feel sad",
    parent: "vq",
    order: 10,
    lang: "en",
    required: true,
  },
  {
    id: "vq_11",
    name: "Respiratory quality of life VQ11",
    type: "categoricalShort",
    value: category,
    description: "My respiratory condition restricts my emotional life",
    parent: "vq",
    order: 11,
    lang: "en",
    required: true,
  },
]

export default vqEN

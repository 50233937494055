import * as React from "react"
import Table from "@mui/material/Table"
import TableBody from "@mui/material/TableBody"
import TableCell from "@mui/material/TableCell"
import TableContainer from "@mui/material/TableContainer"
import TableHead from "@mui/material/TableHead"
import TableRow from "@mui/material/TableRow"
import Paper from "@mui/material/Paper"
import { Feature } from "../@types/patient"

interface BasicTableProps {
  features: Feature[]
}

export default function BasicTable(features: BasicTableProps) {
  return (
    <TableContainer component={Paper} sx={{ maxHeight: 200 }}>
      <Table sx={{ minWidth: 300 }} size="small" stickyHeader={true} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>Feature</TableCell>
            <TableCell align="right">Value</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {features.features.map((row: Feature) => (
            <TableRow key={row.name} sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
              <TableCell component="th" scope="row" sx={{ fontSize: 12 }}>
                {row.name}
              </TableCell>
              <TableCell align="right" sx={{ fontSize: 12 }}>
                {Number(row.value) <= 0.0001
                  ? Number(row.value).toFixed(2)
                  : Number(Number(row.value) * (1 + Math.random() / 2)).toFixed(2)}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  )
}

import React from "react"
import ThemeProvider from "./theme"
import ScrollToTop from "./components/ScrollToTop"
import Router from "./routes"
import { MotionLazyContainer } from "./components/animate"
import { AuthProvider } from "./context/Auth"

function App() {
  return (
    <AuthProvider>
      <MotionLazyContainer>
        <ThemeProvider>
          <ScrollToTop />
          <Router />
        </ThemeProvider>
      </MotionLazyContainer>
    </AuthProvider>
  )
}

export default App

import { Categorical, MedicalQuestion } from "../../../../@types/medicalQuestion"

const category: Categorical[] = [
  {
    label: "Überhaupt nicht",
    value: "not-at-all",
  },
  {
    label: "An einzelnen Tagen",
    value: "several-days",
  },
  {
    label: "An mehr als der Hälfte der Tage",
    value: "more-than-half-the-days",
  },
  {
    label: "Beinahe jeden Tag",
    value: "nearly-every-day",
  },
]

const phqDE: MedicalQuestion[] = [
  {
    id: "phq_intro",
    name: "intro",
    value: "Wie oft fühlten Sie sich im Verlauf der letzten 2 Wochen durch die folgenden Beschwerden beeinträchtigt ?",
    type: "intro",
    lang: "de",
    parent: "phq",
    required: false,
  },
  {
    id: "phq_1",
    name: "Patient Health Questionnaire",
    type: "categoricalShort",
    value: category,
    description: "Wenig Interesse oder Freude an Ihren Tätigkeiten",
    parent: "phq",
    order: 1,
    lang: "de",
    required: true,
  },
  {
    id: "phq_2",
    name: "Patient Health Questionnaire",
    type: "categoricalShort",
    value: category,
    description: "Niedergeschlagenheit, Schwermut oder Hoffnungslosigkeit.",
    parent: "phq",
    order: 2,
    lang: "de",
    required: true,
  },
  {
    id: "phq_3",
    name: "Patient Health Questionnaire",
    type: "categoricalShort",
    value: category,
    description: "Schwierigkeiten ein- oder durchzuschlafen oder vermehrter Schlaf",
    parent: "phq",
    order: 3,
    lang: "de",
    required: true,
  },
  {
    id: "phq_4",
    name: "Patient Health Questionnaire",
    type: "categoricalShort",
    value: category,
    description: "Müdigkeit oder Gefühl, keine Energie zu haben",
    parent: "phq",
    order: 4,
    lang: "de",
    required: true,
  },
  {
    id: "phq_5",
    name: "Patient Health Questionnaire",
    type: "categoricalShort",
    value: category,
    description: "Verminderter Appetit oder übermäßiges Bedürfnis zu essen",
    parent: "phq",
    order: 5,
    lang: "de",
    required: true,
  },
  {
    id: "phq_6",
    name: "Patient Health Questionnaire",
    type: "categoricalShort",
    value: category,
    description: "Schlechte Meinung von sich selbst; Gefühl, ein Versager zu sein oder die Familie enttäuscht zu haben",
    parent: "phq",
    order: 6,
    lang: "de",
    required: true,
  },
  {
    id: "phq_7",
    name: "Patient Health Questionnaire",
    type: "categoricalShort",
    value: category,
    description: "Schwierigkeiten, sich auf etwas zu konzentrieren, z. B. beim Zeitunglesen oder Fernsehen",
    parent: "phq",
    order: 7,
    lang: "de",
    required: true,
  },
  {
    id: "phq_8",
    name: "Patient Health Questionnaire",
    type: "categoricalShort",
    value: category,
    description:
      "Waren Ihre Bewegungen oder Ihre Sprache so verlangsamt, dass es auch anderen auffallen würde? Oder waren Sie " +
      "im Gegenteil „zappelig“ oder ruhelos und hatten dadurch einen stärkeren Bewegungsdrang als sonst ?",
    parent: "phq",
    order: 8,
    lang: "de",
    required: true,
  },
  {
    id: "phq_9",
    name: "Patient Health Questionnaire",
    type: "categoricalShort",
    value: category,
    description: "Gedanken, dass Sie lieber tot wären oder sich Leid zufügen möchten",
    parent: "phq",
    order: 9,
    lang: "de",
    required: true,
  },
]

export default phqDE

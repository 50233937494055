import { Consent } from "../../../@types/consent"
/* eslint-disable */
const consentDE: Consent = {
  lang: "de",
  text: `
<br><h2 class="code-line" data-line-start=0 data-line-end=1 ><a id="SPEAKUITY_MH2_0"></a>SPEAKUITY MH2</h2>
<p class="has-line-data" data-line-start="1" data-line-end="3"> <br>
Sammlung von Stimmdaten und Gesundheitsdaten zur Entwicklung stimmlicher Biomarker* im Zusammenhang mit physiopsychologischem Stress</p>
<p class="has-line-data" data-line-start="4" data-line-end="6">* Wörter oder Wortgruppen, die mit einem Sternchen gekennzeichnet sind, sind im Glossar enthalten.<br>
 </p>
<br><h4 class="code-line" data-line-start=6 data-line-end=7 ><a id="Ihre_Kontakte_6"></a>Ihre Kontakte:</h4>
<hr>
<br>
<p class="has-line-data" data-line-start="8" data-line-end="9"><strong>Sponsor</strong>* der Studie und Verantwortlicher für die Datenverarbeitung:</p>
<blockquote>
<p class="has-line-data" data-line-start="9" data-line-end="13">AKUITY CARE<br>
23 rue Léon Jost 75017 Paris<br>
+ 33 6 10 69 47 54<br>
<a href="mailto:contact@akuity.care">contact@akuity.care</a></p>
</blockquote>
<br>
<p class="has-line-data" data-line-start="14" data-line-end="15"><strong>Investigator* Koordinator</strong> der Studie:</p>
<blockquote>
<p class="has-line-data" data-line-start="15" data-line-end="19">Dr. Guy Fagherazzi<br>
Luxemburgisches Institut für Gesundheit<br>
+ 33 1 55 25 01 90<br>
<a href="mailto:guy.fagherazzi@lih.lu">guy.fagherazzi@lih.lu</a></p>
</blockquote>
<br>
<p class="has-line-data" data-line-start="20" data-line-end="21">Datenschutzbeauftragter <strong>(DPO/DSB)</strong>* des Sponsors:</p>
<blockquote>
<p class="has-line-data" data-line-start="21" data-line-end="23">Jean-Marie Nazarenko<br>
<a href="mailto:dpo@akuity.care">dpo@akuity.care</a></p>
</blockquote>
<br>
<p class="has-line-data" data-line-start="25" data-line-end="26">CNIL- Commission nationale de l’Informatique et des libertés:</p>
<blockquote>
<p class="has-line-data" data-line-start="26" data-line-end="29">CNIL / Beschwerdestelle<br>
3 Place de Fontenoy TSA 80715<br>
75334 PARIS CEDEX 07</p>
</blockquote>
<hr>
<p class="has-line-data" data-line-start="31" data-line-end="32"> </p>
<br><h4 class="code-line" data-line-start=32 data-line-end=33 ><a id="Knnen_Sie_an_dieser_Studie_teilnehmen_32"></a>Können Sie an dieser Studie teilnehmen?</h4>
<p class="has-line-data" data-line-start="34" data-line-end="35">Um an dieser Studie teilnehmen zu können, müssen Sie alle folgenden Bedingungen erfüllen:</p>
<ul>
<li class="has-line-data" data-line-start="35" data-line-end="36">volljährig sein (&gt;=18 Jahre)</li>
<li class="has-line-data" data-line-start="36" data-line-end="37">ein internetfähiges Endgerät mit Mikrofon (Smartphone, Tablet oder Computer) verwenden, um die digitalen Fragebögen auszufüllen und zu versenden und die Stimme zu erfassen.</li>
<li class="has-line-data" data-line-start="37" data-line-end="38">eine der folgenden Sprachen fließend sprechen: Französisch, Deutsch, Englisch oder Spanisch.</li>
<li class="has-line-data" data-line-start="38" data-line-end="39">nicht unter Freiheitsentzug, Vormundschaft oder Pflegschaft stehen</li>
<li class="has-line-data" data-line-start="39" data-line-end="40">ausreichende Französisch-, Deutsch-, Englisch-, Spanisch- oder Lesekenntnisse besitzen, um einen Text von wenigen Zeilen zu lesen oder einfache Fragebögen in diesen Sprachen zu beantworten.</li>
<li class="has-line-data" data-line-start="40" data-line-end="41">keine schweren Sprachschwierigkeiten oder eine stark beeinträchtigte Sprechfähigkeit haben</li>
</ul>
<p class="has-line-data" data-line-start="43" data-line-end="44"> </p>
<br><br><h4 class="code-line" data-line-start=44 data-line-end=45 ><a id="Inhaltsverzeichnis__44"></a>Inhaltsverzeichnis :</h4>
<table class="table table-striped table-bordered">
<thead>
<tr>
<th></th>
<th style="text-align:right">Seite</th>
</tr>
</thead>
<tbody>
<tr>
<td><strong>TEIL 1: INFORMATIONEN ÜBER DIE STUDIE</strong></td>
<td style="text-align:right">3</td>
</tr>
<tr>
<td>Warum wird diese Studie eingerichtet?</td>
<td style="text-align:right">3</td>
</tr>
<tr>
<td>Worin besteht die Studie?</td>
<td style="text-align:right">3</td>
</tr>
<tr>
<td>Wie wird die Studie durchgeführt?</td>
<td style="text-align:right">4</td>
</tr>
<tr>
<td>Welche erwarteten Vorteile und Risiken sind mit der Studie verbunden?</td>
<td style="text-align:right">4</td>
</tr>
<tr>
<td>Welche Entschädigungen sind vorgesehen?</td>
<td style="text-align:right">4</td>
</tr>
<tr>
<td>Was passiert, wenn die Studie vorzeitig abgebrochen wird, und was passiert nach der Studie?</td>
<td style="text-align:right">4</td>
</tr>
<tr>
<td>Wie lange werden Ihre Daten aufbewahrt?</td>
<td style="text-align:right">5</td>
</tr>
<tr>
<td>Gesetzliche und regulatorische Bestimmungen</td>
<td style="text-align:right">5</td>
</tr>
<tr>
<td><strong>TEIL 2: INFORMATIONEN ÜBER DIE RECHTE DES TEILNEHMERS UND DEN UMGANG MIT DEN GESAMMELTEN DATEN</strong></td>
<td style="text-align:right">6</td>
</tr>
<tr>
<td>Welche Rechte haben Sie?</td>
<td style="text-align:right">6</td>
</tr>
<tr>
<td>Wie werden Ihre persönlichen Daten* im Rahmen der Studie verarbeitet?</td>
<td style="text-align:right">6</td>
</tr>
<tr>
<td>Was ist die Rechtsgrundlage und der Zweck der Verarbeitung Ihrer personenbezogenen Daten?</td>
<td style="text-align:right">7</td>
</tr>
<tr>
<td>Wie wird die Vertraulichkeit Ihrer Daten gewährleistet?</td>
<td style="text-align:right">8</td>
</tr>
<tr>
<td>Wer hat im Rahmen der Studie Zugriff auf Ihre Daten?</td>
<td style="text-align:right">8</td>
</tr>
<tr>
<td>Welche Rechte haben Sie in Bezug auf Ihre personenbezogenen Daten?</td>
<td style="text-align:right">9</td>
</tr>
<tr>
<td>Wie können Sie Ihre Rechte ausüben?</td>
<td style="text-align:right">9</td>
</tr>
<tr>
<td>Können Ihre verschlüsselten Daten weiterverwendet werden?</td>
<td style="text-align:right">9</td>
</tr>
<tr>
<td><strong>TEIL 3: GLOSSAR</strong></td>
<td style="text-align:right">11</td>
</tr>
</tbody>
</table>
<p class="has-line-data" data-line-start="67" data-line-end="68"> </p>
<br><br><h2 class="code-line" data-line-start=69 data-line-end=70 ><a id="TEIL_1_INFORMATIONEN_BER_DIE_RECHERCHE_69"></a>TEIL 1: INFORMATIONEN ÜBER DIE RECHERCHE</h2>
<br>
<p class="has-line-data" data-line-start="71" data-line-end="72">Sehr geehrte Frau, sehr geehrter Herr,</p>
<p class="has-line-data" data-line-start="73" data-line-end="74">Im Rahmen Ihrer Nutzung der voqia-App oder der Website <a href="https://www.yourvoice.care/">https://www.yourvoice.care/</a> werden Sie aufgefordert, an einem Forschungsprojekt teilzunehmen, dessen Ziel es ist, Daten zu sammeln, um stimmliche Biomarker* in Bezug auf physio-psychologische Notlagen zu entwickeln.</p>
<p class="has-line-data" data-line-start="75" data-line-end="76">Es steht Ihnen frei, daran teilzunehmen oder nicht. Sie können sich die nötige Zeit nehmen, um die untenstehenden Informationen zu lesen, mit Ihren Angehörigen zu sprechen und alle Ihre Fragen an die Kontaktstelle zu richten, die die Studie koordiniert, den sogenannten Prüfer*. Nachdem Ihre Fragen zufriedenstellend beantwortet wurden und Sie genügend Bedenkzeit hatten, können Sie dann entscheiden, ob Sie an der Studie teilnehmen möchten oder nicht.</p>
<p class="has-line-data" data-line-start="77" data-line-end="78"> </p>
<br><h3 class="code-line" data-line-start=78 data-line-end=79 ><a id="Warum_wird_diese_Studie_eingerichtet_78"></a>Warum wird diese Studie eingerichtet?</h5>
<p class="has-line-data" data-line-start="80" data-line-end="82">AKUITY CARE, der Sponsor* dieser Studie, plant die Entwicklung einer KI-Anwendung (Künstliche Intelligenz*), die es ermöglicht, anhand einer Stimmanalyse bestimmte Parameter zu bewerten, die als Marker für physio-psychologische Notlagen definiert werden.<br>
Die KI-Algorithmen*, die letztendlich in eine solche Anwendung integriert würden, werden als <strong>Stimm-Biomarker</strong>* bezeichnet. Sie geben eine Bewertung eines bestimmten Zustands anhand einer Stimmprobe ab.</p>
<p class="has-line-data" data-line-start="83" data-line-end="84">Um sie zu entwickeln, müssen sie trainiert werden, indem man ihnen eine große Anzahl von Malen :</p>
<ul>
<li class="has-line-data" data-line-start="84" data-line-end="85">einerseits Daten über den Zustand von Individuen,</li>
<li class="has-line-data" data-line-start="85" data-line-end="87">andererseits Proben ihrer Stimme.</li>
</ul>
<p class="has-line-data" data-line-start="87" data-line-end="88">Indem wir ihnen die Antwort geben, die von ihnen erwartet wird, bringen wir einem Algorithmus nach und nach bei, diese Antwort selbst zu finden, d. h. den Zustand einer Person in Bezug auf die oben definierten Parameter der physisch-psychologischen Notlage allein aus ihrer Stimme abzuleiten.</p>
<p class="has-line-data" data-line-start="89" data-line-end="90">Ziel dieser Forschung ist es, von Ihnen und anderen Nutzern der voqia-App und der Website <a href="https://www.yourvoice.care/">https://www.yourvoice.care/</a> Daten zu sammeln, um Sprachbiomarker* zu entwickeln und zu trainieren.</p>
<p class="has-line-data" data-line-start="91" data-line-end="92"> </p>
<br><h3 class="code-line" data-line-start=92 data-line-end=93 ><a id="Worin_besteht_die_Recherche_92"></a>Worin besteht die Recherche?</h5>
<p class="has-line-data" data-line-start="94" data-line-end="95">Diese Studie zielt auf mehrere Parameter im Zusammenhang mit physio-psychologischer Belastung ab:</p>
<ul>
<li class="has-line-data" data-line-start="96" data-line-end="97">depressive Symptome</li>
<li class="has-line-data" data-line-start="97" data-line-end="98">Angststörungen</li>
<li class="has-line-data" data-line-start="98" data-line-end="99">Erschöpfungssymptome</li>
<li class="has-line-data" data-line-start="99" data-line-end="100">Stress</li>
<li class="has-line-data" data-line-start="100" data-line-end="101">Schmerzen</li>
</ul>
<p class="has-line-data" data-line-start="103" data-line-end="104">Die Studie wird zwei Jahre dauern und soll die Teilnahme von mehreren Tausend Nutzern der voqia-App ermöglichen und der Internet-Website <a href="https://www.yourvoice.care/">https://www.yourvoice.care/</a>.</p>
<p class="has-line-data" data-line-start="105" data-line-end="106">Sie wird vom Sponsor* finanziert.</p>
<p class="has-line-data" data-line-start="107" data-line-end="108"> </p>
<br><h3 class="code-line" data-line-start=109 data-line-end=110 ><a id="Wie_wird_die_Studie_durchgefhrt_109"></a>Wie wird die Studie durchgeführt?</h5>
<p class="has-line-data" data-line-start="111" data-line-end="112">Ihre Teilnahme an der Studie besteht darin, dass Sie, sofern Sie sich damit einverstanden erklären, die Daten, auf die die Studie abzielt, bereitstellen, d. h. :</p>
<ul>
<li class="has-line-data" data-line-start="112" data-line-end="113"><strong>Informationen über Ihr Profil, Ihre Gewohnheiten und Ihren Gesundheitszustand:</strong> Sie stellen diese Daten zur Verfügung, indem Sie Fragebögen in der voqia-Anwendung beantworten, entweder wenn Sie die Anwendung bei der ersten Verwendung kennenlernen oder wenn Sie die Anwendung in der Folge verwenden, oder auf der Website <a href="https://www.yourvoice.care/">https://www.yourvoice.care/</a>, wenn Sie über diesen Kanal an der Studie teilnehmen.</li>
<li class="has-line-data" data-line-start="113" data-line-end="115"><strong>Stimmproben:</strong> Sie stellen diese bereit, indem Sie die Funktionen “Journaling” und “Entspannung” der voqia-App über das verbundene Gerät nutzen, auf dem Sie die App verwenden, oder auf der Website <a href="https://www.yourvoice.care/">https://www.yourvoice.care/</a>, wenn Sie über diesen Kanal an der Suche teilnehmen.</li>
</ul>
<p class="has-line-data" data-line-start="115" data-line-end="116">Wenn Sie Fragen zur Forschung haben, können Sie dieses Dokument in der Rubrik “Datennutzung” der voqia-Anwendung finden oder den Hersteller und Sponsor* der Forschung unter den auf der ersten Seite angegebenen Kontaktdaten kontaktieren. Dieses Dokument wird außerdem automatisch auf Ihr Gerät heruntergeladen, wenn Sie die Lektüre bestätigen.</p>
<p class="has-line-data" data-line-start="117" data-line-end="118">Die Daten, um die Sie im Rahmen der Recherche gebeten werden, wurden ausgewählt, weil sie alle vermutlich wichtig für das Erlernen der KI*-Algorithmen sind, die im Rahmen der Recherche entwickelt werden sollen.</p>
<p class="has-line-data" data-line-start="119" data-line-end="120"> </p>
<br><h3 class="code-line" data-line-start=120 data-line-end=121 ><a id="Was_sind_die_erwarteten_Vorteile_und_Risiken_der_Studie_120"></a>Was sind die erwarteten Vorteile und Risiken der Studie?</h5>
<p class="has-line-data" data-line-start="122" data-line-end="123">Diese Studie wird als “Studie ohne Beteiligung von Menschen*” (RNIPH) bezeichnet, da sie prospektive Daten sammelt, ohne auf die Entwicklung biologischer oder medizinischer Kenntnisse abzuzielen, lediglich auf die Entwicklung eines internen Instruments durch ihren Sponsor*, in diesem Fall stimmliche Biomarker*, die mit physio-psychologischer Not in Verbindung gebracht werden, ohne eine Bewertung ihrer Wirksamkeit anzustreben.</p>
<p class="has-line-data" data-line-start="124" data-line-end="125">Die verlangten Fragebögen und Stimmübungen sind sehr einfach und stellen kein Risiko für Sie dar.</p>
<p class="has-line-data" data-line-start="126" data-line-end="127">Die Studie könnte langfristigen Nutzen bringen, falls die aus den gesammelten Daten entwickelten Stimm-Biomarker später in einer anderen Studie ausgewertet werden und sich als ausreichend leistungsfähig erweisen. Dann könnten sie es zum Beispiel ermöglichen, den klinischen Zustand von Patienten mit Depressionen mithilfe ihrer Stimme, also einfach und aus der Ferne, zu beurteilen. Auf diese Weise könnten medizinische Eingriffe angemessener sein: zur richtigen Zeit, bei den richtigen Patienten und auf der Grundlage von Informationen über ihren jüngsten Zustand.</p>
<p class="has-line-data" data-line-start="128" data-line-end="129"> </p>
<br><h3 class="code-line" data-line-start=129 data-line-end=130 ><a id="Welche_Entschdigungen_sind_vorgesehen_129"></a>Welche Entschädigungen sind vorgesehen?</h5>
<p class="has-line-data" data-line-start="131" data-line-end="132">Diese Studie wird anlässlich Ihrer Nutzung der Anwendung voqia durchgeführt oder der Internetseite <a href="https://www.yourvoice.care/">https://www.yourvoice.care/</a>. Sie ist für Sie mit keinerlei Kosten verbunden. Für Ihre Teilnahme an dieser Recherche ist keine Entschädigung vorgesehen.</p>
<p class="has-line-data" data-line-start="133" data-line-end="134"> </p>
<br><h3 class="code-line" data-line-start=134 data-line-end=135 ><a id="Was_passiert_wenn_die_Suche_vorzeitig_beendet_wird_und_was_passiert_nach_der_Suche_134"></a>Was passiert, wenn die Suche vorzeitig beendet wird, und was passiert nach der Suche?</h5>
<p class="has-line-data" data-line-start="136" data-line-end="138">Ein vorzeitiger Abbruch der Studie hat keine Auswirkungen auf Ihre Nutzung der voqia-Anwendung oder der Internetseite <a href="https://www.yourvoice.care/">https://www.yourvoice.care/</a>.<br>
Während und nach der Studie werden die gesammelten Daten zur Entwicklung von Stimm-Biomarkern für physio-psychologische Notlagen verwendet.</p>
<p class="has-line-data" data-line-start="139" data-line-end="140"> </p>
<br><h3 class="code-line" data-line-start=140 data-line-end=141 ><a id="Wie_lange_werden_Ihre_Daten_aufbewahrt_140"></a>Wie lange werden Ihre Daten aufbewahrt?</h5>
<p class="has-line-data" data-line-start="141" data-line-end="142">Gemäß den gesetzlichen Bestimmungen werden Ihre Daten bis zu zwei Jahre nach der letzten Veröffentlichung der Studienergebnisse oder, falls keine Veröffentlichung erfolgt, bis zur Unterzeichnung des Abschlussberichts über den Abschluss der Studie aufbewahrt.</p>
<p class="has-line-data" data-line-start="143" data-line-end="144">Ihre Daten werden 15 Jahre lang archiviert.</p>
<p class="has-line-data" data-line-start="145" data-line-end="146"> </p>
<br><h3 class="code-line" data-line-start=146 data-line-end=147 ><a id="Gesetzliche_Bestimmungen_und_Vorschriften_146"></a>Gesetzliche Bestimmungen und Vorschriften</h5>
<p class="has-line-data" data-line-start="147" data-line-end="148">Gemäß den gesetzlichen Bestimmungen wurde diese Studie beim Health Data Hub angemeldet.</p>
<p class="has-line-data" data-line-start="149" data-line-end="150">Die Verarbeitung Ihrer personenbezogenen Daten im Rahmen der Studie erfolgt gemäß einer Referenzmethode (MR)* MR-004, die von der Commission Nationale Informatique et Libertés (CNIL) erstellt wurde.</p>
<p class="has-line-data" data-line-start="151" data-line-end="152"> </p>
<br><h2 class="code-line" data-line-start=152 data-line-end=153 ><a id="TEIL_2_INFORMATIONEN_BER_DIE_RECHTE_DES_TEILNEHMERS_UND_DEN_UMGANG_MIT_DEN_GESAMMELTEN_DATEN_152"></a>TEIL 2: INFORMATIONEN ÜBER DIE RECHTE DES TEILNEHMERS UND DEN UMGANG MIT DEN GESAMMELTEN DATEN</h2>
<p class="has-line-data" data-line-start="154" data-line-end="155"> </p>
<br><h3 class="code-line" data-line-start=155 data-line-end=156 ><a id="Welche_Rechte_haben_Sie_155"></a>Welche Rechte haben Sie?</h5>
<p class="has-line-data" data-line-start="157" data-line-end="158">Ihre Teilnahme an der Studie ist frei und freiwillig: Sie können frei entscheiden, ob Sie an der Studie teilnehmen möchten oder nicht, und Sie können Ihre Teilnahme jederzeit ohne Angabe von Gründen abbrechen, ohne dafür haftbar gemacht werden zu können oder Schaden zu erleiden. Sie müssen dies lediglich dem Sponsor, dessen Kontaktdaten auf der ersten Seite angegeben sind, oder seinem Datenschutzbeauftragten (DPO)* mitteilen. Ihre Kontaktdaten finden Sie auf der ersten Seite dieses Dokuments.</p>
<p class="has-line-data" data-line-start="160" data-line-end="161">Wir laden Sie ein, AKUITY CARE unter der folgenden E-Mail-Adresse Fragen zu stellen, die Sie im Zusammenhang mit dieser Recherche haben könnten: <a href="mailto:contact@akuity.care">contact@akuity.care</a>.</p>
<p class="has-line-data" data-line-start="162" data-line-end="163">Sie haben das Recht, während oder nach Abschluss der Studie die Daten zu erhalten, die sich auf den Sponsor* beziehen und von ihm gespeichert werden.</p>
<p class="has-line-data" data-line-start="164" data-line-end="165"> </p>
<br><h3 class="code-line" data-line-start=165 data-line-end=166 ><a id="Wie_werden_Ihre_persnlichen_Daten_im_Rahmen_der_Studie_verarbeitet_165"></a>Wie werden Ihre persönlichen Daten* im Rahmen der Studie verarbeitet?</h5>
<p class="has-line-data" data-line-start="166" data-line-end="167">Wenn Sie sich bereit erklären, an der Studie teilzunehmen, werden Ihre persönlichen Daten, einschließlich Ihrer Gesundheitsdaten, vom Sponsor als dem für die Verarbeitung dieser Daten Verantwortlichen verarbeitet*.</p>
<p class="has-line-data" data-line-start="168" data-line-end="169">Die folgenden Daten werden unter angemessenen Sicherheitsbedingungen erhoben und verarbeitet:</p>
<table class="table table-striped table-bordered">
<thead>
<tr>
<th style="text-align:left">Welche Daten?</th>
<th style="text-align:left">Wie?</th>
<th style="text-align:center">Bei wem?</th>
</tr>
</thead>
<tbody>
<tr>
<td style="text-align:left">Stimmaufnahmen</td>
<td style="text-align:left">1) In der Anwendung voqia : a) freie Stimmeaufnahmen, die im Modul “Journaling” der App gemacht werden b) Aufnahmen von Vokalisen (Ton “AAAaaa”, der während des Ausatmens gehalten wird) im Modul “Entspannung” der App. - 2) Auf der Website <a href="https://www.yourvoice.care/">https://www.yourvoice.care/</a> : a) Aufnahme des Lesens eines kurzen Textes b) Aufnahme einer Vokalisation (Ton “AAAaaa”, der für die Dauer eines Ausatmens gehalten wird) c) Aufnahme der freien Beschreibung eines Bildes d) Aufnahme der freien Antwort auf die Frage “Wie geht es Ihnen heute?” e) Aufnahme von 3 Ein-/Ausatmungen durch den Mund f) Aufnahme von 3 Husten.</td>
<td style="text-align:center">Sie selbst.</td>
</tr>
<tr>
<td style="text-align:left">Bewertung depressiver Symptome (durch einen Fragebogen)</td>
<td style="text-align:left">Multiple-Choice-Fragebogen mit 9 Fragen</td>
<td style="text-align:center">Sie selbst</td>
</tr>
<tr>
<td style="text-align:left">Bewertung von Angststörungen (durch einen Fragebogen)</td>
<td style="text-align:left">Multiple-Choice-Fragebogen in 7 Fragen</td>
<td style="text-align:center">Sie selbst</td>
</tr>
<tr>
<td style="text-align:left">Bewertung von Ermüdungserscheinungen (durch einen Fragebogen)</td>
<td style="text-align:left">Multiple-Choice-Fragebogen in 9 Fragen</td>
<td style="text-align:center">Sie selbst</td>
</tr>
<tr>
<td style="text-align:left">Bewertung von Stress (durch eine Skala)</td>
<td style="text-align:left">Dropdown-Menü (von 0 bis 10)</td>
<td style="text-align:center">Sie selbst</td>
</tr>
<tr>
<td style="text-align:left">Bewertung von Schmerzen (durch eine Skala)</td>
<td style="text-align:left">Dropdown-Menü (von 0 bis 10)</td>
<td style="text-align:center">Sie selbst</td>
</tr>
<tr>
<td style="text-align:left">Bekannte Diagnosen Depression, generalisierte Angststörung, Schizophrenie, bipolare Störung, COPD, Asthma, Atemwegsinfektionen in den letzten 3 Monaten und aktuell, aktuelle HNO*-Symptome, anhaltende Covid-19-Symptome, Atemwegsallergie, Lungenfibrose, Krebs (+ Informationen über Krebs, falls zutreffend)</td>
<td style="text-align:left">Ja / Nein</td>
<td style="text-align:center">Sie selbst</td>
</tr>
<tr>
<td style="text-align:left">Andere bekannte Komorbiditäten (bitte in einer Liste angeben)</td>
<td style="text-align:left">Dropdown-Menü</td>
<td style="text-align:center">Sie selbst</td>
</tr>
<tr>
<td style="text-align:left">Aktuelle Behandlungen (bitte in einer Liste angeben)</td>
<td style="text-align:left">Dropdown-Menü</td>
<td style="text-align:center">Selbst</td>
</tr>
<tr>
<td style="text-align:left">Einmaliger Code</td>
<td style="text-align:left">Alpha-numerische Zeichen</td>
<td style="text-align:center">Automatisch</td>
</tr>
<tr>
<td style="text-align:left">Muttersprache</td>
<td style="text-align:left">Dropdown-Menü</td>
<td style="text-align:center">Sie selbst</td>
</tr>
<tr>
<td style="text-align:left">Geburtsjahr</td>
<td style="text-align:left">Dropdown-Menü</td>
<td style="text-align:center">Sie selbst</td>
</tr>
<tr>
<td style="text-align:left">Geschlecht</td>
<td style="text-align:left">Multiple-Choice-Fragebogen</td>
<td style="text-align:center">Sie selbst</td>
</tr>
<tr>
<td style="text-align:left">Größe</td>
<td style="text-align:left">Dropdown-Menü</td>
<td style="text-align:center">Sie selbst</td>
</tr>
<tr>
<td style="text-align:left">Gewicht</td>
<td style="text-align:left">Dropdown-Menü</td>
<td style="text-align:center">Sie selbst</td>
</tr>
<tr>
<td style="text-align:left">Aktueller Konsum von Tabak oder anderen gerauchten Substanzen (Häufigkeit, Menge pro Form, Alter)</td>
<td style="text-align:left">Multiple-Choice-Fragebogen und Dropdown-Menü</td>
<td style="text-align:center">Sie selbst</td>
</tr>
<tr>
<td style="text-align:left">Früherer Konsum von Tabak oder anderen gerauchten Substanzen (Häufigkeit, Menge nach Form, Dauer, Datum des Ausstiegs)</td>
<td style="text-align:left">Multiple-Choice-Fragebögen und Dropdown-Menü</td>
<td style="text-align:center">Sie selbst</td>
</tr>
<tr>
<td style="text-align:left">Aktuelle Schwangerschaft (und Stadium, falls zutreffend)</td>
<td style="text-align:left">Ja / Nein (und Dropdown-Menü)</td>
<td style="text-align:center">Sie selbst.</td>
</tr>
<tr>
<td style="text-align:left">Daten zur Durchführung der Studie</td>
<td style="text-align:left">Datum und Uhrzeit des Beginns und Endes jedes Bildschirms für Fragebögen und Sprachübungen</td>
<td style="text-align:center">Automatisch (von der für die Studie verwendeten Hardware).</td>
</tr>
</tbody>
</table>
<p class="has-line-data" data-line-start="194" data-line-end="195">Diese Daten wurden ausgewählt, da davon ausgegangen wird, dass sie alle wichtig für das Erlernen der KI-Algorithmen* sind, die mit der Studie entwickelt werden sollen.</p>
<p class="has-line-data" data-line-start="196" data-line-end="197"> </p>
<br><h3 class="code-line" data-line-start=197 data-line-end=198 ><a id="Was_ist_die_Rechtsgrundlage_und_der_Zweck_der_Verarbeitung_Ihrer_personenbezogenen_Daten_197"></a>Was ist die Rechtsgrundlage und der Zweck der Verarbeitung Ihrer personenbezogenen Daten?</h5>
<p class="has-line-data" data-line-start="198" data-line-end="202">Die Verarbeitung Ihrer personenbezogenen Daten ist für die Durchführung der Studie, d. h. für die Entwicklung der oben genannten Stimmbiomarker, erforderlich. In diesem Rahmen werden folgende Unterzwecke verfolgt: Erstellung des Teilnehmerregisters, Gewährleistung der Datensicherheit, Testen der Datenqualität und Beantwortung von Anträgen auf Ausübung der Rechte der Teilnehmer.<br>
Somit ist die Verarbeitung Ihrer personenbezogenen Daten zulässig, da sie einem von AKUITY CARE verfolgten berechtigten Interesse zu wissenschaftlichen Studienzwecken gemäß Artikel 6.1 f) und 9.2. j) der DSGVO* entspricht.<br>
Das verfolgte legitime Interesse ist die Erhebung von Daten zum Zweck der Entwicklung der oben beschriebenen Stimmbiomarker.<br>
Der für die Verarbeitung Verantwortliche muss geeignete Maßnahmen ergreifen, die es ermöglichen, Ihre Rechte und Freiheiten zu gewährleisten, insbesondere die ausschließliche Erhebung von Daten, die für die Studie unbedingt erforderlich sind.</p>
<p class="has-line-data" data-line-start="203" data-line-end="204"> </p>
<br><h3 class="code-line" data-line-start=204 data-line-end=205 ><a id="Wie_wird_die_Vertraulichkeit_Ihrer_Daten_gewhrleistet_204"></a>Wie wird die Vertraulichkeit Ihrer Daten gewährleistet?</h5>
<p class="has-line-data" data-line-start="206" data-line-end="208">Ihre persönlichen Daten werden vertraulich behandelt, gemäß dem Gesetz vom 6. Januar 1978 in seiner geänderten Fassung, dem sogenannten “Loi Informatique et Libertés”, und gemäß der Allgemeinen Datenschutzverordnung (DSGVO*).<br>
Ihre Daten werden verschlüsselt*, d. h. Sie werden für die Zwecke der Recherche durch eine Codenummer identifiziert, ohne “Klartext”-Erwähnung der einzigen potenziellen direkt identifizierenden Daten, die in der Recherche gesammelt werden, nämlich der E-Mail-Adresse, die mit Ihrem Konto bei der voqia-App oder Ihrer Nutzung der Website <a href="https://www.yourvoice.care/">https://www.yourvoice.care/</a> verknüpft ist. Ein Drittanbieter von Datenhosting wird die Liste der Übereinstimmungen zwischen diesem Code und Ihrer E-Mail-Adresse speichern. AKUITY CARE hat als Bauträger* vertraglich keinen Zugriff auf Ihre E-Mail-Adresse, außer in den von der DSGVO* vorgesehenen Fällen.</p>
<p class="has-line-data" data-line-start="209" data-line-end="210">Sprachaufnahmen sind für manche Menschen Übungen zur freien Meinungsäußerung. Auch wenn die Art der Anwendung (emotionales Journaling und Herzkohärenz-Tool) Sie nicht dazu veranlassen wird, achten Sie bitte darauf, <strong>keine identifizierbaren Informationen (wie Ihren Namen oder Ihre Kontaktdaten) in Ihren Aufzeichnungen preiszugeben</strong>. Solche Informationen würden uns dazu zwingen, Ihre Daten zu vernichten, und würden Ihre Teilnahme an der Studie nicht ermöglichen.</p>
<p class="has-line-data" data-line-start="211" data-line-end="212">Schließlich werden diese Daten in einer computergestützten Datenbank mit allen erforderlichen technischen und organisatorischen Sicherheitsmaßnahmen und auf vertrauliche Weise unter Wahrung des Berufsgeheimnisses verarbeitet.</p>
<p class="has-line-data" data-line-start="213" data-line-end="214"> </p>
<br><h3 class="code-line" data-line-start=214 data-line-end=215 ><a id="Wer_hat_im_Rahmen_der_Studie_Zugang_zu_Ihren_Daten_214"></a>Wer hat im Rahmen der Studie Zugang zu Ihren Daten?</h5>
<p class="has-line-data" data-line-start="216" data-line-end="217">Ihre potenziell identifizierenden Informationen, d.h. Ihre E-Mail-Adresse, werden nur bekannt sein durch :</p>
<ul>
<li class="has-line-data" data-line-start="217" data-line-end="218">das Unternehmen AZ Network als Drittanbieter von Gesundheitsdaten, das von AKUITY CARE als Sponsor ausgewählt wurde, um die Liste der Übereinstimmungen zwischen den E-Mail-Adressen und den Identifikationscodes der Forschungsteilnehmer zu speichern (diese Liste wird daher getrennt von den anderen Forschungsdaten gespeichert, insbesondere den Gesundheitsdaten der Teilnehmer, die ihrerseits bei dem Unternehmen OVHCloud, einem anderen Anbieter von Gesundheitsdaten, gespeichert werden).</li>
<li class="has-line-data" data-line-start="218" data-line-end="219">den DPO (Datenschutzbeauftragten*) des Sponsors*, wenn Sie sich an ihn wenden <a href="mailto:dpo@akuity.care">dpo@akuity.care</a></li>
<li class="has-line-data" data-line-start="219" data-line-end="221">im Falle eines Rechtsstreits das befugte Personal des Versicherungsträgers des Sponsors*.</li>
</ul>
<p class="has-line-data" data-line-start="221" data-line-end="222">Diese Personen unterliegen der beruflichen Schweigepflicht.</p>
<p class="has-line-data" data-line-start="223" data-line-end="224">Ihre verschlüsselten Daten sind den folgenden Personen zugänglich:</p>
<ul>
<li class="has-line-data" data-line-start="224" data-line-end="225">dem Sponsor*.</li>
<li class="has-line-data" data-line-start="225" data-line-end="227">die Firma OVHCloud als Drittanbieter von Gesundheitsdaten, der von AKUITY CARE in seiner Eigenschaft als Sponsor ausgewählt wurde, um alle Forschungsdaten mit Ausnahme der Liste der Übereinstimmungen zwischen den E-Mail-Adressen und den Identifikationscodes der Teilnehmer zu speichern.</li>
</ul>
<p class="has-line-data" data-line-start="227" data-line-end="228">Diese Personen, die der beruflichen Schweigepflicht unterliegen, haben im Rahmen ihrer Funktion und in Übereinstimmung mit den gesetzlichen Bestimmungen Zugang zu Ihren verschlüsselten Daten.</p>
<p class="has-line-data" data-line-start="229" data-line-end="230"> </p>
<br><h3 class="code-line" data-line-start=230 data-line-end=231 ><a id="Welche_Rechte_haben_Sie_in_Bezug_auf_Ihre_personenbezogenen_Daten_230"></a>Welche Rechte haben Sie in Bezug auf Ihre personenbezogenen Daten?</h5>
<p class="has-line-data" data-line-start="232" data-line-end="234">Sie haben das Recht, über den Datenschutzbeauftragten (DSB)* des Sponsors oder Prüfers* der Studie auf Ihre Daten zuzugreifen und deren Berichtigung oder Vervollständigung zu verlangen.<br>
Sie können auch die Einschränkung der Verarbeitung Ihrer Daten beantragen (d. h. den Sponsor bitten, die Verwendung Ihrer Daten vorübergehend einzufrieren).</p>
<p class="has-line-data" data-line-start="235" data-line-end="237">Auch wenn Sie sich bereit erklären, an der Studie teilzunehmen, können Sie jederzeit der Verarbeitung Ihrer Daten zum Zweck der Durchführung der Studie widersprechen. In diesem Fall werden keine weiteren Informationen über Sie gesammelt.<br>
Sie können auch Ihr Recht auf Löschung bereits gesammelter Daten ausüben.</p>
<p class="has-line-data" data-line-start="238" data-line-end="239"> </p>
<br><h3 class="code-line" data-line-start=239 data-line-end=240 ><a id="Wie_knnen_Sie_Ihre_Rechte_ausben_239"></a>Wie können Sie Ihre Rechte ausüben?</h5>
<p class="has-line-data" data-line-start="240" data-line-end="242">Sie können Ihre Rechte jederzeit und ohne Angabe von Gründen ausüben.<br>
Da der Promoter* keinen Zugang zu Ihrer Identität hat, sollten Sie sich zunächst an seinen Datenschutzbeauftragten (DSB)* wenden, dessen Kontaktdaten in diesem Hinweis auf der ersten Seite zu finden sind.</p>
<p class="has-line-data" data-line-start="243" data-line-end="244">In diesem Fall wird Ihre Identität (Vorname, Nachname) dem DSB* des Sponsors* zugänglich gemacht.</p>
<p class="has-line-data" data-line-start="245" data-line-end="246">Falls Sie Ihre Rechte nicht ausüben können, haben Sie auch das Recht, eine Beschwerde über die Verarbeitung Ihrer personenbezogenen Daten bei der Commission nationale de l’informatique et des libertés (CNIL) einzureichen, die in Frankreich die zuständige Aufsichtsbehörde für den Datenschutz ist:</p>
<ul>
<li class="has-line-data" data-line-start="246" data-line-end="247">Über den Online-Beschwerdeteledienst</li>
<li class="has-line-data" data-line-start="247" data-line-end="249">Per Post, indem Sie an folgende Adresse schreiben: CNIL - Service des Plaintes - 3 Place de Fontenoy - TSA 80715 - 75334 PARIS CEDEX 07.</li>
</ul>
<p class="has-line-data" data-line-start="249" data-line-end="250"> </p>
<br><h3 class="code-line" data-line-start=250 data-line-end=251 ><a id="Knnen_Ihre_kodierten_Daten_wiederverwendet_werden_250"></a>Können Ihre kodierten Daten wiederverwendet werden?</h5>
<p class="has-line-data" data-line-start="251" data-line-end="252">Ihre Daten können in anderen Forschungsprojekten zur Entwicklung von Sprachbiomarkern* wiederverwendet werden. So kann beispielsweise die Leistung von Stimm-Biomarkern*, die auf der Grundlage der in dieser Studie gesammelten Daten entwickelt wurden, durch die Kombination mit Daten aus einer ähnlichen Studie (oder umgekehrt) bewertet werden.</p>
<p class="has-line-data" data-line-start="253" data-line-end="255">Um Informationen über die neue(n) Verarbeitung(en) Ihrer Daten zu erhalten, hat der Sponsor* eine dynamische Website (“Transparenzportal”) eingerichtet, die Sie regelmäßig und vor der Durchführung jeder neuen Verarbeitung unter der folgenden Adresse aufrufen können: <a href="http://www.akuity.care/transparence/nv92j2">www.akuity.care/transparence/nv92j2</a>.<br>
Diese Seite wird alle Pflichtangaben der DSGVO* detailliert aufführen, insbesondere für jedes betroffene Projekt die Identität des für die Verarbeitung Verantwortlichen und die verfolgten Zwecke, die die Weiterverwendung Ihrer Daten rechtfertigen. Die Website wird bei jeder neuen Studie, die Ihre Daten weiterverwendet, aktualisiert.</p>
<p class="has-line-data" data-line-start="256" data-line-end="258">Mit dieser Information können Sie entscheiden, ob Sie Ihr Recht auf Zugang, Berichtigung, Einschränkung, Widerspruch oder Löschung Ihrer Daten ausüben möchten. Die Anzeige der Projekte auf der Seite der Website gilt als Information für diese Weiterverwendung von Daten und daher ist es nicht vorgesehen, Ihnen für jedes Forschungsprojekt ein individuelles Schreiben mit zusätzlichen Informationen zukommen zu lassen.<br>
Die Widerspruchsmodalitäten für jedes Forschungsprojekt werden auf dieser Internetseite angegeben.</p>
<p class="has-line-data" data-line-start="259" data-line-end="260"> </p>
<br><h2 class="code-line" data-line-start=260 data-line-end=261 ><a id="TEIL_3_GLOSSAR_260"></a>TEIL 3: GLOSSAR</h2>
<ul>
<li class="has-line-data" data-line-start="262" data-line-end="263"><strong>Stimmbiomarker</strong>: KI-Algorithmus, der eine Schätzung eines klinischen Kriteriums bei einer Person basierend auf einer Stimmprobe liefert.</li>
<li class="has-line-data" data-line-start="263" data-line-end="264"><strong>Kodierung Ihrer Daten</strong>: eindeutige Identifizierung Ihrer Daten in der Studiendatenbank durch einen nicht signifikanten Code, der keine Rückschlüsse auf Ihre Person zulässt (alphanumerischer Code).</li>
<li class="has-line-data" data-line-start="264" data-line-end="265"><strong>Personenbezogene Daten</strong>: alle Informationen, die sich auf eine identifizierte oder identifizierbare natürliche Person beziehen und deren Verarbeitung in Europa der DSGVO unterliegt.</li>
<li class="has-line-data" data-line-start="265" data-line-end="266"><strong>DPO/DSB (Datenschutzbeauftragter)</strong>: Person, die für den Schutz personenbezogener Daten innerhalb einer Organisation verantwortlich ist.</li>
<li class="has-line-data" data-line-start="266" data-line-end="267"><strong>KI</strong> (Künstliche Intelligenz): Konstruktion von Computerprogrammen, die automatisch definierte Aufgaben ausführen, wobei diese <strong>Programme „Algorithmen“ genannt werden.</strong></li>
<li class="has-line-data" data-line-start="267" data-line-end="268"><strong>Ermittler</strong>: Natürliche Person, die für die Überwachung und Leitung der Studie verantwortlich ist.</li>
<li class="has-line-data" data-line-start="268" data-line-end="269"><strong>Referenzmethodik (MR)</strong>: Vereinfachtes Verfahren für den Zugang zu Gesundheitsdaten für Forschungsförderer.</li>
<li class="has-line-data" data-line-start="269" data-line-end="270"><strong>Promoter</strong>: Natürliche oder juristische Person, die für die Studie verantwortlich ist, deren Verwaltung gewährleistet und die die Planung der Finanzierung überprüft.</li>
<li class="has-line-data" data-line-start="270" data-line-end="271"><strong>Studie ohne Menschen (RNIPH)</strong>: Studie außerhalb des Bereichs der Studie mit Menschen (RIPH), wobei letztere am Menschen mit dem Ziel organisiert und durchgeführt wird, biologische oder medizinische Entwicklungen zu entwickeln (Artikel L.1121-1). des Gesetzes über die öffentliche Gesundheit)</li>
<li class="has-line-data" data-line-start="271" data-line-end="272"><strong>DSGVO</strong> (Datenschutz-Grundverordnung): Verordnung (EU) 2016/679 des Europäischen Parlaments und des Rates vom 27. April 2016 zum Schutz natürlicher Personen bei der Verarbeitung personenbezogener und personenbezogener Daten Verbreitung dieser Daten</li>
<li class="has-line-data" data-line-start="272" data-line-end="273"><strong>HNO-Symptome</strong>: Hals-Nasen-Ohren-Symptome (Beispiel: verstopfte Nase oder Nebenhöhlen)</li>
<li class="has-line-data" data-line-start="273" data-line-end="274"><strong>Verarbeitung personenbezogener Daten</strong>: Die Verarbeitung personenbezogener Daten ist ein Vorgang oder eine Reihe von Vorgängen im Zusammenhang mit personenbezogenen Daten, unabhängig vom verwendeten Verfahren (Erhebung, Aufzeichnung, Organisation, Aufbewahrung, Anpassung, Änderung, Extraktion, Abfrage, Verwendung). , Mitteilung durch Übermittlung oder Verbreitung oder jede andere Form der Zugänglichmachung, Abgleich).</li>
</ul>
<hr>
`,
}

export default consentDE

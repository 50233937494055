import { Categorical, MedicalQuestion } from "../../../../@types/medicalQuestion"

const category: Categorical[] = [
  {
    label: "Überhaupt nicht",
    value: "not-at-all",
  },
  {
    label: "Wenig",
    value: "a-little",
  },
  {
    label: "Mittelmässig",
    value: "moderately",
  },
  {
    label: "Ziemlich",
    value: "a-lot",
  },
  {
    label: "Extrem",
    value: "extremely",
  },
]

const vqDE: MedicalQuestion[] = [
  {
    id: "vq_intro",
    name: "intro",
    value:
      "Die folgenden Aussagen drücken Gefühle über die Folgen einer Atemwegserkrankung aus. Klicken Sie für jede Aussage" +
      " die Intensität an, die Ihr Gefühl in diesem Moment am besten wiedergibt (von 'Überhaupt nicht' bis 'Extrem')." +
      "Es gibt keine falschen Antworten. Jede Aussage ist persönlich.",
    type: "intro",
    lang: "de",
    parent: "vq",
    required: false,
  },
  {
    id: "vq_1",
    name: "Respiratory quality of life VQ11",
    type: "categoricalShort",
    value: category,
    description: "Ich leide unter meiner Kurzatmigkeit",
    parent: "vq",
    order: 1,
    lang: "de",
    required: true,
  },
  {
    id: "vq_2",
    name: "Respiratory quality of life VQ11",
    type: "categoricalShort",
    value: category,
    description: "Ich mache mir Sorgen über mein Atmungszustand",
    parent: "vq",
    order: 2,
    lang: "de",
    required: true,
  },
  {
    id: "vq_3",
    name: "Respiratory quality of life VQ11",
    type: "categoricalShort",
    value: category,
    description: "Ich fühle mich von meinen Mitmenschen missverstanden",
    parent: "vq",
    order: 3,
    lang: "de",
    required: true,
  },
  {
    id: "vq_4",
    name: "Respiratory quality of life VQ11",
    type: "categoricalShort",
    value: category,
    description: "Meine Atemwegserkrankung hindert mich daran, mich so leicht zu bewegen, wie ich es gerne würde",
    parent: "vq",
    order: 4,
    lang: "de",
    required: true,
  },
  {
    id: "vq_5",
    name: "Respiratory quality of life VQ11",
    type: "categoricalShort",
    value: category,
    description: "Ich fühle mich tagsüber schläfrig",
    parent: "vq",
    order: 5,
    lang: "de",
    required: true,
  },
  {
    id: "vq_6",
    name: "Respiratory quality of life VQ11",
    type: "categoricalShort",
    value: category,
    description: "Ich fühle mich nicht in der Lage meine Projekte zu verwirklichen",
    parent: "vq",
    order: 6,
    lang: "de",
    required: true,
  },
  {
    id: "vq_7",
    name: "Respiratory quality of life VQ11",
    type: "categoricalShort",
    value: category,
    description: "Ich werde bei alltäglichen Aktivitäten schnell müde",
    parent: "vq",
    order: 7,
    lang: "de",
    required: true,
  },
  {
    id: "vq_8",
    name: "Respiratory quality of life VQ11",
    type: "categoricalShort",
    value: category,
    description: "Körperlich bin ich unzufrieden mit dem, was ich tun kann",
    parent: "vq",
    order: 8,
    lang: "de",
    required: true,
  },
  {
    id: "vq_9",
    name: "Respiratory quality of life VQ11",
    type: "categoricalShort",
    value: category,
    description: "Meine Atemwegserkrankung stört mein soziales Leben",
    parent: "vq",
    order: 9,
    lang: "de",
    required: true,
  },
  {
    id: "vq_10",
    name: "Respiratory quality of life VQ11",
    type: "categoricalShort",
    value: category,
    description: "Ich fühle mich traurig",
    parent: "vq",
    order: 10,
    lang: "de",
    required: true,
  },
  {
    id: "vq_11",
    name: "Respiratory quality of life VQ11",
    type: "categoricalShort",
    value: category,
    description: "Mein Atemwegsleiden schränkt mein Gefühlsleben ein",
    parent: "vq",
    order: 11,
    lang: "de",
    required: true,
  },
]

export default vqDE

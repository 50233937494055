import { styled } from "@mui/material/styles"
import { Box, Button, Container, Grid, Typography } from "@mui/material"
import GaugeChart from "react-gauge-chart"
import Page from "../../components/Page"
import React, { useEffect, useState } from "react"
import { Project } from "../../@types/project"
import projects from "../../@projects"
import { useNavigate, useParams } from "react-router-dom"
import usePatient from "../../hooks/usePatient"
import useLocales from "../../hooks/useLocales"
import { MedicalQuestion } from "../../@types/medicalQuestion"

const ContentStyle = styled("div")(({ theme }) => ({
  maxWidth: 680,
  margin: "auto",
  display: "flex",
  justifyContent: "center",
  flexDirection: "column",
}))

function getRandomNumber(min: number, max: number): number {
  return Math.random() * (max - min) + min
}

const Result = () => {
  const { translate, currentLang } = useLocales()
  const [project, setProject] = useState<Project>()
  const navigate = useNavigate()
  const { id, patientid } = useParams()
  const { patient, cleanPatient } = usePatient()
  const [firstQuestionnaire, setFirstQuestionnaire] = useState<string>()

  useEffect(() => {
    const proj = projects.find((project) => project.projectID === id)
    if (proj) {
      setProject(proj)
      if (proj.questionnaires !== undefined) {
        const filteredQuestionsByLang = proj.questionnaires.map((questionnaire_structure) =>
          questionnaire_structure.questionnaire.filter((question) => question.lang === currentLang.value)
        )
        const allQuestionnaires: string[] = filteredQuestionsByLang
          .flatMap((questions: MedicalQuestion[]) => questions.map((question) => question.parent))
          .filter((v, i, a) => a.indexOf(v) === i)

        setFirstQuestionnaire(allQuestionnaires[0])
      }
    } else {
      navigate("/")
    }
  }, [])

  if (!project) {
    return <></>
  } else {
    return (
      <Page title={`${project.projectName}`}>
        <Container>
          <ContentStyle sx={{ textAlign: "center" }}>
            {/*<Typography variant="h1">{translate("project.final.title")}</Typography>*/}
            <Typography variant="h2">Voici ce que nous détectons dans votre voix</Typography>
            <Grid container rowSpacing={5} columnSpacing={{ xs: 1, sm: 2, md: 3 }} sx={{ mt: 1 }}>
              <Grid item xs={12} sm={6}>
                <Container>
                  <Typography variant="h5">Humeur (PHQ-9)</Typography>
                  <GaugeChart
                    id="gauge-chart3"
                    nrOfLevels={3}
                    colors={["#ff001a", "#f5fa02", "#39af04"]}
                    arcWidth={0.2}
                    percent={getRandomNumber(0.5, 0.9)}
                    hideText={true}
                    textColor={"black"}
                  />
                  {/*<Typography variant="h6">0.37</Typography>*/}
                </Container>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Typography variant="h5">Sérénité (GAD-7)</Typography>
                <Container>
                  <GaugeChart
                    id="gauge-chart3"
                    nrOfLevels={3}
                    colors={["#ff001a", "#f5fa02", "#39af04"]}
                    arcWidth={0.2}
                    percent={getRandomNumber(0.7, 0.8)}
                    hideText={true}
                    textColor={"black"}
                  />
                  {/*<Typography variant="h6">0.88</Typography>*/}
                </Container>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Container>
                  <Typography variant="h5">Relaxation (EVA Stress)</Typography>

                  <GaugeChart
                    id="gauge-chart3"
                    nrOfLevels={3}
                    colors={["#ff001a", "#f5fa02", "#39af04"]}
                    arcWidth={0.2}
                    percent={getRandomNumber(0.25, 0.35)}
                    hideText={true}
                    textColor={"black"}
                  />
                  {/*<Typography variant="h6">0.54</Typography>*/}
                </Container>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Container>
                  <Typography variant="h5">Forme physique (FSS)</Typography>
                  <GaugeChart
                    id="gauge-chart3"
                    nrOfLevels={3}
                    colors={["#ff001a", "#f5fa02", "#39af04"]}
                    arcWidth={0.2}
                    percent={getRandomNumber(0.15, 0.3)}
                    hideText={true}
                    textColor={"black"}
                  />
                  {/*<Typography variant="h6">0.1</Typography>*/}
                </Container>
              </Grid>
            </Grid>
            <Box sx={{ pt: 5 }}>
              <Button
                type="submit"
                variant="contained"
                color="primary"
                sx={{ width: "80%", height: 60, fontSize: 24 }}
                onClick={() => {
                  navigate(`/project/${id}/final/${patient.questionnaireId}`)
                  //navigate(`/project/${id}/done`)
                }}
              >
                {translate("project.result.continue")}
              </Button>
            </Box>
          </ContentStyle>
        </Container>
      </Page>
    )
  }
}

export default Result

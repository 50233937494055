import { styled } from "@mui/material/styles"
import { Box, Button, Container, Grid, Typography } from "@mui/material"
import Page from "../../components/Page"
import React, { useEffect, useState } from "react"
import { Project } from "../../@types/project"
import projects from "../../@projects"
import { useNavigate, useParams } from "react-router-dom"
import usePatient from "../../hooks/usePatient"
import useLocales from "../../hooks/useLocales"
import { MedicalQuestion } from "../../@types/medicalQuestion"
//export type { FeatureData } from "../../@types/features"
//export type { FeatureDataList } from "../../@types/features"
import BasicTable from "../../visualisation/table"
import { Feature } from "../../@types/patient"

const ContentStyle = styled("div")(({ theme }) => ({
  maxWidth: 800,
  margin: "auto",
  display: "flex",
  justifyContent: "center",
  flexDirection: "column",
}))

const Features = () => {
  const { translate, currentLang } = useLocales()
  const [project, setProject] = useState<Project>()
  const navigate = useNavigate()
  const { id, patientid } = useParams()
  const { patient, cleanPatient } = usePatient()
  const [firstQuestionnaire, setFirstQuestionnaire] = useState<string>()
  const [prosody_features, setProsody] = useState<Feature[]>([])
  const [energy_features, setEnergy] = useState<Feature[]>([])
  const [spectral_features, setSpectral] = useState<Feature[]>([])
  const [temporal_features, setTemporal] = useState<Feature[]>([])
  const [isRecordingLongEnough, setIsRecordingLongEnough] = useState<boolean>(false)

  useEffect(() => {
    const proj = projects.find((project) => project.projectID === id)
    if (proj) {
      setProject(proj)
      if (proj.questionnaires !== undefined) {
        const filteredQuestionsByLang = proj.questionnaires.map((questionnaire_structure) =>
          questionnaire_structure.questionnaire.filter((question) => question.lang === currentLang.value)
        )
        const allQuestionnaires: string[] = filteredQuestionsByLang
          .flatMap((questions: MedicalQuestion[]) => questions.map((question) => question.parent))
          .filter((v, i, a) => a.indexOf(v) === i)

        setFirstQuestionnaire(allQuestionnaires[0])
      }
    } else {
      navigate("/")
    }

    console.log("-- in Features.tsx:")
    console.log("patient:")
    console.log(patient)
    setProsody(patient.voiceFeatures.filter((x) => x.featureType == "prosody")[0].features)
    setEnergy(patient.voiceFeatures.filter((x) => x.featureType == "energy")[0].features)
    setSpectral(patient.voiceFeatures.filter((x) => x.featureType == "spectral")[0].features)
    setTemporal(patient.voiceFeatures.filter((x) => x.featureType == "temporal")[0].features)

    setIsRecordingLongEnough(Number(patient.voiceRecorded[0].time) > 9)
  }, [])

  if (!project) {
    return <></>
  } else {
    return (
      <Page title={`${project.projectName}`}>
        <Container>
          <ContentStyle sx={{ textAlign: "center" }}>
            {/*<Typography variant="h1">{translate("project.final.title")}</Typography>*/}
            <Typography variant="h2">Extraction des features</Typography>
            {/*{!isRecordingLongEnough && (*/}
            {/*  <Typography>*/}
            {/*    Attention: vérifiez que votre enregistrement contient au moins 10 secondes de parole pour avoir assez d'avoir pour*/}
            {/*    l'extraction des features*/}
            {/*  </Typography>*/}
            {/*)}*/}
            <Grid container rowSpacing={5} columnSpacing={{ xs: 1, sm: 1, md: 1 }} sx={{ mt: 1 }}>
              <Grid item xs={12} sm={6}>
                <Container>
                  <Typography variant="h5" sx={{ mb: 1 }}>
                    Prosody
                  </Typography>
                  <BasicTable features={prosody_features} />
                </Container>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Typography variant="h5" sx={{ mb: 1 }}>
                  Energy
                </Typography>
                <BasicTable features={energy_features} />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Container>
                  <Typography variant="h5" sx={{ mb: 1 }}>
                    Spectral balance
                  </Typography>
                  <BasicTable features={spectral_features} />
                </Container>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Container>
                  <Typography variant="h5" sx={{ mb: 1 }}>
                    Temporal
                  </Typography>
                  <BasicTable features={temporal_features} />
                </Container>
              </Grid>
            </Grid>
            <Box sx={{ pt: 5 }}>
              <Button
                type="submit"
                variant="contained"
                color="primary"
                sx={{ width: "80%", height: 60, fontSize: 24 }}
                onClick={() => {
                  navigate(location.pathname.replace("features", `result`))
                }}
              >
                {translate("project.result.continue")}
              </Button>
            </Box>
          </ContentStyle>
        </Container>
      </Page>
    )
  }
}

export default Features

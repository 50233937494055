import { MedicalQuestion } from "../../../../@types/medicalQuestion"

const cancerEN: MedicalQuestion[] = [
  {
    id: "c_type",
    name: "cancer type",
    type: "multi",
    value: [
      { label: "Breast", value: "breast" },
      { label: "Lung", value: "lung" },
      { label: "Brain", value: "brain" },
      { label: "Lips", value: "lips" },
      { label: "Tongue", value: "tongue" },
      { label: "Mouth", value: "mouth" },
      { label: "Throat", value: "throat" },
      { label: "Larynx", value: "larynx" },
      { label: "Salivary glands", value: "salivary-glands" },
      { label: "Pharynx", value: "pharynx" },
      { label: "Sinus", value: "sinus" },
      { label: "Nasal cavity", value: "nasal-cavity" },
      { label: "Other localization on head or neck", value: "other-head-neck" },
      { label: "Other localization", value: "other" },
    ],
    description: "Where is your current cancer located?",
    parent: "cancer",
    order: 1,
    lang: "en",
    required: true,
  },
  {
    id: "c_diag",
    name: "first cancer diagnosis",
    type: "categoricalShort",
    value: [
      { label: "First diagnosis", value: "first-diagnosis" },
      { label: "First recurrence", value: "first-recurrence" },
      { label: "Second recurrence", value: "second-recurrence" },
      { label: "Third recurrence", value: "third-recurrence" },
      { label: "Fourth recurrence", value: "fourth-recurrence" },
      { label: "Fifth recurrence", value: "fifth-recurrence" },
      { label: "Sixth recurrence", value: "sixth-recurrence" },
      { label: "Seventh recurrence", value: "seventh-recurrence" },
      { label: "Eighth recurrence", value: "eighth-recurrence" },
      { label: "Ninth recurrence", value: "ninth-recurrence" },
      { label: "Tenth recurrence", value: "tenth-recurrence" },
    ],
    description: "Is it your first diagnosis with cancer of this type or is it a cancer recurrence?",
    parent: "cancer",
    order: 2,
    lang: "en",
    required: true,
  },
  {
    id: "c_diag_y",
    name: "cancer diag year",
    type: "number",
    minValue: 1980,
    maxValue: 2030,
    description: "When were you diagnosed with your current cancer?",
    parent: "cancer",
    order: 3,
    lang: "en",
    required: false,
  },
  {
    id: "c_treat_p",
    name: "cancer treatment past",
    type: "multi",
    value: [
      { label: "Surgery", value: "surgery" },
      { label: "Radiotherapy", value: "radiotherapy" },
      { label: "Immunotherapy", value: "immunotherapy" },
      { label: "Chemotherapy", value: "chemotherapy" },
      { label: "Other", value: "other" },
    ],
    description:
      "For your current cancer or possible past cancer, what type of treatment have you received? " +
      "Note: only consider here the treatments that you no longer receive",
    parent: "cancer",
    order: 4,
    lang: "en",
    required: false,
  },
  {
    id: "c_treat_c",
    name: "cancer treatment current",
    type: "multi",
    value: [
      { label: "Surgery", value: "surgery" },
      { label: "Radiotherapy", value: "radiotherapy" },
      { label: "Immunotherapy", value: "immunotherapy" },
      { label: "Chemotherapy", value: "chemotherapy" },
      { label: "Other", value: "other" },
    ],
    description: "For your current cancer, what type of treatment do you currently receive?",
    parent: "cancer",
    order: 5,
    lang: "en",
    required: false,
  },
  {
    id: "c_type_b",
    name: "cancer subcategory breast",
    type: "categoricalShort",
    value: [
      { label: "Luminal A", value: "luminal-a" },
      { label: "Luminal B", value: "luminal-b" },
      { label: "HER2-positive", value: "HER2-positive" },
      { label: "triple negative", value: "triple-negative" },
      { label: "not found", value: "missing-information" },
    ],
    description: "What sub-category of breast cancer was it?",
    parent: "cancer",
    order: 6,
    lang: "en",
    dependsOn: {
      id: "c_type",
      values: ["breast"],
    },
    required: false,
    doctor: true,
  },
  {
    id: "c_type_l",
    name: "cancer subcategory lung",
    type: "categoricalShort",
    value: [
      { label: "NSCLC", value: "nsclc" },
      { label: "SCLC", value: "sclc" },
      { label: "not found", value: "missing-information" },
    ],
    description: "What sub-category of lung cancer was it?",
    parent: "cancer",
    order: 7,
    lang: "en",
    dependsOn: {
      id: "c_type",
      values: ["lung"],
    },
    required: false,
    doctor: true,
  },
  {
    id: "c_tnm_t",
    name: "cancer tnm classification - t",
    type: "select",
    value: [
      { label: "Tx", value: "tx" },
      { label: "T0", value: "t0" },
      { label: "Tis", value: "tis" },
      { label: "T1", value: "t1" },
      { label: "T2", value: "t2" },
      { label: "T3", value: "t3" },
      { label: "T4", value: "t4" },
      { label: "missing information", value: "missing-information" },
    ],
    description: "What is the TNM classification (T)?",
    parent: "cancer",
    order: 8,
    lang: "en",
    required: true,
    doctor: true,
  },
  {
    id: "c_tnm_n",
    name: "cancer tnm classification - n",
    type: "select",
    value: [
      { label: "Nx", value: "nx" },
      { label: "N0", value: "n0" },
      { label: "N1", value: "n1" },
      { label: "N2", value: "n2" },
      { label: "N3", value: "n3" },
      { label: "missing information", value: "missing-information" },
    ],
    description: "What is the TNM classification (N)?",
    parent: "cancer",
    order: 9,
    lang: "en",
    required: true,
    doctor: true,
  },
  {
    id: "c_tnm_m",
    name: "cancer tnm classification - m",
    type: "select",
    value: [
      { label: "Mx", value: "mx" },
      { label: "M0", value: "m0" },
      { label: "M1", value: "m1" },
      { label: "missing information", value: "missing-information" },
    ],
    description: "What is the TNM classification (M)?",
    parent: "cancer",
    order: 10,
    lang: "en",
    required: true,
    doctor: true,
  },
]

export default cancerEN

import { TaskRecording } from "../../../@types/taskRecording"

const taskRecordingsEN: TaskRecording[] = [
  {
    id: "free_speech_2",
    name: "Free speech answer a question",
    type: "text",
    media: "How are you today?",
    description:
      "1. Click the green button to start the recording.\n" +
      "2. Answer the following question by speaking at least 20 seconds. \n" +
      "3. Once you are finished, click the button again to stop recording.",
    lang: "en",
  },
]

export default taskRecordingsEN

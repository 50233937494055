import { MedicalQuestion } from "../../../../@types/medicalQuestion"

const generalEN: MedicalQuestion[] = [
  {
    id: "lang",
    name: "Language",
    type: "select",
    value: [
      { value: "english_american$", label: "English (American)" },
      { value: "arabic", label: "Arabic" },
      { value: "bengali", label: "Bengali" },
      { value: "chinese_mandarin_wu", label: "Chinese (mandarin, wu...)" },
      { value: "czech", label: "Czech" },
      { value: "dutch", label: "Dutch" },
      { value: "english_american", label: "English (American)" },
      { value: "english_british", label: "English (UK)" },
      { value: "french_france", label: "French (France)" },
      { value: "french_africa", label: "French (Africa)" },
      { value: "french_belgium", label: "French (Belgium)" },
      { value: "french_quebec", label: "French (Quebec)" },
      { value: "french_switzerland", label: "French (Switzerland)" },
      { value: "finnish", label: "Finnish" },
      { value: "german_germany", label: "German (Germany)" },
      { value: "german_swiss", label: "German (Switzerland)" },
      { value: "greek", label: "Greek" },
      { value: "hindi", label: "Hindi" },
      { value: "hungarian", label: "Hungarian" },
      { value: "italian", label: "Italian" },
      { value: "japanese", label: "Japanese" },
      { value: "korean", label: "Korean" },
      { value: "norwegian", label: "Norwegian" },
      { value: "portuguese", label: "Portuguese" },
      { value: "polish", label: "Polish" },
      { value: "romanian", label: "Romanian" },
      { value: "russian", label: "Russian" },
      { value: "spanish", label: "Spanish" },
      { value: "swedish", label: "Swedish" },
      { value: "turkish", label: "Turkish" },
      { value: "other", label: "Other" },
    ],
    description: "What is your mother tongue?",
    parent: "general_info",
    order: 1,
    lang: "en",
    required: true,
  },
  {
    id: "age",
    name: "Age",
    type: "number",
    minValue: 1930,
    maxValue: 2011,
    description: "Birth year",
    parent: "general_info",
    order: 2,
    lang: "en",
    required: true,
  },
  {
    id: "sex",
    name: "Gender",
    type: "categoricalShort",
    value: [
      { label: "Male", value: "male" },
      { label: "Female", value: "female" },
      { label: "Non-binary", value: "non-binary" },
    ],
    description: "Gender",
    parent: "general_info",
    order: 3,
    lang: "en",
    required: true,
  },
  {
    id: "height",
    name: "Height",
    type: "number",
    minValue: 100,
    maxValue: 220,
    description: "Height (cm)",
    parent: "general_info",
    order: 4,
    lang: "en",
    required: true,
  },
  {
    id: "weight",
    name: "Weight",
    type: "number",
    minValue: 40,
    maxValue: 220,
    description: "Weight (kg)",
    parent: "general_info",
    order: 5,
    lang: "en",
    required: true,
  },
  {
    id: "c_smo",
    name: "Current tobacco smoking",
    type: "categoricalShort",
    value: [
      { label: "Daily", value: "daily" },
      { label: "Less than daily", value: "less-than-daily" },
      { label: "Not at all", value: "not-at-all" },
    ],
    description: "Do you currently smoke (tobacco or any other substance, whatever the form, including electronic cigarette) ?",
    parent: "general_info",
    order: 6,
    lang: "en",
    required: true,
  },
  {
    id: "c_smo_quant",
    name: "Current smoking quantity",
    type: "number",
    minValue: 0,
    maxValue: 500,
    description: "On average, how many cigarettes (or equivalents) do you smoke each week?",
    info:
      "A few equivalents as a reminder:\n" +
      "- 1 packet of cigarettes = 20 cigarettes\n" +
      "- 1 cigar is equivalent to about 15 cigarettes\n" +
      "- 1 cigarillo is equivalent to about 3 cigarettes\n" +
      "- 1 joint is equivalent to about 3 cigarettes\n" +
      "- 1 hour of hookah smoking is equivalent to about 5 packets of cigarettes\n" +
      "- 1 10ml bottle of e-liquid is equivalent to around 10 packets of cigarettes",
    parent: "general_info",
    order: 7,
    lang: "en",
    dependsOn: {
      id: "c_smo",
      values: ["daily", "less-than-daily"],
    },
    required: false,
  },
  {
    id: "c_dur",
    name: "Current duration smoking",
    type: "number",
    minValue: 1,
    maxValue: 60,
    description: "How many years have you been smoking?",
    parent: "general_info",
    order: 8,
    lang: "en",
    dependsOn: {
      id: "c_smo",
      values: ["daily", "less-than-daily"],
    },
    required: false,
  },
  {
    id: "p_smo",
    name: "Past tobacco smoking",
    type: "categoricalShort",
    value: [
      { label: "Daily", value: "daily" },
      { label: "Less than daily", value: "less-than-daily" },
      { label: "Not at all", value: "not-at-all" },
    ],
    description: "Have you smoked in the past (tobacco or any other substance, whatever the form, including electronic cigarette)?",
    parent: "general_info",
    order: 9,
    lang: "en",
    dependsOn: {
      id: "c_smo",
      values: ["not-at-all"],
    },
    required: false,
  },
  {
    id: "p_smo_quant",
    name: "Current smoking quantity",
    type: "number",
    minValue: 1,
    maxValue: 500,
    description: "On average, how many cigarettes (or equivalents) did you smoke each week?",
    info:
      "A few equivalents as a reminder:\n" +
      "- 1 packet of cigarettes = 20 cigarettes\n" +
      "- 1 cigar is equivalent to about 15 cigarettes\n" +
      "- 1 cigarillo is equivalent to about 3 cigarettes\n" +
      "- 1 joint is equivalent to about 3 cigarettes\n" +
      "- 1 hour of hookah smoking is equivalent to about 5 packets of cigarettes\n" +
      "- 1 10ml bottle of e-liquid is equivalent to around 10 packets of cigarettes",
    parent: "general_info",
    order: 10,
    lang: "en",
    dependsOn: {
      id: "p_smo",
      values: ["daily", "less-than-daily"],
    },
    required: false,
  },
  {
    id: "p_dur",
    name: "Past duration smoking",
    type: "number",
    minValue: 1,
    maxValue: 60,
    description: "How many years have you smoked?",
    parent: "general_info",
    order: 11,
    lang: "en",
    dependsOn: {
      id: "p_smo",
      values: ["daily", "less-than-daily"],
    },
    required: false,
  },
  {
    id: "p_dur_stop",
    name: "Past duration smoking stop",
    type: "number",
    minValue: 0,
    maxValue: 60,
    description: "How many years ago did you quit smoking?",
    parent: "general_info",
    order: 12,
    lang: "en",
    dependsOn: {
      id: "p_smo",
      values: ["daily", "less-than-daily"],
    },
    required: false,
  },
  {
    id: "alc_freq",
    name: "Alcohol frequency",
    type: "categoricalShort",
    value: [
      { label: "Every day", value: "daily" },
      { label: "5 or 6 times a week", value: "5-6-a-week" },
      { label: "3 or 4 times a week", value: "3-4-a-week" },
      { label: "Twice a week", value: "twice-a-week" },
      { label: "Once a week", value: "once-a-week" },
      { label: "2 or 3 times a month", value: "2-3-a-month" },
      { label: "Once a month", value: "once-a-month" },
      { label: "3 to 11 times in the past year", value: "3-11-a-year" },
      { label: "1 or 2 times in the past year", value: "1-2-a-year" },
      { label: "Never", value: "never" },
    ],
    description: "Over the last 12 months on average, how often have you had alcool?",
    info: "whatever the alcool and quantity",
    parent: "general_info",
    order: 13,
    lang: "en",
    required: true,
  },
  {
    id: "alc_quant",
    name: "Alcool quantity ",
    type: "number",
    minValue: 0,
    maxValue: 20,
    description: "And on these drinking days, how many standard glasses did you consume on average?",
    info: "(1 standard glass = e.g. 25cL beer, 10cL wine, 2.5cL pastis, 2.5cl whisky)",
    parent: "general_info",
    order: 14,
    lang: "en",
    dependsOn: {
      id: "alc_freq",
      values: [
        "daily",
        "5-6-a-week",
        "3-4-a-week",
        "twice-a-week",
        "once-a-week",
        "2-3-a-month",
        "once-a-month",
        "3-11-a-year",
        "1-2-a-year",
      ],
    },
    required: false,
  },
]

export default generalEN

import { Categorical, MedicalQuestion } from "../../../../@types/medicalQuestion"

const category: Categorical[] = [
  {
    label: "Pas du tout",
    value: "not-at-all",
  },
  {
    label: "Un peu",
    value: "a-little",
  },
  {
    label: "Moyennement",
    value: "moderately",
  },
  {
    label: "Beaucoup",
    value: "a-lot",
  },
  {
    label: "Extrêmement",
    value: "extremely",
  },
]

const vqFR: MedicalQuestion[] = [
  {
    id: "vq_intro",
    name: "intro",
    value:
      "Les phrases suivantes expriment des sentiments sur les conséquences d'une maladie respiratoire. Pour chacune, cochez l’intensité" +
      " qui vous correspond le mieux maintenant (de « pas du tout » à « extrêmement »). Aucune réponse n’est juste. Elle" +
      " est avant tout personnelle.",
    type: "intro",
    lang: "fr",
    parent: "vq",
    required: false,
  },
  {
    id: "vq_1",
    name: "Respiratory quality of life VQ11",
    type: "categoricalShort",
    value: category,
    description: "Je souffre de mon essoufflement",
    parent: "vq",
    order: 1,
    lang: "fr",
    required: true,
  },
  {
    id: "vq_2",
    name: "Respiratory quality of life VQ11",
    type: "categoricalShort",
    value: category,
    description: "Je me fais du souci pour mon état respiratoire",
    parent: "vq",
    order: 2,
    lang: "fr",
    required: true,
  },
  {
    id: "vq_3",
    name: "Respiratory quality of life VQ11",
    type: "categoricalShort",
    value: category,
    description: "Je me sens incompris(e) par mon entourage",
    parent: "vq",
    order: 3,
    lang: "fr",
    required: true,
  },
  {
    id: "vq_4",
    name: "Respiratory quality of life VQ11",
    type: "categoricalShort",
    value: category,
    description: "Mon état respiratoire m’empêche de me déplacer comme je le voudrais",
    parent: "vq",
    order: 4,
    lang: "fr",
    required: true,
  },
  {
    id: "vq_5",
    name: "Respiratory quality of life VQ11",
    type: "categoricalShort",
    value: category,
    description: "Je suis somnolent(e) dans la journée",
    parent: "vq",
    order: 5,
    lang: "fr",
    required: true,
  },
  {
    id: "vq_6",
    name: "Respiratory quality of life VQ11",
    type: "categoricalShort",
    value: category,
    description: "Je me sens incapable de réaliser mes projets",
    parent: "vq",
    order: 6,
    lang: "fr",
    required: true,
  },
  {
    id: "vq_7",
    name: "Respiratory quality of life VQ11",
    type: "categoricalShort",
    value: category,
    description: "Je me fatigue rapidement dans les activités de la vie quotidienne",
    parent: "vq",
    order: 7,
    lang: "fr",
    required: true,
  },
  {
    id: "vq_8",
    name: "Respiratory quality of life VQ11",
    type: "categoricalShort",
    value: category,
    description: "Physiquement, je suis insatisfait(e) de ce que je peux faire",
    parent: "vq",
    order: 8,
    lang: "fr",
    required: true,
  },
  {
    id: "vq_9",
    name: "Respiratory quality of life VQ11",
    type: "categoricalShort",
    value: category,
    description: "Ma maladie respiratoire perturbe ma vie sociale",
    parent: "vq",
    order: 9,
    lang: "fr",
    required: true,
  },
  {
    id: "vq_10",
    name: "Respiratory quality of life VQ11",
    type: "categoricalShort",
    value: category,
    description: "Je me sens triste",
    parent: "vq",
    order: 10,
    lang: "fr",
    required: true,
  },
  {
    id: "vq_11",
    name: "Respiratory quality of life VQ11",
    type: "categoricalShort",
    value: category,
    description: "Mon état respiratoire limite ma vie affective",
    parent: "vq",
    order: 11,
    lang: "fr",
    required: true,
  },
]

export default vqFR

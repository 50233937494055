import { MedicalQuestion } from "../../../../@types/medicalQuestion"

const generalEN: MedicalQuestion[] = [
  {
    id: "lang",
    name: "Language",
    type: "select",
    value: [
      { value: "english_american$", label: "English (American)" },
      { value: "arabic", label: "Arabic" },
      { value: "bengali", label: "Bengali" },
      { value: "chinese_mandarin_wu", label: "Chinese (mandarin, wu...)" },
      { value: "czech", label: "Czech" },
      { value: "dutch", label: "Dutch" },
      { value: "english_american", label: "English (American)" },
      { value: "english_british", label: "English (UK)" },
      { value: "french_france", label: "French (France)" },
      { value: "french_africa", label: "French (Africa)" },
      { value: "french_belgium", label: "French (Belgium)" },
      { value: "french_quebec", label: "French (Quebec)" },
      { value: "french_switzerland", label: "French (Switzerland)" },
      { value: "finnish", label: "Finnish" },
      { value: "german_germany", label: "German (Germany)" },
      { value: "german_swiss", label: "German (Switzerland)" },
      { value: "greek", label: "Greek" },
      { value: "hindi", label: "Hindi" },
      { value: "hungarian", label: "Hungarian" },
      { value: "italian", label: "Italian" },
      { value: "japanese", label: "Japanese" },
      { value: "korean", label: "Korean" },
      { value: "norwegian", label: "Norwegian" },
      { value: "portuguese", label: "Portuguese" },
      { value: "polish", label: "Polish" },
      { value: "romanian", label: "Romanian" },
      { value: "russian", label: "Russian" },
      { value: "spanish", label: "Spanish" },
      { value: "swedish", label: "Swedish" },
      { value: "turkish", label: "Turkish" },
      { value: "other", label: "Other" },
    ],
    description: "What is your mother tongue?",
    parent: "general_info",
    order: 1,
    lang: "en",
    required: true,
  },
  {
    id: "age",
    name: "Age",
    type: "number",
    minValue: 1930,
    maxValue: 2011,
    description: "Birth year",
    parent: "general_info",
    order: 2,
    lang: "en",
    required: true,
  },
  {
    id: "sex",
    name: "Gender",
    type: "categoricalShort",
    value: [
      { label: "Male", value: "male" },
      { label: "Female", value: "female" },
      { label: "Non-binary", value: "non-binary" },
    ],
    description: "Gender",
    parent: "general_info",
    order: 3,
    lang: "en",
    required: true,
  },
  {
    id: "height",
    name: "Height",
    type: "number",
    minValue: 100,
    maxValue: 220,
    description: "Height (cm)",
    parent: "general_info",
    order: 4,
    lang: "en",
    required: true,
  },
  {
    id: "weight",
    name: "Weight",
    type: "number",
    minValue: 40,
    maxValue: 220,
    description: "Weight (kg)",
    parent: "general_info",
    order: 5,
    lang: "en",
    required: true,
  },
  {
    id: "c_smo",
    name: "Current tobacco smoking",
    type: "categoricalShort",
    value: [
      { label: "Daily", value: "daily" },
      { label: "Less than daily", value: "less-than-daily" },
      { label: "Not at all", value: "not-at-all" },
    ],
    description: "Do you currently smoke (tobacco or any other substance, whatever the form, including electronic cigarette) ?",
    parent: "general_info",
    order: 6,
    lang: "en",
    required: true,
  },
  {
    id: "c_smo_view",
    name: "Current smoking overview question",
    type: "headerText",
    headerStyle: "subtitle1",
    description: "On average, how many of each form below do you smoke currently?",
    parent: "general_info",
    order: 7,
    lang: "en",
    dependsOn: {
      id: "c_smo",
      values: ["daily", "less-than-daily"],
    },
    required: false,
  },
  {
    id: "c_smo_c",
    name: "Current smoking cigarettes quantity",
    type: "number",
    minValue: 0,
    maxValue: 150,
    description: "cigarettes per day (as a reminder, 1 pack = 20 cigarettes) ?",
    parent: "general_info",
    order: 8,
    lang: "en",
    dependsOn: {
      id: "c_smo",
      values: ["daily", "less-than-daily"],
    },
    required: false,
  },
  {
    id: "c_smo_cig",
    name: "Current smoking cigars quantity",
    type: "number",
    minValue: 0,
    maxValue: 100,
    description: "Cigars per week ?",
    parent: "general_info",
    order: 9,
    lang: "en",
    dependsOn: {
      id: "c_smo",
      values: ["daily", "less-than-daily"],
    },
    required: false,
  },
  {
    id: "c_smo_ril",
    name: "Current smoking cigarillos quantity",
    type: "number",
    minValue: 0,
    maxValue: 150,
    description: "Cigarillos per week ?",
    parent: "general_info",
    order: 10,
    lang: "en",
    dependsOn: {
      id: "c_smo",
      values: ["daily", "less-than-daily"],
    },
    required: false,
  },
  {
    id: "c_smo_joi",
    name: "Current smoking joints quantity",
    type: "number",
    minValue: 0,
    maxValue: 150,
    description: "Joints per week ?",
    parent: "general_info",
    order: 11,
    lang: "en",
    dependsOn: {
      id: "c_smo",
      values: ["daily", "less-than-daily"],
    },
    required: false,
  },
  {
    id: "c_smo_nar",
    name: "Current smoking water pipes quantity",
    type: "number",
    minValue: 0,
    maxValue: 10,
    description: "Hookah / water pipes, in hours per week ?",
    parent: "general_info",
    order: 12,
    lang: "en",
    dependsOn: {
      id: "c_smo",
      values: ["daily", "less-than-daily"],
    },
    required: false,
  },
  {
    id: "c_smo_ele",
    name: "Current smoking electronic cigarettes quantity",
    type: "number",
    minValue: 0,
    maxValue: 5,
    description: "Electronic cigarettes, in bottles of 10mL of e-liquid per week?",
    parent: "general_info",
    order: 13,
    lang: "en",
    dependsOn: {
      id: "c_smo",
      values: ["daily", "less-than-daily"],
    },
    required: false,
  },
  {
    id: "c_dur",
    name: "Current duration smoking",
    type: "number",
    minValue: 1,
    maxValue: 60,
    description: "How many years have you been smoking?",
    parent: "general_info",
    order: 14,
    lang: "en",
    dependsOn: {
      id: "c_smo",
      values: ["daily", "less-than-daily"],
    },
    required: false,
  },
  {
    id: "p_smo",
    name: "Past tobacco smoking",
    type: "categoricalShort",
    value: [
      { label: "Daily", value: "daily" },
      { label: "Less than daily", value: "less-than-daily" },
      { label: "Not at all", value: "not-at-all" },
    ],
    description: "Have you smoked in the past (tobacco or any other substance, whatever the form, including electronic cigarette)?",
    parent: "general_info",
    order: 15,
    lang: "en",
    required: true,
  },
  {
    id: "p_smo_view",
    name: "Past smoking overview question",
    type: "headerText",
    headerStyle: "subtitle1",
    description: "On average, how many of each form below were you smoking then?",
    parent: "general_info",
    order: 16,
    lang: "en",
    dependsOn: {
      id: "p_smo",
      values: ["daily", "less-than-daily"],
    },
    required: false,
  },
  {
    id: "p_smo_c",
    name: "Past smoking cigarettes quantity",
    type: "number",
    minValue: 0,
    maxValue: 150,
    description: "Cigarettes per day (as a reminder, 1 pack = 20 cigarettes)?",
    parent: "general_info",
    order: 17,
    lang: "en",
    dependsOn: {
      id: "p_smo",
      values: ["daily", "less-than-daily"],
    },
    required: false,
  },
  {
    id: "p_smo_cig",
    name: "Past smoking cigars quantity",
    type: "number",
    minValue: 0,
    maxValue: 100,
    description: "Cigars per week?",
    parent: "general_info",
    order: 18,
    lang: "en",
    dependsOn: {
      id: "p_smo",
      values: ["daily", "less-than-daily"],
    },
    required: false,
  },
  {
    id: "p_smo_ril",
    name: "Past smoking cigarillos quantity",
    type: "number",
    minValue: 0,
    maxValue: 150,
    description: "Cigarillos per week?",
    parent: "general_info",
    order: 19,
    lang: "en",
    dependsOn: {
      id: "c_smo",
      values: ["daily", "less-than-daily"],
    },
    required: false,
  },
  {
    id: "p_smo_joi",
    name: "Past smoking joints quantity",
    type: "number",
    minValue: 0,
    maxValue: 150,
    description: "Joints per week?",
    parent: "general_info",
    order: 20,
    lang: "en",
    dependsOn: {
      id: "p_smo",
      values: ["daily", "less-than-daily"],
    },
    required: false,
  },
  {
    id: "p_smo_nar",
    name: "Past smoking water pipes quantity",
    type: "number",
    minValue: 0,
    maxValue: 10,
    description: "Hookah / water pipes, in hours per week?",
    parent: "general_info",
    order: 21,
    lang: "en",
    dependsOn: {
      id: "p_smo",
      values: ["daily", "less-than-daily"],
    },
    required: false,
  },
  {
    id: "p_smo_ele",
    name: "Past smoking electronic cigarettes quantity",
    type: "number",
    minValue: 0,
    maxValue: 5,
    description: "Electronic cigarettes, in bottles of 10mL of e-liquid per week?",
    parent: "general_info",
    order: 22,
    lang: "en",
    dependsOn: {
      id: "p_smo",
      values: ["daily", "less-than-daily"],
    },
    required: false,
  },
  {
    id: "p_dur",
    name: "Past duration smoking",
    type: "number",
    minValue: 1,
    maxValue: 60,
    description: "How many years have you smoked?",
    parent: "general_info",
    order: 23,
    lang: "en",
    dependsOn: {
      id: "p_smo",
      values: ["daily", "less-than-daily"],
    },
    required: false,
  },
  {
    id: "p_dur_stop",
    name: "Past duration smoking stop",
    type: "number",
    minValue: 0,
    maxValue: 60,
    description: "How many years ago did you quit smoking?",
    parent: "general_info",
    order: 24,
    lang: "en",
    dependsOn: {
      id: "p_smo",
      values: ["daily", "less-than-daily"],
    },
    required: false,
  },
]

export default generalEN

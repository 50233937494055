import { MedicalQuestion } from "../../../../@types/medicalQuestion"

const generalDE: MedicalQuestion[] = [
  {
    id: "lang",
    name: "Language",
    type: "select",
    value: [
      { value: "german_germany$", label: "Deutsch (Deutschland)" },
      { value: "arabic", label: "Arabisch" },
      { value: "bengali", label: "Bengali" },
      { value: "chinese_mandarin_wu", label: "Chinesisch (mandarin, wu...)" },
      { value: "czech", label: "Tschechisch" },
      { value: "dutch", label: "Holländisch" },
      { value: "english_american", label: "Englisch (amerikanisch)" },
      { value: "english_british", label: "Englisch (britisch)" },
      { value: "french_france", label: "Französisch (Frankreich)" },
      { value: "french_africa", label: "Französisch (Afrikanisch)" },
      { value: "french_belgium", label: "Französisch (Belgisch)" },
      { value: "french_quebec", label: "Französisch (Quebec)" },
      { value: "french_switzerland", label: "Französisch (Schweiz)" },
      { value: "finnish", label: "Finnisch" },
      { value: "german_germany", label: "Deutsch (Deutschland)" },
      { value: "german_swiss", label: "Deutsch (Schweiz)" },
      { value: "greek", label: "Griechisch" },
      { value: "hindi", label: "Hindi" },
      { value: "hungarian", label: "Ungarisch" },
      { value: "italian", label: "Italienisch" },
      { value: "japanese", label: "Japanisch" },
      { value: "korean", label: "Koreanisch" },
      { value: "norwegian", label: "Norwegisch" },
      { value: "portuguese", label: "Portugiesisch" },
      { value: "polish", label: "Polnisch" },
      { value: "romanian", label: "Rumänisch" },
      { value: "russian", label: "Russisch" },
      { value: "spanish", label: "Spanisch" },
      { value: "swedish", label: "Schwedisch" },
      { value: "turkish", label: "Türkisch" },
      { value: "other", label: "Andere" },
    ],
    description: "Was ist Ihre Muttersprache?",
    parent: "general_info",
    order: 1,
    lang: "de",
    required: true,
  },
  {
    id: "age",
    name: "Age",
    type: "number",
    minValue: 1930,
    maxValue: 2011,
    description: "Geburtsjahr",
    parent: "general_info",
    order: 2,
    lang: "de",
    required: true,
  },
  {
    id: "sex",
    name: "Gender",
    type: "categoricalShort",
    value: [
      { label: "Männlich", value: "male" },
      { label: "Weiblich", value: "female" },
      { label: "Nicht-binär", value: "non-binary" },
    ],
    description: "Geschlecht",
    parent: "general_info",
    order: 3,
    lang: "de",
    required: true,
  },
  {
    id: "height",
    name: "Height",
    type: "number",
    minValue: 100,
    maxValue: 220,
    description: "Größe (cm)",
    parent: "general_info",
    order: 4,
    lang: "de",
    required: true,
  },
  {
    id: "weight",
    name: "Weight",
    type: "number",
    minValue: 40,
    maxValue: 200,
    description: "Gewicht (kg)",
    parent: "general_info",
    order: 5,
    lang: "de",
    required: true,
  },
  {
    id: "c_smo",
    name: "Current tobacco smoking",
    type: "categoricalShort",
    value: [
      { label: "Täglich", value: "daily" },
      { label: "Weniger als täglich", value: "less-than-daily" },
      { label: "Überhaupt nicht", value: "not-at-all" },
    ],
    description:
      "Sind Sie derzeit Raucher/in (Tabak oder andere Substanzen, in jeglicher Form, einschließlich " + "elektronischer Zigaretten) ?",
    parent: "general_info",
    order: 6,
    lang: "de",
    required: true,
  },
  {
    id: "c_smo_quant",
    name: "Current smoking quantity",
    type: "number",
    minValue: 0,
    maxValue: 500,
    description: "Wie viele Zigaretten (oder Äquivalente) rauchen Sie durchschnittlich pro Woche?",
    info:
      "Einige Äquivalente zur Erinnerung:\n" +
      "- 1 Schachtel Zigaretten = 20 Zigaretten\n" +
      "- 1 Zigarre entspricht ca. 15 Zigaretten\n" +
      "- 1 Zigarillo entspricht ca. 3 Zigaretten\n" +
      "- 1 Joint entspricht ca. 3 Zigaretten\n" +
      "- 1 Stunde Wasserpfeife entspricht ca. 5 Schachteln Zigaretten.\n" +
      "- 1 Flasche mit 10mL E-Liquid entspricht ca. 10 Schachteln Zigaretten",
    parent: "general_info",
    order: 7,
    lang: "de",
    dependsOn: {
      id: "c_smo",
      values: ["daily", "less-than-daily"],
    },
    required: false,
  },
  {
    id: "c_dur",
    name: "Current duration smoking",
    type: "number",
    minValue: 1,
    maxValue: 60,
    description: "Seit wie vielen Jahren rauchen Sie ?",
    parent: "general_info",
    order: 8,
    lang: "de",
    dependsOn: {
      id: "c_smo",
      values: ["daily", "less-than-daily"],
    },
    required: false,
  },
  {
    id: "p_smo",
    name: "Past tobacco smoking",
    type: "categoricalShort",
    value: [
      { label: "Täglich", value: "daily" },
      { label: "Weniger als täglich", value: "less-than-daily" },
      { label: "Überhaupt nicht", value: "not-at-all" },
    ],
    description:
      "Haben Sie in der Vergangenheit geraucht (Tabak oder andere Substanzen, in jeglicher Form, " +
      "einschließlich elektronischer Zigaretten)?",
    parent: "general_info",
    order: 9,
    lang: "de",
    dependsOn: {
      id: "c_smo",
      values: ["not-at-all"],
    },
    required: false,
  },
  {
    id: "p_smo_quant",
    name: "Current smoking quantity",
    type: "number",
    minValue: 1,
    maxValue: 500,
    description: "Wie viele Zigaretten (oder Äquivalente) haben Sie damals durchschnittlich pro Woche geraucht?",
    info:
      "Einige Äquivalente zur Erinnerung:\n" +
      "- 1 Schachtel Zigaretten = 20 Zigaretten\n" +
      "- 1 Zigarre entspricht ca. 15 Zigaretten\n" +
      "- 1 Zigarillo entspricht ca. 3 Zigaretten\n" +
      "- 1 Joint entspricht ca. 3 Zigaretten\n" +
      "- 1 Stunde Wasserpfeife entspricht ca. 5 Schachteln Zigaretten.\n" +
      "- 1 Flasche mit 10mL E-Liquid entspricht ca. 10 Schachteln Zigaretten",
    parent: "general_info",
    order: 10,
    lang: "de",
    dependsOn: {
      id: "p_smo",
      values: ["daily", "less-than-daily"],
    },
    required: false,
  },
  {
    id: "p_dur",
    name: "Past duration smoking",
    type: "number",
    minValue: 1,
    maxValue: 60,
    description: "Wie viele Jahre lang haben Sie geraucht ?",
    parent: "general_info",
    order: 23,
    lang: "de",
    dependsOn: {
      id: "p_smo",
      values: ["daily", "less-than-daily"],
    },
    required: false,
  },
  {
    id: "p_dur_stop",
    name: "Past duration smoking stop",
    type: "number",
    minValue: 0,
    maxValue: 60,
    description: "Vor wie vielen Jahren haben Sie mit dem Rauchen aufgehört ?",
    parent: "general_info",
    order: 24,
    lang: "de",
    dependsOn: {
      id: "p_smo",
      values: ["daily", "less-than-daily"],
    },
    required: false,
  },
  {
    id: "alc_freq",
    name: "Alcohol frequency",
    type: "categoricalShort",
    value: [
      { label: "Täglich", value: "daily" },
      { label: "5 oder 6 Mal pro Woche", value: "5-6-a-week" },
      { label: "3 oder 4 Mal pro Woche", value: "3-4-a-week" },
      { label: "2 Mal pro Woche", value: "twice-a-week" },
      { label: "1 Mal pro Woche", value: "once-a-week" },
      { label: "2 oder 3 Mal pro Monat", value: "2-3-a-month" },
      { label: "1 Mal pro Monat", value: "once-a-month" },
      { label: "3 bis 11 Mal in den letzten 12 Monaten", value: "3-11-a-year" },
      { label: "1 oder 2 Mal in den letzten 12 Monaten", value: "1-2-a-year" },
      { label: "nie", value: "never" },
    ],
    description: "Wie oft haben Sie in den letzten 12 Monaten durchschnittlich Alkohol getrunken?",
    info: "jede Form von Alkohol und jede Menge",
    parent: "general_info",
    order: 13,
    lang: "de",
    required: true,
  },
  {
    id: "alc_quant",
    name: "Alcool quantity ",
    type: "number",
    minValue: 0,
    maxValue: 20,
    description: "Und an diesen Tagen, an denen Sie getrunken haben, wie viele Standardgläser haben Sie im Durchschnitt getrunken?",
    info: "(1 Standardglas = z.B. 25cL Bier, 10cL Wein, 2.5cL Pastis, 2.5cl Whisky)",
    parent: "general_info",
    order: 14,
    lang: "de",
    dependsOn: {
      id: "alc_freq",
      values: [
        "daily",
        "5-6-a-week",
        "3-4-a-week",
        "twice-a-week",
        "once-a-week",
        "2-3-a-month",
        "once-a-month",
        "3-11-a-year",
        "1-2-a-year",
      ],
    },
    required: false,
  },
]

export default generalDE

import { Categorical, MedicalQuestion } from "../../../../@types/medicalQuestion"

const category: Categorical[] = [
  {
    label: "Not at all",
    value: "not-at-all",
  },
  {
    label: "A little",
    value: "a-little",
  },
  {
    label: "Moderately",
    value: "moderately",
  },
  {
    label: "A lot",
    value: "a-lot",
  },
]

const emotionsEN: MedicalQuestion[] = [
  {
    id: "emot_intro",
    name: "intro",
    value:
      // eslint-disable-next-line max-len
      "Which of the following emotions best describe how you feel right now? Choose how intense you are currently feeling the following emotions.",
    type: "intro",
    lang: "en",
    parent: "emotion",
    required: false,
  },
  {
    id: "emot_joy",
    name: "Emotions",
    type: "categoricalShort",
    value: category,
    description: "Joy",
    parent: "emotion",
    order: 1,
    lang: "en",
    required: true,
  },
  {
    id: "emot_neut",
    name: "Emotions",
    type: "categoricalShort",
    value: category,
    description: "Neutral",
    parent: "emotion",
    order: 2,
    lang: "en",
    required: true,
  },
  {
    id: "emot_trust",
    name: "Emotions",
    type: "categoricalShort",
    value: category,
    description: "Trust",
    parent: "emotion",
    order: 3,
    lang: "en",
    required: true,
  },
  {
    id: "emot_fear",
    name: "Emotions",
    type: "categoricalShort",
    value: category,
    description: "Fear",
    parent: "emotion",
    order: 4,
    lang: "en",
    required: true,
  },
  {
    id: "emot_surprise",
    name: "Emotions",
    type: "categoricalShort",
    value: category,
    description: "Surprise",
    parent: "emotion",
    order: 5,
    lang: "en",
    required: true,
  },
  {
    id: "emot_sad",
    name: "Emotions",
    type: "categoricalShort",
    value: category,
    description: "Sad",
    parent: "emotion",
    order: 6,
    lang: "en",
    required: true,
  },
  {
    id: "emot_anger",
    name: "Emotions",
    type: "categoricalShort",
    value: category,
    description: "Anger",
    parent: "emotion",
    order: 7,
    lang: "en",
    required: true,
  },
]

export default emotionsEN

import Iconify from "../../components/Iconify"
import useLocales from "../../hooks/useLocales"

// ----------------------------------------------------------------------

const ICON_SIZE = {
  width: 22,
  height: 22,
}

export default function AdminHeaderConfig() {
  const { translate } = useLocales()
  return [
    {
      title: translate("menu.admin"),
      icon: <Iconify icon={"eva:home-fill"} {...ICON_SIZE} />,
      path: "/admin",
    },
  ]
}

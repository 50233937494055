import { MedicalQuestion } from "../../../../@types/medicalQuestion"

const cancerFR: MedicalQuestion[] = [
  {
    id: "c_type",
    name: "cancer type",
    type: "multi",
    value: [
      { label: "Sein", value: "breast" },
      { label: "Poumon", value: "lung" },
      { label: "Cerveau", value: "brain" },
      { label: "Lèvres", value: "lips" },
      { label: "Langue", value: "tongue" },
      { label: "Bouche", value: "mouth" },
      { label: "Gorge", value: "throat" },
      { label: "Larynx", value: "larynx" },
      { label: "Glandes salivaires", value: "salivary-glands" },
      { label: "Pharynx", value: "pharynx" },
      { label: "Sinus", value: "sinus" },
      { label: "Fosse nasale", value: "nasal-cavity" },
      { label: "Autre localisation sur la tête où le cou", value: "other-head-neck" },
      { label: "Autre localisation", value: "other" },
    ],
    description: "Où se situe votre cancer actuel ?",
    parent: "cancer",
    order: 1,
    lang: "fr",
    required: true,
  },
  {
    id: "c_diag",
    name: "first cancer diagnosis",
    type: "categoricalShort",
    value: [
      { label: "Premier diagnostic", value: "first-diagnosis" },
      { label: "Première récidive", value: "first-recurrence" },
      { label: "Deuxième récidive", value: "second-recurrence" },
      { label: "Troisième récidive", value: "third-recurrence" },
      { label: "Quatrième récidive", value: "fourth-recurrence" },
      { label: "Cinquième récidive", value: "fifth-recurrence" },
      { label: "Sixième récidive", value: "sixth-recurrence" },
      { label: "Septième récidive", value: "seventh-recurrence" },
      { label: "Huitième récidive", value: "eighth-recurrence" },
      { label: "Neuvième récidive", value: "ninth-recurrence" },
      { label: "Dixième récidive", value: "tenth-recurrence" },
    ],
    description: "Est-ce votre premier diagnostic de cancer de ce type ou s'agit-il d'une récidive ?",
    parent: "cancer",
    order: 2,
    lang: "fr",
    required: true,
  },
  {
    id: "c_diag_y",
    name: "cancer diag year",
    type: "number",
    minValue: 1980,
    maxValue: 2030,
    description: "Quelle est la date du diagnostic de votre cancer actuel ?",
    parent: "cancer",
    order: 3,
    lang: "fr",
    required: false,
  },
  {
    id: "c_treat_p",
    name: "cancer treatment past",
    type: "multi",
    value: [
      { label: "Chirurgie", value: "surgery" },
      { label: "Radiothérapie", value: "radiotherapy" },
      { label: "Immunothérapie", value: "immunotherapy" },
      { label: "Chimiothérapie", value: "chemotherapy" },
      { label: "Autre", value: "other" },
    ],
    description:
      "Pour votre cancer actuel ou pour un éventuel cancer passé, quel type de traitement avez-vous reçu ? " +
      "Note : ici, ne prenez en compte que les traitements que vous ne recevez plus",
    parent: "cancer",
    order: 4,
    lang: "fr",
    required: false,
  },
  {
    id: "c_treat_c",
    name: "cancer treatment current",
    type: "multi",
    value: [
      { label: "Chirurgie", value: "surgery" },
      { label: "Radiothérapie", value: "radiotherapy" },
      { label: "Immunothérapie", value: "immunotherapy" },
      { label: "Chimiothérapie", value: "chemotherapy" },
      { label: "Autre", value: "other" },
    ],
    description: "Pour votre cancer actuel, quel type de traitement recevez-vous actuellement ?",
    parent: "cancer",
    order: 5,
    lang: "fr",
    required: false,
  },
  {
    id: "c_type_b",
    name: "cancer subcategory breast",
    type: "categoricalShort",
    value: [
      { label: "Luminal A", value: "luminal-a" },
      { label: "Luminal B", value: "luminal-b" },
      { label: "HER2-positive", value: "HER2-positive" },
      { label: "triple negative", value: "triple-negative" },
      { label: "information-manquante", value: "missing-information" },
    ],
    description: "De quelle sous-catégorie de cancer du sein s'agissait-il ?",
    parent: "cancer",
    order: 6,
    lang: "fr",
    dependsOn: {
      id: "c_type",
      values: ["breast"],
    },
    required: false,
    doctor: true,
  },
  {
    id: "c_type_l",
    name: "cancer subcategory lung",
    type: "categoricalShort",
    value: [
      { label: "NSCLC", value: "nsclc" },
      { label: "SCLC", value: "sclc" },
      { label: "information manquante", value: "missing-information" },
    ],
    description: "De quelle sous-catégorie de cancer du poumon s'agissait-il ?",
    parent: "cancer",
    order: 7,
    lang: "fr",
    dependsOn: {
      id: "c_type",
      values: ["lung"],
    },
    required: false,
    doctor: true,
  },
  {
    id: "c_tnm_t",
    name: "cancer tnm classification - t",
    type: "select",
    value: [
      { label: "Tx", value: "tx" },
      { label: "T0", value: "t0" },
      { label: "Tis", value: "tis" },
      { label: "T1", value: "t1" },
      { label: "T2", value: "t2" },
      { label: "T3", value: "t3" },
      { label: "T4", value: "t4" },
      { label: "information manquante", value: "missing-information" },
    ],
    description: "Quelle est la classification TNM (T) ?",
    parent: "cancer",
    order: 8,
    lang: "fr",
    required: true,
    doctor: true,
  },
  {
    id: "c_tnm_n",
    name: "cancer tnm classification - n",
    type: "select",
    value: [
      { label: "Nx", value: "nx" },
      { label: "N0", value: "n0" },
      { label: "N1", value: "n1" },
      { label: "N2", value: "n2" },
      { label: "N3", value: "n3" },
      { label: "information manquante", value: "missing-information" },
    ],
    description: "Quelle est la classification TNM (N) ?",
    parent: "cancer",
    order: 9,
    lang: "fr",
    required: true,
    doctor: true,
  },
  {
    id: "c_tnm_m",
    name: "cancer tnm classification - m",
    type: "select",
    value: [
      { label: "Mx", value: "mx" },
      { label: "M0", value: "m0" },
      { label: "M1", value: "m1" },
      { label: "information manquante", value: "missing-information" },
    ],
    description: "Quelle est la classification TNM (M) ?",
    parent: "cancer",
    order: 10,
    lang: "fr",
    required: true,
    doctor: true,
  },
]

export default cancerFR

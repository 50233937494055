import consents from "./consent"
import cancer from "./questions/cancer"
import tasksRecording from "./tasks"
import { Project } from "../../@types/project"
import FSS from "./questions/fss"
import cofounding from "./questions/cofounding"
import PHQ from "./questions/phq"
import GAD from "./questions/gad"
import general from "./questions/general"

const project: Project = {
  projectID: "f0ffcf6c-1c3f-46cb-979b-17b6f09d26c0",
  projectName: "Speakuity C1",
  projectLocation: "University hospital Tübingen",
  projectCreated: "01/01/2023",
  projectType: "oneshot",
  defaultLang: "en",
  projectLang: ["en", "fr", "de"],
  voiceRecordings: tasksRecording,
  questionnaires: [
    { questionnaire: general, optional: false },
    { questionnaire: PHQ, optional: false },
    { questionnaire: GAD, optional: false },
    { questionnaire: FSS, optional: false },
    { questionnaire: cofounding, optional: false },
  ],
  diseaseQuestions: [{ questionnaire: cancer, optional: false }],
  consents: consents,
  isPatientAnsweringDiseaseQuestions: true,
  showOptionToStartWithNonDefaultTasks: true,
  isStartWithQuestionnaires: true,
  isAskPhoneNumber: false,
  showQuestionnaireIdFinalPage: true,
  showVocalTaskInstructions: false,
  showResult: false,
  showAdmin: true,
  versionData: "1",
  transparenceID: "983klp",
}

export default project

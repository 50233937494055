import { Consent } from "../../../@types/consent"
/* eslint-disable */
const consentFR: Consent = {
  lang: "fr",
  text: `
<h2 class="code-line" data-line-start=0 data-line-end=1 ><a id="SPEAKUITY_MH2_0"></a>SPEAKUITY MH2</h2>
<p class="has-line-data" data-line-start="1" data-line-end="3"> <br>
Collecte de données vocales et de données de santé pour développer des biomarqueurs vocaux* relatifs à la détresse physio-psychologique</p>
<p class="has-line-data" data-line-start="4" data-line-end="6">* Les mots ou groupes de mots surmontés d’un astérisque sont inclus dans le glossaire<br>
 </p>
<br><h4 class="code-line" data-line-start=6 data-line-end=7 ><a id="Vos_contacts_6"></a>Vos contacts:</h4>
<hr>
<br>
<p class="has-line-data" data-line-start="8" data-line-end="9"><strong>Promoteur</strong>* de la recherche et responsable du traitement:</p>
<blockquote>
<p class="has-line-data" data-line-start="9" data-line-end="13">AKUITY CARE<br>
23 rue Léon Jost 75017 Paris<br>
+ 33 6 10 69 47 54<br>
<a href="mailto:contact@akuity.care">contact@akuity.care</a></p>
</blockquote>
<br>
<p class="has-line-data" data-line-start="14" data-line-end="15"><strong>Investigateur* coordinateur</strong> de la recherche:</p>
<blockquote>
<p class="has-line-data" data-line-start="15" data-line-end="19">Dr. Guy Fagherazzi<br>
Luxembourg Institute of Health<br>
+ 33 1 55 25 01 90<br>
<a href="mailto:guy.fagherazzi@lih.lu">guy.fagherazzi@lih.lu</a></p>
</blockquote>
<br>
<p class="has-line-data" data-line-start="20" data-line-end="21">Délégué à la protection des données <strong>(DPO)</strong>* du promoteur:</p>
<blockquote>
<p class="has-line-data" data-line-start="21" data-line-end="23">Jean-Marie Nazarenko<br>
<a href="mailto:dpo@akuity.care">dpo@akuity.care</a></p>
</blockquote>
<br>
<p class="has-line-data" data-line-start="25" data-line-end="26">CNIL- Commission nationale de l’Informatique et des libertés:</p>
<blockquote>
<p class="has-line-data" data-line-start="26" data-line-end="29">CNIL / Service des Plaintes<br>
3 Place de Fontenoy TSA 80715<br>
75334 PARIS CEDEX 07</p>
</blockquote>
<hr>
<p class="has-line-data" data-line-start="31" data-line-end="32"> </p>
<br><h4 class="code-line" data-line-start=32 data-line-end=33 ><a id="Pouvezparticiper__cette_Recherche__32"></a>Pouvez-participer à cette Recherche ?</h4>
<p class="has-line-data" data-line-start="34" data-line-end="35">Pour participer à cette Recherche, vous devez valider toutes les conditions ci-après :</p>
<ul>
<li class="has-line-data" data-line-start="35" data-line-end="36">être majeur (&gt;=18 ans)</li>
<li class="has-line-data" data-line-start="36" data-line-end="37">être utilisateur d’un terminal connecté à internet et équipé d’un microphone (smartphone, tablette ou ordinateur) pour le remplissage et l’envoi des questionnaires numériques et la capture de la voix</li>
<li class="has-line-data" data-line-start="37" data-line-end="38">parler couramment l’une des langues suivantes : le français, l’allemand, l’anglais ou l’espagnol</li>
<li class="has-line-data" data-line-start="38" data-line-end="39">ne pas être privé(e) de liberté, sous tutelle ou curatelle</li>
<li class="has-line-data" data-line-start="39" data-line-end="40">maîtriser suffisamment le français, l’allemand, l’anglais, l’espagnol ou la lecture pour lire un texte de quelques lignes ou répondre à des questionnaires simples dans ces langues</li>
<li class="has-line-data" data-line-start="40" data-line-end="41">ne pas avoir de lourdes difficultés d’élocution ou une élocution très altérée</li>
</ul>
<p class="has-line-data" data-line-start="43" data-line-end="44"> </p>
<br>
<br><h4 class="code-line" data-line-start=44 data-line-end=45 ><a id="Sommaire__44"></a>Sommaire :</h4>
<table class="table table-striped table-bordered">
<thead>
<tr>
<th></th>
<th style="text-align:right">Page</th>
</tr>
</thead>
<tbody>
<tr>
<td><strong>PARTIE 1 : INFORMATIONS SUR LA RECHERCHE</strong></td>
<td style="text-align:right">3</td>
</tr>
<tr>
<td>Pourquoi cette Recherche est-elle mise en place ?</td>
<td style="text-align:right">3</td>
</tr>
<tr>
<td>En quoi la Recherche consiste-t-elle ?</td>
<td style="text-align:right">3</td>
</tr>
<tr>
<td>Comment la Recherche se déroule-t-elle ?</td>
<td style="text-align:right">4</td>
</tr>
<tr>
<td>Quels sont les bénéfices attendus et risques liés à la Recherche ?</td>
<td style="text-align:right">4</td>
</tr>
<tr>
<td>Quelles sont les indemnités prévues ?</td>
<td style="text-align:right">4</td>
</tr>
<tr>
<td>Que se passera-t-il en cas d’arrêt prématuré de la Recherche et après la Recherche ?</td>
<td style="text-align:right">4</td>
</tr>
<tr>
<td>Combien de temps vos données seront-elles conservées ?</td>
<td style="text-align:right">5</td>
</tr>
<tr>
<td>Dispositions législatives et réglementaires</td>
<td style="text-align:right">5</td>
</tr>
<tr>
<td><strong>PARTIE 2 : INFORMATIONS SUR LES DROITS DU PARTICIPANT ET SUR LA GESTION DES DONNEES RECUEILLIES</strong></td>
<td style="text-align:right">6</td>
</tr>
<tr>
<td>Quels sont vos droits ?</td>
<td style="text-align:right">6</td>
</tr>
<tr>
<td>Comment vos données personnelles* seront-elles traitées dans le cadre de la recherche ?</td>
<td style="text-align:right">6</td>
</tr>
<tr>
<td>Quelle est la base juridique et la finalité du traitement de vos données personnelles ?</td>
<td style="text-align:right">7</td>
</tr>
<tr>
<td>Comment la confidentialité de vos données sera-t-elle assurée ?</td>
<td style="text-align:right">8</td>
</tr>
<tr>
<td>Qui aura accès à vos données dans le cadre de la Recherche ?</td>
<td style="text-align:right">8</td>
</tr>
<tr>
<td>Quels sont vos droits relatifs à vos données personnelles ?</td>
<td style="text-align:right">9</td>
</tr>
<tr>
<td>Comment exercer vos droits ?</td>
<td style="text-align:right">9</td>
</tr>
<tr>
<td>Vos données codées pourront-elles être réutilisées ?</td>
<td style="text-align:right">9</td>
</tr>
<tr>
<td><strong>PARTIE 3 : GLOSSAIRE</strong></td>
<td style="text-align:right">11</td>
</tr>
</tbody>
</table>
<p class="has-line-data" data-line-start="67" data-line-end="68"> </p><br>
<br><h2 class="code-line" data-line-start=69 data-line-end=70 ><a id="PARTIE_1__INFORMATIONS_SUR_LA_RECHERCHE_69"></a>PARTIE 1 : INFORMATIONS SUR LA RECHERCHE</h2>
<p class="has-line-data" data-line-start="71" data-line-end="72">Madame, Monsieur,</p>
<p class="has-line-data" data-line-start="73" data-line-end="74">Dans le cadre de votre utilisation de l’application voqia ou du site internet <a href="https://www.yourvoice.care/">https://www.yourvoice.care/</a>, il vous est proposé de participer à une recherche qui a pour objectif de collecter des données pour développer des biomarqueurs vocaux* relatifs à la détresse physio-psychologique.</p>
<p class="has-line-data" data-line-start="75" data-line-end="76">Vous êtes libre d’y participer ou non. Vous pouvez prendre le temps nécessaire pour lire les informations ci-dessous, discuter avec vos proches et poser toutes vos questions au point de contact coordonnant la recherche, appelé investigateur*. Après avoir obtenu les réponses satisfaisantes à vos questions et disposé d’un délai suffisant de réflexion, vous pourrez alors décider si vous acceptez de participer à la recherche ou non.</p>
<p class="has-line-data" data-line-start="77" data-line-end="78"> </p>
<br><h3 class="code-line" data-line-start=78 data-line-end=79 ><a id="Pourquoi_cette_Recherche_estelle_mise_en_place__78"></a>Pourquoi cette Recherche est-elle mise en place ?</h5>
<p class="has-line-data" data-line-start="80" data-line-end="82">AKUITY CARE, le promoteur* de cette Recherche, envisage de développer une solution d’IA (Intelligence Artificielle*) permettant, à partir d’une analyse de la voix, d’évaluer certains paramètres définis comme des marqueurs de la détresse physio-psychologique.<br>
Les algorithmes d’IA* qui seraient à terme intégrés dans une telle solution sont appelés <strong>biomarqueurs vocaux</strong>*. Ils donnent une évaluation d’un état particulier à partir d’un échantillon de voix.</p>
<p class="has-line-data" data-line-start="83" data-line-end="84">Pour les développer, il faut les entraîner en leur fournissant, un grand nombre de fois :</p>
<ul>
<li class="has-line-data" data-line-start="84" data-line-end="85">d’une part, des données sur l’état d’individus,</li>
<li class="has-line-data" data-line-start="85" data-line-end="87">d’autre part, des échantillons de leur voix.</li>
</ul>
<p class="has-line-data" data-line-start="87" data-line-end="88">En lui donnant la réponse qui est attendue de sa part, on apprend ainsi peu à peu à un algorithme à trouver cette réponse tout seul, c’est-à-dire à déduire l’état d’un individu sur les paramètres de la détresse physio-psychologique définis ci-dessus, uniquement à partir de sa voix.</p>
<p class="has-line-data" data-line-start="89" data-line-end="90">Cette Recherche vise à collecter, auprès de vous et d’autres utilisateur de l’application voqia et du site internet <a href="https://www.yourvoice.care/">https://www.yourvoice.care/</a>, des données pour développer et entraîner des biomarqueurs vocaux*.</p>
<p class="has-line-data" data-line-start="91" data-line-end="92"> </p>
<br><h3 class="code-line" data-line-start=92 data-line-end=93 ><a id="En_quoi_la_Recherche_consistetelle__92"></a>En quoi la Recherche consiste-t-elle ?</h5>
<p class="has-line-data" data-line-start="94" data-line-end="95">Cette Recherche vise plusieurs paramètres relatifs à la détresse physio-psychologique :</p>
<ul>
<li class="has-line-data" data-line-start="96" data-line-end="97">les symptômes dépressifs</li>
<li class="has-line-data" data-line-start="97" data-line-end="98">les troubles anxieux</li>
<li class="has-line-data" data-line-start="98" data-line-end="99">les symptômes de fatigue</li>
<li class="has-line-data" data-line-start="99" data-line-end="100">le stress</li>
<li class="has-line-data" data-line-start="100" data-line-end="101">la douleur</li>
</ul>
<p class="has-line-data" data-line-start="103" data-line-end="104">La Recherche durera 2 ans et devrait permettre la participation de plusieurs milliers d’utilisateurs de l’application voqia et du site internet <a href="https://www.yourvoice.care/">https://www.yourvoice.care/</a>…</p>
<p class="has-line-data" data-line-start="105" data-line-end="106">Elle est financée par le promoteur*.</p>
<p class="has-line-data" data-line-start="107" data-line-end="108"> </p>
<br><h3 class="code-line" data-line-start=109 data-line-end=110 ><a id="Comment_la_Recherche_se_drouletelle__109"></a>Comment la Recherche se déroule-t-elle ?</h5>
<p class="has-line-data" data-line-start="111" data-line-end="112">Votre participation à la Recherche consistera, si vous y consentez, à fournir les données visées par la Recherche, à savoir :</p>
<ul>
<li class="has-line-data" data-line-start="112" data-line-end="113"><strong>des informations sur votre profil, vos habitudes et votre état de santé :</strong> vous les fournirez en répondant à des questionnaires dans l’application voqia, soit lors de la prise en main de l’application à votre première utilisation, soit lors de vos utilisations ultérieures de l’application, ou sur le site internet <a href="https://www.yourvoice.care/">https://www.yourvoice.care/</a> si vous participez à la Recherche par ce canal</li>
<li class="has-line-data" data-line-start="113" data-line-end="114"><strong>des échantillons de votre voix :</strong> vous les fournirez en utilisation les fonctionnalités “Journal” et “Relaxation” de l’application voqia à l’aide de l’appareil connecté sur lequel vous utilisez l’application, ou sur le site internet <a href="https://www.yourvoice.care/">https://www.yourvoice.care/</a> si vous participez à la Recherche par ce canal</li>
</ul>
<p class="has-line-data" data-line-start="116" data-line-end="117">En cas de question sur la Recherche, vous pouvez retrouver ce document dans la rubrique “Utilisation des données” de l’application voqia ou contacter son fabricant, promoteur* de la Recherche, aux coordonnées indiquées en première page. Ce document est par ailleurs téléchargé automatiquement sur votre appareil au moment où vous validez sa lecture.</p>
<p class="has-line-data" data-line-start="118" data-line-end="119">Les données qui vous seront demandées dans la Recherche ont été choisies car elles sont toutes présumées importantes pour l’apprentissage des algorithmes d’IA* que la Recherche vise à développer.</p>
<p class="has-line-data" data-line-start="120" data-line-end="121"> </p>
<br><h3 class="code-line" data-line-start=121 data-line-end=122 ><a id="Quels_sont_les_bnfices_attendus_et_risques_lis__la_Recherche__121"></a>Quels sont les bénéfices attendus et risques liés à la Recherche ?</h5>
<p class="has-line-data" data-line-start="123" data-line-end="124">Cette recherche est qualifiée de “Recherche n’impliquant pas la personne humaine*” (RNIPH) car elle collecte des données prospectives sans viser le développement des connaissances biologiques ou médicales, uniquement le développement d’un outil interne par son promoteur*, en l’occurrence des biomarqueurs vocaux* associés à la détresse physio-psychologique, sans chercher à évaluer leur efficacité.</p>
<p class="has-line-data" data-line-start="125" data-line-end="126">Les questionnaires et exercices vocaux demandés sont très simples et ne présentent aucun risque pour vous.</p>
<p class="has-line-data" data-line-start="127" data-line-end="128">La Recherche pourrait produire des bénéfices à long-terme, au cas où les biomarqueurs vocaux développés à partir des données collectées sont par la suite évalués dans une autre étude et s’ils s’avèrent suffisamment performants. Ils pourraient alors par exemple permettre d’évaluer l’état clinique de patients atteints de dépression en utilisant leur voix, donc facilement et à distance. Ainsi, les interventions médicales pourraient être plus appropriées : au bon moment, auprès des bons patients et à partir d’informations sur leur état récent.</p>
<p class="has-line-data" data-line-start="129" data-line-end="130"> </p>
<br><h3 class="code-line" data-line-start=130 data-line-end=131 ><a id="Quelles_sont_les_indemnits_prvues__130"></a>Quelles sont les indemnités prévues ?</h5>
<p class="has-line-data" data-line-start="132" data-line-end="133">Cette Recherche est réalisée à l’occasion de votre utilisation de l’application voqia ou du site internet <a href="https://www.yourvoice.care/">https://www.yourvoice.care/</a>. Elle n’entraîne aucun frais pour vous. Aucune indemnité n’est prévue pour votre participation à cette Recherche.</p>
<p class="has-line-data" data-line-start="134" data-line-end="135"> </p>
<br><h3 class="code-line" data-line-start=135 data-line-end=136 ><a id="Que_se_passeratil_en_cas_darrt_prmatur_de_la_Recherche_et_aprs_la_Recherche__135"></a>Que se passera-t-il en cas d’arrêt prématuré de la Recherche et après la Recherche ?</h5>
<p class="has-line-data" data-line-start="137" data-line-end="139">L’arrêt prématuré de la Recherche n’aura aucune conséquence sur votre utilisation de l’application voqia ou du site internet <a href="https://www.yourvoice.care/">https://www.yourvoice.care/</a>.<br>
Pendant et après la Recherche, les données collectées seront exploitées pour développer les biomarqueurs vocaux relatifs à la détresse physio-psychologique.</p>
<p class="has-line-data" data-line-start="140" data-line-end="141"> </p>
<br><h3 class="code-line" data-line-start=141 data-line-end=142 ><a id="Combien_de_temps_vos_donnes_serontelles_conserves__141"></a>Combien de temps vos données seront-elles conservées ?</h5>
<p class="has-line-data" data-line-start="142" data-line-end="143">Conformément à la règlementation, vos données seront conservées jusqu’à deux ans après la dernière publication des résultats de la Recherche ou, en l’absence de publication, jusqu’à la signature du rapport final de fin de la Recherche.</p>
<p class="has-line-data" data-line-start="144" data-line-end="145">Elles feront l’objet d’un archivage pour une durée de 15 ans.</p>
<p class="has-line-data" data-line-start="146" data-line-end="147"> </p>
<br><h3 class="code-line" data-line-start=147 data-line-end=148 ><a id="Dispositions_lgislatives_et_rglementaires_147"></a>Dispositions législatives et réglementaires</h5>
<p class="has-line-data" data-line-start="148" data-line-end="149">Conformément à la réglementation, cette Recherche a été déclarée au Health Data Hub.</p>
<p class="has-line-data" data-line-start="150" data-line-end="151">Le traitement de vos données personnelles dans le cadre de la recherche est conforme à une Méthodologie de référence (MR)* MR-004 établie par la Commission Nationale Informatique et Libertés (CNIL).</p>
<p class="has-line-data" data-line-start="152" data-line-end="153"> </p>
<br><h2 class="code-line" data-line-start=153 data-line-end=154 ><a id="PARTIE_2__INFORMATIONS_SUR_LES_DROITS_DU_PARTICIPANT_ET_SUR_LA_GESTION_DES_DONNEES_RECUEILLIES_153"></a>PARTIE 2 : INFORMATIONS SUR LES DROITS DU PARTICIPANT ET SUR LA GESTION DES DONNEES RECUEILLIES</h2>
<p class="has-line-data" data-line-start="155" data-line-end="156"> </p>
<br><h3 class="code-line" data-line-start=156 data-line-end=157 ><a id="Quels_sont_vos_droits__156"></a>Quels sont vos droits ?</h5>
<p class="has-line-data" data-line-start="158" data-line-end="159">Votre participation à la Recherche est libre et volontaire : vous êtes libre d’accepter ou de refuser de participer à cette recherche et vous pouvez interrompre à tout moment votre participation sans avoir à donner de raison et sans encourir aucune responsabilité ni préjudice de ce fait. Il vous suffit de le signaler au promoteur dont les coordonnées sont indiquées en première page, ou à son Délégué à la protection des données (DPO)*. Leurs coordonnées se trouvent en première page de ce document.</p>
<p class="has-line-data" data-line-start="161" data-line-end="162">Nous vous invitons à poser à AKUITY CARE les questions que vous pourriez avoir à propos de cette Recherche, en utilisant l’adresse email suivante : <a href="mailto:contact@akuity.care">contact@akuity.care</a></p>
<p class="has-line-data" data-line-start="163" data-line-end="164">Vous avez le droit d’obtenir communication, au cours ou à l’issue de la recherche, des données qui concernent et qui sont détenues par le promoteur*.</p>
<p class="has-line-data" data-line-start="165" data-line-end="166"> </p>
<br><h3 class="code-line" data-line-start=166 data-line-end=167 ><a id="Comment_vos_donnes_personnelles_serontelles_traites_dans_le_cadre_de_la_recherche__166"></a>Comment vos données personnelles* seront-elles traitées dans le cadre de la recherche ?</h5>
<p class="has-line-data" data-line-start="167" data-line-end="168">Si vous acceptez de participer à la recherche, vos données personnelles, y compris vos données de santé, feront l’objet d’un traitement* par le promoteur, en qualité de responsable du traitement de ces données.</p>
<p class="has-line-data" data-line-start="169" data-line-end="170">Les données suivantes seront recueillies et traitées dans des conditions de sécurité adaptées :</p>
<table class="table table-striped table-bordered">
<thead>
<tr>
<th style="text-align:left">Quelle donnée ?</th>
<th style="text-align:left">Comment ?</th>
<th style="text-align:center">Auprès de qui ?</th>
</tr>
</thead>
<tbody>
<tr>
<td style="text-align:left">Enregistrements de voix</td>
<td style="text-align:left">1) Dans l’application voqia : a) enregistrements libres effectués dans le module “Journal” de l’application b) enregistrements de vocalises (sons “AAAaa” soutenus le temps d’une expiration) dans le module “Relaxation” de l’application. – 2) Sur le site internet <a href="https://www.yourvoice.care/">https://www.yourvoice.care/</a> : a) enregistrement de la lecture d’un court texte b) enregistrement de vocalise (son “AAAaa” soutenu le temps d’une expiration) c) enregistrement de la description libre d’une image d) enregistrement de la réponse libre à la question “Comment ça va aujourd’hui ?” e) enregistrement de 3 inspirations / expirations par la bouche f) enregistrement de 3 toux</td>
<td style="text-align:left">Vous-même</td>
<td style="text-align:center"></td>
</tr>
<tr>
<td style="text-align:left">Evaluation des symptômes dépressifs (par un questionnaire)</td>
<td style="text-align:left">Questionnaire à Choix Multiples en 9 questions</td>
<td style="text-align:center">Vous-même</td>
</tr>
<tr>
<td style="text-align:left">Evaluation des troubles anxieux (par un questionnaire)</td>
<td style="text-align:left">Questionnaire à Choix Multiples en 7 questions</td>
<td style="text-align:center">Vous-même</td>
</tr>
<tr>
<td style="text-align:left">Evaluation des symptômes de fatigue (par un questionnaire)</td>
<td style="text-align:left">Questionnaire à Choix Multiples en 9 questions</td>
<td style="text-align:center">Vous-même</td>
</tr>
<tr>
<td style="text-align:left">Evaluation du stress (par une échelle)</td>
<td style="text-align:left">Menu déroulant (de 0 à 10)</td>
<td style="text-align:center">Vous-même</td>
</tr>
<tr>
<td style="text-align:left">Evaluation de la douleur (par une échelle)</td>
<td style="text-align:left">Menu déroulant (de 0 à 10)</td>
<td style="text-align:center">Vous-même</td>
</tr>
<tr>
<td style="text-align:left">Diagnostics connus de dépression, trouble anxieux généralisé, schizophrénie, troubles bipolaires, BPCO, asthme, infections respiratoires dans les 3 derniers mois et actuellement, symptômes ORL* actuels, symptômes persistants de Covid-19, allergie respiratoire, fibrose pulmonaire, cancer (+ informations relatives au cancer si applicable)</td>
<td style="text-align:left">Oui / Non</td>
<td style="text-align:center">Vous-même</td>
</tr>
<tr>
<td style="text-align:left">Autres comorbidités connues (à indiquer dans une liste)</td>
<td style="text-align:left">Menu déroulant</td>
<td style="text-align:center">Vous-même</td>
</tr>
<tr>
<td style="text-align:left">Traitements en cours (à indiquer dans une liste)</td>
<td style="text-align:left">Menu déroulant</td>
<td style="text-align:center">Vous-même</td>
</tr>
<tr>
<td style="text-align:left">Code unique</td>
<td style="text-align:left">Caractères alpha-numériques</td>
<td style="text-align:center">Automatique</td>
</tr>
<tr>
<td style="text-align:left">Langue maternelle</td>
<td style="text-align:left">Menu déroulant</td>
<td style="text-align:center">Vous-même</td>
</tr>
<tr>
<td style="text-align:left">Année de naissance</td>
<td style="text-align:left">Menu déroulant</td>
<td style="text-align:center">Vous-même</td>
</tr>
<tr>
<td style="text-align:left">Sexe</td>
<td style="text-align:left">Questionnaire à Choix Multiples</td>
<td style="text-align:center">Vous-même</td>
</tr>
<tr>
<td style="text-align:left">Taille</td>
<td style="text-align:left">Menu déroulant</td>
<td style="text-align:center">Vous-même</td>
</tr>
<tr>
<td style="text-align:left">Poids</td>
<td style="text-align:left">Menu déroulant</td>
<td style="text-align:center">Vous-même</td>
</tr>
<tr>
<td style="text-align:left">Consommation actuelle de tabac ou autres substances fumées (fréquence, quantité par forme, ancienneté)</td>
<td style="text-align:left">Questionnaires à Choix Multiples et menu déroulant</td>
<td style="text-align:center">Vous-même</td>
</tr>
<tr>
<td style="text-align:left">Consommation historique de tabac ou autres substances fumées (fréquence, quantité par forme, durée, date de l’arrêt)</td>
<td style="text-align:left">Questionnaires à Choix Multiples et menu déroulant</td>
<td style="text-align:center">Vous-même</td>
</tr>
<tr>
<td style="text-align:left">Grossesse en cours (et stade si applicable)</td>
<td style="text-align:left">Oui / Non (et menu déroulant)</td>
<td style="text-align:center">Vous-même</td>
</tr>
<tr>
<td style="text-align:left">Données relatives à la conduite de la recherche</td>
<td style="text-align:left">Date et heure de début et de fin de chaque écran pour les questionnaires et les exercices vocaux</td>
<td style="text-align:center">Automatique (depuis le matériel utilisé pour la Recherche)</td>
</tr>
</tbody>
</table>
<p class="has-line-data" data-line-start="194" data-line-end="195">Ces données ont été choisies car elles sont toutes présumées importantes pour l’apprentissage des algorithmes d’IA* que la Recherche vise à développer.</p>
<p class="has-line-data" data-line-start="196" data-line-end="197"> </p>
<br>
<h3 class="code-line" data-line-start=197 data-line-end=198 ><a id="Quelle_est_la_base_juridique_et_la_finalit_du_traitement_de_vos_donnes_personnelles__197"></a>Quelle est la base juridique et la finalité du traitement de vos données personnelles ?</h5>
<p class="has-line-data" data-line-start="198" data-line-end="202">Le traitement de vos données personnelles est nécessaire à la réalisation de la recherche, c’est-à-dire au développement des biomarqueurs vocaux précités. Dans ce cadre, les sous-finalités sont les suivantes : élaborer le registre des participants, assurer la sécurité des données, tester leur qualité et répondre aux demandes d’exercice des droits des participants.<br>
Ainsi, le traitement de vos données personnelles est autorisé car il répond à un intérêt légitime poursuivi par AKUITY CARE à des fins de recherche scientifique conformément aux articles 6.1 f) et 9.2. j) du RGPD*.<br>
L’intérêt légitime poursuivi est la collecte de données aux fins de développer les biomarqueurs vocaux décrits précédemment.<br>
Le responsable de traitement doit mettre en œuvre des mesures appropriées permettant de garantir vos droits et libertés, notamment le seul recueil de données strictement nécessaires à la recherche.</p>
<p class="has-line-data" data-line-start="203" data-line-end="204"> </p>
<br>
<h3 class="code-line" data-line-start=204 data-line-end=205 ><a id="Comment_la_confidentialit_de_vos_donnes_seratelle_assure__204"></a>Comment la confidentialité de vos données sera-t-elle assurée ?</h5>
<p class="has-line-data" data-line-start="206" data-line-end="208">Vos données personnelles seront traitées de manière confidentielle, conformément à la loi du 6 janvier 1978 modifiée dite « Loi Informatique et Libertés », et conformément au Règlement Général sur la Protection des Données (RGPD*).<br>
Vos données seront codées*, c’est-à-dire que vous serez identifié par un numéro de code pour les besoins de la recherche, sans mention “en clair” de la seule donnée potentielle directement identifiante collectée dans la Recherche, à savoir l’adresse email associée à votre compte sur l’application voqia ou à votre utilisation du site internet <a href="https://www.yourvoice.care/">https://www.yourvoice.care/</a>. Un hébergeur de données tiers conservera la liste de correspondance entre ce code et votre adresse email. AKUITY CARE, en sa qualité de promoteur*, n’aura contractuellement pas accès à votre adresse email, en dehors des cas prévus par le RGPD*.</p>
<p class="has-line-data" data-line-start="209" data-line-end="210">Les enregistrements vocaux sont, pour certains, des exercices d’expression libre. Même si la nature de l’application (journal de bord émotionnel et outil de cohérence cardiaque) ne vous y incitera pas, veuillez svp prendre garde à <strong>ne divulguer aucune information identifiante (comme votre nom ou vos coordonnées) dans vos enregistrements</strong>. De telles informations nous obligeront à détruire vos données et ne permettront pas votre participation à la Recherche.</p>
<p class="has-line-data" data-line-start="211" data-line-end="212">Enfin, ces données seront traitées dans une base de données informatisée présentant toutes les mesures de sécurité techniques et organisationnelles requises et de manière confidentielle, dans le respect du secret professionnel.</p>
<p class="has-line-data" data-line-start="213" data-line-end="214"> </p>
<br>
<h3 class="code-line" data-line-start=214 data-line-end=215 ><a id="Qui_aura_accs__vos_donnes_dans_le_cadre_de_la_Recherche__214"></a>Qui aura accès à vos données dans le cadre de la Recherche ?</h5>
<p class="has-line-data" data-line-start="216" data-line-end="217">Vos informations potentiellement identifiantes, c’est-à-dire votre adresse email, ne seront connues que par :</p>
<ul>
<li class="has-line-data" data-line-start="217" data-line-end="218">la société AZ Network, en tant qu’hébergeur de données de santé tiers choisi par AKUITY CARE en sa qualité de promoteur pour stocker la liste de correspondance entre les adresses emails et les codes d’identification des participants à la Recherche (cette liste est donc stockée séparément des autres données de la Recherche, notamment les données de santé des participants, qui sont quant à elle stockées chez la société OVHCloud, autre hébergeur de données de santé)</li>
<li class="has-line-data" data-line-start="218" data-line-end="219">le DPO (Délégué à la Protection des Données*) du promoteur* si vous le contactez <a href="mailto:dpo@akuity.care">dpo@akuity.care</a></li>
<li class="has-line-data" data-line-start="219" data-line-end="221">en cas de litige, le personnel habilité de l’organisme d’assurance du promoteur*.</li>
</ul>
<p class="has-line-data" data-line-start="221" data-line-end="222">Ces personnes sont soumises au secret professionnel.</p>
<p class="has-line-data" data-line-start="223" data-line-end="224">Vos données codées seront accessibles aux personnes suivantes :</p>
<ul>
<li class="has-line-data" data-line-start="224" data-line-end="225">le promoteur*</li>
<li class="has-line-data" data-line-start="225" data-line-end="227">la société OVHCloud, en tant qu’hébergeur de données de santé tiers choisi par AKUITY CARE en sa qualité de promoteur pour stocker toutes les données de la Recherche à l’exception de la liste de correspondance entre les adresses emails et les codes d’identification des participants</li>
</ul>
<p class="has-line-data" data-line-start="227" data-line-end="228">Ces personnes, soumises au secret professionnel, auront accès à vos données codées dans le cadre de leur fonction et en conformité avec la réglementation.</p>
<p class="has-line-data" data-line-start="229" data-line-end="230"> </p>
<br>
<h3 class="code-line" data-line-start=230 data-line-end=231 ><a id="Quels_sont_vos_droits_relatifs__vos_donnes_personnelles__230"></a>Quels sont vos droits relatifs à vos données personnelles ?</h5>
<p class="has-line-data" data-line-start="232" data-line-end="234">Vous avez le droit d’accéder à vos données, par l’intermédiaire du Délégué à la protection des données (DPO)* du promoteur ou de l’investigateur* de la Recherche, et demander à ce qu’elles soient rectifiées ou complétées.<br>
Vous pouvez également demander la limitation du traitement de vos données (c’est-à-dire demander au promoteur de geler temporairement l’utilisation de vos données).</p>
<p class="has-line-data" data-line-start="235" data-line-end="237">Même si vous acceptez de participer à la Recherche, vous pourrez à tout moment vous opposer au traitement de vos données aux fins de réalisation de la Recherche. Dans ce cas, aucune information supplémentaire vous concernant ne sera collectée.<br>
Vous pouvez également exercer votre droit à l’effacement sur les données déjà recueillies.</p>
<p class="has-line-data" data-line-start="238" data-line-end="239"> </p>
<br>
<h3 class="code-line" data-line-start=239 data-line-end=240 ><a id="Comment_exercer_vos_droits__239"></a>Comment exercer vos droits ?</h5>
<p class="has-line-data" data-line-start="240" data-line-end="242">Vous pouvez exercer vos droits à tout moment et sans avoir à vous justifier.<br>
Le promoteur* n’ayant pas accès à votre identité, il est recommandé de vous adresser, dans un premier temps, à son Délégué à la protection des données (DPO)*, aux coordonnées disponibles dans la présente note en première page.</p>
<p class="has-line-data" data-line-start="243" data-line-end="244">Dans ce cas, votre identité (prénom, nom) sera rendue accessible au DPO* du promoteur*.</p>
<p class="has-line-data" data-line-start="245" data-line-end="246">Dans l’hypothèse où vous ne parvenez pas à exercer vos droits, vous disposez également du droit de déposer une réclamation concernant le traitement de vos données personnelles auprès de la Commission nationale de l’informatique et des libertés (CNIL), qui est l’autorité de contrôle compétente en France en matière de protection des données :</p>
<ul>
<li class="has-line-data" data-line-start="246" data-line-end="247">Via le téléservice de plainte en ligne</li>
<li class="has-line-data" data-line-start="247" data-line-end="249">Par courrier postal en écrivant à : CNIL - Service des Plaintes - 3 Place de Fontenoy - TSA 80715 - 75334 PARIS CEDEX 07.</li>
</ul>
<p class="has-line-data" data-line-start="249" data-line-end="250"> </p>
<br>
<h3 class="code-line" data-line-start=250 data-line-end=251 ><a id="Vos_donnes_codes_pourrontelles_tre_rutilises__250"></a>Vos données codées pourront-elles être réutilisées ?</h5>
<p class="has-line-data" data-line-start="251" data-line-end="252">Vos données pourront être réutilisées dans d’autres recherches visant le développement de biomarqueurs vocaux*. Ceci permettra par exemple d’évaluer la performance des biomarqueurs vocaux* développés à partir des données collectées dans cette Recherche en les combinant aux données issues d’une recherche similaire (ou inversement).</p>
<p class="has-line-data" data-line-start="253" data-line-end="255">Pour obtenir des informations sur le(s) nouveau(x) traitement(s) de vos données, le promoteur* a mis en place un site web dynamique (« portail de transparence ») que vous pourrez consulter régulièrement et préalablement à la mise en œuvre de chaque nouveau traitement, à l’adresse suivante : <a href="http://www.akuity.care/transparence/nv92j2">www.akuity.care/transparence/nv92j2</a>.<br>
Cette page détaillera l’ensemble des mentions obligatoires du RGPD*, notamment, pour chaque projet concerné, l’identité du responsable de traitement et les finalités poursuivies justifiant la réutilisation de vos données. Le site sera mis à jour à chaque nouvelle Recherche réutilisant vos données.</p>
<p class="has-line-data" data-line-start="256" data-line-end="258">Grâce à cette information, vous pourrez choisir d’exercer vos droits d’accès, de rectification, de limitation, d’opposition ou d’effacement de vos données. L’affichage des projets sur la page du site internet vaut information pour cette réutilisation de données et dès lors, il n’est pas prévu de vous adresser une lettre individuelle d’information complémentaire pour chaque projet de recherche.<br>
Les modalités d’opposition pour chaque projet de recherche seront indiquées sur ce site Internet.</p>
<p class="has-line-data" data-line-start="259" data-line-end="260"> </p>
<br>
<h2 class="code-line" data-line-start=260 data-line-end=261 ><a id="PARTIE_3__GLOSSAIRE_260"></a>PARTIE 3 : GLOSSAIRE</h2>
<ul>
<li class="has-line-data" data-line-start="262" data-line-end="263"><strong>Biomarqueur vocal</strong> : algorithme d’IA fournissant une estimation d’un critère clinique chez un individu à partir d’un échantillon de sa voix.</li>
<li class="has-line-data" data-line-start="263" data-line-end="264"><strong>Codage de vos données</strong> : identification de vos données de manière unique dans la base de données de la Recherche, par un code non-signifiant c’est-à-dire ne permettant pas de vous identifier personnellement (code alphanumérique).</li>
<li class="has-line-data" data-line-start="264" data-line-end="265"><strong>Donnée personnelle</strong> : toute information se rapportant à une personne physique identifiée ou identifiable, dont le traitement est encadré, en Europe, par le RGPD.</li>
<li class="has-line-data" data-line-start="265" data-line-end="266"><strong>DPO (Délégué à la Protection des Données)</strong> : personne chargée de la protection des données personnelles au sein d’une organisation.</li>
<li class="has-line-data" data-line-start="266" data-line-end="267"><strong>IA</strong> (Intelligence Artificielle) : construction de programmes informatiques réalisant, de manière automatique, des tâches définies, ces <strong>programmes étant appelées “algorithmes”.</strong></li>
<li class="has-line-data" data-line-start="267" data-line-end="268"><strong>Investigateur</strong> : Personne physique chargée de surveiller et de diriger la recherche.</li>
<li class="has-line-data" data-line-start="268" data-line-end="269"><strong>Méthodologie de référence (MR)</strong> : Procédure simplifiée encadrant l’accès aux données de santé pour les promoteurs de recherche.</li>
<li class="has-line-data" data-line-start="269" data-line-end="270"><strong>Promoteur</strong> : Personne physique ou morale responsable de la recherche, qui en assure la gestion et qui vérifie que son financement est prévu.</li>
<li class="has-line-data" data-line-start="270" data-line-end="271"><strong>Recherche n’impliquant pas la personne humaine (RNIPH)</strong> : recherches sortant du champ des recherches impliquant la personne humaine (RIPH), ces dernières étant organisées et pratiquées sur l’être humain en vue du développement des connaissances biologiques ou médicales (article L.1121-1 du Code de la santé publique)</li>
<li class="has-line-data" data-line-start="271" data-line-end="272"><strong>RGPD</strong> (Règlement Général sur la Protection des Données) : Règlement (UE) 2016/679 du Parlement européen et du Conseil du 27 avril 2016 relatif à la protection des personnes physiques à l’égard du traitement des données à caractère personnel et à la libre circulation de ces données</li>
<li class="has-line-data" data-line-start="272" data-line-end="273"><strong>Symptômes ORL</strong> : symptômes oto rhino laryngologiques (exemple : congestion nasale ou des sinus)</li>
<li class="has-line-data" data-line-start="273" data-line-end="274"><strong>Traitement des données personnelles</strong> : un traitement de données personnelles est une opération, ou ensemble d’opérations, portant sur des données personnelles, quel que soit le procédé utilisé (collecte, enregistrement, organisation, conservation, adaptation, modification, extraction, consultation, utilisation, communication par transmission ou diffusion ou toute autre forme de mise à disposition, rapprochement).</li>
</ul>
<hr>`,
  /* eslint-enable */
}

export default consentFR

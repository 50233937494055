import { Categorical, MedicalQuestion } from "../../../../@types/medicalQuestion"

const category: Categorical[] = [
  {
    label: "oui",
    value: "yes",
  },
  {
    label: "non",
    value: "no",
  },
]

const cofoundingFR: MedicalQuestion[] = [
  {
    id: "preg",
    name: "Pregnancy to date",
    type: "categoricalShort",
    value: category,
    description: "Êtes-vous actuellement enceinte ?",
    parent: "cofounding",
    order: 1,
    lang: "fr",
    required: false,
    dependsOn: {
      id: "sex",
      values: ["female"],
    },
  },
  {
    id: "preg_d",
    name: "pregnancy month",
    type: "number",
    minValue: 1,
    maxValue: 9,
    description: "A quel mois de grossesse êtes-vous ?",
    parent: "cofounding",
    order: 2,
    lang: "fr",
    dependsOn: {
      id: "preg",
      values: ["yes"],
    },
    required: false,
  },
  {
    id: "pain",
    name: "Pain scale",
    type: "categoricalLong",
    value: [
      { label: "0", value: "0" },
      { label: "1", value: "1" },
      { label: "2", value: "2" },
      { label: "3", value: "3" },
      { label: "4", value: "4" },
      { label: "5", value: "5" },
      { label: "6", value: "6" },
      { label: "7", value: "7" },
      { label: "8", value: "8" },
      { label: "9", value: "9" },
      { label: "10", value: "10" },
    ],
    labelStart: "Pas de douleur",
    labelEnd: "Douleur maximale imaginable",
    description: "Veuillez évaluer la douleur que vous ressentez en ce moment : de 0 ‘pas de douleur’ à 10 ‘douleur maximale imaginable’",
    parent: "cofounding",
    order: 3,
    lang: "fr",
    required: false,
  },
  {
    id: "stress",
    name: "Stress scale",
    type: "categoricalLong",
    value: [
      { label: "0", value: "0" },
      { label: "1", value: "1" },
      { label: "2", value: "2" },
      { label: "3", value: "3" },
      { label: "4", value: "4" },
      { label: "5", value: "5" },
      { label: "6", value: "6" },
      { label: "7", value: "7" },
      { label: "8", value: "8" },
      { label: "9", value: "9" },
      { label: "10", value: "10" },
    ],
    labelStart: "Pas de stress",
    labelEnd: "Stress maximale imaginable",
    description: "Veuillez évaluer le stress que vous ressentez en ce moment : de 0 ‘pas de stress’ à 10 ‘stress maximal imaginable’",
    parent: "cofounding",
    order: 4,
    lang: "fr",
    required: false,
  },
  {
    id: "depr",
    name: "Depression",
    type: "categoricalShort",
    value: category,
    description: "Avez-vous reçu un diagnostic d'une dépression ?",
    parent: "cofounding",
    order: 5,
    lang: "fr",
    required: true,
  },
  {
    id: "anx",
    name: "Generalized Anxiety Disorder question",
    type: "categoricalShort",
    value: category,
    description: "Avez-vous reçu un diagnostic d'un trouble d'anxiété généralisée ?",
    parent: "cofounding",
    order: 6,
    lang: "fr",
    required: true,
  },
  {
    id: "schiz",
    name: "Schizophrenia diagnosis",
    type: "categoricalShort",
    value: category,
    description: "Avez-vous reçu un diagnostic de schizophrénie ?",
    parent: "cofounding",
    order: 7,
    lang: "fr",
    required: true,
  },
  {
    id: "bipol",
    name: "Bipolar disorder diagnosis",
    type: "categoricalShort",
    value: category,
    description: "Avez-vous reçu un diagnostic de trouble bipolaire ?",
    parent: "cofounding",
    order: 8,
    lang: "fr",
    required: true,
  },
  {
    id: "copd",
    name: "Chronic obstructive pulmonary disease",
    type: "categoricalShort",
    value: [
      { label: "oui", value: "yes" },
      { label: "non", value: "no" },
    ],
    description: "Avez-vous reçu un diagnostic de Broncho Pneumopathie Chronique Obstructive (BPCO) ?",
    parent: "cofounding",
    order: 9,
    lang: "fr",
    required: true,
  },
  {
    id: "asth",
    name: "Asthma",
    type: "categoricalShort",
    value: [
      { label: "oui", value: "yes" },
      { label: "non", value: "no" },
    ],
    description: "Avez-vous reçu un diagnostic d'asthme ?",
    parent: "cofounding",
    order: 10,
    lang: "fr",
    required: true,
  },
  {
    id: "infect_dis_sympt",
    name: "Infectious disease symptoms",
    type: "multi",
    value: [
      { label: "Congestion nasale ou des sinus ('nez bouché')", value: "stuffy-nose" },
      { label: "Ecoulement nasal ('nez qui coule')", value: "runny-nose" },
      { label: "Mal de gorge, irritation ou gêne à la gorge", value: "sore-throat" },
      { label: "Dysphonie (voix audiblement anormale, enrouée, fluctuante...)", value: "dysphonia" },
    ],
    description: "Avez-vous actuellement l'un des symptômes suivants ?",
    parent: "cofounding",
    order: 11,
    lang: "fr",
    required: false,
  },
  {
    id: "infect_dis_c",
    name: "Infectious disease currently",
    type: "multi",
    value: [
      { label: "Grippe", value: "influenza" },
      { label: "Pneumonie", value: "pneumonia" },
      { label: "Bronchite", value: "bronchitis" },
      { label: "Angine", value: "angina" },
      { label: "Laryngite", value: "laryngitis" },
      { label: "Sinusite", value: "sinusitis" },
      { label: 'Rhinopharyngite ("rhume")', value: "rhinopharyngitis" },
      { label: "Covid-19", value: "covid-19" },
      { label: "Allergie", value: "allergy" },
      { label: "Autre", value: "other" },
    ],
    description: "Souffrez-vous actuellement d'une infection respiratoire ?",
    parent: "cofounding",
    order: 12,
    lang: "fr",
    required: false,
  },
  {
    id: "infect_dis",
    name: "Infectious disease",
    type: "multi",
    value: [
      { label: "Grippe", value: "influenza" },
      { label: "Pneumonie", value: "pneumonia" },
      { label: "Bronchite", value: "bronchitis" },
      { label: "Angine", value: "angina" },
      { label: "Laryngite", value: "laryngitis" },
      { label: "Sinusite", value: "sinusitis" },
      { label: 'Rhinopharyngite ("rhume")', value: "rhinopharyngitis" },
      { label: "Covid-19", value: "covid-19" },
      { label: "Allergie", value: "allergy" },
      { label: "Autre", value: "other" },
    ],
    description: "Avez-vous reçu un diagnostic d'une autre infection respiratoire au cours des 3 derniers mois ?",
    parent: "cofounding",
    order: 13,
    lang: "fr",
    required: false,
  },
  {
    id: "l_cov19",
    name: "Long covid-19",
    type: "categoricalShort",
    value: category,
    description: "Vous avez des symptômes persistants du Covid-19 (Covid long) ?",
    parent: "cofounding",
    order: 14,
    lang: "fr",
    required: true,
  },
  {
    id: "allergie_resp",
    name: "Allergie respiratoire",
    type: "categoricalShort",
    value: category,
    description: "Avez-vous reçu un diagnostic d'une allergie respiratoire ?",
    parent: "cofounding",
    order: 15,
    lang: "fr",
    required: true,
  },
  {
    id: "fib_pul",
    name: "Fibrose pulmonaire",
    type: "categoricalShort",
    value: category,
    description: "Avez-vous reçu un diagnostic de fibrose pulmonaire ?",
    parent: "cofounding",
    order: 16,
    lang: "fr",
    required: true,
  },
  {
    id: "c_type",
    name: "cancer type",
    type: "multi",
    value: [
      { label: "Sein", value: "breast" },
      { label: "Poumon", value: "lung" },
      { label: "Cerveau", value: "brain" },
      { label: "Lèvres", value: "lips" },
      { label: "Langue", value: "tongue" },
      { label: "Bouche", value: "mouth" },
      { label: "Gorge", value: "throat" },
      { label: "Larynx", value: "larynx" },
      { label: "Glandes salivaires", value: "salivary-glands" },
      { label: "Pharynx", value: "pharynx" },
      { label: "Sinus", value: "sinus" },
      { label: "Fosse nasale", value: "nasal-cavity" },
      { label: "Autre localisation sur la tête et le cou", value: "other-head-neck" },
      { label: "Autre", value: "other" },
    ],
    description: "Avez-vous reçu un diagnostic d'un cancer ?",
    parent: "cofounding",
    order: 17,
    lang: "fr",
    required: false,
  },
  {
    id: "comorb",
    name: "Comorbidities",
    type: "multi",
    value: [
      { label: "Infarctus/Crise cardiaque", value: "infarction/heart-attack" },
      { label: "Insuffisance cardiaque congestive", value: "congestive-heart-failure" },
      { label: "Maladie coronarienne", value: "coronary-heart-disease" },
      { label: "Angine de poitrine", value: "angina-pectoris" },
      { label: "Hypertension", value: "hypertension" },
      { label: "Diabète", value: "diabetes" },
      { label: "Maladie thyroïdienne", value: "thyroidic-disease" },
      { label: "Maladie rénale chronique", value: "chronic-kidney-disease" },
      { label: "Allergie (ex : acariens, pollen, aliments, animaux de compagnie..)", value: "allergy" },
      { label: "Emphysème", value: "emphysema" },
      { label: "Paludisme", value: "malaria" },
      { label: "Tuberculose", value: "tuberculosis" },
      { label: "Zika", value: "zika" },
      { label: "Goutte", value: "gout" },
      { label: "Hépatite", value: "hepatitis" },
      { label: "Maladie de Crohn", value: "crohn-disease" },
      { label: "Colite ulcéreuse", value: "ulcerative-colitis" },
      { label: "Polyarthrite rhumatoïde ou lupus disséminé (LED)", value: "rheumatoid-arthritis-or-systemic-lupus" },
      { label: "Sclérose en plaques", value: "multiple-sclerosis" },
      { label: "Épilepsie", value: "epilepsy" },
      { label: "Sclérose Amyotrophique Latérale (SLA)", value: "amyotrophic-lat-sclerosis" },
      { label: "Narcolepsie", value: "narcolepsy" },
      { label: "Maladie de Parkinson", value: "parkinson-disease" },
      { label: "Accident vasculaire cérébral", value: "stroke" },
      { label: "Migraine", value: "migraine" },
      { label: "Laryngite", value: "laryngitis" },
      {
        label: "Lésion non cancéreuse des cordes vocales (polype, nodule, kyste)",
        value: "non-cancerous-vocal-cord-lesion",
      },
      { label: "Reflux  laryngopharyngé", value: "laryngopharyngeal-reflux" },
      { label: "Reflux gastro-oesophagien", value: "gastroesophageal-reflu" },
      { label: "Leucoplasie", value: "leukoplakia" },
    ],
    description: "Avez-vous déjà été diagnostiqué d’une ou plusieurs des maladies suivantes ?",
    parent: "cofounding",
    order: 18,
    lang: "fr",
    required: false,
  },
  {
    id: "med",
    name: "Medication",
    type: "multi",
    value: [
      { label: "Paracétamol", value: "paracetamol" },
      { label: "Ibuprofène", value: "ibuprofen" },
      { label: "Codéine", value: "codeine" },
      { label: "Morphine ou médicaments dérivés de la morphine", value: "morphine-or-morphine-derived-drugs" },
      { label: "Médicaments contre le cholestérol", value: "medication-for-cholesterol" },
      { label: "Médicaments (comprimés ou insuline) pour le diabète", value: "medication-diabetes" },
      { label: "Médicament contre l'hypertension", value: "medication-hypertension" },
      { label: "Médicaments à base d'enzymes de conversion de l'angiotensine", value: "angiotensin-converting-enzyme-medications" },
      { label: "Antibiotiques", value: "antibiotics" },
      { label: "Médicaments pour traiter l'anxiété ou la dépression", value: "medication-anxiety-or-depression" },
      { label: "Médicaments anti-reflux", value: "anti-reflux-medication" },
      { label: "Traitement des problèmes de thyroïde", value: "treatment-thyroid" },
      {
        label: "Corticostéroïdes tels que la prednisone ou budésonide (Entocort EC)",
        value: "corticosteroids",
      },
      { label: "Relaxants musculaires", value: "muscle-relaxants" },
      { label: "Décongestionnants", value: "decongestants" },
      { label: "Diurétiques", value: "diuretics" },
      { label: "Antidiarrhéiques (ex. lsopéramide (Imodium A-D))", value: "antidiarrheal-agents" },
      { label: "Antihistaminiques", value: "antihistamines" },
      { label: "Anticholinergiques", value: "anticholinergics" },
      { label: "Antiallergiques", value: "anti-allergic" },
      { label: "Anti-inflammatoires", value: "anti-inflammatory" },
      {
        label: "Doses élevées de vitamine C (plus de cinq grammes par jour)",
        value: "high-doses-of-vitamin-c",
      },
      { label: "Anticoagulants", value: "anticoagulants" },
      { label: "Médicaments contre les maux de gorge", value: "medication-throat-pain" },
      { label: "Traitement hormonal (contraception, ménopause...)", value: "hormonal-treatment" },
    ],
    description: "Prenez-vous actuellement l'un des médicaments suivants au moins 3 fois par semaine?",
    parent: "cofounding",
    order: 19,
    lang: "fr",
    required: false,
  },
]

export default cofoundingFR

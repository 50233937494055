import { Categorical, MedicalQuestion } from "../../../../@types/medicalQuestion"

const category: Categorical[] = [
  {
    label: "oui",
    value: "yes",
  },
  {
    label: "non",
    value: "no",
  },
]

const respiratoryFR: MedicalQuestion[] = [
  {
    id: "asth",
    name: "Asthma",
    type: "categoricalShort",
    value: category,
    description: "Diagnostic d'asthme ?",
    parent: "respiratory",
    order: 1,
    lang: "fr",
    required: true,
    doctor: true,
  },
  {
    id: "copd",
    name: "Chronic obstructive pulmonary disease",
    type: "categoricalShort",
    value: category,
    description: "Diagnostic de BPCO ?",
    parent: "respiratory",
    order: 2,
    lang: "fr",
    required: true,
    doctor: true,
  },
  {
    id: "hvs",
    name: "Hyperventilation syndrome",
    type: "categoricalShort",
    value: category,
    description: "Diagnostic du syndrome d'hyperventilation ?",
    parent: "respiratory",
    order: 3,
    lang: "fr",
    required: false,
    doctor: true,
  },
  {
    id: "gerd",
    name: "Gastroesophageal reflux disease (GERD)?",
    type: "categoricalShort",
    value: category,
    description: "Diagnostic de Reflux gastro-œsophagien ?",
    parent: "respiratory",
    order: 4,
    lang: "fr",
    required: false,
    doctor: true,
  },
  {
    id: "vems",
    name: "Forced expiratory volume in 1 second ",
    type: "writeNumeric",
    minValue: 500,
    maxValue: 10000,
    description: "VEMS (mL) ?",
    parent: "respiratory",
    order: 5,
    lang: "fr",
    required: true,
    doctor: true,
  },
  {
    id: "vems_z",
    name: "VEMS / Z-score",
    type: "writeNumeric",
    minValue: -20,
    maxValue: 20,
    description: "VEMS / Z-score ?",
    parent: "respiratory",
    order: 6,
    lang: "fr",
    required: false,
    doctor: true,
  },
  {
    id: "tiff",
    name: "Tiffeneau-Pinelli index",
    type: "writeNumeric",
    minValue: 0,
    maxValue: 100,
    description: "Coefficient de Tiffeneau (%) ?",
    parent: "respiratory",
    order: 7,
    lang: "fr",
    required: true,
    doctor: true,
  },
  {
    id: "lung_vol",
    name: "Lung volumes",
    type: "writeNumeric",
    minValue: 500,
    maxValue: 15000,
    description: "Capacité pulmonaire totale (mL) ?",
    parent: "respiratory",
    order: 8,
    lang: "fr",
    required: false,
    doctor: true,
  },
  {
    id: "lung_vol_res",
    name: "Lung volume residuel",
    type: "writeNumeric",
    minValue: 500,
    maxValue: 8000,
    description: "Volume pulmonaire résiduel (mL) ?",
    parent: "respiratory",
    order: 9,
    lang: "fr",
    required: false,
    doctor: true,
  },
  {
    id: "mmrc",
    name: "Scale shortness of breath mMRC",
    type: "number",
    minValue: 0,
    maxValue: 4,
    description: "Échelle de dyspnée mMRC ?",
    parent: "respiratory",
    order: 10,
    lang: "fr",
    required: false,
    doctor: true,
  },
  {
    id: "peak_flow",
    name: "Peak expiratory flow (L/min)",
    type: "writeNumeric",
    minValue: 0,
    maxValue: 1000,
    description: "Débit Expiratoire de Pointe (L/min)",
    parent: "respiratory",
    order: 11,
    lang: "fr",
    required: false,
    doctor: true,
  },
  {
    id: "raw",
    name: "Airway resistance RAW (%)",
    type: "writeNumeric",
    minValue: 0,
    maxValue: 1000,
    description: "Résistances de voies aériennes RAW (%)",
    parent: "respiratory",
    order: 12,
    lang: "fr",
    required: false,
    doctor: true,
  },
  {
    id: "exacer",
    name: "Current exacerbation",
    type: "categoricalShort",
    value: category,
    description: "Exacerbation actuelle ?",
    parent: "respiratory",
    order: 13,
    lang: "fr",
    required: false,
    doctor: true,
  },
  {
    id: "n_exacer",
    name: "Number exacerbation over 12 last months",
    type: "number",
    minValue: 0,
    maxValue: 20,
    description: "Nombre d'exacerbations sur les 12 derniers mois?",
    parent: "respiratory",
    order: 14,
    lang: "fr",
    required: false,
    doctor: true,
  },
  {
    id: "date_exacer_m",
    name: "Date last exacerbation month",
    type: "select",
    value: [
      { label: "Janvier", value: "january" },
      { label: "Février", value: "february" },
      { label: "Mars", value: "march" },
      { label: "Avril", value: "april" },
      { label: "Mai", value: "mai" },
      { label: "Juin", value: "june" },
      { label: "Juillet", value: "juli" },
      { label: "Août", value: "august" },
      { label: "Septembre", value: "september" },
      { label: "Octobre", value: "october" },
      { label: "Novembre", value: "november" },
      { label: "Decembre", value: "december" },
      { label: "Information manquante", value: "missing-information" },
    ],
    description: "Date de la dernière exacerbation (mois) ?",
    parent: "respiratory",
    order: 15,
    lang: "fr",
    required: false,
    doctor: true,
  },
  {
    id: "date_exacer_y",
    name: "Date last exacerbation year",
    type: "number",
    minValue: 2000,
    maxValue: 2024,
    description: "Date de la dernière exacerbation (année) ?",
    parent: "respiratory",
    order: 16,
    lang: "fr",
    required: false,
    doctor: true,
  },
  {
    id: "n_hosp_exac",
    name: "Number hospitalisations due to exacerbation over 12 last months",
    type: "number",
    minValue: 0,
    maxValue: 20,
    description: "Nombre d'hospitalisation pour cause d'exacerbation sur les 12 derniers mois?",
    parent: "respiratory",
    order: 17,
    lang: "fr",
    required: false,
    doctor: true,
  },
  {
    id: "date_hosp_m",
    name: "Date last hospitalisation due to une exacerbation (month)",
    type: "select",
    value: [
      { label: "Janvier", value: "january" },
      { label: "Février", value: "february" },
      { label: "Mars", value: "march" },
      { label: "Avril", value: "april" },
      { label: "Mai", value: "mai" },
      { label: "Juin", value: "june" },
      { label: "Juillet", value: "juli" },
      { label: "Août", value: "august" },
      { label: "Septembre", value: "september" },
      { label: "Octobre", value: "october" },
      { label: "Novembre", value: "november" },
      { label: "Decembre", value: "december" },
      { label: "Information manquante", value: "missing-information" },
    ],
    description: "Date de la dernière hospitalisation pour cause d'exacerbation (mois) ?",
    parent: "respiratory",
    order: 18,
    lang: "fr",
    required: false,
    doctor: true,
  },
  {
    id: "date_hosp_y",
    name: "Date last hospitalisation due to une exacerbation (year)",
    type: "number",
    minValue: 2000,
    maxValue: 2024,
    description: "Date de la dernière hospitalisation pour cause d'exacerbation (année) ?",
    parent: "respiratory",
    order: 19,
    lang: "fr",
    required: false,
    doctor: true,
  },
  {
    id: "other_recent_respi_event",
    name: "Other recent respiratory events",
    type: "freeText",
    description: "Autres évnements récents:",
    parent: "respiratory",
    order: 20,
    lang: "fr",
    required: false,
    doctor: true,
  },
]

export default respiratoryFR

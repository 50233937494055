import { TaskRecording } from "../../../@types/taskRecording"

const taskRecordingsFR: TaskRecording[] = [
  {
    id: "free_speech_3",
    name: "Free speech answer a question",
    type: "text",
    media: "Comment vous sentez-vous aujourd'hui comparé à la semaine dernière ?",
    description:
      "1. Cliquez sur le bouton vert pour lancer l'enregistrement.\n" +
      "2. Répondez à la question suivante en parlant au moins 20 secondes.\n" +
      "3. Lorsque vous avez terminé, cliquez à nouveau sur le bouton pour arrêter l'enregistrement.",
    lang: "fr",
  },
]

export default taskRecordingsFR

import { Link as RouterLink, useNavigate, useParams } from "react-router-dom"
// @mui
import { useTheme } from "@mui/material/styles"
import { Box, BoxProps } from "@mui/material"
import useLocales from "../hooks/useLocales"
import useAuth from "../hooks/useAuth"

// ----------------------------------------------------------------------

interface Props extends BoxProps {
  disabledLink?: boolean
}

export default function Logo({ disabledLink = false, sx }: Props) {
  const theme = useTheme()
  const { id } = useParams()
  const navigate = useNavigate()
  const { translate } = useLocales()
  const { projectId } = useAuth()

  const logo = <Box component="img" src="/assets/logo.png" sx={{ width: 40, height: 40, cursor: "pointer", ...sx }} />

  if (disabledLink) {
    return <>{logo}</>
  }
  if (id) {
    return (
      <RouterLink
        to={`/project/${id}`}
        onClick={() => {
          window.confirm(translate("menu.confirm")) && navigate(`/project/${id}`)
        }}
      >
        {logo}
      </RouterLink>
    )
  } else if (projectId) {
    return <RouterLink to={`logout/${projectId}`}>{logo}</RouterLink>
  } else {
    return <RouterLink to="/">{logo}</RouterLink>
  }
}

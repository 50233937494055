import { Categorical, MedicalQuestion } from "../../../../@types/medicalQuestion"

const category: Categorical[] = [
  {
    label: "Überhaupt nicht",
    value: "not-at-all",
  },
  {
    label: "Wenig",
    value: "a-little",
  },
  {
    label: "Mittelmässig",
    value: "moderately",
  },
  {
    label: "Ziemlich",
    value: "a-lot",
  },
]

const emotionsDE: MedicalQuestion[] = [
  {
    id: "emot_intro",
    name: "intro",
    value:
      // eslint-disable-next-line max-len
      "Welche der folgenden Emotionen beschreiben am besten, wie Sie sich im Moment fühlen? Wählen Sie aus, wie intensiv Sie die folgenden Emotionen derzeit empfinden.",
    type: "intro",
    lang: "de",
    parent: "emotion",
    required: false,
  },
  {
    id: "emot_joy",
    name: "Emotions",
    type: "categoricalShort",
    value: category,
    description: "Freude",
    parent: "emotion",
    order: 1,
    lang: "de",
    required: true,
  },
  {
    id: "emot_neut",
    name: "Emotions",
    type: "categoricalShort",
    value: category,
    description: "Neutral",
    parent: "emotion",
    order: 2,
    lang: "de",
    required: true,
  },
  {
    id: "emot_trust",
    name: "Emotions",
    type: "categoricalShort",
    value: category,
    description: "Vertrauen",
    parent: "emotion",
    order: 3,
    lang: "de",
    required: true,
  },
  {
    id: "emot_fear",
    name: "Emotions",
    type: "categoricalShort",
    value: category,
    description: "Angst",
    parent: "emotion",
    order: 4,
    lang: "de",
    required: true,
  },
  {
    id: "emot_surprise",
    name: "Emotions",
    type: "categoricalShort",
    value: category,
    description: "Überraschung",
    parent: "emotion",
    order: 5,
    lang: "de",
    required: true,
  },
  {
    id: "emot_sad",
    name: "Emotions",
    type: "categoricalShort",
    value: category,
    description: "Traurigkeit",
    parent: "emotion",
    order: 6,
    lang: "de",
    required: true,
  },
  {
    id: "emot_anger",
    name: "Emotions",
    type: "categoricalShort",
    value: category,
    description: "Wut",
    parent: "emotion",
    order: 7,
    lang: "de",
    required: true,
  },
]

export default emotionsDE

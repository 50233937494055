import { TaskRecording } from "../../../@types/taskRecording"

const taskRecordingsDE: TaskRecording[] = [
  {
    id: "read_speech_1",
    name: "Reading task Human Rights",
    type: "text",
    description:
      "1. Klicken Sie auf die grüne Taste, um die Aufnahme zu starten.\n" +
      "2. Bitte lesen Sie den folgenden Text laut vor. \n" +
      "3. Wenn Sie fertig sind, klicken Sie erneut auf die Taste, um die Aufnahme zu beenden.",
    media:
      "Jeder Mensch hat das Recht auf einen Lebensstandard, der Gesundheit und Wohl für sich selbst und die eigene " +
      "Familie gewährleistet, einschließlich Nahrung, Kleidung, Wohnung, ärztliche Versorgung und notwendige soziale " +
      "Leistungen, sowie das Recht auf Sicherheit im Falle von Arbeitslosigkeit, Krankheit, Invalidität, Verwitwung " +
      "oder Alter sowie bei anderweitigem Verlust der eigenen Unterhaltsmittel durch unverschuldete Umstände.",
    lang: "de",
  },
  {
    id: "inh_exh",
    name: "Inhale and exhale",
    type: "text",
    media: undefined,
    description:
      "1. Klicken Sie auf die grüne Taste, um die Aufnahme zu starten.\n" +
      "2. Atmen Sie 3-mal kräftig durch den Mund ein und aus. \n" +
      "3. Wenn Sie fertig sind, klicken Sie erneut auf die Taste, um die Aufnahme zu beenden.",
    lang: "de",
  },
  {
    id: "cough",
    name: "Coughing",
    type: "text",
    media: undefined,
    description:
      "1. Klicken Sie auf die grüne Taste, um die Aufnahme zu starten.\n" +
      "2. Husten Sie drei Mal. \n" +
      "3. Wenn Sie fertig sind, klicken Sie erneut auf die Taste, um die Aufnahme zu beenden.",
    lang: "de",
  },
  {
    id: "free_speech_im_1",
    name: "Free speech image description",
    type: "image",
    media: "Image_task_family_kitchen.jpg",
    description:
      "1. Klicken Sie auf die grüne Taste, um die Aufnahme zu starten.\n" +
      "2. Beschreiben Sie so viele Elemente wie sie können in diesem Bild für mindestens 30 Sekunden. \n" +
      "3. Wenn Sie fertig sind, klicken Sie erneut auf die Taste, um die Aufnahme zu beenden.",
    lang: "de",
  },
  {
    id: "free_speech_2",
    name: "Free speech answer a question",
    type: "text",
    media: "Beschreiben Sie bitte wie sich Ihre Erkrankung derzeit auf Ihr tägliches Leben auswirkt ?",
    description:
      "1. Klicken Sie auf die grüne Taste, um die Aufnahme zu starten.\n" +
      "2. Beantworten Sie folgende Frage, indem Sie mindestens 20 Sekunden sprechen. \n" +
      "3. Wenn Sie fertig sind, klicken Sie erneut auf die Taste, um die Aufnahme zu beenden.",
    lang: "de",
  },
]

export default taskRecordingsDE

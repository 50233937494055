import { styled } from "@mui/material/styles"
import { Box, Button, Container, Grid, Typography, Zoom } from "@mui/material"
import Page from "../../components/Page"
import React, { useEffect, useState } from "react"
import { Project } from "../../@types/project"
import projects from "../../@projects"
import { useNavigate, useParams } from "react-router-dom"
import usePatient from "../../hooks/usePatient"
import useLocales from "../../hooks/useLocales"
import { MedicalQuestion } from "../../@types/medicalQuestion"
import { VoiceFeatures } from "../../@types/patient"
import useAuth from "../../hooks/useAuth"
import { LoginDTO } from "../../@types/auth"

//export type { FeatureData } from "../../@types/features"
//export type { FeatureDataList } from "../../@types/features"

const ContentStyle = styled("div")(({ theme }) => ({
  maxWidth: 680,
  margin: "auto",
  display: "flex",
  justifyContent: "center",
  flexDirection: "column",
}))

const voiceFeatures: VoiceFeatures[] = [
  {
    voiceTask: "",
    featureType: "prosody",
    features: [
      { name: "F0semitoneFrom27.5Hz_mean", value: "25.42070960998535" },
      { name: "F0semitoneFrom27.5Hz_stddevNorm", value: "0.3288290500640869" },
      { name: "F0semitoneFrom27.5Hz_percentile20.0", value: "22.00278091430664" },
      { name: "F0semitoneFrom27.5Hz_percentile50.0", value: "23.875524520874023" },
      { name: "F0semitoneFrom27.5Hz_percentile80.0", value: "26.04935646057129" },
      { name: "F0semitoneFrom27.5Hz_pctlrange0-2", value: "4.046575546264648" },
      { name: "F0semitoneFrom27.5Hz_meanRisingSlope", value: "424.7052001953125" },
      { name: "F0semitoneFrom27.5Hz_stddevRisingSlope", value: "457.7170715332031" },
      { name: "F0semitoneFrom27.5Hz_meanFallingSlope", value: "100.79503631591797" },
      { name: "F0semitoneFrom27.5Hz_stddevFallingSlope", value: "83.25257110595703" },
      { name: "jitterLocal_amean", value: "0.03926185891032219" },
      { name: "jitterLocal_stddevNorm", value: "1.4207470417022705" },
      { name: "logRelF0-H1-H2_amean", value: "5.432931423187256" },
      { name: "logRelF0-H1-H2_stddevNorm", value: "1.8864576816558838" },
      { name: "logRelF0-H1-A3_amean", value: "28.425132751464844" },
      { name: "logRelF0-H1-A3_stddevNorm", value: "0.5094187259674072" },
      { name: "F1frequency_amean", value: "547.5684204101562" },
      { name: "F1frequency_stddevNorm", value: "0.42563027143478394" },
      { name: "F1bandwidth_amean", value: "1238.4586181640625" },
      { name: "F1bandwidth_stddevNorm", value: "0.280332088470459" },
      { name: "F2frequency_amean", value: "1569.7503662109375" },
      { name: "F2frequency_stddevNorm", value: "0.22662730515003204" },
      { name: "F2bandwidth_amean", value: "939.167724609375" },
      { name: "F2bandwidth_stddevNorm", value: "0.38851186633110046" },
      { name: "F3frequency_amean", value: "2653.87451171875" },
      { name: "F3frequency_stddevNorm", value: "0.14091327786445618" },
      { name: "F3bandwidth_amean", value: "825.9796142578125" },
      { name: "F3bandwidth_stddevNorm", value: "0.540099263191223" },
    ],
  },
  {
    voiceTask: "",
    featureType: "energy",
    features: [
      { name: "loudness_amean", value: "0.7910451889038086" },
      { name: "loudness_stddevNorm", value: "1.0973477363586426" },
      { name: "loudness_percentile20.0", value: "0.14669981598854065" },
      { name: "loudness_percentile50.0", value: "0.5076659917831421" },
      { name: "loudness_percentile80.0", value: "1.2544116973876953" },
      { name: "loudness_pctlrange0-2", value: "1.107711911201477" },
      { name: "loudness_meanRisingSlope", value: "11.23244571685791" },
      { name: "loudness_stddevRisingSlope", value: "6.74150276184082" },
      { name: "loudness_meanFallingSlope", value: "9.039560317993164" },
      { name: "loudness_stddevFallingSlope", value: "5.4829020500183105" },
      { name: "shimmerLocaldB_amean", value: "1.3993276357650757" },
      { name: "shimmerLocaldB_stddevNorm", value: "0.8022211194038391" },
      { name: "HNRdBACF_amean", value: "2.794785499572754" },
      { name: "HNRdBACF_stddevNorm", value: "1.7827588319778442" },
      { name: "loudnessPeaksPerSec", value: "2.349624156951904" },
    ],
  },
  {
    voiceTask: "",
    featureType: "spectral",
    features: [
      { name: "spectralFlux_amean", value: "0.6609601974487305" },
      { name: "spectralFlux_stddevNorm", value: "1.354922890663147" },
      { name: "mfcc1_amean", value: "23.82941246032715" },
      { name: "mfcc1_stddevNorm", value: "1.0123440027236938" },
      { name: "mfcc2_amean", value: "14.765328407287598" },
      { name: "mfcc2_stddevNorm", value: "0.9250609278678894" },
      { name: "mfcc3_amean", value: "12.776257514953613" },
      { name: "mfcc3_stddevNorm", value: "1.4199849367141724" },
      { name: "mfcc4_amean", value: "1.7381778955459595" },
      { name: "mfcc4_stddevNorm", value: "8.541631698608398" },
      { name: "F1amplitudeLogRelF0_amean", value: "-69.75352478027344" },
      { name: "F1amplitudeLogRelF0_stddevNorm", value: "-1.242651343345642" },
      { name: "F2amplitudeLogRelF0_amean", value: "-77.94793701171875" },
      { name: "F2amplitudeLogRelF0_stddevNorm", value: "-1.0123411417007446" },
      { name: "F3amplitudeLogRelF0_amean", value: "-81.95746612548828" },
      { name: "F3amplitudeLogRelF0_stddevNorm", value: "-0.9249182343482971" },
      { name: "alphaRatioV_amean", value: "-19.546648025512695" },
      { name: "alphaRatioV_stddevNorm", value: "-0.6505926847457886" },
      { name: "hammarbergIndexV_amean", value: "29.651012420654297" },
      { name: "hammarbergIndexV_stddevNorm", value: "0.4453713893890381" },
      { name: "slopeV0-500_amean", value: "0.01844790391623974" },
      { name: "slopeV0-500_stddevNorm", value: "2.5100362300872803" },
      { name: "slopeV500-1500_amean", value: "-0.02334383875131607" },
      { name: "slopeV500-1500_stddevNorm", value: "-0.7795518040657043" },
      { name: "spectralFluxV_amean", value: "0.9101601243019104" },
      { name: "spectralFluxV_stddevNorm", value: "1.0673823356628418" },
      { name: "mfcc1V_amean", value: "34.457557678222656" },
      { name: "mfcc1V_stddevNorm", value: "0.5520211458206177" },
      { name: "mfcc2V_amean", value: "16.72629165649414" },
      { name: "mfcc2V_stddevNorm", value: "0.8301082849502563" },
      { name: "mfcc3V_amean", value: "14.783638000488281" },
      { name: "mfcc3V_stddevNorm", value: "1.334086298942566" },
      { name: "mfcc4V_amean", value: "0.5752443075180054" },
      { name: "mfcc4V_stddevNorm", value: "28.248029708862305" },
      { name: "alphaRatioUV_amean", value: "-1.9990218877792358" },
      { name: "hammarbergIndexUV_amean", value: "9.446380615234375" },
      { name: "slopeUV0-500_amean", value: "0.042951080948114395" },
      { name: "slopeUV500-1500_amean", value: "0.006136434152722359" },
      { name: "spectralFluxUV_amean", value: "0.1258414834737777" },
    ],
  },
  {
    voiceTask: "",
    featureType: "temporal",
    features: [
      { name: "MeanVoicedSegmentLengthSec", value: "0.31173914670944214" },
      { name: "StddevVoicedSegmentLengthSec", value: "0.4145888388156891" },
      { name: "MeanUnvoicedSegmentLength", value: "0.12782607972621918" },
      { name: "StddevUnvoicedSegmentLength", value: "0.165528297424316" },
    ],
  },
]

const FeatureVisualisation = () => {
  const { translate, currentLang } = useLocales()
  const { token, authError, login } = useAuth()
  const { savePatientLocally } = usePatient()

  const [formValues, setFormValues] = useState<LoginDTO>({ username: "", password: "" })
  const [project, setProject] = useState<Project>()
  const navigate = useNavigate()
  const { id, patientid } = useParams()
  const { patient, cleanPatient } = usePatient()
  const [firstQuestionnaire, setFirstQuestionnaire] = useState<string>()

  useEffect(() => {
    const proj = projects.find((project) => project.projectID === id)
    if (proj) {
      setProject(proj)
      if (proj.questionnaires !== undefined) {
        const filteredQuestionsByLang = proj.questionnaires.map((questionnaire_structure) =>
          questionnaire_structure.questionnaire.filter((question) => question.lang === currentLang.value)
        )
        const allQuestionnaires: string[] = filteredQuestionsByLang
          .flatMap((questions: MedicalQuestion[]) => questions.map((question) => question.parent))
          .filter((v, i, a) => a.indexOf(v) === i)

        setFirstQuestionnaire(allQuestionnaires[0])
      }
    } else {
      navigate("/")
    }

    savePatientLocally({
      ...patient,
      voiceFeatures: voiceFeatures,
    })
    // Dirty: login with superuser to get token
    //login({ username: "doctorMH7@akuity.care", password: "speaknice" })
    //console.log("token: " + token)
    // TODO: potentially construct while around and make several requests
    //getAuthorized<VoiceFeatures[]>(process.env.REACT_APP_BACKEND_PATH_QUESTIONS, token.access_token).then((response) => {
    // getFeatures<VoiceFeatures[]>("api/v1/features", patient.questionnaireId, token).then((response) => {
    //   console.log("response:")
    //   console.log(response)
    //
    //   const voiceFeatures: VoiceFeatures[] = Object.keys(response).map((featureType: string) => {
    //     // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    //     // @ts-ignore
    //     const featureValues = response[featureType]
    //     //console.log("featureResponse:")
    //     //console.log(featureValues)
    //     //console.log(featureType)
    //     const feat: VoiceFeatures = {
    //       voiceTask: "",
    //       featureType: featureType,
    //       features: featureValues,
    //     }
    //     return feat
    //   })
    //   savePatientLocally({
    //     ...patient,
    //     voiceFeatures: voiceFeatures,
    //   })
    // })
  }, [])

  //logout()

  if (!project) {
    return <></>
  } else {
    return (
      <Page title={`${project.projectName}`}>
        <Container>
          <ContentStyle sx={{ textAlign: "center" }}>
            {/*<Typography variant="h1">{translate("project.final.title")}</Typography>*/}
            <Typography variant="h2">Extraction des features</Typography>
            <Grid container rowSpacing={2} columnSpacing={{ xs: 1, sm: 2, md: 3 }} sx={{ mt: 1 }}>
              <Grid item xs={12} sm={6}>
                <Box sx={{ display: "flex", pt: 1, pb: 1 }}>
                  <Zoom in={true}>
                    <Box component="img" src={`/assets/audio_features/8_seconds/raw_audio.png`} sx={{ width: 400, margin: "auto" }} />
                  </Zoom>
                </Box>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Box sx={{ display: "flex", pt: 1, pb: 1 }}>
                  <Zoom in={true}>
                    <Box component="img" src={`/assets/audio_features/8_seconds/Shitter_jimmer.png`} sx={{ width: 400, margin: "auto" }} />
                  </Zoom>
                </Box>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Box sx={{ display: "flex", pt: 1, pb: 1 }}>
                  <Zoom in={true}>
                    <Box
                      component="img"
                      src={`/assets/audio_features/8_seconds/log_spectrogram_f0.png`}
                      sx={{ width: 400, margin: "auto" }}
                    />
                  </Zoom>
                </Box>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Box sx={{ display: "flex", pt: 1, pb: 1 }}>
                  <Zoom in={true}>
                    <Box component="img" src={`/assets/audio_features/8_seconds/mfcc.png`} sx={{ width: 400, margin: "auto" }} />
                  </Zoom>
                </Box>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Box sx={{ display: "flex", pt: 1, pb: 1 }}>
                  <Zoom in={true}>
                    <Box
                      component="img"
                      src={`/assets/audio_features/8_seconds/spectral_centroid.png`}
                      sx={{ width: 400, margin: "auto" }}
                    />
                  </Zoom>
                </Box>
              </Grid>
            </Grid>
            <Box sx={{ pt: 5 }}>
              <Button
                type="submit"
                variant="contained"
                color="primary"
                sx={{ width: "80%", height: 60, fontSize: 24 }}
                onClick={() => {
                  navigate(location.pathname.replace("featurevisualisation", `features`))
                }}
              >
                {translate("project.result.continue")}
              </Button>
            </Box>
          </ContentStyle>
        </Container>
      </Page>
    )
  }
}

export default FeatureVisualisation

import { Categorical, MedicalQuestion } from "../../../../@types/medicalQuestion"

const category: Categorical[] = [
  { label: "1", value: "1" },
  { label: "2", value: "2" },
  { label: "3", value: "3" },
  { label: "4", value: "4" },
  { label: "5", value: "5" },
  { label: "6", value: "6" },
  { label: "7", value: "7" },
]

const fssFR: MedicalQuestion[] = [
  {
    id: "fss_intro",
    name: "intro",
    value:
      "Choisissez un chiffre de 1 (pas du tout d'accord) à 7 (tout à fait d'accord) qui indique " +
      "votre degré d'accord avec les affirmations suivantes. Veuillez répondre aux questions en vous référant " +
      "à ce que vous avez ressenti en moyenne au cours de la semaine dernière.",
    type: "intro",
    lang: "fr",
    parent: "fss",
    required: false,
  },
  {
    id: "fss_1",
    name: "Fatigue Severity Scale",
    type: "categoricalLong",
    value: category,
    labelStart: "Pas du tout d'accord",
    labelEnd: "Tout à fait d'accord",
    description: "Je me sens moins motivé du fait de la fatigue",
    parent: "fss",
    order: 1,
    lang: "fr",
    required: false,
  },
  {
    id: "fss_2",
    name: "Fatigue Severity Scale",
    type: "categoricalLong",
    value: category,
    labelStart: "Pas du tout d'accord",
    labelEnd: "Tout à fait d'accord",
    description: "L'exercice physique est pour moi source de fatigue",
    parent: "fss",
    order: 2,
    lang: "fr",
    required: false,
  },
  {
    id: "fss_3",
    name: "Fatigue Severity Scale",
    type: "categoricalLong",
    value: category,
    labelStart: "Pas du tout d'accord",
    labelEnd: "Tout à fait d'accord",
    description: "Je suis facilement fatigué(e)",
    parent: "fss",
    order: 3,
    lang: "fr",
    required: false,
  },
  {
    id: "fss_4",
    name: "Fatigue Severity Scale",
    type: "categoricalLong",
    value: category,
    labelStart: "Pas du tout d'accord",
    labelEnd: "Tout à fait d'accord",
    description: "La fatigue interfère avec mon activité physique",
    parent: "fss",
    order: 4,
    lang: "fr",
    required: false,
  },
  {
    id: "fss_5",
    name: "Fatigue Severity Scale",
    type: "categoricalLong",
    value: category,
    labelStart: "Pas du tout d'accord",
    labelEnd: "Tout à fait d'accord",
    description: "La fatigue est souvent un problème pour moi",
    parent: "fss",
    order: 5,
    lang: "fr",
    required: false,
  },
  {
    id: "fss_6",
    name: "Fatigue Severity Scale",
    type: "categoricalLong",
    value: category,
    labelStart: "Pas du tout d'accord",
    labelEnd: "Tout à fait d'accord",
    description: "Ma fatigue m’empêche de réaliser des tâches physiques soutenues et prolongées",
    parent: "fss",
    order: 6,
    lang: "fr",
    required: false,
  },
  {
    id: "fss_7",
    name: "Fatigue Severity Scale",
    type: "categoricalLong",
    value: category,
    labelStart: "Pas du tout d'accord",
    labelEnd: "Tout à fait d'accord",
    description: "La fatigue interfère avec mes facultés pour la réalisation de certaines activités et responsabilités",
    parent: "fss",
    order: 7,
    lang: "fr",
    required: false,
  },
  {
    id: "fss_8",
    name: "Fatigue Severity Scale",
    type: "categoricalLong",
    value: category,
    labelStart: "Pas du tout d'accord",
    labelEnd: "Tout à fait d'accord",
    description: "La fatigue fait partie des mes 3 symptômes les plus gênants ",
    parent: "fss",
    order: 8,
    lang: "fr",
    required: false,
  },
  {
    id: "fss_9",
    name: "Fatigue Severity Scale",
    type: "categoricalLong",
    value: category,
    labelStart: "Pas du tout d'accord",
    labelEnd: "Tout à fait d'accord",
    description: "La fatigue interfère avec mon travail, ma famille ou ma vie sociale",
    parent: "fss",
    order: 9,
    lang: "fr",
    required: false,
  },
]

export default fssFR

import { Categorical, MedicalQuestion } from "../../../../@types/medicalQuestion"

const category: Categorical[] = [
  {
    label: "Überhaupt nicht",
    value: "not-at-all",
  },
  {
    label: "An einzelnen Tagen",
    value: "several-days",
  },
  {
    label: "An mehr als der Hälfte der Tage",
    value: "more-than-half-the-days",
  },
  {
    label: "Beinahe jeden Tag",
    value: "nearly-every-day",
  },
]

const gadDE: MedicalQuestion[] = [
  {
    id: "gad_intro",
    name: "intro",
    value: "Wie oft fühlten Sie sich im Verlauf der letzten 2 Wochen durch die folgenden Beschwerden beeinträchtigt ?",
    type: "intro",
    lang: "de",
    parent: "gad",
    required: false,
  },
  {
    id: "gad_1",
    name: "Generalized Anxiety Disorder",
    type: "categoricalShort",
    value: category,
    description: "Nervosität, Ängstlichkeit oder Anspannung",
    parent: "gad",
    order: 1,
    lang: "de",
    required: true,
  },
  {
    id: "gad_2",
    name: "Generalized Anxiety Disorder",
    type: "categoricalShort",
    value: category,
    description: "Nicht in der Lage sein, Sorgen zu stoppen oder zu kontrollieren",
    parent: "gad",
    order: 2,
    lang: "de",
    required: true,
  },
  {
    id: "gad_3",
    name: "Generalized Anxiety Disorder",
    type: "categoricalShort",
    value: category,
    description: "Übermäßige Sorgen bezüglich verschiedener Angelegenheiten",
    parent: "gad",
    order: 3,
    lang: "de",
    required: true,
  },
  {
    id: "gad_4",
    name: "Generalized Anxiety Disorder",
    type: "categoricalShort",
    value: category,
    description: "Schwierigkeiten zu entspannen",
    parent: "gad",
    order: 4,
    lang: "de",
    required: true,
  },
  {
    id: "gad_5",
    name: "Generalized Anxiety Disorder",
    type: "categoricalShort",
    value: category,
    description: "Rastlosigkeit, so dass Stillsitzen schwer fällt",
    parent: "gad",
    order: 5,
    lang: "de",
    required: true,
  },
  {
    id: "gad_6",
    name: "Generalized Anxiety Disorder",
    type: "categoricalShort",
    value: category,
    description: "Schnelle Verärgerung oder Gereiztheit",
    parent: "gad",
    order: 6,
    lang: "de",
    required: true,
  },
  {
    id: "gad_7",
    name: "Generalized Anxiety Disorder",
    type: "categoricalShort",
    value: category,
    description: "Gefühl der Angst, so als würde etwas Schlimmes passieren",
    parent: "gad",
    order: 7,
    lang: "de",
    required: true,
  },
]

export default gadDE

import { Categorical, MedicalQuestion } from "../../../../@types/medicalQuestion"

const category: Categorical[] = [
  {
    label: "yes",
    value: "yes",
  },
  {
    label: "no",
    value: "no",
  },
]

const cofoundingEN: MedicalQuestion[] = [
  {
    id: "preg",
    name: "Pregnancy to date",
    type: "categoricalShort",
    value: category,
    description: "Are you currently pregnant?",
    parent: "cofounding",
    order: 1,
    lang: "en",
    required: true,
  },
  {
    id: "preg_d",
    name: "pregnancy month",
    type: "number",
    minValue: 1,
    maxValue: 9,
    description: "What month of pregnancy are you in?",
    parent: "cofounding",
    order: 2,
    lang: "en",
    dependsOn: {
      id: "preg",
      values: ["yes"],
    },
    required: false,
  },
  {
    id: "pain",
    name: "Pain scale",
    type: "categoricalLong",
    value: [
      { label: "0", value: "0" },
      { label: "1", value: "1" },
      { label: "2", value: "2" },
      { label: "3", value: "3" },
      { label: "4", value: "4" },
      { label: "5", value: "5" },
      { label: "6", value: "6" },
      { label: "7", value: "7" },
      { label: "8", value: "8" },
      { label: "9", value: "9" },
      { label: "10", value: "10" },
    ],
    labelStart: "No pain",
    labelEnd: "Pain as bad as you can imagine",
    description: "Please rate how much pain you have right now: from 0=no pain to 10=pain as bad as you can imagine",
    parent: "cofounding",
    order: 3,
    lang: "en",
    required: false,
  },
  {
    id: "stress",
    name: "Stress scale",
    type: "categoricalLong",
    value: [
      { label: "0", value: "0" },
      { label: "1", value: "1" },
      { label: "2", value: "2" },
      { label: "3", value: "3" },
      { label: "4", value: "4" },
      { label: "5", value: "5" },
      { label: "6", value: "6" },
      { label: "7", value: "7" },
      { label: "8", value: "8" },
      { label: "9", value: "9" },
      { label: "10", value: "10" },
    ],
    labelStart: "No stress",
    labelEnd: "Pain as bad as you can stress",
    description: "Please rate how much stress you have right now: from 0=no stress to 10=Highest imaginable stress",
    parent: "cofounding",
    order: 4,
    lang: "en",
    required: false,
  },
  {
    id: "depr",
    name: "Depression",
    type: "categoricalShort",
    value: category,
    description: "Have you been diagnosed with depression?",
    parent: "cofounding",
    order: 5,
    lang: "en",
    required: true,
  },
  {
    id: "anx",
    name: "Generalized Anxiety Disorder question",
    type: "categoricalShort",
    value: category,
    description: "Have you been diagnosed with generalized anxiety disorder?",
    parent: "cofounding",
    order: 6,
    lang: "en",
    required: true,
  },
  {
    id: "schiz",
    name: "Schizophrenia diagnosis",
    type: "categoricalShort",
    value: category,
    description: "Have you been diagnosed with schizophrenia?",
    parent: "cofounding",
    order: 7,
    lang: "en",
    required: true,
  },
  {
    id: "bipol",
    name: "Bipolar disorder diagnosis",
    type: "categoricalShort",
    value: category,
    description: "Have you been diagnosed with bipolar disorder?",
    parent: "cofounding",
    order: 8,
    lang: "en",
    required: true,
  },
  {
    id: "copd",
    name: "Chronic obstructive pulmonary disease",
    type: "categoricalShort",
    value: [
      { label: "yes", value: "yes" },
      { label: "no", value: "no" },
    ],
    description: "Have you been diagnosed with chronic obstructive pulmonary disease (COPD)?",
    parent: "cofounding",
    order: 9,
    lang: "en",
    required: true,
  },
  {
    id: "asth",
    name: "Asthma",
    type: "categoricalShort",
    value: [
      { label: "yes", value: "yes" },
      { label: "no", value: "no" },
    ],
    description: "Have you been diagnosed with asthma?",
    parent: "cofounding",
    order: 10,
    lang: "en",
    required: true,
  },
  {
    id: "infect_dis_sympt",
    name: "Infectious disease symptoms",
    type: "multi",
    value: [
      { label: "Nasal or sinus congestion ('stuffy nose')", value: "stuffy-nose" },
      { label: "Runny nose", value: "runny-nose" },
      { label: "Sore throat, irritation or discomfort in the throat", value: "sore-throat" },
      { label: "Dysphonia (audibly abnormal, hoarse, fluctuating voice...)", value: "dysphonia" },
    ],
    description: "Do you currently have any of the following symptoms?",
    parent: "cofounding",
    order: 11,
    lang: "en",
    required: false,
  },
  {
    id: "infect_dis_c",
    name: "Infectious disease currently",
    type: "multi",
    value: [
      { label: "Flu", value: "influenza" },
      { label: "Pneumonia", value: "pneumonia" },
      { label: "Bronchitis", value: "bronchitis" },
      { label: "Angina", value: "angina" },
      { label: "Laryngitis", value: "laryngitis" },
      { label: "Sinusitis", value: "sinusitis" },
      { label: 'Rhinopharyngitis ("cold")', value: "rhinopharyngitis" },
      { label: "Covid-19", value: "covid-19" },
      { label: "Allergy", value: "allergy" },
      { label: "Other", value: "other" },
    ],
    description: "Are you currently suffering from a respiratory infection?",
    parent: "cofounding",
    order: 12,
    lang: "en",
    required: false,
  },
  {
    id: "infect_dis",
    name: "Infectious disease",
    type: "multi",
    value: [
      { label: "Flu", value: "influenza" },
      { label: "Pneumonia", value: "pneumonia" },
      { label: "Bronchitis", value: "bronchitis" },
      { label: "Angina", value: "angina" },
      { label: "Laryngitis", value: "laryngitis" },
      { label: "Sinusitis", value: "sinusitis" },
      { label: 'Rhinopharyngitis ("cold")', value: "rhinopharyngitis" },
      { label: "Covid-19", value: "covid-19" },
      { label: "Allergy", value: "allergy" },
      { label: "Other", value: "other" },
    ],
    description: "Have you been diagnosed with another respiratory infection in the last 3 months?",
    parent: "cofounding",
    order: 13,
    lang: "en",
    required: false,
  },
  {
    id: "l_cov19",
    name: "Long covid-19",
    type: "categoricalShort",
    value: category,
    description: "Do you have persisting Covid-19 symptoms (Long-Covid)?",
    parent: "cofounding",
    order: 14,
    lang: "en",
    required: true,
  },
  {
    id: "allergie_resp",
    name: "Allergie respiratoire",
    type: "categoricalShort",
    value: category,
    description: "Have you been diagnosed with a respiratory allergy?",
    parent: "cofounding",
    order: 15,
    lang: "en",
    required: true,
  },
  {
    id: "fib_pul",
    name: "Fibrose pulmonaire",
    type: "categoricalShort",
    value: category,
    description: "Have you been diagnosed with pulmonary fibrosis?",
    parent: "cofounding",
    order: 16,
    lang: "en",
    required: true,
  },
  {
    id: "c_type",
    name: "cancer type",
    type: "multi",
    value: [
      { label: "Breast", value: "breast" },
      { label: "Lung", value: "lung" },
      { label: "Brain", value: "brain" },
      { label: "Lips", value: "lips" },
      { label: "Tongue", value: "tongue" },
      { label: "Mouth", value: "mouth" },
      { label: "Throat", value: "throat" },
      { label: "Larynx", value: "larynx" },
      { label: "Salivary glands", value: "salivary-glands" },
      { label: "Pharynx", value: "pharynx" },
      { label: "Sinus", value: "sinus" },
      { label: "Nasal cavity", value: "nasal-cavity" },
      { label: "Other localization on head and neck", value: "other-head-neck" },
      { label: "Other localization", value: "other" },
    ],
    description: "Have you been diagnosed with cancer?",
    parent: "cofounding",
    order: 17,
    lang: "en",
    required: false,
  },
  {
    id: "c_diag_h",
    name: "history of the cancer?",
    type: "select",
    value: [
      { label: "First diagnosis", value: "1-diag" },
      { label: "First recurrence", value: "1" },
      { label: "Second recurrence", value: "2" },
      { label: "Third recurrence", value: "3" },
      { label: "Fourth recurrence", value: "4" },
      { label: "Fifth recurrence", value: "5" },
      { label: "Sixth recurrence", value: "6" },
      { label: "Seventh recurrence", value: "7" },
      { label: "Eighth recurrence", value: "8" },
      { label: "Ninth recurrence", value: "9" },
      { label: "Tenth recurrence", value: "10" },
    ],
    description: "Is it your first diagnosis with cancer of this type or is it a cancer recurrence?",
    parent: "cofounding",
    order: 18,
    lang: "en",
    dependsOn: {
      id: "c_type",
      values: [
        "breast",
        "lung",
        "brain",
        "lips",
        "tongue",
        "mouth",
        "throat",
        "larynx",
        "salivary-glands",
        "pharynx",
        "sinus",
        "nasal-cavity",
        "other-head-neck",
        "other",
      ],
    },
    required: false,
  },
  {
    id: "c_diag_y",
    name: "cancer diag year",
    type: "number",
    minValue: 1980,
    maxValue: 2023,
    description: "When were you diagnosed with your current cancer?",
    parent: "cofounding",
    order: 19,
    lang: "en",
    dependsOn: {
      id: "c_type",
      values: [
        "breast",
        "lung",
        "brain",
        "lips",
        "tongue",
        "mouth",
        "throat",
        "larynx",
        "salivary-glands",
        "pharynx",
        "sinus",
        "nasal-cavity",
        "other-head-neck",
        "other",
      ],
    },
    required: false,
  },
  {
    id: "comorb",
    name: "Comorbidities",
    type: "multi",
    value: [
      { label: "Infarction/Heart attack", value: "infarction/heart-attack" },
      { label: "Congestive heart failure", value: "congestive-heart-failure" },
      { label: "Coronary heart disease", value: "coronary-heart-disease" },
      { label: "Angina pectoris", value: "angina-pectoris" },
      { label: "Hypertension", value: "hypertension" },
      { label: "Diabetes", value: "diabetes" },
      { label: "Thyroidic disease", value: "thyroidic-disease" },
      { label: "Chronic kidney disease", value: "chronic-kidney-disease" },
      { label: "Allergy (ex: mites, pollen, food, pets..)", value: "allergy" },
      { label: "Emphysema", value: "emphysema" },
      { label: "Malaria", value: "malaria" },
      { label: "Tuberculosis", value: "tuberculosis" },
      { label: "Zika", value: "zika" },
      { label: "Gout", value: "gout" },
      { label: "Hepatitis", value: "hepatitis" },
      { label: "Crohn’s disease", value: "crohn-disease" },
      { label: "Ulcerative colitis", value: "ulcerative-colitis" },
      { label: "Rheumatoid Arthritis or Systemic Lupus (SLE)", value: "rheumatoid-arthritis-or-systemic-lupus" },
      { label: "Multiple Sclerosis", value: "multiple-sclerosis" },
      { label: "Epilepsy", value: "epilepsy" },
      { label: "Amyotrophic Lat. Sclerosis (ALS)", value: "amyotrophic-lat-sclerosis" },
      { label: "Narcolepsy", value: "narcolepsy" },
      { label: "Parkinson's disease", value: "parkinson-disease" },
      { label: "Stroke", value: "stroke" },
      { label: "Migraine", value: "migraine" },
      { label: "Laryngitis", value: "laryngitis" },
      { label: "Non cancerous vocal cord lesion (polyp, nodul,cyst)", value: "non-cancerous-vocal-cord-lesion" },
      { label: "Laryngopharyngeal reflux", value: "laryngopharyngeal-reflux" },
      { label: "Gastroesophageal reflux", value: "gastroesophageal-reflu" },
      { label: "Leukoplakia", value: "leukoplakia" },
    ],
    description: "Have you ever been diagnosed with one or several of the following diseases?",
    parent: "cofounding",
    order: 20,
    lang: "en",
    required: false,
  },
  {
    id: "med",
    name: "Medication",
    type: "multi",
    value: [
      { label: "Paracetamol", value: "paracetamol" },
      { label: "Ibuprofen", value: "ibuprofen" },
      { label: "Codeine", value: "codeine" },
      { label: "Morphine or morphine-derived drugs", value: "morphine-or-morphine-derived drugs" },
      { label: "Medication for cholesterol", value: "medication-for-cholesterol" },
      { label: "Medication (tablets or insulin) for diabetes", value: "medication-diabetes" },
      { label: "Medication for hypertension", value: "medication-hypertension" },
      { label: "Angiotensin-converting enzyme medications", value: "angiotensin-converting-enzyme-medications" },
      { label: "Antibiotics", value: "antibiotics" },
      { label: "Medication to treat anxiety or depression", value: "medication-anxiety-or-depression" },
      { label: "Anti-reflux medication", value: "anti-reflux-medication" },
      { label: "Treatment for thyroid problems", value: "treatment-thyroid" },
      {
        label: "Corticosteroids such as prednisone or budesonide (Entocort EC)",
        value: "corticosteroids",
      },
      { label: "Muscle relaxants", value: "muscle-relaxants" },
      { label: "Decongestants", value: "decongestants" },
      { label: "Diuretics", value: "diuretics" },
      { label: "Antidiarrheal agents (ex. lsoperamide (Imodium A-D)", value: "antidiarrheal-agents" },
      { label: "Antihistamines", value: "antihistamines" },
      { label: "Anticholinergics", value: "anticholinergics" },
      { label: "Anti-allergic", value: "anti-allergic" },
      { label: "Anti-inflammatory", value: "anti-inflammatory" },
      {
        label: "High doses of Vitamin C (greater than five grams per day)",
        value: "high-doses-of-vitamin-c",
      },
      { label: "Anticoagulants", value: "anticoagulants" },
      { label: "Medication for throat pain", value: "medication-throat-pain" },
      { label: "Hormonal treatment (contraception, menopause...)", value: "hormonal-treatment" },
    ],
    description: "Are you currently taking any of the following medications at least 3 times a week?",
    parent: "cofounding",
    order: 21,
    lang: "en",
    required: false,
  },
]

export default cofoundingEN

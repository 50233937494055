import { MedicalQuestion } from "../../../../@types/medicalQuestion"

const cancerDE: MedicalQuestion[] = [
  {
    id: "c_type",
    name: "cancer type",
    type: "multi",
    value: [
      { label: "Brust", value: "breast" },
      { label: "Lunge", value: "lung" },
      { label: "Gehirn", value: "brain" },
      { label: "Lippen", value: "lips" },
      { label: "Zunge", value: "tongue" },
      { label: "Mund", value: "mouth" },
      { label: "Hals", value: "throat" },
      { label: "Kehlkopf", value: "larynx" },
      { label: "Speicheldrüsen", value: "salivary-glands" },
      { label: "Rachen", value: "pharynx" },
      { label: "Nebenhöhlen", value: "sinus" },
      { label: "Nasenhöhle", value: "nasal-cavity" },
      { label: "Andere Lokalisation auf dem Kopf oder Hals", value: "other-head-neck" },
      { label: "Andere Lokalisation", value: "other" },
    ],
    description: "Wo befindet sich Ihr aktueller Krebs ?",
    parent: "cancer",
    order: 1,
    lang: "de",
    required: true,
  },
  {
    id: "c_diag",
    name: "first cancer diagnosis",
    type: "categoricalShort",
    value: [
      { label: "Erstdiagnose", value: "first-diagnosis" },
      { label: "Erstes Wiederauftreten", value: "first-recurrence" },
      { label: "Zweites Wiederauftreten", value: "second-recurrence" },
      { label: "Drittes Wiederauftreten", value: "third-recurrence" },
      { label: "Viertes Wiederauftreten", value: "fourth-recurrence" },
      { label: "Fünftes Wiederauftreten", value: "fifth-recurrence" },
      { label: "Sechstes Wiederauftreten", value: "sixth-recurrence" },
      { label: "Siebtes Wiederauftreten", value: "seventh-recurrence" },
      { label: "Achtes Wiederauftreten", value: "eighth-recurrence" },
      { label: "Neuntes Wiederauftreten", value: "ninth-recurrence" },
      { label: "Zehntes Wiederauftreten", value: "tenth-recurrence" },
    ],
    description: "Handelt es sich um Ihre erste Krebsdiagnose dieser Art oder ist es ein Wiederauftreten des Krebses ?",
    parent: "cancer",
    order: 2,
    lang: "de",
    required: true,
  },
  {
    id: "c_diag_y",
    name: "cancer diag year",
    type: "number",
    minValue: 1980,
    maxValue: 2030,
    description: "Wann wurde bei Ihnen Ihre aktuelle Krebserkrankung diagnostiziert ?",
    parent: "cancer",
    order: 3,
    lang: "de",
    required: false,
  },
  {
    id: "c_treat_p",
    name: "cancer treatment past",
    type: "multi",
    value: [
      { label: "Chirurgie", value: "surgery" },
      { label: "Strahlentherapie", value: "radiotherapy" },
      { label: "Immuntherapie", value: "immunotherapy" },
      { label: "Chemotherapie", value: "chemotherapy" },
      { label: "Sonstiges", value: "other" },
    ],
    description:
      "Welche Art von Behandlung haben Sie für Ihre derzeitige oder möglicherweise frühere Krebserkrankung erhalten? " +
      "Hinweis: Berücksichtigen Sie hier nur die Behandlungen, die Sie nicht mehr erhalten",
    parent: "cancer",
    order: 4,
    lang: "de",
    required: false,
  },
  {
    id: "c_treat_c",
    name: "cancer treatment current",
    type: "multi",
    value: [
      { label: "Chirurgie", value: "surgery" },
      { label: "Strahlentherapie", value: "radiotherapy" },
      { label: "Immuntherapie", value: "immunotherapy" },
      { label: "Chemotherapie", value: "chemotherapy" },
      { label: "Sonstiges", value: "other" },
    ],
    description: "Welche Art von Behandlung erhalten Sie derzeit für Ihre Krebserkrankung ?",
    parent: "cancer",
    order: 5,
    lang: "de",
    required: false,
  },
  {
    id: "c_type_b",
    name: "cancer subcategory breast",
    type: "categoricalShort",
    value: [
      { label: "Luminal A", value: "luminal-a" },
      { label: "Luminal B", value: "luminal-b" },
      { label: "HER2-positive", value: "HER2-positive" },
      { label: "triple negative", value: "triple-negative" },
      { label: "Fehlende Information", value: "missing-information" },
    ],
    description: "Welche Unterkategorie von Brustkrebs war es ?",
    parent: "cancer",
    order: 6,
    lang: "de",
    dependsOn: {
      id: "c_type",
      values: ["breast"],
    },
    required: false,
    doctor: true,
  },
  {
    id: "c_type_l",
    name: "cancer subcategory lung",
    type: "categoricalShort",
    value: [
      { label: "NSCLC", value: "nsclc" },
      { label: "SCLC", value: "sclc" },
      { label: "fehlende Information", value: "missing-information" },
    ],
    description: "Welche Unterkategorie von Lungenkrebs war es ?",
    parent: "cancer",
    order: 7,
    lang: "de",
    dependsOn: {
      id: "c_type",
      values: ["lung"],
    },
    required: false,
    doctor: true,
  },
  {
    id: "c_tnm_t",
    name: "cancer tnm classification - t",
    type: "select",
    value: [
      { label: "Tx", value: "tx" },
      { label: "T0", value: "t0" },
      { label: "Tis", value: "tis" },
      { label: "T1", value: "t1" },
      { label: "T2", value: "t2" },
      { label: "T3", value: "t3" },
      { label: "T4", value: "t4" },
      { label: "fehlende Information", value: "missing-information" },
    ],
    description: "Was ist die TNM-Klassifikation (T) ?",
    parent: "cancer",
    order: 8,
    lang: "de",
    required: true,
    doctor: true,
  },
  {
    id: "c_tnm_n",
    name: "cancer tnm classification - n",
    type: "select",
    value: [
      { label: "Nx", value: "nx" },
      { label: "N0", value: "n0" },
      { label: "N1", value: "n1" },
      { label: "N2", value: "n2" },
      { label: "N3", value: "n3" },
      { label: "fehlende Information", value: "missing-information" },
    ],
    description: "Was ist die TNM-Klassifikation (N) ?",
    parent: "cancer",
    order: 9,
    lang: "de",
    required: true,
    doctor: true,
  },
  {
    id: "c_tnm_m",
    name: "cancer tnm classification - m",
    type: "select",
    value: [
      { label: "Mx", value: "mx" },
      { label: "M0", value: "m0" },
      { label: "M1", value: "m1" },
      { label: "fehlende Information", value: "missing-information" },
    ],
    description: "Was ist die TNM-Klassifikation (M) ?",
    parent: "cancer",
    order: 10,
    lang: "de",
    required: true,
    doctor: true,
  },
]

export default cancerDE

import { Categorical, MedicalQuestion } from "../../../../@types/medicalQuestion"

const category: Categorical[] = [
  {
    label: "Jamais",
    value: "not-at-all",
  },
  {
    label: "Plusieurs jours",
    value: "several-days",
  },
  {
    label: "Plus de la moitié du temps",
    value: "more-than-half-the-days",
  },
  {
    label: "Presque tous les jours",
    value: "nearly-every-day",
  },
]

const gadFR: MedicalQuestion[] = [
  {
    id: "gad_intro",
    name: "intro",
    value: "Au cours des 2 dernières semaines, selon quelle fréquence avez-vous été gêné(e) par les problèmes suivants ?",
    type: "intro",
    lang: "fr",
    parent: "gad",
    required: false,
  },
  {
    id: "gad_1",
    name: "Generalized Anxiety Disorder",
    type: "categoricalShort",
    value: category,
    description: "Sentiment de nervosité, d’anxiété ou de tension",
    parent: "gad",
    order: 1,
    lang: "fr",
    required: true,
  },
  {
    id: "gad_2",
    name: "Generalized Anxiety Disorder",
    type: "categoricalShort",
    value: category,
    description: "Incapable d’arrêter de vous inquiéter ou de contrôler vos inquiétudes",
    parent: "gad",
    order: 2,
    lang: "fr",
    required: true,
  },
  {
    id: "gad_3",
    name: "Generalized Anxiety Disorder",
    type: "categoricalShort",
    value: category,
    description: "Inquiétudes excessives à propos de tout et de rien",
    parent: "gad",
    order: 3,
    lang: "fr",
    required: true,
  },
  {
    id: "gad_4",
    name: "Generalized Anxiety Disorder",
    type: "categoricalShort",
    value: category,
    description: "Difficulté à se détendre",
    parent: "gad",
    order: 4,
    lang: "fr",
    required: true,
  },
  {
    id: "gad_5",
    name: "Generalized Anxiety Disorder",
    type: "categoricalShort",
    value: category,
    description: "Agitation telle qu’il est difficile de rester tranquille",
    parent: "gad",
    order: 5,
    lang: "fr",
    required: true,
  },
  {
    id: "gad_6",
    name: "Generalized Anxiety Disorder",
    type: "categoricalShort",
    value: category,
    description: "Devenir facilement contrarié(e) ou irritable",
    parent: "gad",
    order: 6,
    lang: "fr",
    required: true,
  },
  {
    id: "gad_7",
    name: "Generalized Anxiety Disorder",
    type: "categoricalShort",
    value: category,
    description: "Avoir peur que quelque chose d’épouvantable puisse arriver",
    parent: "gad",
    order: 7,
    lang: "fr",
    required: true,
  },
]

export default gadFR

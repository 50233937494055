import { MedicalQuestion } from "../../../../@types/medicalQuestion"

const generalFR: MedicalQuestion[] = [
  {
    id: "lang",
    name: "Language",
    type: "select",
    value: [
      { value: "french_france$", label: "Français (France)" },
      { value: "arabic", label: "Arabe" },
      { value: "bengali", label: "Bengali" },
      { value: "chinese_mandarin_wu", label: "Chinois (mandarin, wu...)" },
      { value: "czech", label: "Tchèque" },
      { value: "dutch", label: "Hollandais" },
      { value: "english_american", label: "Anglais (américain)" },
      { value: "english_british", label: "Anglais (britannique)" },
      { value: "french_africa", label: "Français (Africa)" },
      { value: "french_belgium", label: "Français (Belgique)" },
      { value: "french_france", label: "Français (France)" },
      { value: "french_quebec", label: "Français (Quebec)" },
      { value: "french_switzerland", label: "Français (Suisse)" },
      { value: "finnish", label: "Finlandais" },
      { value: "german_germany", label: "Allemand (Allemagne)" },
      { value: "german_swiss", label: "Allemand (Suisse)" },
      { value: "greek", label: "Grec" },
      { value: "hindi", label: "Hindi" },
      { value: "hungarian", label: "Hongrois" },
      { value: "italian", label: "Italien" },
      { value: "japanese", label: "Japonais" },
      { value: "korean", label: "Coréen" },
      { value: "norwegian", label: "Norvégien" },
      { value: "portuguese", label: "Portugais" },
      { value: "polish", label: "Polonais" },
      { value: "romanian", label: "Roumain" },
      { value: "russian", label: "Russe" },
      { value: "spanish", label: "Espagnol" },
      { value: "swedish", label: "Suédois" },
      { value: "turkish", label: "Turc" },
      { value: "other", label: "Autre" },
    ],
    description: "Quelle est votre langue maternelle ?",
    parent: "general_info",
    order: 1,
    lang: "fr",
    required: true,
  },
  {
    id: "age",
    name: "Age",
    type: "number",
    minValue: 1930,
    maxValue: 2011,
    description: "Année de naissance",
    parent: "general_info",
    order: 2,
    lang: "fr",
    required: true,
  },
  {
    id: "sex",
    name: "Gender",
    type: "categoricalShort",
    value: [
      { label: "Masculin", value: "male" },
      { label: "Féminin", value: "female" },
      { label: "Non-binaire", value: "non-binary" },
    ],
    description: "Sexe",
    parent: "general_info",
    order: 3,
    lang: "fr",
    required: true,
  },
  {
    id: "height",
    name: "Height",
    type: "number",
    minValue: 100,
    maxValue: 220,
    description: "Taille (cm)",
    parent: "general_info",
    order: 4,
    lang: "fr",
    required: true,
  },
  {
    id: "weight",
    name: "Weight",
    type: "number",
    minValue: 40,
    maxValue: 200,
    description: "Poids (kg)",
    parent: "general_info",
    order: 5,
    lang: "fr",
    required: true,
  },
  {
    id: "c_smo",
    name: "Current tobacco smoking",
    type: "categoricalShort",
    value: [
      { label: "Tous les jours", value: "daily" },
      { label: "Moins d’une fois par jour", value: "less-than-daily" },
      { label: "Pas du tout", value: "not-at-all" },
    ],
    description:
      "Êtes-vous actuellement fumeur(euse) (tabac ou autres substances, quelle que soit la forme y compris cigarette électronique) ?",
    parent: "general_info",
    order: 6,
    lang: "fr",
    required: true,
  },
  {
    id: "c_smo_view",
    name: "Current smoking overview question",
    type: "headerText",
    headerStyle: "subtitle1",
    description: "En moyenne, quelle quantité de chaque présentation ci-dessous fumez-vous ?",
    parent: "general_info",
    order: 7,
    lang: "fr",
    dependsOn: {
      id: "c_smo",
      values: ["daily", "less-than-daily"],
    },
    required: false,
  },
  {
    id: "c_smo_c",
    name: "Current smoking cigarettes quantity",
    type: "number",
    minValue: 0,
    maxValue: 150,
    description: "Cigarettes par jour (pour rappel, 1 paquet = 20 cigarettes) ?",
    parent: "general_info",
    order: 8,
    lang: "fr",
    dependsOn: {
      id: "c_smo",
      values: ["daily", "less-than-daily"],
    },
    required: false,
  },
  {
    id: "c_smo_cig",
    name: "Current smoking cigars quantity",
    type: "number",
    minValue: 0,
    maxValue: 100,
    description: "Cigares par semaine ?",
    parent: "general_info",
    order: 9,
    lang: "fr",
    dependsOn: {
      id: "c_smo",
      values: ["daily", "less-than-daily"],
    },
    required: false,
  },
  {
    id: "c_smo_ril",
    name: "Current smoking cigarillos quantity",
    type: "number",
    minValue: 0,
    maxValue: 150,
    description: "Cigarillos par semaine ?",
    parent: "general_info",
    order: 10,
    lang: "fr",
    dependsOn: {
      id: "c_smo",
      values: ["daily", "less-than-daily"],
    },
    required: false,
  },
  {
    id: "c_smo_joi",
    name: "Current smoking joints quantity",
    type: "number",
    minValue: 0,
    maxValue: 150,
    description: "Joints par semaine ?",
    parent: "general_info",
    order: 11,
    lang: "fr",
    dependsOn: {
      id: "c_smo",
      values: ["daily", "less-than-daily"],
    },
    required: false,
  },
  {
    id: "c_smo_nar",
    name: "Current smoking water pipes quantity",
    type: "number",
    minValue: 0,
    maxValue: 10,
    description: "Narguilés, en heures par semaine ?",
    parent: "general_info",
    order: 12,
    lang: "fr",
    dependsOn: {
      id: "c_smo",
      values: ["daily", "less-than-daily"],
    },
    required: false,
  },
  {
    id: "c_smo_ele",
    name: "Current smoking electronic cigarettes quantity",
    type: "number",
    minValue: 0,
    maxValue: 5,
    description: "Cigarettes électroniques, en flacons de 10mL de e-liquide par semaine ?",
    parent: "general_info",
    order: 13,
    lang: "fr",
    dependsOn: {
      id: "c_smo",
      values: ["daily", "less-than-daily"],
    },
    required: false,
  },
  {
    id: "c_dur",
    name: "Current duration smoking",
    type: "number",
    minValue: 1,
    maxValue: 60,
    description: "Depuis combien d'années fumez-vous ?",
    parent: "general_info",
    order: 14,
    lang: "fr",
    dependsOn: {
      id: "c_smo",
      values: ["daily", "less-than-daily"],
    },
    required: false,
  },
  {
    id: "p_smo",
    name: "Past tobacco smoking",
    type: "categoricalShort",
    value: [
      { label: "Tous les jours", value: "daily" },
      { label: "Moins d’une fois par jour", value: "less-than-daily" },
      { label: "Pas du tout", value: "not-at-all" },
    ],
    description: "Avez-vous fumé dans le passé (tabac ou autres substances, quelle que soit la forme y compris cigarette électronique) ?",
    parent: "general_info",
    order: 15,
    lang: "fr",
    dependsOn: {
      id: "c_smo",
      values: ["not-at-all"],
    },
    required: false,
  },
  {
    id: "p_smo_view",
    name: "Past smoking overview question",
    type: "headerText",
    headerStyle: "subtitle1",
    description: "En moyenne, quelle quantité de chaque présentation ci-dessous fumiez-vous alors ?",
    parent: "general_info",
    order: 16,
    lang: "fr",
    dependsOn: {
      id: "p_smo",
      values: ["daily", "less-than-daily"],
    },
    required: false,
  },
  {
    id: "p_smo_c",
    name: "Past smoking cigarettes quantity",
    type: "number",
    minValue: 0,
    maxValue: 150,
    description: "Cigarettes par jour (pour rappel, 1 paquet = 20 cigarettes) ?",
    parent: "general_info",
    order: 17,
    lang: "fr",
    dependsOn: {
      id: "p_smo",
      values: ["daily", "less-than-daily"],
    },
    required: false,
  },
  {
    id: "p_smo_cig",
    name: "Past smoking cigars quantity",
    type: "number",
    minValue: 0,
    maxValue: 100,
    description: "Cigares par semaine ?",
    parent: "general_info",
    order: 18,
    lang: "fr",
    dependsOn: {
      id: "p_smo",
      values: ["daily", "less-than-daily"],
    },
    required: false,
  },
  {
    id: "p_smo_ril",
    name: "Past smoking cigarillos quantity",
    type: "number",
    minValue: 0,
    maxValue: 150,
    description: "Cigarillos par semaine ?",
    parent: "general_info",
    order: 19,
    lang: "fr",
    dependsOn: {
      id: "p_smo",
      values: ["daily", "less-than-daily"],
    },
    required: false,
  },
  {
    id: "p_smo_joi",
    name: "Past smoking joints quantity",
    type: "number",
    minValue: 0,
    maxValue: 150,
    description: "Joints par semaine ?",
    parent: "general_info",
    order: 20,
    lang: "fr",
    dependsOn: {
      id: "p_smo",
      values: ["daily", "less-than-daily"],
    },
    required: false,
  },
  {
    id: "p_smo_nar",
    name: "Past smoking water pipes quantity",
    type: "number",
    minValue: 0,
    maxValue: 10,
    description: "Narguilés par semaine ?",
    parent: "general_info",
    order: 21,
    lang: "fr",
    dependsOn: {
      id: "p_smo",
      values: ["daily", "less-than-daily"],
    },
    required: false,
  },
  {
    id: "p_smo_ele",
    name: "Past smoking electronic cigarettes quantity",
    type: "number",
    minValue: 0,
    maxValue: 5,
    description: "Cigarettes électroniques, en flacons de 10mL de e-liquide par semaine ?",
    parent: "general_info",
    order: 22,
    lang: "fr",
    dependsOn: {
      id: "p_smo",
      values: ["daily", "less-than-daily"],
    },
    required: false,
  },
  {
    id: "p_dur",
    name: "Past duration smoking",
    type: "number",
    minValue: 1,
    maxValue: 60,
    description: "Pendant combien d'années avez-vous fumé ?",
    parent: "general_info",
    order: 23,
    lang: "fr",
    dependsOn: {
      id: "p_smo",
      values: ["daily", "less-than-daily"],
    },
    required: false,
  },
  {
    id: "p_dur_stop",
    name: "Past duration smoking stop",
    type: "number",
    minValue: 0,
    maxValue: 60,
    description: "Depuis combien d'années avez-vous arrêté de fumer ?",
    parent: "general_info",
    order: 24,
    lang: "fr",
    dependsOn: {
      id: "p_smo",
      values: ["daily", "less-than-daily"],
    },
    required: false,
  },
]

export default generalFR

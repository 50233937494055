import Page from "../../components/Page"
import { Box, Container, Grid, TextField, Button, Alert } from "@mui/material"
import { styled } from "@mui/material/styles"
import React, { useState } from "react"
import useAuth from "../../hooks/useAuth"
import { LoginDTO } from "../../@types/auth"

const ContentStyle = styled("div")(({ theme }) => ({
  maxWidth: 680,
  margin: "auto",
  display: "flex",
  justifyContent: "center",
  flexDirection: "column",
}))

const Login = () => {
  const { login, authError } = useAuth()
  const [formValues, setFormValues] = useState<LoginDTO>({ username: "", password: "" })

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target
    setFormValues({ ...formValues, [name]: value })
  }
  return (
    <Page title="Welcome">
      <Container>
        <ContentStyle sx={{ textAlign: "center" }}>
          <form>
            <Box display="flex" justifyContent="center" alignItems="center" sx={{ width: "70%", margin: "auto", pt: 5 }}>
              <Grid container justifyContent="flex-end" alignItems="center" columnSpacing={{ xs: 1, sm: 2, md: 3 }} sx={{ rowGap: 2 }}>
                <Grid item xs={4}>
                  Email
                </Grid>
                <Grid item xs={8}>
                  <TextField fullWidth size="small" value={formValues.username} name={"username"} onChange={handleInputChange} />
                </Grid>
                <Grid item xs={4}>
                  Password
                </Grid>
                <Grid item xs={8}>
                  <TextField
                    fullWidth
                    size="small"
                    type="password"
                    value={formValues.password}
                    name={"password"}
                    onChange={handleInputChange}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Button sx={{ width: "100%", mt: 1 }} variant="contained" onClick={() => login(formValues)}>
                    Login
                  </Button>
                </Grid>
                <Grid item xs={12}>
                  {authError && <Alert severity="error">{authError}</Alert>}
                </Grid>
              </Grid>
            </Box>
          </form>
        </ContentStyle>
      </Container>
    </Page>
  )
}

export default Login

import { TaskRecording } from "../../../@types/taskRecording"

const taskRecordingsDE: TaskRecording[] = [
  {
    id: "free_speech_2",
    name: "Free speech answer a question",
    type: "text",
    media: "Wie geht es Ihnen heute?",
    description:
      "1. Klicken Sie auf die grüne Taste, um die Aufnahme zu starten.\n" +
      "2. Beantworten Sie folgende Frage, indem Sie mindestens 20 Sekunden sprechen. \n" +
      "3. Wenn Sie fertig sind, klicken Sie erneut auf die Taste, um die Aufnahme zu beenden.",
    lang: "de",
  },
]

export default taskRecordingsDE

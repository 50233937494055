import { Categorical, MedicalQuestion } from "../../../../@types/medicalQuestion"

const category: Categorical[] = [
  {
    label: "Not at all",
    value: "not-at-all",
  },
  {
    label: "Several days",
    value: "several-days",
  },
  {
    label: "More than half the days",
    value: "more-than-half-the-days",
  },
  {
    label: "Nearly every day",
    value: "nearly-every-day",
  },
]

const gadEN: MedicalQuestion[] = [
  {
    id: "gad_intro",
    name: "intro",
    value: "Over the last two weeks, how often have you been bothered by any of the following problems?",
    type: "intro",
    lang: "en",
    parent: "gad",
    required: false,
  },
  {
    id: "gad_1",
    name: "Generalized Anxiety Disorder",
    type: "categoricalShort",
    value: category,
    description: "Feeling nervous, anxious or on edge?",
    parent: "gad",
    order: 1,
    lang: "en",
    required: true,
  },
  {
    id: "gad_2",
    name: "Generalized Anxiety Disorder",
    type: "categoricalShort",
    value: category,
    description: "Not being able to stop or control worrying?",
    parent: "gad",
    order: 2,
    lang: "en",
    required: true,
  },
  {
    id: "gad_3",
    name: "Generalized Anxiety Disorder",
    type: "categoricalShort",
    value: category,
    description: "Worrying too much about different things?",
    parent: "gad",
    order: 3,
    lang: "en",
    required: true,
  },
  {
    id: "gad_4",
    name: "Generalized Anxiety Disorder",
    type: "categoricalShort",
    value: category,
    description: "Trouble relaxing?",
    parent: "gad",
    order: 4,
    lang: "en",
    required: true,
  },
  {
    id: "gad_5",
    name: "Generalized Anxiety Disorder",
    type: "categoricalShort",
    value: category,
    description: "Being so restless that it is hard to sit still?",
    parent: "gad",
    order: 5,
    lang: "en",
    required: true,
  },
  {
    id: "gad_6",
    name: "Generalized Anxiety Disorder",
    type: "categoricalShort",
    value: category,
    description: "Becoming easily annoyed or irritable?",
    parent: "gad",
    order: 6,
    lang: "en",
    required: true,
  },
  {
    id: "gad_7",
    name: "Generalized Anxiety Disorder",
    type: "categoricalShort",
    value: category,
    description: "Feeling afraid as if something awful might happen?",
    parent: "gad",
    order: 7,
    lang: "en",
    required: true,
  },
]
export default gadEN

import { useContext } from "react"
import { PatientContext } from "../context/Patient"

const usePatient = () => {
  const context = useContext(PatientContext)
  if (!context) throw new Error("context must be use inside PatientProvider")
  return context
}

export default usePatient

import { Consent } from "../../../@types/consent"
/* eslint-disable */
const consentFR: Consent = {
  lang: "fr",
  text: `
<h1><strong>Information et consentement</strong></h1>
<p>&nbsp;</p>
<h2><strong>Qu&rsquo;est-ce que l&rsquo;&eacute;tude &ldquo;SPEAKUITY R1&rdquo; ?</strong></h2>
<p><span style="font-weight: 400;">L'&eacute;tude "SPEAKUITY R1" vise &agrave; identifier des biomarqueurs vocaux pour plusieurs troubles de sant&eacute; ou sympt&ocirc;mes.</span></p>
<p><span style="font-weight: 400;">L'&eacute;tude "SPEAKUITY R1" est mise en &oelig;uvre par Akuity Care SAS, une entreprise fran&ccedil;aise de sant&eacute; num&eacute;rique, en coop&eacute;ration avec le Centre de Sant&eacute; du Square de la Mutualit&eacute;.</span></p>
<p><span style="font-weight: 400;">Akuity Care SAS souhaite d&eacute;velopper des biomarqueurs vocaux et les int&eacute;grer dans un logiciel utilis&eacute; en pratique clinique, par exemple pour diagnostiquer des probl&egrave;mes de sant&eacute; ou les surveiller &agrave; distance gr&acirc;ce &agrave; l'analyse de la voix.</span></p>
<p><span style="font-weight: 400;">Le Centre de Sant&eacute; du Square de la Mutualit&eacute; est int&eacute;ress&eacute; par la participation &agrave; ce projet &agrave; des fins de recherche ou m&eacute;dicales.&nbsp;</span></p>
<p>&nbsp;</p>
<h2><strong>Qu&rsquo;est-ce-qu&rsquo;un biomarqueur vocal ?</strong></h2>
<p><span style="font-weight: 400;">Un biomarqueur vocal est une caract&eacute;ristique de la voix, ou une combinaison de caract&eacute;ristiques, qui peut aider &agrave; d&eacute;tecter un &eacute;tat de sant&eacute; ou &agrave; surveiller un sympt&ocirc;me sp&eacute;cifique. Gr&acirc;ce aux biomarqueurs vocaux, la sant&eacute; pourra un jour &ecirc;tre mesur&eacute;e facilement, par exemple en parlant dans un smartphone.</span></p>
<p>&nbsp;</p>
<h2><strong>Comment identifier des biomarqueurs vocaux ?</strong></h2>
<p><span style="font-weight: 400;">Pour atteindre cet objectif, Akuity Care SAS doit constituer une vaste base de donn&eacute;es d'enregistrements vocaux combin&eacute;s &agrave; des donn&eacute;es de sant&eacute;. Sur cette base, Akuity Care SAS d&eacute;tectera des caract&eacute;ristiques de sant&eacute; sp&eacute;cifiques &agrave; partir de la voix, en utilisant des techniques d'apprentissage automatique, c'est-&agrave;-dire des algorithmes capables d'apprendre &agrave; partir de donn&eacute;es et d'identifier des mod&egrave;les (biomarqueurs) avec une intervention humaine minimale. La pr&eacute;cision des algorithmes s'am&eacute;liorera avec le temps, gr&acirc;ce &agrave; l'ajout de donn&eacute;es suppl&eacute;mentaires provenant d'autres participants.</span></p>
<p>&nbsp;</p>
<h2><strong>Comment participer ?</strong></h2>
<p><span style="font-weight: 400;">Votre participation implique deux types d'actions :</span></p>
<ul>
<li style="font-weight: 400;" aria-level="1"><span style="font-weight: 400;">r&eacute;aliser les 4 t&acirc;ches vocales qui vous seront expliqu&eacute;es - elles devraient permettre d'enregistrer environ 3 minutes de votre voix</span></li>
<li style="font-weight: 400;" aria-level="1"><span style="font-weight: 400;">r&eacute;pondre &agrave; quelques questionnaires sur votre sant&eacute; - ils devraient vous prendre environ 15 minutes &agrave; remplir</span></li>
</ul>
<p>&nbsp;</p>
<h2><strong>Type de donn&eacute;es collect&eacute;es</strong></h2>
<p><span style="font-weight: 400;">Votre participation &agrave; l'&eacute;tude implique la collecte de donn&eacute;es personnelles vous concernant dans la mesure o&ugrave; cela est n&eacute;cessaire pour atteindre les objectifs scientifiques de l'&eacute;tude. Les donn&eacute;es ne seront collect&eacute;es qu'une seule fois, lors de la r&eacute;alisation de cette &eacute;tude, nous ne reviendrons pas vers vous pour des demandes suppl&eacute;mentaires.</span></p>
<p><span style="font-weight: 400;">Seront notamment collect&eacute;es :</span></p>
<ul>
<li style="font-weight: 400;" aria-level="1"><strong>Des enregistrements vocaux </strong><span style="font-weight: 400;">: Il vous sera demand&eacute; de lire un court texte, d&rsquo;inspirer et expirer fortement par la bouche, de tousser, de d&eacute;crire une image et de vous exprimer librement sur votre maladie respiratoire.</span></li>
<li style="font-weight: 400;" aria-level="1"><strong>Des donn&eacute;es de base </strong><span style="font-weight: 400;">: facteurs d&eacute;mographiques (&acirc;ge, sexe, langue, ...), facteurs li&eacute;s au mode de vie (tabagisme...) et facteurs anthropom&eacute;triques (poids, taille, ...).</span></li>
<li style="font-weight: 400;" aria-level="1"><strong>Des donn&eacute;es de sant&eacute;</strong><span style="font-weight: 400;"> : &eacute;tat de sant&eacute; (sympt&ocirc;mes, traitements, maladies), sant&eacute; psychologique, ...</span></li>
</ul>
<p><span style="font-weight: 400;">Vos donn&eacute;es personnelles seront pseudonymis&eacute;es avant d'&ecirc;tre transmises &agrave; Akuity Care SAS. Cela signifie qu'un code unique sera attribu&eacute; &agrave; toutes les informations qui vous concernent et qui sont transmises &agrave; Akuity Care SAS. Akuity Care SAS ne recevra aucune donn&eacute;e permettant de vous identifier directement, comme votre nom, votre adresse e-mail, votre date de naissance ou tout &eacute;l&eacute;ment identifiant direct et unique.</span></p>
<p><span style="font-weight: 400;">Le Centre de Sant&eacute; du Square de la Mutualit&eacute; restera toutefois en mesure d'associer le code unique &agrave; votre nom, afin de faciliter la mise en &oelig;uvre effective des droits que vous pouvez exercer &agrave; l'&eacute;gard de vos donn&eacute;es personnelles trait&eacute;es par Akuity Care, comme d&eacute;crit ci-apr&egrave;s.</span></p>
<p>&nbsp;</p>
<h2><strong>Responsabilit&eacute;s</strong></h2>
<p><span style="font-weight: 400;">Akuity Care SAS, dont le si&egrave;ge social est situ&eacute; 23 rue L&eacute;on Jost 75017 Paris, France, est responsable de traitement des donn&eacute;es personnelles collect&eacute;es dans l&rsquo;&eacute;tude &ldquo;SPEAKUITY R1&rdquo; au sens du R&egrave;glement G&eacute;n&eacute;ral Europ&eacute;en sur la Protection des Donn&eacute;es (RGPD).</span></p>
<p>&nbsp;</p>
<p><span style="font-weight: 400;">Le Centre de Sant&eacute; du Square de la Mutualit&eacute; sera quant &agrave; lui responsable de la mise en &oelig;uvre du traitement des donn&eacute;es.</span></p>
<p>&nbsp;</p>
<p><span style="font-weight: 400;">Le Centre de Sant&eacute; du Square de la Mutualit&eacute; sera votre principal point de contact si vous souhaitez exercer l'un de vos droits &eacute;nonc&eacute;s ci-dessous. Si vous exercez l'un de vos droits vis-&agrave;-vis du Centre de Sant&eacute; du Square de la Mutualit&eacute;, le Centre de Sant&eacute; du Square de la Mutualit&eacute; notifiera votre demande &agrave; Akuity Care SAS sous forme pseudonyme, en utilisant le code unique qui vous a &eacute;t&eacute; attribu&eacute;, et Akuity Care SAS veillera &agrave; la mise en &oelig;uvre effective de vos droits conform&eacute;ment aux exigences applicables en mati&egrave;re de protection des donn&eacute;es.&nbsp;</span></p>
<p>&nbsp;</p>
<p><span style="font-weight: 400;">Le Centre de Sant&eacute; du Square de la Mutualit&eacute; peut &ecirc;tre contact&eacute; comme suit :</span></p>
<p>&nbsp;</p>
<h2><strong>Centre de Sant&eacute; du Square de la Mutualit&eacute;</strong></h2>
<p><span style="font-weight: 400;">Dr. Ang&eacute;lique Sentilhes</span></p>
<p><span style="font-weight: 400;">1-3 Square de la Mutualit&eacute;, 75005 Paris - France</span></p>
<p><span style="font-weight: 400;">angelique.sentilhes@centresante-squaremutualite.fr</span></p>
<p>&nbsp;</p>
<p><span style="font-weight: 400;">Akuity Care SAS peut &ecirc;tre contact&eacute; comme suit :</span></p>
<p><strong>AKUITY CARE SAS</strong></p>
<p><span style="font-weight: 400;">Gr&eacute;gory Lista</span></p>
<p><span style="font-weight: 400;">23 rue L&eacute;on Jost 75017 Paris - France</span></p>
<p><span style="font-weight: 400;">contact@akuity.care</span></p>
<p>&nbsp;</p>
<h2><strong>Objectif et base l&eacute;gale de traitement&nbsp;</strong></h2>
<p><span style="font-weight: 400;">Akuity Care SAS traitera vos donn&eacute;es personnelles dans le but de d&eacute;velopper des biomarqueurs vocaux et, par la suite, de les int&eacute;grer dans un logiciel utilis&eacute; en pratique clinique, par exemple pour diagnostiquer des probl&egrave;mes de sant&eacute; ou les surveiller &agrave; distance.</span></p>
<p><span style="font-weight: 400;">Akuity Care SAS et le Centre de Sant&eacute; du Square de la Mutualit&eacute; ont l'intention de publier conjointement les r&eacute;sultats qu'ils obtiennent en ce qui concerne le d&eacute;veloppement de biomarqueurs vocaux et leur utilisation potentielle &agrave; des fins m&eacute;dicales. Cependant, toute publication de ce type sera bas&eacute;e sur des donn&eacute;es agr&eacute;g&eacute;es provenant de tous les participants &agrave; l'&Eacute;tude et ne rapportera aucune de vos donn&eacute;es personnelles ni ne se r&eacute;f&eacute;rera &agrave; vous en tant que personne identifiable.</span></p>
<p><span style="font-weight: 400;">En outre, Akuity Care SAS peut rendre compl&egrave;tement anonymes vos donn&eacute;es personnelles &agrave; un stade ult&eacute;rieur. Cela signifie que toutes les informations qui pourraient permettre votre identification en tant que personne concern&eacute;e, y compris le code unique, sont irr&eacute;vocablement supprim&eacute;es.</span></p>
<p><span style="font-weight: 400;">La base juridique du traitement de vos donn&eacute;es &agrave; caract&egrave;re personnel est l'article 6, paragraphe 1, point a), du RGPD en liaison avec l'article 9, paragraphe 2, point a), du RGPD. Conform&eacute;ment &agrave; ces dispositions, vos donn&eacute;es personnelles peuvent &ecirc;tre trait&eacute;es sur la base de votre consentement expr&egrave;s.</span></p>
<p>&nbsp;</p>
<h2><strong>H&eacute;bergement des donn&eacute;es</strong></h2>
<p><span style="font-weight: 400;">Vos donn&eacute;es personnelles seront stock&eacute;es sur des serveurs OVHcloud, prestataire fran&ccedil;ais. Ces serveurs sont d&eacute;di&eacute;s &agrave; Akuity Care SAS et physiquement situ&eacute;s en France. Ils sont certifi&eacute;s par le certificat fran&ccedil;ais "H&eacute;bergement de donn&eacute;es de sant&eacute;", qui atteste de mesures de s&eacute;curit&eacute; suppl&eacute;mentaires pour stocker les donn&eacute;es de sant&eacute;. Les serveurs sont &eacute;galement conformes au R&egrave;glement G&eacute;n&eacute;ral sur la Protection des Donn&eacute;es (RGPD) de l'UE.</span></p>
<p>&nbsp;</p>
<h2><strong>S&eacute;curit&eacute; et int&eacute;grit&eacute; des donn&eacute;es</strong></h2>
<p><span style="font-weight: 400;">Akuity Care SAS prend les mesures de s&eacute;curit&eacute; appropri&eacute;es pour prot&eacute;ger vos donn&eacute;es personnelles contre les risques d'acc&egrave;s non autoris&eacute;, de perte, d'utilisation frauduleuse, de divulgation, de modification et de destruction. Vos donn&eacute;es seront trait&eacute;es de mani&egrave;re strictement confidentielle.</span></p>
<p>&nbsp;</p>
<h2><strong>Dur&eacute;e de conservation</strong></h2>
<p><span style="font-weight: 400;">Akuity Care SAS conservera les donn&eacute;es personnelles collect&eacute;es dans le cadre de l'&eacute;tude &ldquo;SPEAKUITY R1&rdquo; pendant une dur&eacute;e maximale de 2 ans &agrave; compter de la date de derni&egrave;re publication des r&eacute;sultats de l&rsquo;&eacute;tude. Vos donn&eacute;es seront supprim&eacute;es d&egrave;s qu'elles ne seront plus n&eacute;cessaires aux fins susmentionn&eacute;es.</span></p>
<p>&nbsp;</p>
<h2><strong>Droit d&rsquo;information et de rectification</strong></h2>
<p><span style="font-weight: 400;">Vous avez le droit de demander des informations sur vos donn&eacute;es personnelles trait&eacute;es par les responsables du traitement. Par l&rsquo;interm&eacute;diaire du Centre de Sant&eacute; du Square de la Mutualit&eacute;, Akuity Care SAS peut fournir, &agrave; votre demande, une copie des donn&eacute;es personnelles faisant l'objet du traitement. Si les donn&eacute;es devaient &ecirc;tre inexactes ou incompl&egrave;tes, vous pouvez demander &agrave; ce qu'elles soient rectifi&eacute;es ou compl&eacute;t&eacute;es. Sous r&eacute;serve des exigences l&eacute;gales respectives, vous avez le droit de demander que le traitement de vos donn&eacute;es soit limit&eacute;. C'est notamment le cas si l'exactitude de vos donn&eacute;es est contest&eacute;e. Dans ce cas, vous avez le droit de demander la limitation du traitement de vos donn&eacute;es pendant une p&eacute;riode permettant &agrave; Akuity Care SAS de v&eacute;rifier l'exactitude des donn&eacute;es.</span></p>
<p>&nbsp;</p>
<h2><strong>Suppression des donn&eacute;es</strong></h2>
<p><span style="font-weight: 400;">Sous r&eacute;serve des exigences l&eacute;gales respectives, vous avez le droit de demander &agrave; tout moment la suppression de vos donn&eacute;es personnelles par Akuity Care SAS en notifiant votre demande au Centre de Sant&eacute; du Square de la Mutualit&eacute;. Le Centre de Sant&eacute; du Square de la Mutualit&eacute; transmettra votre demande &agrave; Akuity Care SAS sans d&eacute;lai et, si les exigences l&eacute;gales respectives sont remplies, Akuity Care SAS entreprendra la suppression de vos donn&eacute;es personnelles, y compris toutes les copies de sauvegarde, sans d&eacute;lai excessif. Veuillez noter, cependant, qu'un effacement peut ne pas &ecirc;tre l&eacute;galement possible, par exemple en raison d'exigences l&eacute;gales de conservation.</span></p>
<p>&nbsp;</p>
<h2><strong>Portabilit&eacute; des donn&eacute;es</strong></h2>
<p><span style="font-weight: 400;">Dans la mesure o&ugrave; (a) Akuity Care SAS traite les donn&eacute;es personnelles que vous avez fournies sur la base de votre consentement &eacute;clair&eacute; et (b) o&ugrave; le traitement est effectu&eacute; par des moyens automatis&eacute;s, Akuity Care SAS, par l&rsquo;interm&eacute;diaire du Centre de Sant&eacute; du Square de la Mutualit&eacute;, mettra, &agrave; votre demande, ces donn&eacute;es personnelles &agrave; votre disposition ou &agrave; celle d'un tiers, &agrave; votre choix, dans un format structur&eacute; et lisible par machine.</span></p>
<p>&nbsp;</p>
<p>&nbsp;</p>
<h2><strong>Droit de r&eacute;clamation</strong></h2>
<p><span style="font-weight: 400;">Si vous estimez que vos droits ont &eacute;t&eacute; viol&eacute;s par Akuity Care SAS dans le cadre du traitement de vos donn&eacute;es personnelles, vous avez le droit de d&eacute;poser une r&eacute;clamation aupr&egrave;s de l'autorit&eacute; de contr&ocirc;le. La r&eacute;clamation peut &ecirc;tre d&eacute;pos&eacute;e aupr&egrave;s de l'autorit&eacute; de protection des donn&eacute;es du pays dans lequel vous r&eacute;sidez ou travaillez ou dans lequel la violation pr&eacute;sum&eacute;e a eu lieu.</span></p>
<p><span style="font-weight: 400;">Vous pouvez, par exemple, adresser votre plainte par courrier &agrave; :</span></p>
<p>&nbsp;</p>
<p><span style="font-weight: 400;">CNIL - Service des Plaintes</span></p>
<p><span style="font-weight: 400;">3 Place de Fontenoy</span></p>
<p><span style="font-weight: 400;">TSA 80715</span></p>
<p><span style="font-weight: 400;">75334 PARIS CEDEX 07</span></p>
<p>&nbsp;</p>
<p>&nbsp;</p>
<h2><strong>Droit de retrait du consentement</strong></h2>
<p><span style="font-weight: 400;">Vous avez le droit de retirer votre consentement au traitement de vos donn&eacute;es personnelles &agrave; tout moment. Le retrait de votre consentement n'affectera toutefois pas la l&eacute;galit&eacute; du traitement fond&eacute; sur votre consentement avant son retrait.</span></p>
<p><br /><br /><br /></p>`,
  /* eslint-enable */
}

export default consentFR

import { TaskRecording } from "../../../@types/taskRecording"

const taskRecordingsEN: TaskRecording[] = [
  {
    id: "read_speech_1",
    name: "Reading task Human Rights",
    type: "text",
    description:
      "1. Click the green button to start the recording.\n" +
      "2. Please read the following text aloud.\n" +
      "3. Once you are finished, click the button again to stop recording.",
    media:
      "Everyone has the right to a standard of living adequate for the health and well-being of himself and of " +
      "his family, including food, clothing, housing and medical care and necessary social services, and the right to " +
      "security in the event of unemployment, sickness, disability, widowhood, old age or other lack of livelihood in " +
      "circumstances beyond his control.",
    lang: "en",
  },
  {
    id: "sust_vowel",
    name: "Sustained vowel task aaah",
    type: "text",
    media: undefined,
    description:
      "1. Click the green button to start the recording.\n" +
      "2. Say ‘aaaaah’ as long as you can without breathing.\n" +
      "3. Once you are finished, click the button again to stop recording.",
    lang: "en",
  },
  {
    id: "free_speech_im_1",
    name: "Free speech image description",
    type: "image",
    media: "Image_task_family_kitchen.jpg",
    description:
      "1. Click the green button to start the recording.\n" +
      "2. Describe as many elements as you can in this picture for at least 30 seconds. \n" +
      "3. Once you are finished, click the button again to stop recording.",
    lang: "en",
  },
  {
    id: "free_speech_1",
    name: "Free speech answer a question",
    type: "text",
    media: "How do you feel today ?",
    description:
      "1. Click the green button to start the recording.\n" +
      "2. Answer the following question by speaking at least 20 seconds. \n" +
      "3. Once you are finished, click the button again to stop recording.",
    lang: "en",
  },
  {
    id: "free_speech_2",
    name: "Free speech answer a question",
    type: "text",
    media: "Please describe how your disease is currently impacting your daily life ?",
    description:
      "1. Click the green button to start the recording.\n" +
      "2. Answer the following question by speaking at least 20 seconds. \n" +
      "3. Once you are finished, click the button again to stop recording.",
    lang: "en",
  },
]

export default taskRecordingsEN

import { Consent } from "../../../@types/consent"
/* eslint-disable */

const css_style = `<head>
<style>
table {
  font-family: arial, sans-serif;
  border-collapse: collapse;
  width: 100%;
}

td, th {
  border: 1px solid #dddddd;
  text-align: left;
  padding: 5px;
}

li {
  margin-left: 15px;
}
</style>
</head>
`

const consentFR: Consent = {
  lang: "fr",
  text: css_style+`
<p>Note d'information Participants</p>
<p>Speakuity R1 - Promoteur:<span style="color: #ff00ff;"> AKUITY CARE</span></p>
<p>&nbsp;</p>
<p>&nbsp;</p>
<h2 style="text-align: justify;"><strong>Document d&rsquo;information &agrave; l&rsquo;attention du participant &agrave; la recherche SPEAKUITY R1</strong></h2>
<p>&nbsp;</p>
<p><span style="font-weight: 400;">Collecte de donn&eacute;es vocales et de donn&eacute;es de sant&eacute; pour recherche de biomarqueurs relatifs &agrave; l&rsquo;asthme et &agrave; la BPCO (BronchoPneumopathie Chronique Obstructive*)</span></p>
<p>&nbsp;</p>
<p><strong>Num&eacute;ro d&rsquo;enregistrement de la Recherche (ID-RCB*)</strong><span style="font-weight: 400;"> : 2023-A00704-41</span></p>
<p>&nbsp;</p>
<p><span style="text-decoration: underline;"><strong>Vos contacts</strong></span></p>
<p>&nbsp;</p>
<table>
<tbody>
<tr>
<td>
<p><span style="font-weight: 400;">Promoteur* de la recherche et responsable du traitement</span></p>
</td>
<td>
<p><span style="font-weight: 400;">AKUITY CARE</span></p>
<p><span style="font-weight: 400;">23 rue L&eacute;on Jost 75017 Paris</span></p>
<p><span style="font-weight: 400;">+33 6 10 69 47 54</span></p>
<p><a href="mailto:contact@akuity.care"><span style="font-weight: 400;">contact@akuity.care</span></a><span style="font-weight: 400;">&nbsp;</span></p>
</td>
</tr>
<tr>
<td>
<p><span style="font-weight: 400;">Investigateur* principal / coordonnateur de la recherche</span></p>
</td>
<td>
<p><span style="font-weight: 400;">Dr. Ang&eacute;lique Sentilhes-Monkam</span></p>
<p><span style="font-weight: 400;">Centre de Sant&eacute; du Square de la Mutualit&eacute;</span></p>
<p><span style="font-weight: 400;">1-3 Square de la Mutualit&eacute; 75005 Paris</span></p>
<p><span style="font-weight: 400;">+33 1 55 25 01 90</span></p>
<p><a href="mailto:angelique.sentilhes@centresante-squaremutualite.fr"><span style="font-weight: 400;">angelique.sentilhes@centresante-squaremutualite.fr</span></a><span style="font-weight: 400;">&nbsp;</span></p>
</td>
</tr>
<tr>
<td>
<p><span style="font-weight: 400;">Investigateur* du lieu de recherche</span></p>
</td>
<td>
<p><span style="font-weight: 400;">Dr. Ang&eacute;lique Sentilhes-Monkam</span></p>
<p><span style="font-weight: 400;">Centre de Sant&eacute; du Square de la Mutualit&eacute;</span></p>
<p><span style="font-weight: 400;">1-3 Square de la Mutualit&eacute; 75005 Paris</span></p>
<p><span style="font-weight: 400;">+33 1 55 25 01 90</span></p>
<p><a href="mailto:angelique.sentilhes@centresante-squaremutualite.fr"><span style="font-weight: 400;">angelique.sentilhes@centresante-squaremutualite.fr</span></a></p>
<p><span style="font-weight: 400;">ou</span></p>
<p><span style="font-weight: 400;">Dr. Delphine Natali / Dr. Lo&iuml;c Perrot / Dr. Julien Saussereau</span></p>
<p><span style="font-weight: 400;">Institut Mutualiste Montsouris</span></p>
<p><span style="font-weight: 400;">42 Bd Jourdan 75014 Paris</span></p>
<p><span style="font-weight: 400;">+33 1 56 61 62 63</span></p>
<p><a href="mailto:delphine.natali@imm.fr"><span style="font-weight: 400;">delphine.natali@imm.fr</span></a><span style="font-weight: 400;"> / </span><a href="mailto:loic.perrot@imm.fr"><span style="font-weight: 400;">loic.perrot@imm.fr</span></a><span style="font-weight: 400;"> / </span><a href="mailto:julien.saussereau@imm.fr"><span style="font-weight: 400;">julien.saussereau@imm.fr</span></a><span style="font-weight: 400;">&nbsp;</span></p>
</td>
</tr>
<tr>
<td>
<p><span style="font-weight: 400;">D&eacute;l&eacute;gu&eacute; &agrave; la protection des donn&eacute;es</span><strong> (DPO)*</strong><span style="font-weight: 400;"> du centre investigateur</span></p>
</td>
<td>
<p><span style="font-weight: 400;">Matmut / D&eacute;l&eacute;gu&eacute; &agrave; la Protection des Donn&eacute;es</span></p>
<p><span style="font-weight: 400;">rue de Sotteville</span> <span style="font-weight: 400;">76100 ROUEN</span></p>
<p><a href="mailto:dpd@matmut.fr"><span style="font-weight: 400;">dpd@matmut.fr</span></a><span style="font-weight: 400;">&nbsp;</span></p>
</td>
</tr>
<tr>
<td>
<p><span style="font-weight: 400;">D&eacute;l&eacute;gu&eacute; &agrave; la protection des donn&eacute;es </span><strong>(DPO)* </strong><span style="font-weight: 400;">du promoteur</span></p>
</td>
<td>
<p><span style="font-weight: 400;">Jean-Marie Nazarenko</span></p>
<p><span style="font-weight: 400;">jmn@lepoissonpilote.com</span></p>
<p><span style="font-weight: 400;">6 avenue de Craponne 13100 Aix-en-Provence</span></p>
<p><span style="font-weight: 400;">+33 6 86 67 30 38</span></p>
</td>
</tr>
<tr>
<td>
<p><span style="font-weight: 400;">CNIL- Commission nationale de l&rsquo;Informatique et</span><span style="font-weight: 400;"><br /></span><span style="font-weight: 400;">des libert&eacute;s</span></p>
</td>
<td>
<p><span style="font-weight: 400;">CNIL</span><strong> / </strong><span style="font-weight: 400;">Service des Plaintes</span></p>
<p><span style="font-weight: 400;">3 Place de Fontenoy TSA 80715</span></p>
<p><span style="font-weight: 400;">75334 PARIS CEDEX 07</span></p>
</td>
</tr>
</tbody>
</table>
<p><br /><br /></p>
<p><span style="font-weight: 400;">* Les mots ou groupes de mots surmont&eacute;s d&rsquo;un ast&eacute;risque sont inclus dans le glossaire</span></p>
<p>&nbsp;</p>
<p>&nbsp;</p>
<p style="text-align: center;">Akuity Care &ndash; SPEAKUITY R1 &ndash; N&deg;ID-RCB 2023-A00704-41- Version n&deg;2 du 05/06/2023</p>
<p style="text-align: right;">1/13</p>
<hr />
<p>&nbsp;</p>
<p>&nbsp;</p>
<h3><span style="text-decoration: underline;"><strong>Sommaire</strong></span></h3>
<p>&nbsp;</p>
<p style="text-align: left;"><strong>PARTIE 1 : INFORMATIONS SUR LA RECHERCHE ............................................... 3</strong></p>
<p style="text-align: left;"><span style="font-weight: 400;">Pourquoi cette Recherche est-elle mise en place ? ..........................................................</span> <span style="font-weight: 400;">3</span></p>
<p style="text-align: left;"><span style="font-weight: 400;">En quoi la Recherche consiste-t-elle ? ......... 3</span></p>
<p style="text-align: left;"><span style="font-weight: 400;">Comment la Recherche se d&eacute;roule-t-elle ? .. 4</span></p>
<p style="text-align: left;"><span style="font-weight: 400;">Quels sont les b&eacute;n&eacute;fices attendus et risques li&eacute;s &agrave; la Recherche ? .................................... 4</span></p>
<p style="text-align: left;"><span style="font-weight: 400;">Quelles sont les indemnit&eacute;s pr&eacute;vues ? ......... 5</span></p>
<p style="text-align: left;"><span style="font-weight: 400;">Que se passera-t-il en cas d'arr&ecirc;t pr&eacute;matur&eacute; de la Recherche et apr&egrave;s la Recherche ? ......... 5</span></p>
<p style="text-align: left;"><span style="font-weight: 400;">Combien de temps vos donn&eacute;es seront-elles conserv&eacute;es ? ................................................ 5</span></p>
<p style="text-align: left;"><span style="font-weight: 400;">Dispositions l&eacute;gislatives et r&eacute;glementaires ...................................................................... 5</span></p>
<p>&nbsp;</p>
<p style="text-align: left;"><strong>PARTIE 2 : INFORMATIONS SUR LES DROITS DU PARTICIPANT ET SUR LA GESTION DES DONNEES RECUEILLIES ..................................................................... 6</strong></p>
<p style="text-align: left;"><span style="font-weight: 400;">Quels sont vos droits ? ............................... 6</span></p>
<p style="text-align: left;"><span style="font-weight: 400;">Comment vos donn&eacute;es personnelles* seront-elles trait&eacute;es dans le cadre de la recherche ? .................................................................... 6</span></p>
<p style="text-align: left;"><span style="font-weight: 400;">Quelle est la base juridique et la finalit&eacute; du traitement de vos donn&eacute;es personnelles ? . 8</span></p>
<p style="text-align: left;"><span style="font-weight: 400;">Comment la confidentialit&eacute; de vos donn&eacute;es sera-t-elle assur&eacute;e ? .................................. 9</span></p>
<p style="text-align: left;"><span style="font-weight: 400;">Qui aura acc&egrave;s &agrave; vos donn&eacute;es dans le cadre de la recherche ? ...................................... 9<br /></span></p>
<p style="text-align: left;"><span style="font-weight: 400;">Quels sont vos droits relatifs &agrave; vos donn&eacute;es personnelles ? ........................................ 10</span></p>
<p style="text-align: left;"><span style="font-weight: 400;">Comment exercer vos droits ? ............... 10</span></p>
<p style="text-align: left;"><span style="font-weight: 400;">Vos donn&eacute;es cod&eacute;es pourront-elles &ecirc;tre r&eacute;utilis&eacute;es ? ............................................ 11</span></p>
<p>&nbsp;</p>
<p style="text-align: left;"><strong>PARTIE 3 : GLOSSAIRE ...................... 12</strong></p>
<p><br /><br /></p>
<p>&nbsp;</p>
<p style="text-align: center;">Akuity Care &ndash; SPEAKUITY R1 &ndash; N&deg;ID-RCB 2023-A00704-41- Version n&deg;2 du 05/06/2023</p>
<p style="text-align: right;">2/13</p>
<hr />
<p>&nbsp;</p>
<p>&nbsp;</p>
<h2><strong>PARTIE 1 : INFORMATIONS SUR LA RECHERCHE</strong></h2>
<p>&nbsp;</p>
<p><span style="font-weight: 400;">Madame, Monsieur,</span></p>
<p>&nbsp;</p>
<p><span style="font-weight: 400;">Dans le cadre du suivi de votre pathologie (BPCO* ou asthme), votre m&eacute;decin vous propose de participer &agrave; une recherche impliquant la personne humaine* qui a pour objectif de collecter des donn&eacute;es pour r&eacute;pondre &agrave; la question : "</span><strong>Avec quel niveau de fiabilit&eacute; peut-on &eacute;valuer l&rsquo;asthme et la BPCO (BronchoPneumopathie Chronique Obstructive*) dans la voix ?</strong><span style="font-weight: 400;">".</span></p>
<p>&nbsp;</p>
<p><span style="font-weight: 400;">Vous &ecirc;tes libre d&rsquo;y participer ou non. Vous pouvez prendre le temps n&eacute;cessaire pour lire les informations ci-dessous, discuter avec vos proches et votre m&eacute;decin traitant et poser toutes vos questions au m&eacute;decin de la recherche, appel&eacute; investigateur*. Apr&egrave;s avoir obtenu les r&eacute;ponses satisfaisantes &agrave; vos questions et dispos&eacute; d&rsquo;un d&eacute;lai suffisant de r&eacute;flexion, vous pourrez alors d&eacute;cider si vous acceptez de participer &agrave; la recherche ou non. Votre d&eacute;cision ne changera rien &agrave; votre prise en charge.</span></p>
<p>&nbsp;</p>
<h3><strong>Pourquoi cette Recherche est-elle mise en place ?</strong></h3>
<p>&nbsp;</p>
<p><span style="font-weight: 400;">AKUITY CARE, le promoteur* de cette Recherche, envisage de d&eacute;velopper une solution d&rsquo;IA (Intelligence Artificielle*) permettant, &agrave; partir d&rsquo;une analyse de la voix, d'&eacute;valuer certaines maladies, notamment l'asthme et la BPCO*.</span></p>
<p><span style="font-weight: 400;">Les algorithmes d'IA* qui seraient &agrave; terme int&eacute;gr&eacute;s dans une telle solution sont appel&eacute;s </span><strong>biomarqueurs vocaux*</strong><span style="font-weight: 400;">. Ils donnent une &eacute;valuation d'un &eacute;tat clinique particulier &agrave; partir d'un &eacute;chantillon de voix.</span></p>
<p>&nbsp;</p>
<p><span style="font-weight: 400;">Pour les d&eacute;velopper, il faut les entra&icirc;ner en leur fournissant, un grand nombre de fois :</span></p>
<p>&nbsp;</p>
<ul>
<li style="font-weight: 400;"><span style="font-weight: 400;">d'une part, des donn&eacute;es sur l'&eacute;tat clinique d'individus,</span></li>
<li style="font-weight: 400;"><span style="font-weight: 400;">d'autre part, des &eacute;chantillons de leur voix.</span></li>
</ul>
<p>&nbsp;</p>
<p><span style="font-weight: 400;">En lui donnant la r&eacute;ponse qui est attendue de sa part, on apprend ainsi peu &agrave; peu &agrave; un algorithme &agrave; trouver cette r&eacute;ponse tout seul, c'est-&agrave;-dire &agrave; d&eacute;duire l'&eacute;tat clinique d'un individu &agrave; partir de la voix.</span></p>
<p>&nbsp;</p>
<p><span style="font-weight: 400;">Cette Recherche vise &agrave; collecter, aupr&egrave;s de vous et d'autres patients, des donn&eacute;es pour d&eacute;velopper et entra&icirc;ner des biomarqueurs vocaux*.</span></p>
<p>&nbsp;</p>
<h3><strong>En quoi la Recherche consiste-t-elle ?</strong></h3>
<p>&nbsp;</p>
<p><span style="font-weight: 400;">Cette Recherche vise plusieurs param&egrave;tres cliniques relatifs &agrave; la BPCO* et &agrave; l'asthme.&nbsp;</span></p>
<p>&nbsp;</p>
<p><span style="font-weight: 400;">Le principal est </span><strong>l'obstruction des voies respiratoires*</strong><span style="font-weight: 400;"> (objectif principal*)</span><strong>.</strong></p>
<p><span style="font-weight: 400;">Les autres (objectifs secondaires*) sont :</span></p>
<p>&nbsp;</p>
<ul>
<li style="font-weight: 400;"><span style="font-weight: 400;">le contr&ocirc;le de l'asthme*</span></li>
<li style="font-weight: 400;"><span style="font-weight: 400;">l'impact de la BPCO* sur le quotidien du patient</span></li>
<li style="font-weight: 400;"><span style="font-weight: 400;">le D&eacute;bit Expiratoire de Pointe*</span></li>
<li style="font-weight: 400;"><span style="font-weight: 400;">le niveau de s&eacute;v&eacute;rit&eacute; de la dyspn&eacute;e*</span></li>
<li style="font-weight: 400;"><span style="font-weight: 400;">la situation d&rsquo;exacerbation actuelle*</span></li>
<li style="font-weight: 400;"><span style="font-weight: 400;">la qualit&eacute; de vie respiratoire*</span></li>
</ul>
<p>&nbsp;</p>
<p><span style="font-weight: 400;">La Recherche implique 2 &eacute;tablissements hospitaliers (centres investigateurs*), elle durera 1 an et devrait permettre la participation d'environ 600 patients souffrant de BPCO* ou d'asthme.</span></p>
<p>&nbsp;</p>
<p>&nbsp;</p>
<p style="text-align: center;">Akuity Care &ndash; SPEAKUITY R1 &ndash; N&deg;ID-RCB 2023-A00704-41- Version n&deg;2 du 05/06/2023</p>
<p style="text-align: right;">3/13</p>
<hr />
<p>&nbsp;</p>
<p>&nbsp;</p>
<p><span style="font-weight: 400;">Elle est financ&eacute;e par le promoteur* et b&eacute;n&eacute;fice de la mise &agrave; disposition de ressources de la part des centres investigateurs*.</span></p>
<p>&nbsp;</p>
<h3><strong>Comment la Recherche se d&eacute;roule-t-elle ?</strong></h3>
<p>&nbsp;</p>
<p><span style="font-weight: 400;">Avant le d&eacute;marrage, votre m&eacute;decin d&eacute;terminera si vous pouvez ou non participer &agrave; cette Recherche.</span></p>
<p>&nbsp;</p>
<p><span style="font-weight: 400;">Si c'est le cas, votre participation &agrave; la Recherche consistera, si vous y consentez, &agrave; fournir les donn&eacute;es vis&eacute;es par la Recherche, &agrave; savoir :</span></p>
<p>&nbsp;</p>

<ul>
<li style="font-weight: 400;"><strong>des informations sur votre profil, vos habitudes et votre &eacute;tat de sant&eacute;</strong><span style="font-weight: 400;"> : vous les fournirez en r&eacute;pondant &agrave; des questionnaires sur un smartphone qui vous sera remis pour cette Recherche &ndash; certains donn&eacute;es compl&eacute;mentaires seront fournies par votre Pneumologue</span></li>
<li style="font-weight: 400;"><strong>des &eacute;chantillons de votre voix</strong><span style="font-weight: 400;"> : vous les fournirez en r&eacute;alisant des exercices vocaux tr&egrave;s simples avec un smartphone et un casque audio qui vous seront remis pour cette Recherche &ndash; les exercices vocaux seront expliqu&eacute;s sur le smartphone et votre voix sera enregistr&eacute;e par le micro du smartphone pendant que vous r&eacute;aliserez ces exercices</span></li>
</ul>
<p>&nbsp;</p>
<p><span style="font-weight: 400;">Vous remplirez ces questionnaires et r&eacute;aliserez ces exercices vocaux dans une salle qui vous sera indiqu&eacute;e par un Technicien d'Etude Clinique*. Il pourra aussi vous aider si vous avez une question ou si vous rencontrez un probl&egrave;me technique pendant votre participation.</span></p>
<p>&nbsp;</p>
<p><span style="font-weight: 400;">Les donn&eacute;es qui vous seront demand&eacute;es dans la Recherche ont &eacute;t&eacute; choisies car elles sont toutes pr&eacute;sum&eacute;es importantes pour l'apprentissage des algorithmes d'IA* que la Recherche vise &agrave; d&eacute;velopper.</span></p>
<p>&nbsp;</p>
<p><span style="font-weight: 400;">La r&eacute;alisation compl&egrave;te de la Recherche (questionnaires et exercices vocaux) prend </span><strong>environ 15 minutes</strong><span style="font-weight: 400;">.</span></p>
<p>&nbsp;</p>
<h3><strong>Quels sont les b&eacute;n&eacute;fices attendus et risques li&eacute;s &agrave; la Recherche ?</strong></h3>
<p>&nbsp;</p>
<p><span style="font-weight: 400;">Cette recherche est non-interventionnelle, ce qui signifie que </span><strong>votre participation n'aura aucun impact sur votre prise en charge</strong><span style="font-weight: 400;">.</span></p>
<p>&nbsp;</p>
<p><span style="font-weight: 400;">Les questionnaires et exercices vocaux demand&eacute;s sont tr&egrave;s simples et ne pr&eacute;sentent aucun risque pour vous.</span></p>
<p>&nbsp;</p>
<p><span style="font-weight: 400;">La Recherche pourrait produire des b&eacute;n&eacute;fices &agrave; long-terme, au cas o&ugrave; les biomarqueurs vocaux d&eacute;velopp&eacute;s &agrave; partir des donn&eacute;es collect&eacute;es s'av&egrave;rent suffisamment performants. Ils pourraient alors par exemple permettre d'&eacute;valuer l'&eacute;tat clinique de patients atteints d'asthme ou de BPCO* en utilisant leur voix, donc facilement et &agrave; distance. Ainsi, les interventions m&eacute;dicales pourraient &ecirc;tre plus appropri&eacute;es : au bon moment, aupr&egrave;s des bons patients et &agrave; partir d'informations sur leur &eacute;tat r&eacute;cent.</span></p>
<p style="text-align: center;">&nbsp;</p>
<p>&nbsp;</p>

<p style="text-align: center;">Akuity Care &ndash; SPEAKUITY R1 &ndash; N&deg;ID-RCB 2023-A00704-41- Version n&deg;2 du 05/06/2023</p>
<p style="text-align: right;">4/13</p>
<hr />
<p>&nbsp;</p>
<p>&nbsp;</p>
<h3><strong>Quelles sont les indemnit&eacute;s pr&eacute;vues ?</strong></h3>
<p>&nbsp;</p>
<p><span style="font-weight: 400;">Cette Recherche est r&eacute;alis&eacute;e &agrave; l'occasion d'une consultation avec votre m&eacute;decin. Elle n'entra&icirc;ne aucun frais pour vous. Aucune indemnit&eacute; n&rsquo;est pr&eacute;vue pour votre participation &agrave; cette Recherche.</span></p>
<p>&nbsp;</p>
<h3><strong>Que se passera-t-il en cas d'arr&ecirc;t pr&eacute;matur&eacute; de la Recherche et apr&egrave;s la Recherche ?</strong></h3>
<p>&nbsp;</p>
<p><span style="font-weight: 400;">L'arr&ecirc;t pr&eacute;matur&eacute; de la Recherche n'aura aucune cons&eacute;quence sur votre prise en charge.</span></p>
<p><span style="font-weight: 400;">Apr&egrave;s la Recherche, les donn&eacute;es collect&eacute;es seront exploit&eacute;es pour d&eacute;velopper les biomarqueurs vocaux relatifs &agrave; l'asthme et &agrave; la BPCO*.</span></p>
<p>&nbsp;</p>
<h3><strong>Combien de temps vos donn&eacute;es seront-elles conserv&eacute;es ?</strong></h3>
<p>&nbsp;</p>
<p><span style="font-weight: 400;">Conform&eacute;ment &agrave; la r&egrave;glementation, vos donn&eacute;es seront conserv&eacute;es jusqu'&agrave; deux ans apr&egrave;s la derni&egrave;re publication des r&eacute;sultats de la Recherche ou, en l&rsquo;absence de publication, jusqu&rsquo;&agrave; la signature du rapport final de fin de la Recherche.</span></p>
<p>&nbsp;</p>
<p><span style="font-weight: 400;">Elles feront l&rsquo;objet d&rsquo;un archivage pour une dur&eacute;e de 15 ans.</span></p>
<p>&nbsp;</p>
<h3><strong>Dispositions l&eacute;gislatives et r&eacute;glementaires</strong></h3>
<p>&nbsp;</p>
<p><span style="font-weight: 400;">Conform&eacute;ment &agrave; l&rsquo;article L. 1121-4 du Code de la sant&eacute; publique, cette Recherche a &eacute;t&eacute; d&eacute;clar&eacute;e &agrave; l&rsquo;Agence Nationale de S&eacute;curit&eacute; du M&eacute;dicament et des produits de sant&eacute; (ANSM) et a obtenu un avis favorable du Comit&eacute; de Protection des Personnes Ouest VI en date du 19/06/2023.</span></p>
<p><span style="font-weight: 400;"><br /></span><span style="font-weight: 400;">Le traitement de vos donn&eacute;es personnelles dans le cadre de la recherche est conforme &agrave; une M&eacute;thodologie de r&eacute;f&eacute;rence (MR)* MR-003 &eacute;tablie par la Commission Nationale Informatique et Libert&eacute;s (CNIL). </span><span style="font-weight: 400;"><br /><br /></span></p>
<p><span style="font-weight: 400;">Une convention a &eacute;t&eacute; &eacute;tablie entre l&rsquo;&eacute;tablissement de sant&eacute; et le promoteur, lequel prend en charge les frais suppl&eacute;mentaires engendr&eacute;s par la Recherche.</span></p>
<p>&nbsp;</p>
<p>&nbsp;</p>

<p style="text-align: center;">Akuity Care &ndash; SPEAKUITY R1 &ndash; N&deg;ID-RCB 2023-A00704-41- Version n&deg;2 du 05/06/2023</p>
<p style="text-align: right;">5/13</p>
<hr />
<p>&nbsp;</p>
<p>&nbsp;</p>
<p>&nbsp;</p>
<h2><strong>PARTIE 2 : INFORMATIONS SUR LES DROITS DU PARTICIPANT ET SUR LA GESTION DES DONNEES RECUEILLIES</strong></h2>
<p>&nbsp;</p>
<h3><strong>Quels sont vos droits ?</strong></h3>
<p>&nbsp;</p>
<p><span style="font-weight: 400;">Votre participation &agrave; une recherche impliquant la personne humaine* est libre et volontaire : vous &ecirc;tes libre d&rsquo;accepter ou de refuser de participer &agrave; cette &eacute;tude recherche et vous pouvez interrompre &agrave; tout moment votre participation sans avoir &agrave; donner de raison et sans encourir aucune responsabilit&eacute; ni pr&eacute;judice de ce fait. Il vous suffit de le signaler &agrave; l&rsquo;investigateur*.</span><span style="font-weight: 400;"><br /></span><span style="font-weight: 400;">Votre d&eacute;cision de participer ou de ne pas participer n&rsquo;aura aucune cons&eacute;quence sur votre prise en charge m&eacute;dicale et la qualit&eacute; de vos soins ou sur votre relation avec l&rsquo;investigateur*.</span></p>
<p>&nbsp;</p>
<p><span style="font-weight: 400;">Nous vous invitons &agrave; poser &agrave; AKUITY CARE les questions que vous pourriez avoir &agrave; propos de cette Recherche, en utilisant l'adresse email suivante : </span><a href="mailto:contact@akuity.care"><span style="font-weight: 400;">contact@akuity.care</span></a></p>
<p>&nbsp;</p>
<p><span style="font-weight: 400;">Cette Recherche ne n&eacute;cessite ni consultation, ni prescription ou examen particulier.</span></p>
<p>&nbsp;</p>
<p><span style="font-weight: 400;">Vous avez le droit d'obtenir communication, au cours ou &agrave; l'issue de la recherche, des informations concernant votre sant&eacute;, d&eacute;tenues par l'investigateur* ou, le cas &eacute;ch&eacute;ant, le m&eacute;decin ou la personne qualifi&eacute;e qui le repr&eacute;sente.</span></p>
<p>&nbsp;</p>
<p><span style="font-weight: 400;">A l&rsquo;issue de la Recherche, vous pourrez, si vous le souhaitez, prendre connaissance de ses r&eacute;sultats globaux en sollicitant AKUITY CARE, conform&eacute;ment &agrave; l&rsquo;article L.1122-1 du Code de la Sant&eacute; Publique.</span></p>
<p>&nbsp;</p>
<h3><strong>Comment vos donn&eacute;es personnelles* seront-elles trait&eacute;es dans le cadre de la recherche ?</strong></h3>
<p>&nbsp;</p>
<p><span style="font-weight: 400;">Si vous acceptez de participer &agrave; la recherche, vos donn&eacute;es personnelles, y compris vos donn&eacute;es de sant&eacute;, feront l&rsquo;objet d&rsquo;un traitement* par le promoteur, en qualit&eacute; de responsable du traitement de ces donn&eacute;es.</span></p>
<p><span style="font-weight: 400;"><br /></span><span style="font-weight: 400;">Les donn&eacute;es suivantes seront recueillies et trait&eacute;es dans des conditions de s&eacute;curit&eacute; adapt&eacute;es :</span></p>
<p>&nbsp;</p>
<p>&nbsp;</p>

<p style="text-align: center;">Akuity Care &ndash; SPEAKUITY R1 &ndash; N&deg;ID-RCB 2023-A00704-41- Version n&deg;2 du 05/06/2023</p>
<p style="text-align: right;">6/13</p>
<hr />
<p>&nbsp;</p>
<p>&nbsp;</p>

<p>&nbsp;</p>
<table border="1" cellpadding="10">
<tbody>
<tr>
<td>
<p><strong>Quelle donn&eacute;e ?</strong></p>
</td>
<td>
<p><strong>Comment ?</strong></p>
</td>
<td>
<p><strong>Aupr&egrave;s de qui ?</strong></p>
</td>
</tr>
<tr>
<td>
<p><span style="font-weight: 400;">Enregistrements de voix</span></p>
</td>
<td>
<p><span style="font-weight: 400;">5 exercices vocaux :</span></p>
<ul>
<li style="font-weight: 400;"><span style="font-weight: 400;">lecture d'un texte</span></li>
<li style="font-weight: 400;"><span style="font-weight: 400;">respirations fortes par la bouche</span></li>
<li style="font-weight: 400;"><span style="font-weight: 400;">toux</span></li>
<li style="font-weight: 400;"><span style="font-weight: 400;">description libre d'une image</span></li>
<li style="font-weight: 400;"><span style="font-weight: 400;">r&eacute;ponse libre &agrave; une question</span></li>
</ul>
</td>
<td>
<p><span style="font-weight: 400;">Vous-m&ecirc;me</span></p>
</td>
</tr>
<tr>
<td>
<p><span style="font-weight: 400;">Evaluation du contr&ocirc;le de l&rsquo;asthme</span></p>
</td>
<td>
<p><span style="font-weight: 400;">Questionnaire &agrave; Choix Multiples en 5 questions</span></p>
</td>
<td>
<p><span style="font-weight: 400;">Vous-m&ecirc;me</span></p>
</td>
</tr>
<tr>
<td>
<p><span style="font-weight: 400;">Evaluation de l&rsquo;impact de la BPCO</span><strong>*</strong><span style="font-weight: 400;"> sur votre quotidien</span></p>
</td>
<td>
<p><span style="font-weight: 400;">Questionnaire &agrave; Choix Multiples en 8 questions</span></p>
</td>
<td>
<p><span style="font-weight: 400;">Vous-m&ecirc;me</span></p>
</td>
</tr>
<tr>
<td>
<p><span style="font-weight: 400;">Evaluation de la qualit&eacute; de vie respiratoire</span><strong>*</strong><span style="font-weight: 400;"> (par un questionnaire)</span></p>
</td>
<td>
<p><span style="font-weight: 400;">Questionnaire &agrave; Choix Multiples en 11 questions</span></p>
</td>
<td>
<p><span style="font-weight: 400;">Vous-m&ecirc;me</span></p>
</td>
</tr>
<tr>
<td>
<p><span style="font-weight: 400;">Evaluation des sympt&ocirc;mes d&eacute;pressifs (par un questionnaire)</span></p>
</td>
<td>
<p><span style="font-weight: 400;">Questionnaire &agrave; Choix Multiples en 9 questions</span></p>
</td>
<td>
<p><span style="font-weight: 400;">Vous-m&ecirc;me</span></p>
</td>
</tr>
<tr>
<td>
<p><span style="font-weight: 400;">Evaluation des troubles anxieux (par un questionnaire)</span></p>
</td>
<td>
<p><span style="font-weight: 400;">Questionnaire &agrave; Choix Multiples en 7 questions</span></p>
</td>
<td>
<p><span style="font-weight: 400;">Vous-m&ecirc;me</span></p>
</td>
</tr>
<tr>
<td>
<p><span style="font-weight: 400;">Evaluation des sympt&ocirc;mes de fatigue (par un questionnaire)</span></p>
</td>
<td>
<p><span style="font-weight: 400;">Questionnaire &agrave; Choix Multiples en 9 questions</span></p>
</td>
<td>
<p><span style="font-weight: 400;">Vous-m&ecirc;me</span></p>
</td>
</tr>
<tr>
<td>
<p><span style="font-weight: 400;">Evaluation du stress (par une &eacute;chelle)</span></p>
</td>
<td>
<p><span style="font-weight: 400;">Menu d&eacute;roulant</span></p>
<p><span style="font-weight: 400;">(de 0 &agrave; 10)</span></p>
</td>
<td>
<p><span style="font-weight: 400;">Vous-m&ecirc;me</span></p>
</td>
</tr>
<tr>
<td>
<p><span style="font-weight: 400;">Evaluation de la douleur (par une &eacute;chelle)</span></p>
</td>
<td>
<p><span style="font-weight: 400;">Menu d&eacute;roulant</span></p>
<p><span style="font-weight: 400;">(de 0 &agrave; 10)</span></p>
</td>
<td>
<p><span style="font-weight: 400;">Vous-m&ecirc;me</span></p>
</td>
</tr>
<tr>
<td>
<p><span style="font-weight: 400;">Diagnostics connus de d&eacute;pression, trouble anxieux g&eacute;n&eacute;ralis&eacute;, schizophr&eacute;nie, troubles bipolaires, infections respiratoires dans les 3 derniers mois et actuellement, sympt&ocirc;mes ORL</span><strong>*</strong><span style="font-weight: 400;"> actuels, sympt&ocirc;mes persistants de Covid-19, allergie respiratoire, fibrose pulmonaire, cancer (+ informations relatives au cancer si applicable)</span></p>
</td>
<td>
<p><span style="font-weight: 400;">Oui / Non</span></p>
</td>
<td>
<p><span style="font-weight: 400;">Vous-m&ecirc;me</span></p>
</td>
</tr>
<tr>
<td>
<p><span style="font-weight: 400;">Autres comorbidit&eacute;s connues (&agrave; indiquer dans une liste)</span></p>
</td>
<td>
<p><span style="font-weight: 400;">Menu d&eacute;roulant</span></p>
</td>
<td>
<p><span style="font-weight: 400;">Vous-m&ecirc;me</span></p>
</td>
</tr>
<tr>
<td>
<p><span style="font-weight: 400;">Traitements en cours (&agrave; indiquer dans une liste)</span></p>
</td>
<td>
<p><span style="font-weight: 400;">Menu d&eacute;roulant</span></p>
</td>
<td>
<p><span style="font-weight: 400;">Vous-m&ecirc;me</span></p>
</td>
</tr>
<tr>
<td>
<p><span style="font-weight: 400;">Fr&eacute;quence de prise du traitement de fond et du traitement de secours lors de la derni&egrave;re semaine</span></p>
</td>
<td>
<p><span style="font-weight: 400;">Menu d&eacute;roulant</span></p>
</td>
<td>
<p><span style="font-weight: 400;">Vous-m&ecirc;me, uniquement si vous souffrez d'asthme</span></p>
</td>
</tr>
<tr>
<td>
<p><span style="font-weight: 400;">Bronchites r&eacute;centes avec prise d'antibiotiques (nombre d&rsquo;&eacute;pisodes sur les 12 derniers mois et date du dernier &eacute;pisode)</span></p>
</td>
<td>
<p><span style="font-weight: 400;">Valeur &agrave; indiquer</span></p>
</td>
<td>
<p><span style="font-weight: 400;">Vous-m&ecirc;me</span></p>
</td>
</tr>
<tr>
<td>
<p><span style="font-weight: 400;">Niveau d'essoufflement ressenti actuellement</span></p>
</td>
<td>
<p><span style="font-weight: 400;">Menu d&eacute;roulant</span></p>
<p><span style="font-weight: 400;">(de 0 &agrave; 10)</span></p>
</td>
<td>
<p><span style="font-weight: 400;">Vous-m&ecirc;me</span></p>
</td>
</tr>
<tr>
<td>
<p><span style="font-weight: 400;">Volume Expiratoire Maximal par Seconde</span></p>
</td>
<td>
<p><span style="font-weight: 400;">Valeur</span></p>
</td>
<td>
<p><span style="font-weight: 400;">Votre m&eacute;decin</span></p>
</td>
</tr>
<tr>
<td>
<p><span style="font-weight: 400;">D&eacute;bit Expiratoire de Pointe</span><strong>*</strong></p>
</td>
<td>
<p><span style="font-weight: 400;">Valeur</span></p>
</td>
<td>
<p><span style="font-weight: 400;">Votre m&eacute;decin</span></p>
</td>
</tr>
<tr>
<td>
<p><span style="font-weight: 400;">Evaluation de la dyspn&eacute;e</span><strong>*</strong></p>
</td>
<td>
<p><span style="font-weight: 400;">Menu d&eacute;roulant</span></p>
<p><span style="font-weight: 400;">(de 0 &agrave; 4)</span></p>
</td>
<td>
<p><span style="font-weight: 400;">Votre m&eacute;decin</span></p>
</td>
</tr>
<tr>
<td>
<p><span style="font-weight: 400;">Identification d&rsquo;une situation actuelle d&rsquo;exacerbation</span><strong>*</strong></p>
</td>
<td>
<p><span style="font-weight: 400;">Oui / Non</span></p>
</td>
<td>
<p><span style="font-weight: 400;">Votre m&eacute;decin</span></p>
</td>
</tr>
<tr>
<td>
<p><span style="font-weight: 400;">Donn&eacute;es compl&eacute;mentaires sur la capacit&eacute; respiratoire : coefficient de Tiffeneau, capacit&eacute; pulmonaire totale, volume pulmonaire r&eacute;siduel, r&eacute;sistance des voies a&eacute;riennes (RVA / RAW)</span></p>
</td>
<td>
<p><span style="font-weight: 400;">Valeur</span></p>
</td>
<td>
<p><span style="font-weight: 400;">Votre m&eacute;decin</span></p>
</td>
</tr>
<tr>
<td>
<p><span style="font-weight: 400;">Historique des exacerbations : nombre d'&eacute;pisodes sur les 12 derniers mois, date du dernier &eacute;pisode, nombre d'&eacute;pisodes avec hospitalisation sur les 12 derniers mois et date du dernier &eacute;pisode avec hospitalisation</span></p>
</td>
<td>
<p><span style="font-weight: 400;">Valeurs et Mois / Ann&eacute;es</span></p>
</td>
<td>
<p><span style="font-weight: 400;">Votre m&eacute;decin</span></p>
</td>
</tr>
<tr>
<td>
<p><span style="font-weight: 400;">Code unique</span></p>
</td>
<td>
<p><span style="font-weight: 400;">Caract&egrave;res alpha-num&eacute;riques</span></p>
</td>
<td>
<p><span style="font-weight: 400;">Automatique</span></p>
</td>
</tr>
<tr>
<td>
<p><span style="font-weight: 400;">Langue maternelle</span></p>
</td>
<td>
<p><span style="font-weight: 400;">Menu d&eacute;roulant</span></p>
</td>
<td>
<p><span style="font-weight: 400;">Vous-m&ecirc;me</span></p>
</td>
</tr>
<tr>
<td>
<p><span style="font-weight: 400;">Ann&eacute;e de naissance</span></p>
</td>
<td>
<p><span style="font-weight: 400;">Menu d&eacute;roulant</span></p>
</td>
<td>
<p><span style="font-weight: 400;">Vous-m&ecirc;me</span></p>
</td>
</tr>
<tr>
<td>
<p><span style="font-weight: 400;">Sexe</span></p>
</td>
<td>
<p><span style="font-weight: 400;">Questionnaire &agrave; Choix Multiples</span></p>
</td>
<td>
<p><span style="font-weight: 400;">Vous-m&ecirc;me</span></p>
</td>
</tr>
<tr>
<td>
<p><span style="font-weight: 400;">Taille</span></p>
</td>
<td>
<p><span style="font-weight: 400;">Menu d&eacute;roulant</span></p>
</td>
<td>
<p><span style="font-weight: 400;">Vous-m&ecirc;me</span></p>
</td>
</tr>
<tr>
<td>
<p><span style="font-weight: 400;">Poids</span></p>
</td>
<td>
<p><span style="font-weight: 400;">Menu d&eacute;roulant</span></p>
</td>
<td>
<p><span style="font-weight: 400;">Vous-m&ecirc;me</span></p>
</td>
</tr>
<tr>
<td>
<p><span style="font-weight: 400;">Consommation</span> <span style="font-weight: 400;">actuelle</span><span style="font-weight: 400;"> de</span> <span style="font-weight: 400;">tabac</span> <span style="font-weight: 400;">ou</span> <span style="font-weight: 400;">autres substances fum&eacute;es (fr&eacute;quence, quantit&eacute; par forme, anciennet&eacute;)</span></p>
</td>
<td>
<p><span style="font-weight: 400;">Questionnaires &agrave; Choix Multiples et menu d&eacute;roulant</span></p>
</td>
<td>
<p><span style="font-weight: 400;">Vous-m&ecirc;me</span></p>
</td>
</tr>
<tr>
<td>
<p><span style="font-weight: 400;">Consommation </span><span style="font-weight: 400;">historique</span><span style="font-weight: 400;"> de tabac ou autres substances fum&eacute;es (fr&eacute;quence, quantit&eacute; par forme, dur&eacute;e, date de l'arr&ecirc;t)</span></p>
</td>
<td>
<p><span style="font-weight: 400;">Questionnaires &agrave; Choix Multiples et menu d&eacute;roulant</span></p>
</td>
<td>
<p><span style="font-weight: 400;">Vous-m&ecirc;me</span></p>
</td>
</tr>
<tr>
<td>
<p><span style="font-weight: 400;">Grossesse en cours (et stade si applicable)</span></p>
</td>
<td>
<p><span style="font-weight: 400;">Oui / Non </span><strong>(</strong><span style="font-weight: 400;">et menu d&eacute;roulant</span><strong>)</strong></p>
</td>
<td>
<p><span style="font-weight: 400;">Vous-m&ecirc;me</span></p>
</td>
</tr>
<tr>
<td>
<p><span style="font-weight: 400;">Affiliation &agrave; un r&eacute;gime de s&eacute;curit&eacute; sociale</span></p>
</td>
<td>
<p><span style="font-weight: 400;">Oui (obligatoire pour participer &agrave; la Recherche)</span></p>
</td>
<td>
<p><span style="font-weight: 400;">Votre m&eacute;decin</span></p>
</td>
</tr>
<tr>
<td>
<p><span style="font-weight: 400;">Donn&eacute;es relatives &agrave; la conduite de la recherche</span></p>
</td>
<td>
<p><span style="font-weight: 400;">Date et heure de d&eacute;but et de fin de chaque &eacute;cran pour les questionnaires et les exercices vocaux</span></p>
</td>
<td>
<p><span style="font-weight: 400;">Automatique (depuis le mat&eacute;riel utilis&eacute; pour la Recherche)</span></p>
</td>
</tr>
</tbody>
</table>
<p>&nbsp;</p>
<p><span style="font-weight: 400;">Ces donn&eacute;es ont &eacute;t&eacute; choisies car elles sont toutes pr&eacute;sum&eacute;es importantes pour l'apprentissage des algorithmes d'IA* que la Recherche vise &agrave; d&eacute;velopper.</span></p>
<p>&nbsp;</p>
<h3><strong>Quelle est la base juridique et la finalit&eacute; du traitement de vos donn&eacute;es personnelles ?</strong></h3>
<p>&nbsp;</p>
<p><span style="font-weight: 400;">Le traitement de vos donn&eacute;es personnelles est n&eacute;cessaire &agrave; la r&eacute;alisation de la recherche, c'est-&agrave;-dire au d&eacute;veloppement des biomarqueurs vocaux pr&eacute;cit&eacute;s. Dans ce cadre, les sous-finalit&eacute;s sont les suivantes&nbsp;: &eacute;laborer le registre des participants, assurer la s&eacute;curit&eacute; des donn&eacute;es, tester leur qualit&eacute; et r&eacute;pondre aux demandes d'exercice des droits des participants.</span></p>
<p><span style="font-weight: 400;">Ainsi, le traitement de vos donn&eacute;es personnelles est autoris&eacute; car il r&eacute;pond &agrave; un int&eacute;r&ecirc;t l&eacute;gitime poursuivi par AKUITY CARE &agrave; des fins de recherche scientifique conform&eacute;ment aux articles 6.1 f) et 9.2. j) du RGPD*.</span></p>
<p><span style="font-weight: 400;">L&rsquo;int&eacute;r&ecirc;t l&eacute;gitime poursuivi est l&rsquo;am&eacute;lioration de ses connaissances aux fins de d&eacute;velopper les biomarqueurs vocaux d&eacute;crits pr&eacute;c&eacute;demment.</span></p>
<p><span style="font-weight: 400;">Le responsable de traitement doit mettre en &oelig;uvre des mesures appropri&eacute;es permettant de garantir vos droits et libert&eacute;s, notamment le seul recueil de donn&eacute;es strictement n&eacute;cessaires &agrave; la recherche.</span></p>
<p>&nbsp;</p>
<p>&nbsp;</p>

<p style="text-align: center;">Akuity Care &ndash; SPEAKUITY R1 &ndash; N&deg;ID-RCB 2023-A00704-41- Version n&deg;2 du 05/06/2023</p>
<p style="text-align: right;">8/13</p>
<hr />
<p>&nbsp;</p>
<p>&nbsp;</p>
<h3><strong>Comment la confidentialit&eacute; de vos donn&eacute;es sera-t-elle assur&eacute;e ?</strong></h3>
<p>&nbsp;</p>
<p><span style="font-weight: 400;">Vos donn&eacute;es personnelles seront trait&eacute;es de mani&egrave;re confidentielle, conform&eacute;ment &agrave; la loi du 6 janvier 1978 modifi&eacute;e dite &laquo; Loi Informatique et Libert&eacute;s &raquo;, et conform&eacute;ment au R&egrave;glement G&eacute;n&eacute;ral sur la Protection des Donn&eacute;es (RGPD*).</span></p>
<p><span style="font-weight: 400;">Vos donn&eacute;es seront cod&eacute;es*, c&rsquo;est-&agrave;-dire que vous serez identifi&eacute; par un num&eacute;ro de code pour les besoins de la recherche, sans mention de vos noms et pr&eacute;noms. Seul l&rsquo;investigateur conservera la liste de correspondance entre le code et votre nom.</span></p>
<p>&nbsp;</p>
<p><span style="font-weight: 400;">Les t&acirc;ches vocales comprennent 2 t&acirc;ches d&rsquo;expression libre. M&ecirc;me si la nature des t&acirc;ches ne vous y incitera pas, veuillez svp prendre garde &agrave; </span><strong>ne divulguer aucune information identifiante (comme votre nom ou vos coordonn&eacute;es) dans vos r&eacute;ponses aux t&acirc;ches vocales</strong><span style="font-weight: 400;">. De telles informations nous obligeront &agrave; d&eacute;truire vos donn&eacute;es et ne permettront pas votre participation &agrave; l&rsquo;&eacute;tude.</span></p>
<p>&nbsp;</p>
<p><span style="font-weight: 400;">Ainsi, en aucun cas et de quelque mani&egrave;re que ce soit AKUITY CARE en sa qualit&eacute; de promoteur* n&rsquo;aura acc&egrave;s &agrave; vos donn&eacute;es directement identifiantes.</span></p>
<p>&nbsp;</p>
<p><span style="font-weight: 400;">Par ailleurs, les r&eacute;sultats de la Recherche ne pourront en aucun cas permettre votre identification directe ou indirecte. Ils pourront &ecirc;tre pr&eacute;sent&eacute;s dans des journaux m&eacute;dicaux et aux autorit&eacute;s comp&eacute;tentes.</span></p>
<p>&nbsp;</p>
<p><span style="font-weight: 400;">Enfin, ces donn&eacute;es seront trait&eacute;es dans une base de donn&eacute;es informatis&eacute;e pr&eacute;sentant toutes les mesures de s&eacute;curit&eacute; techniques et organisationnelles requises et de mani&egrave;re confidentielle, dans le respect du secret professionnel.</span></p>
<p>&nbsp;</p>
<h3><strong>Qui aura acc&egrave;s &agrave; vos donn&eacute;es dans le cadre de la recherche ?</strong></h3>
<p>&nbsp;</p>
<p><span style="font-weight: 400;">Les informations concernant votre identit&eacute; (nom, pr&eacute;nom) ne seront connues que par :</span></p>
<p>&nbsp;</p>

<ul >
<li style="font-weight: 400;"><span style="font-weight: 400;">l&rsquo;&eacute;quipe m&eacute;dicale vous prenant en charge</span></li>
<li style="font-weight: 400;"><span style="font-weight: 400;">les &eacute;ventuelles personnes r&eacute;alisant le contr&ocirc;le de la qualit&eacute; de la Recherche mandat&eacute;es par le promoteur*</span></li>
<li style="font-weight: 400;"><span style="font-weight: 400;">les autorit&eacute;s sanitaires ou de contr&ocirc;le</span></li>
<li style="font-weight: 400;"><span style="font-weight: 400;">le DPO (D&eacute;l&eacute;gu&eacute; &agrave; la Protection des Donn&eacute;es*) du promoteur* si vous le contactez (</span><a href="mailto:jmn@lepoissonpilote.com"><span style="font-weight: 400;">jmn@lepoissonpilote.com</span></a><span style="font-weight: 400;">)</span></li>
<li style="font-weight: 400;"><span style="font-weight: 400;">en cas de litige, le personnel habilit&eacute; de l'organisme d&rsquo;assurance du promoteur*.</span></li>
</ul>
<p><span style="font-weight: 400;">Ces personnes sont soumises au secret professionnel.</span></p>
<p>&nbsp;</p>
<p><span style="font-weight: 400;">Vos donn&eacute;es cod&eacute;es seront accessibles aux personnes suivantes :</span></p>
<p>&nbsp;</p>

<ul>
<li style="font-weight: 400;"><span style="font-weight: 400;">le promoteur*</span></li>
<li style="font-weight: 400;"><span style="font-weight: 400;">les personnes agissant pour son compte, en particulier la soci&eacute;t&eacute; de OVHCloud, en tant qu&rsquo;h&eacute;bergeur de donn&eacute;es de sant&eacute;, dans la limite de sa mission technique</span></li>
<li style="font-weight: 400;"><span style="font-weight: 400;">les &eacute;ventuels experts ind&eacute;pendants charg&eacute;s de r&eacute;-analyser les donn&eacute;es pour v&eacute;rifier les r&eacute;sultats de la recherche, en vue de leur publication, dans des conditions strictes de s&eacute;curit&eacute;.</span></li>
</ul>
<p><span style="font-weight: 400;">Ces personnes, soumises au secret professionnel, auront acc&egrave;s &agrave; vos donn&eacute;es cod&eacute;es dans le cadre de leur fonction et en conformit&eacute; avec la r&eacute;glementation.</span></p>
<p>&nbsp;</p>
<p>&nbsp;</p>

<p style="text-align: center;">Akuity Care &ndash; SPEAKUITY R1 &ndash; N&deg;ID-RCB 2023-A00704-41- Version n&deg;2 du 05/06/2023</p>
<p style="text-align: right;">9/13</p>
<hr />
<p>&nbsp;</p>
<p>&nbsp;</p>

<h3><strong>Quels sont vos droits relatifs &agrave; vos donn&eacute;es personnelles ?</strong><strong><br /><br /></strong></h3>
<p><span style="font-weight: 400;">Vous avez le droit d&rsquo;acc&eacute;der &agrave; vos donn&eacute;es, par l&rsquo;interm&eacute;diaire de l&rsquo;investigateur*, et demander &agrave; ce</span> <span style="font-weight: 400;">qu&rsquo;elles soient rectifi&eacute;es ou compl&eacute;t&eacute;es.</span><span style="font-weight: 400;"><br /></span><span style="font-weight: 400;">Vous pouvez &eacute;galement demander la limitation du traitement de vos donn&eacute;es (c&rsquo;est-&agrave;-dire demander au promoteur de geler temporairement l&rsquo;utilisation de vos donn&eacute;es).</span></p>
<p><span style="font-weight: 400;"><br /></span><span style="font-weight: 400;">M&ecirc;me si vous acceptez de participer &agrave; la recherche, vous pourrez &agrave; tout moment vous opposer au</span> <span style="font-weight: 400;">traitement de vos donn&eacute;es aux fins de r&eacute;alisation de la recherche. Dans ce cas, aucune information suppl&eacute;mentaire vous concernant ne sera collect&eacute;e.</span></p>
<p><span style="font-weight: 400;">Vous pouvez &eacute;galement exercer votre droit &agrave; l&rsquo;effacement sur les donn&eacute;es d&eacute;j&agrave; recueillies.</span><span style="font-weight: 400;"><br /></span><span style="font-weight: 400;">Vous pouvez &eacute;galement acc&eacute;der directement ou par l&rsquo;interm&eacute;diaire d&rsquo;un m&eacute;decin de votre choix &agrave;</span> <span style="font-weight: 400;">l&rsquo;ensemble de vos donn&eacute;es m&eacute;dicales en application des dispositions de l&rsquo;article L. 1111-7 du Code de la Sant&eacute; Publique.</span></p>
<p>&nbsp;</p>
<p><span style="font-weight: 400;">Vous pouvez exercer l&rsquo;ensemble de ces droits aupr&egrave;s du Groupe Matmut :</span></p>
<p>&nbsp;</p>

<ul>
<li style="font-weight: 400;"><span style="font-weight: 400;">par email: </span><a href="mailto:dpd@matmut.fr"><span style="font-weight: 400;">dpd@matmut.fr</span></a></li>
<li style="font-weight: 400;"><span style="font-weight: 400;">par courrier :</span></li>
</ul>
<p><span style="font-weight: 400;">Matmut</span></p>
<p><span style="font-weight: 400;">A l&rsquo;attention du D&eacute;l&eacute;gu&eacute; &agrave; la Protection des Donn&eacute;es</span></p>
<p><span style="font-weight: 400;">rue de Sotteville, 76100 ROUEN</span></p>
<p><span style="font-weight: 400;">en justifiant de votre identit&eacute;</span></p>
<p>&nbsp;</p>
<h3><strong>Comment exercer vos droits ?</strong></h3>
<p>&nbsp;</p>
<p><span style="font-weight: 400;">Vous pouvez exercer vos droits &agrave; tout moment et sans avoir &agrave; vous justifier.</span></p>
<p><span style="font-weight: 400;">Le promoteur* n&rsquo;ayant pas acc&egrave;s &agrave; votre identit&eacute;, il est recommand&eacute; de vous adresser, dans un premier temps, &agrave; l&rsquo;investigateur*, aux coordonn&eacute;es disponibles dans la pr&eacute;sente note au paragraphe pr&eacute;c&eacute;dent.</span></p>
<p>&nbsp;</p>
<p><span style="font-weight: 400;">Vous pouvez en outre, si vous le souhaitez, exercer vos droits aupr&egrave;s du DPD *du promoteur* qui g&egrave;rera cette demande en coordination avec le m&eacute;decin et les professionnels impliqu&eacute;s dans l&rsquo;&eacute;tude :</span></p>
<p>&nbsp;</p>

<p><span style="font-weight: 400;">Jean-Marie Nazarenko</span></p>
<p><a href="mailto:jmn@lepoissonpilote.com"><span style="font-weight: 400;">jmn@lepoissonpilote.com</span></a></p>
<p><span style="font-weight: 400;">6 avenue de Craponne</span></p>
<p><span style="font-weight: 400;">13100 Aix-en-Provence</span></p>
<p><span style="font-weight: 400;">+33 6 86 67 30 38</span></p>
<p>&nbsp;</p>
<p><span style="font-weight: 400;">Dans ce cas, votre identit&eacute; (pr&eacute;nom, nom) sera rendue accessible au DPD* du promoteur*.</span></p>
<p>&nbsp;</p>
<p><span style="font-weight: 400;">Dans l&rsquo;hypoth&egrave;se o&ugrave; vous ne parvenez pas &agrave; exercer vos droits, vous disposez &eacute;galement du droit de d&eacute;poser une r&eacute;clamation concernant le traitement de vos donn&eacute;es personnelles aupr&egrave;s de la Commission nationale de l&rsquo;informatique et des libert&eacute;s (CNIL), qui est l&rsquo;autorit&eacute; de contr&ocirc;le comp&eacute;tente en France en mati&egrave;re de protection des donn&eacute;es :</span></p>
<p>&nbsp;</p>
<ul>
<li style="font-weight: 400;"><span style="font-weight: 400;">Via le t&eacute;l&eacute;service de plainte en ligne</span></li>
<li style="font-weight: 400;"><span style="font-weight: 400;">Par courrier postal en &eacute;crivant &agrave; : CNIL - Service des Plaintes - 3 Place de Fontenoy - TSA 80715 - 75334 PARIS CEDEX 07.</span></li>
</ul>
<p>&nbsp;</p>
<p>&nbsp;</p>
<p style="text-align: center;">Akuity Care &ndash; SPEAKUITY R1 &ndash; N&deg;ID-RCB 2023-A00704-41- Version n&deg;2 du 05/06/2023</p>
<p style="text-align: right;">10/13</p>
<hr />
<p>&nbsp;</p>
<p>&nbsp;</p>
<h3><strong>Vos donn&eacute;es cod&eacute;es pourront-elles &ecirc;tre r&eacute;utilis&eacute;es ?</strong></h3>
<p>&nbsp;</p>
<p><span style="font-weight: 400;">Vos donn&eacute;es pourront &ecirc;tre r&eacute;utilis&eacute;es dans d'autres recherches visant le d&eacute;veloppement d'autres biomarqueurs vocaux*. Ceci permettra par exemple de tester les biomarqueurs vocaux* d&eacute;velopp&eacute;s dans cette Recherche sur les donn&eacute;es issues d'une recherche similaire (ou inversement), ou de combiner les donn&eacute;es de plusieurs recherches pour augmenter la performance des biomarqueurs vocaux*.</span></p>
<p>&nbsp;</p>
<p><span style="font-weight: 400;">Pour obtenir des informations sur le(s) nouveau(x) traitement(s) de vos donn&eacute;es, le promoteur* a mis en place un site web dynamique (&laquo; portail de transparence &raquo;) que vous pourrez consulter r&eacute;guli&egrave;rement et pr&eacute;alablement &agrave; la mise en &oelig;uvre de chaque nouveau traitement, &agrave; l&rsquo;adresse suivante : </span><a href="http://www.akuity.care/transparence/f32ke1/"><span style="font-weight: 400;">www.akuity.care/transparence/f32ke1/</span></a><span style="font-weight: 400;">.</span></p>
<p><span style="font-weight: 400;">Cette page d&eacute;taillera l&rsquo;ensemble des mentions obligatoires du RGPD*, notamment, pour chaque projet concern&eacute;, l&rsquo;identit&eacute; du responsable de traitement et les finalit&eacute;s poursuivies justifiant la r&eacute;utilisation de vos donn&eacute;es. Le site sera mis &agrave; jour &agrave; chaque nouvelle Recherche r&eacute;utilisant vos donn&eacute;es.</span></p>
<p><span style="font-weight: 400;">Gr&acirc;ce &agrave; cette information, vous pourrez choisir d&rsquo;exercer vos droits d&rsquo;acc&egrave;s, de rectification, de limitation, d&rsquo;opposition ou d&rsquo;effacement de vos donn&eacute;es. L&rsquo;affichage des projets sur la page du site internet vaut information pour cette r&eacute;utilisation de donn&eacute;es et d&egrave;s lors, il n&rsquo;est pas pr&eacute;vu de vous adresser une lettre individuelle d&rsquo;information compl&eacute;mentaire pour chaque projet de recherche.</span></p>
<p><span style="font-weight: 400;">Les modalit&eacute;s d&rsquo;opposition pour chaque projet de recherche seront indiqu&eacute;es sur ce site Internet.</span></p>
<p><br /><br /></p>
<p style="text-align: center;">Akuity Care &ndash; SPEAKUITY R1 &ndash; N&deg;ID-RCB 2023-A00704-41- Version n&deg;2 du 05/06/2023</p>
<p style="text-align: right;">11/13</p>
<hr />
<p>&nbsp;</p>
<p>&nbsp;</p>

<h2><strong>PARTIE 3 : GLOSSAIRE</strong></h2>
<p>&nbsp;</p>
<ul>
<li style="font-weight: 400;"><strong>Biomarqueur vocal </strong><span style="font-weight: 400;">: algorithme d'IA fournissant une estimation d'un crit&egrave;re clinique chez un individu &agrave; partir d'un &eacute;chantillon de sa voix.</span></li>
<li style="font-weight: 400;"><strong>BPCO</strong><span style="font-weight: 400;"> (BronchoPneumopathie Chronique Obstructive) : maladie pulmonaire chronique courante qui r&eacute;duit le flux d'air et cause des probl&egrave;mes respiratoires.</span></li>
<li style="font-weight: 400;"><strong>Codage de vos donn&eacute;es</strong><span style="font-weight: 400;"> : identification de vos donn&eacute;es de mani&egrave;re unique dans la base de donn&eacute;es de la Recherche, par un code non-signifiant c'est-&agrave;-dire ne permettant pas de vous identifier personnellement (code alphanum&eacute;rique)</span></li>
<li style="font-weight: 400;"><strong>Contr&ocirc;le de l'asthme</strong><span style="font-weight: 400;"> : activit&eacute; r&eacute;cente de la maladie, impact sur le quotidien du patient et niveau de ma&icirc;trise du patient sur sa maladie.</span></li>
<li style="font-weight: 400;"><strong>D&eacute;bit Expiratoire de Pointe</strong><span style="font-weight: 400;"> : vitesse maximale &agrave; laquelle l'air peut &ecirc;tre expuls&eacute; hors des poumons lors d'une expiration forc&eacute;e (exprim&eacute;e en Litres / minute).</span></li>
<li style="font-weight: 400;"><strong>Donn&eacute;e personnelle</strong><span style="font-weight: 400;"> : toute information se rapportant &agrave; une personne physique identifi&eacute;e ou identifiable, dont le traitement est encadr&eacute;, en Europe, par le RGPD</span></li>
<li style="font-weight: 400;"><strong>DPO (D&eacute;l&eacute;gu&eacute; &agrave; la Protection des Donn&eacute;es)</strong><span style="font-weight: 400;"> : personne charg&eacute;e de la protection des donn&eacute;es personnelles au sein d'une organisation.</span></li>
<li style="font-weight: 400;"><strong>Dyspn&eacute;e</strong><span style="font-weight: 400;"> : g&ecirc;ne respiratoire ressentie.</span></li>
<li style="font-weight: 400;"><strong>IA</strong><span style="font-weight: 400;"> (Intelligence Artificielle) : construction de programmes informatiques r&eacute;alisant, de mani&egrave;re automatique, des t&acirc;ches d&eacute;finies, ces </span><strong>programmes &eacute;tant appel&eacute;es "algorithmes".</strong></li>
<li style="font-weight: 400;"><strong>ID-RCB</strong><span style="font-weight: 400;"> : num&eacute;ro d&rsquo;enregistrement de la recherche sur le site internet de l&rsquo;ANSM (Agence Nationale de S&eacute;curit&eacute; du M&eacute;dicament), destin&eacute; &agrave; identifier chaque recherche r&eacute;alis&eacute;e en France.</span></li>
<li style="font-weight: 400;"><strong>Investigateur</strong><span style="font-weight: 400;"> : Personne physique charg&eacute;e de surveiller et de diriger la recherche sur un lieu de recherche.</span></li>
<li style="font-weight: 400;"><strong>M&eacute;thodologie de r&eacute;f&eacute;rence (MR)</strong><span style="font-weight: 400;"> : Proc&eacute;dure simplifi&eacute;e encadrant l&rsquo;acc&egrave;s aux donn&eacute;es de sant&eacute; pour les promoteurs de recherche</span></li>
<li style="font-weight: 400;"><strong>Objectif principal</strong><span style="font-weight: 400;"> : objectif prioritaire d'une recherche clinique.</span></li>
<li style="font-weight: 400;"><strong>Objectif secondaire</strong><span style="font-weight: 400;"> : : objectif d'une recherche clinique, moins prioritaire que l'objectif principal.</span></li>
<li style="font-weight: 400;"><strong>Promoteur</strong><span style="font-weight: 400;"> : Personne physique ou morale responsable de la recherche, qui en assure la gestion et qui v&eacute;rifie que son financement est pr&eacute;vu.</span></li>
<li style="font-weight: 400;"><strong>Qualit&eacute; de vie respiratoire</strong><span style="font-weight: 400;"> : impact de la maladie pour le patient en termes de vie quotidienne et de bien-&ecirc;tre mental.</span></li>
<li style="font-weight: 400;"><strong>Recherche impliquant la personne humaine</strong><span style="font-weight: 400;"> : recherches organis&eacute;es et pratiqu&eacute;es sur l'&ecirc;tre humain en vue du d&eacute;veloppement des connaissances biologiques ou m&eacute;dicales (article L. 1121-1 du Code de la sant&eacute; publique)</span></li>
<li style="font-weight: 400;"><strong>RGPD</strong><span style="font-weight: 400;"> (R&egrave;glement G&eacute;n&eacute;ral sur la Protection des Donn&eacute;es) : R&egrave;glement (UE) 2016/679 du Parlement europ&eacute;en et du Conseil du 27 avril 2016 relatif &agrave; la protection des personnes physiques &agrave; l&rsquo;&eacute;gard du traitement des donn&eacute;es &agrave; caract&egrave;re personnel et &agrave; la libre circulation de ces donn&eacute;es</span></li>
<li style="font-weight: 400;"><strong>Situation d&rsquo;exacerbation</strong><span style="font-weight: 400;"> : aggravation des sympt&ocirc;mes respiratoires (toux, volume, purulence de l'expectoration, dyspn&eacute;e) au-del&agrave; des variations quotidiennes et conduisant le plus souvent &agrave; une modification th&eacute;rapeutique.</span></li>
</ul>
<p style="text-align: center;">&nbsp;</p>
<p>&nbsp;</p>

<p style="text-align: center;">Akuity Care &ndash; SPEAKUITY R1 &ndash; N&deg;ID-RCB 2023-A00704-41- Version n&deg;2 du 05/06/2023</p>
<p style="text-align: right;">12/13</p>
<hr />
<p>&nbsp;</p>
<p>&nbsp;</p>
<ul>
<li style="font-weight: 400;"><strong>Sympt&ocirc;mes ORL</strong><span style="font-weight: 400;"> : sympt&ocirc;mes oto rhino laryngologiques (exemple : congestion nasale ou des sinus)</span></li>
<li style="font-weight: 400;"><strong>Traitement des donn&eacute;es personnelles</strong><span style="font-weight: 400;"> : un traitement de donn&eacute;es personnelles est une op&eacute;ration, ou ensemble d&rsquo;op&eacute;rations, portant sur des donn&eacute;es personnelles, quel que soit le proc&eacute;d&eacute; utilis&eacute; (collecte, enregistrement, organisation, conservation, adaptation, modification, extraction, consultation, utilisation, communication par transmission ou diffusion ou toute autre forme de mise &agrave; disposition, rapprochement).</span></li>
</ul>
<p><br /><br /></p>
<p>&nbsp;</p>
<p style="text-align: center;"><span style="font-weight: 400;">***</span></p>
<p><br /><br /></p>
<p><span style="font-weight: 400;">Je reconnais avoir lu ce document, j'en accepte les termes et je ne m'oppose donc pas &agrave; la Recherche.</span></p>
<p>&nbsp;</p>

<p>&nbsp;</p>

<p style="text-align: center;">Akuity Care &ndash; SPEAKUITY R1 &ndash; N&deg;ID-RCB 2023-A00704-41- Version n&deg;2 du 05/06/2023</p>
<p style="text-align: right;">13/13</p>
<hr />
<p>&nbsp;</p>
`  /* eslint-enable */
}

export default consentFR

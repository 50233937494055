import { TaskRecording } from "../../../@types/taskRecording"

const taskRecordingsFR: TaskRecording[] = [
  {
    id: "read_speech_1",
    name: "Reading task Human Rights",
    type: "text",
    description:
      "1. Cliquez sur le bouton vert pour lancer l'enregistrement.\n" +
      "2. Veuillez lire ensuite le texte à haute voix.\n" +
      "3. Lorsque vous avez terminé, cliquez à nouveau sur le bouton pour arrêter l'enregistrement.",
    media:
      "Toute personne a droit à un niveau de vie suffisant pour assurer sa santé, son bien-être et ceux de sa" +
      " famille, notamment pour l'alimentation, l'habillement, le logement, les soins médicaux ainsi que pour " +
      "les services sociaux nécessaires ; elle a droit à la sécurité en cas de chômage, de maladie, d'invalidité," +
      " de veuvage, de vieillesse ou dans les autres cas de perte de ses moyens de subsistance par suite de " +
      "circonstances indépendantes de sa volonté.",
    lang: "fr",
  },
  {
    id: "sust_vowel",
    name: "Sustained vowel task aaah",
    type: "text",
    media: undefined,
    description:
      "1. Cliquez sur le bouton vert pour lancer l'enregistrement.\n" +
      "2. Dites 'aaaaah' aussi longtemps que possible sans respirer.\n" +
      "3. Lorsque vous avez terminé, cliquez à nouveau sur le bouton pour arrêter l'enregistrement.",
    lang: "fr",
  },
  {
    id: "free_speech_im_1",
    name: "Free speech image description",
    type: "image",
    media: "Image_task_family_kitchen.jpg",
    description:
      "1. Cliquez sur le bouton vert pour lancer l'enregistrement.\n" +
      "2. Décrivez autant d'éléments que possible dans cette image pendant au moins 30 secondes.\n" +
      "3. Lorsque vous avez terminé, cliquez à nouveau sur le bouton pour arrêter l'enregistrement.",
    lang: "fr",
  },
  {
    id: "fs_how_are_you",
    name: "Free speech answer a question",
    type: "text",
    media: "Comment ça va aujourd'hui ?",
    description:
      "1. Cliquez sur le bouton vert pour lancer l'enregistrement.\n" +
      "2. Répondez à la question suivante en parlant au moins 20 secondes.\n" +
      "3. Lorsque vous avez terminé, cliquez à nouveau sur le bouton pour arrêter l'enregistrement.",
    lang: "fr",
  },
  {
    id: "inh_exh",
    name: "Inhale and exhale",
    type: "text",
    media: undefined,
    description:
      "1. Cliquez sur le bouton vert pour lancer l'enregistrement.\n" +
      "2. Inspirez et expirez fortes par la bouche 3 fois.\n" +
      "3. Lorsque vous avez terminé, cliquez à nouveau sur le bouton pour arrêter l'enregistrement.",
    lang: "fr",
  },
  {
    id: "cough",
    name: "Coughing",
    type: "text",
    media: undefined,
    description:
      "1. Cliquez sur le bouton vert pour lancer l'enregistrement.\n" +
      "2. Toussez trois fois.\n" +
      "3. Lorsque vous avez terminé, cliquez à nouveau sur le bouton pour arrêter l'enregistrement.",
    lang: "fr",
  },
]

export default taskRecordingsFR

import { Consent } from "../../../@types/consent"

/* eslint-disable */
const consentEN: Consent = {
  lang: "en",
  text: `
    <h1 style="text-align: justify;"><strong style="background-color: transparent; color: #000000;">Information and Consent</strong></h1>
    <p style="text-align: justify;"></p>
    <br>
    <h2 style="text-align: justify;"><strong style="background-color: transparent; color: #000000;">What is the &lsquo;Sprache f&uuml;r die Stimmung 1&rsquo; study?</strong></h2>
    <p class="ql-align-justify" style="text-align: justify;"><span style="background-color: transparent; color: #000000;">The &lsquo;Sprache f&uuml;r die Stimmung 1&rsquo; study aims to identify vocal biomarkers of several health conditions or symptoms.</span></p>
    <p class="ql-align-justify" style="text-align: justify;"><span style="background-color: transparent; color: #000000;">The 'Sprache f&uuml;r die Stimmung 1' study is implemented by Akuity Care SAS, a French digital health company, in cooperation with the University Hospital T&uuml;bingen.</span></p>
    <p class="ql-align-justify" style="text-align: justify;"><span style="background-color: transparent; color: #000000;">Akuity Care SAS would like to develop vocal biomarkers and integrate them into a software used in clinical practice, for example to diagnose health conditions or to monitor them remotely through the analysis of voice.</span></p>
<p class="ql-align-justify" style="text-align: justify;"><span style="background-color: transparent; color: #000000;">The University Hospital T&uuml;bingen is also interested in collecting this data for research purposes.</span></p>
<p class="ql-align-justify" style="text-align: justify;"></p>
    <br>
  <h2 style="text-align: justify;"><strong style="background-color: transparent; color: #000000;">What is a vocal biomarker?</strong></h2>
<p class="ql-align-justify" style="text-align: justify;"><span style="background-color: transparent; color: #000000;">A vocal biomarker is a feature in the voice, or a combination of features that can help to detect a health condition or to monitor a specific symptom. Thanks to vocal biomarkers, one day, health may be measured easily, for example by talking into a smartphone.</span></p>
<p class="ql-align-justify" style="text-align: justify;"></p>
    <br>
  <h2 style="text-align: justify;"><strong style="background-color: transparent; color: #000000;">How to identify vocal biomarkers?</strong></h2>
<p class="ql-align-justify" style="text-align: justify;"><span style="background-color: transparent; color: #000000;">To achieve this objective, Akuity Care SAS needs to build a large database of vocal records combined with health data. Based on this, Akuity Care SAS will detect specific health characteristics from the voice, using machine learning techniques i.e. algorithms that can learn from data and identify patterns (biomarkers) with minimal human intervention. The accuracy of the algorithms is improving over time, with the addition of more data from other participants.</span></p>
<p class="ql-align-justify" style="text-align: justify;"></p>
    <br>
  <h2 style="text-align: justify;"><strong style="background-color: transparent; color: #000000;">How do I participate?</strong></h2>
<p style="text-align: justify;"><span style="background-color: transparent; color: #000000;">Your participation will imply two types of actions:</span></p>
<ul style="text-align: justify;">
<li style="text-align: justify;"><span style="background-color: transparent; color: #000000;">perform the 4 vocal tasks explained to you - they should enable to record around 2 minutes of your voice</span></li>
<li style="text-align: justify;"><span style="background-color: transparent; color: #000000;">answer to a few questionnaires about your health - they should take you around 15 minutes to fill in</span></li>
</ul>
<p style="text-align: justify;"></p>
    <br>
  <h2 style="text-align: justify;"><strong style="background-color: transparent; color: #000000;">Categories of data collected</strong></h2>
<p class="ql-align-justify" style="text-align: justify;"><span style="background-color: transparent; color: #000000;">Your participation in the study involves collecting personal data about you to the extent that it is necessary to meet the scientific objectives of the study. Data will be collected just once, when completing this study, we will not revert back to you for additional requests.</span></p>
<p style="text-align: justify;"><span style="background-color: transparent; color: #000000;">In particular will be collected:</span></p>
<ul style="text-align: justify;">
<li><span style="background-color: transparent; color: #000000;">Voice records: You will be asked to read a short text, to say "AAaaaaaa" as long as possible in the same breath, to describe a picture and to comment freely about your disease.</span></li>
<li><span style="background-color: transparent; color: #000000;">Basic information: demographic factors (age, gender, language, &hellip;), lifestyle factors (smoking...) and anthropometric factors (weight, height, &hellip;)</span></li>
<li><span style="background-color: transparent; color: #000000;">Health data: health status (symptoms, treatments, diseases), psychological health, &hellip;&nbsp;</span></li>
</ul>
    <br>
<p class="ql-align-justify" style="text-align: justify;"><span style="background-color: transparent; color: #000000;">Your personal data will be pseudonymized before it is transmitted to Akuity Care SAS. This means that a unique number and/or letter code will be assigned to all information related to your person that is transmitted to Akuity Care SAS. Akuity Care SAS will not receive any data that identifies you directly such as your name, email address, date of birth or any other unique piece of identification.&nbsp;</span></p>
<p class="ql-align-justify" style="text-align: justify;"><span style="background-color: transparent; color: #000000;">The University Hospital T&uuml;bingen will, however, remain able to link the unique code with your name, in order to facilitate the effective implementation of the rights that you may exercise with regard to your personal data processed by Akuity Care and the University Hospital T&uuml;bingen, as described herein below.&nbsp;</span></p>
<p class="ql-align-justify" style="text-align: justify;"></p>
    <br>
  <h2 class="ql-align-justify" style="text-align: justify;"><strong style="background-color: transparent; color: #000000;">Data controllers</strong></h2>
<p class="ql-align-justify" style="text-align: justify;"><span style="background-color: transparent; color: #000000;">Akuity Care SAS, having its registered address at 23 rue L&eacute;on Jost 75017 Paris, France, and the University Hospital T&uuml;bingen, having its registered address at Osianderstra&szlig;e 5, 72076 T&uuml;bingen,&nbsp; are joint controllers in the meaning of the European General Data Protection Regulation (GDPR) of the personal data processed in the course of the &lsquo;Sprache f&uuml;r die Stimmung 1&rsquo; study.</span></p>
<p class="ql-align-justify" style="text-align: justify;"></p>   
 <br>
  <p class="ql-align-justify" style="text-align: justify;"><span style="background-color: transparent; color: #000000;">According to the arrangement between Akuity Care SAS and the University Hospital T&uuml;bingen, the parties will be jointly responsible for the collection of your voice audio recordings and health data and for their subsequent secure transmission to the servers of Akuity Care SAS and the University Hospital T&uuml;bingen. Any further processing of the data will be performed by each party in its own responsibility.&nbsp;</span></p>
    <br>
<p class="ql-align-justify" style="text-align: justify;"><span style="background-color: transparent; color: #000000;">The University Hospital T&uuml;bingen will be your primary point of contact if you would like to exercise any of your rights set forth herein below. If you exercise any of your rights vis-&agrave;-vis the University Hospital T&uuml;bingen, the University Hospital T&uuml;bingen will notify Akuity Care SAS of your request in pseudonymous form, using the unique code assigned to you, and both, Akuity Care SAS and the University Hospital T&uuml;bingen, will ensure the effective implementation of your rights in accordance with the applicable data protection requirements.&nbsp;</span></p>
<p style="text-align: justify;"></p>
      <br>
<p style="text-align: justify;"><span style="background-color: transparent; color: #000000;">The University Hospital T&uuml;bingen can be contacted as follows:</span></p>
    <br>
<p style="text-align: justify;"><span style="background-color: transparent; color: #000000;"></span></p>
<p style="text-align: center;"><strong style="background-color: transparent; color: #000000;">Universit&auml;tsklinik T&uuml;bingen</strong></p>
<p class="ql-align-justify" style="text-align: center;"><span style="background-color: transparent; color: #000000;">Dr. Norbert Sch&auml;ffeler</span></p>
<p style="text-align: center;"><span style="background-color: transparent; color: #000000;">Osianderstra&szlig;e 5 - 72076 T&uuml;bingen</span></p>
<p class="ql-align-justify" style="text-align: center;"><span style="background-color: transparent; color: #000000;">norbert.schaeffeler@med.uni-tuebingen.de</span></p>
<p class="ql-align-justify" style="text-align: justify;"></p>
    <br>
  <p style="text-align: justify;"><span style="background-color: transparent; color: #000000;">Akuity Care SAS can be contacted as follows:</span></p>
      <br>
<p style="text-align: justify;"><span style="background-color: transparent; color: #000000;"></span></p>
<p style="text-align: center;"><strong style="background-color: transparent; color: #000000;">AKUITY CARE SAS</strong></p>
<p style="text-align: center;"><span style="background-color: transparent; color: #000000;">Gr&eacute;gory Lista</span></p>
<p style="text-align: center;"><span style="background-color: transparent; color: #000000;">23 rue L&eacute;on Jost 75017 Paris - France</span></p>
<p style="text-align: center;"><span style="background-color: transparent; color: #000000;">contact@akuity.care</span></p>
<p style="text-align: justify;"></p>
    <br>
  <h2 style="text-align: justify;"><strong style="background-color: transparent; color: #000000;">Purpose and legal basis for data processing</strong><strong style="background-color: transparent; color: #000000;"></strong></h2>
<p class="ql-align-justify" style="text-align: justify;"><span style="background-color: transparent; color: #000000;">Akuity Care SAS will process your personal data for the purpose of developing vocal biomarkers and, later on, integrating them into a software used in clinical practice, for example to diagnose health conditions or to monitor them remotely.</span></p>
    <br>
<p class="ql-align-justify" style="text-align: justify;"><span style="background-color: transparent; color: #000000;">The University Hospital T&uuml;bingen will process your personal data for research purposes.</span></p>
<p class="ql-align-justify" style="text-align: justify;"><span style="background-color: transparent; color: #000000;">Akuity Care SAS and the University Hospital T&uuml;bingen intend to jointly publish the results that they obtain with regard to the development of vocal biomarkers and their potential use for medical purposes. However, any such publication will be based on aggregated data from all Study participants and will not report any of your personal data or refer to you as an identifiable person.&nbsp;</span></p>
    <br>
<p class="ql-align-justify" style="text-align: justify;"><span style="background-color: transparent; color: #000000;">In addition, Akuity Care SAS may completely anonymize your personal data at a later stage. This means that all information that could allow your identification as the data subject, including the unique code, is irrevocably removed.</span></p>
    <br>
<p class="ql-align-justify" style="text-align: justify;"><span style="background-color: transparent; color: #000000;">The legal basis for the processing of your personal data is Article 6 (1) (a) of the GDPR in conjunction with Article 9 (2) (a) of the GDPR. According to these provisions, your personal data may be processed based on your express consent.</span></p>
<p class="ql-align-justify" style="text-align: justify;"></p>
    <br>
  <h2 style="text-align: justify;"><strong style="background-color: transparent; color: #000000;">Data hosting</strong></h2>
<p class="ql-align-justify" style="text-align: justify;"><span style="background-color: transparent; color: #000000;">Your personal data will be stored on servers rented by OVH to Akuity Care SAS, which are physically located in France and rented by a French provider. These servers are certified with the French certificate "H&eacute;bergement de donn&eacute;es de sant&eacute;&rdquo;, which attests additional security measures to store health data. The servers also comply with the EU General Data Protection Regulation.</span></p>
<p style="text-align: justify;"></p>
      <br>
<p class="ql-align-justify" style="text-align: justify;"><span style="background-color: transparent; color: #000000;">A copy of the data will be stored by the University Hospital T&uuml;bingen on its secured servers physically located in T&uuml;bingen.</span></p>
<p class="ql-align-justify" style="text-align: justify;"></p>
    <br>
  <h2 style="text-align: justify;"><strong style="background-color: transparent; color: #000000;">Data security and integrity</strong></h2>
<p class="ql-align-justify" style="text-align: justify;"><span style="background-color: transparent; color: #000000;">Akuity Care SAS and the University Hospital T&uuml;bingen take appropriate security measures to protect your personal data from the risk of unauthorized access, loss, fraudulent use, disclosure, modification and destruction. Your data will be treated as strictly confidential.</span></p>
<p class="ql-align-justify" style="text-align: justify;"></p>
    <br>
  <h2 style="text-align: justify;"><strong style="background-color: transparent; color: #000000;">Retention periods</strong></h2>
<p class="ql-align-justify" style="text-align: justify;"><span style="background-color: transparent; color: #000000;">Akuity Care SAS will retain the personal data collected in the &lsquo;Sprache f&uuml;r die Stimmung 1&rsquo; study for a minimum of 10 years. The retention period may be extended, if this should be required for the functioning and potential updates of its software. Your data will be deleted once it is no longer required for the aforesaid purposes.</span></p>
    <br>
<p class="ql-align-justify" style="text-align: justify;"><span style="background-color: transparent; color: #000000;">The University Hospital T&uuml;bingen will retain the personal data collected in the 'Sprache f&uuml;r die Stimmung 1' study for 10 years.&nbsp;</span></p>
<p class="ql-align-justify" style="text-align: justify;"></p>
    <br>
  <h2 style="text-align: justify;"><strong style="background-color: transparent; color: #000000;">Information right and right to rectification</strong></h2>
<p class="ql-align-justify" style="text-align: justify;"><span style="background-color: transparent; color: #000000;">You are entitled to request information about your personal data processed by the controllers. The University Hospital T&uuml;bingen will provide a copy of the personal data undergoing processing to you at your request. If the data should be inaccurate or incomplete, you can ask for your data to be rectified or completed. Subject to the respective legal requirements, you are entitled to ask for the processing of your data to be restricted. This is particularly the case if the accuracy of your data should be contested. If this is the case, you are entitled to ask for the processing of your data to be restricted for a period enabling Akuity Care SAS and/or the University Hospital T&uuml;bingen to verify the accuracy of the data.</span></p>
<p class="ql-align-justify" style="text-align: justify;"></p>
    <br>
  <h2 style="text-align: justify;"><strong style="background-color: transparent; color: #000000;">Deletion of data</strong></h2>
<p class="ql-align-justify" style="text-align: justify;"><span style="background-color: transparent; color: #000000;">Subject to the respective legal requirements, you are entitled to request deletion of your personal data by Akuity Care SAS and the University Hospital T&uuml;bingen at any time by notifying the University Hospital T&uuml;bingen of your request. The University Hospital T&uuml;bingen will forward your request to Akuity Care SAS without delay, and if the respective legal requirements are fulfilled, Akuity Care SAS and the University Hospital T&uuml;bingen will initiate deletion of your personal data, including all back-up copies, without undue delay. Please note, however, that a deletion may not be legally possible, e.g. due to legal retention requirements.</span></p>
<p class="ql-align-justify" style="text-align: justify;"></p>
    <br>
  <h2 style="text-align: justify;"><strong style="background-color: transparent; color: #000000;">Data portability</strong></h2>
<p class="ql-align-justify" style="text-align: justify;"><span style="background-color: transparent; color: #000000;">To the extent (a) Akuity Care SAS and the University Hospital T&uuml;bingen process personal data provided by you on the basis of your informed consent and (b) the processing is carried out by automated means, the University Hospital T&uuml;bingen will, at your request, make available this personal data in a structured and machine-readable format to you or to a third party, at your choice.</span></p>
<p class="ql-align-justify" style="text-align: justify;"></p>
    <br>
  <p style="text-align: justify;"><strong style="background-color: transparent; color: #000000;">Right to lodge a complaint</strong></p>
<p class="ql-align-justify" style="text-align: justify;"><span style="background-color: transparent; color: #000000;">If you believe that your rights have been infringed by Akuity Care SAS or the University Hospital T&uuml;bingen in the context of the processing of your personal data, you are entitled to lodge a complaint with the supervisory authority. The complaint can be lodged with the data protection authority of the country where you reside or work or where the alleged infringement occurred.</span></p>
    <br>
<p style="text-align: justify;"><span style="background-color: transparent; color: #000000;">You may, for example, address your complaint to the following supervisory authority, which is locally competent for the University Hospital T&uuml;bingen:</span></p>
    <br>
<p style="text-align: center;"><strong style="background-color: transparent; color: #000000;">Landesbeauftragten f&uuml;r den Datenschutz und die Informationsfreiheit Baden-W&uuml;rttemberg</strong></p>
<p style="text-align: center;"><span style="background-color: transparent; color: #000000;">Lautenschlagerstra&szlig;e 20</span></p>
<p style="text-align: center;"><span style="background-color: transparent; color: #000000;">70173 Stuttgart &ndash; Germany</span></p>
<p style="text-align: justify;"></p>
    <br>
  <p style="text-align: justify;"><strong style="background-color: transparent; color: #000000;">Right to withdraw consent</strong></p>
<p style="text-align: justify;"><span style="background-color: transparent; color: #000000;">You have the right to withdraw your consent to the processing of your personal data at any time. The withdrawal of your consent will, however, not affect the lawfulness of processing based on your consent before its withdrawal.&nbsp;</span></p>
<p style="text-align: justify;"></p>
  <p style="text-align: justify;"></p>
  <p style="text-align: justify;"></p>
  <p style="text-align: justify;"></p>`,
}
/* eslint-enable */
export default consentEN

import { Categorical, MedicalQuestion } from "../../../../@types/medicalQuestion"

const category: Categorical[] = [
  {
    label: "Pas du tout",
    value: "not-at-all",
  },
  {
    label: "Un peu",
    value: "a-little",
  },
  {
    label: "Moyennement",
    value: "moderately",
  },
  {
    label: "Beaucoup",
    value: "a-lot",
  },
]

const emotionsFR: MedicalQuestion[] = [
  {
    id: "emot_intro",
    name: "intro",
    value:
      // eslint-disable-next-line max-len
      "Quelles émotions décrivent le mieux la façon dont vous vous sentez à cet instant ? Indiquez l'intensité avec laquelle vous ressentez les émotions suivantes à cet instant.",
    type: "intro",
    lang: "fr",
    parent: "emotion",
    required: false,
  },
  {
    id: "emot_joy",
    name: "Emotions",
    type: "categoricalShort",
    value: category,
    description: "Joie",
    parent: "emotion",
    order: 1,
    lang: "fr",
    required: true,
  },
  {
    id: "emot_neut",
    name: "Emotions",
    type: "categoricalShort",
    value: category,
    description: "Neutre",
    parent: "emotion",
    order: 2,
    lang: "fr",
    required: true,
  },
  {
    id: "emot_trust",
    name: "Emotions",
    type: "categoricalShort",
    value: category,
    description: "Confiance",
    parent: "emotion",
    order: 3,
    lang: "fr",
    required: true,
  },
  {
    id: "emot_fear",
    name: "Emotions",
    type: "categoricalShort",
    value: category,
    description: "Peur",
    parent: "emotion",
    order: 4,
    lang: "fr",
    required: true,
  },
  {
    id: "emot_surprise",
    name: "Emotions",
    type: "categoricalShort",
    value: category,
    description: "Surprise",
    parent: "emotion",
    order: 5,
    lang: "fr",
    required: true,
  },
  {
    id: "emot_sad",
    name: "Emotions",
    type: "categoricalShort",
    value: category,
    description: "Tristesse",
    parent: "emotion",
    order: 6,
    lang: "fr",
    required: true,
  },
  {
    id: "emot_anger",
    name: "Emotions",
    type: "categoricalShort",
    value: category,
    description: "Colère",
    parent: "emotion",
    order: 7,
    lang: "fr",
    required: true,
  },
]

export default emotionsFR

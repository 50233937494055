import { useState, ReactNode } from "react"
import { Navigate, useLocation } from "react-router-dom"
// hooks
// pages
import Login from "../pages/auth/Login"
// components
import useAuth from "../hooks/useAuth"

// ----------------------------------------------------------------------

type AuthGuardProps = {
  children: ReactNode
}

export default function AuthGuard({ children }: AuthGuardProps) {
  const { isAuthenticated } = useAuth()

  const { pathname } = useLocation()

  const [requestedLocation, setRequestedLocation] = useState<string | null>(null)

  if (!isAuthenticated) {
    if (pathname !== requestedLocation) {
      setRequestedLocation(pathname)
    }
    return <Login />
  }

  if (requestedLocation && pathname !== requestedLocation) {
    setRequestedLocation(null)
    return <Navigate to={requestedLocation} />
  }

  return <>{children}</>
}

import { Categorical, MedicalQuestion } from "../../../../@types/medicalQuestion"

const category: Categorical[] = [
  { label: "1", value: "1" },
  { label: "2", value: "2" },
  { label: "3", value: "3" },
  { label: "4", value: "4" },
  { label: "5", value: "5" },
  { label: "6", value: "6" },
  { label: "7", value: "7" },
]

const fssDE: MedicalQuestion[] = [
  {
    id: "fss_intro",
    name: "intro",
    value:
      "Wählen Sie eine Zahl von 1 (stimme gar nicht zu) bis 7 (stimme vollkommen zu), die angibt, " +
      "inwieweit Sie den folgenden Aussagen zustimmen. Bitte beantworten Sie die Fragen in Bezug darauf, " +
      "wie Sie sich im Durchschnitt in der vergangenen Woche gefühlt haben.",
    type: "intro",
    lang: "de",
    parent: "fss",
    required: false,
  },
  {
    id: "fss_1",
    name: "Fatigue Severity Scale",
    type: "categoricalLong",
    value: category,
    labelStart: "Stimme gar nicht zu",
    labelEnd: "Stimme vollkommen zu",
    description: "Ich habe weniger Motivation, wenn ich erschöpft bin",
    parent: "fss",
    order: 1,
    lang: "de",
    required: false,
  },
  {
    id: "fss_2",
    name: "Fatigue Severity Scale",
    type: "categoricalLong",
    value: category,
    labelStart: "Stimme gar nicht zu",
    labelEnd: "Stimme vollkommen zu",
    description: "Körperliche Betätigung führt zu mehr Erschöpfung",
    parent: "fss",
    order: 2,
    lang: "de",
    required: false,
  },
  {
    id: "fss_3",
    name: "Fatigue Severity Scale",
    type: "categoricalLong",
    value: category,
    labelStart: "Stimme gar nicht zu",
    labelEnd: "Stimme vollkommen zu",
    description: "Ich bin schnell erschöpft",
    parent: "fss",
    order: 3,
    lang: "de",
    required: false,
  },
  {
    id: "fss_4",
    name: "Fatigue Severity Scale",
    type: "categoricalLong",
    value: category,
    labelStart: "Stimme gar nicht zu",
    labelEnd: "Stimme vollkommen zu",
    description: "Die Erschöpfung beeinflusst meine körperliche Belastbarkeit",
    parent: "fss",
    order: 4,
    lang: "de",
    required: false,
  },
  {
    id: "fss_5",
    name: "Fatigue Severity Scale",
    type: "categoricalLong",
    value: category,
    labelStart: "Stimme gar nicht zu",
    labelEnd: "Stimme vollkommen zu",
    description: "Die Erschöpfung verursacht Probleme für mich",
    parent: "fss",
    order: 5,
    lang: "de",
    required: false,
  },
  {
    id: "fss_6",
    name: "Fatigue Severity Scale",
    type: "categoricalLong",
    value: category,
    labelStart: "Stimme gar nicht zu",
    labelEnd: "Stimme vollkommen zu",
    description: "Meine Erschöpfung behindert körperliche Betätigung",
    parent: "fss",
    order: 6,
    lang: "de",
    required: false,
  },
  {
    id: "fss_7",
    name: "Fatigue Severity Scale",
    type: "categoricalLong",
    value: category,
    labelStart: "Stimme gar nicht zu",
    labelEnd: "Stimme vollkommen zu",
    description: "Die Erschöpfung behindert mich an der Ausführung bestimmter Aufgaben und Pflichten",
    parent: "fss",
    order: 7,
    lang: "de",
    required: false,
  },
  {
    id: "fss_8",
    name: "Fatigue Severity Scale",
    type: "categoricalLong",
    value: category,
    labelStart: "Stimme gar nicht zu",
    labelEnd: "Stimme vollkommen zu",
    description: "Die Erschöpfung gehört zu den drei mich am meisten behindernden Beschwerden",
    parent: "fss",
    order: 8,
    lang: "de",
    required: false,
  },
  {
    id: "fss_9",
    name: "Fatigue Severity Scale",
    type: "categoricalLong",
    value: category,
    labelStart: "Stimme gar nicht zu",
    labelEnd: "Stimme vollkommen zu",
    description: "Die Erschöpfung hat Einfluss auf meine Arbeit, meine Familie bzw. meine Arbeit, meine Familie bzw. mein soziales Leben",
    parent: "fss",
    order: 9,
    lang: "de",
    required: false,
  },
]

export default fssDE

const fr = {
  menu: {
    about: "À propos",
    admin: "Admin",
    confirm: "Attention: Êtes-vous sûr de vouloir revenir au début? Toutes les données que vous avez saisies seront perdues.",
    language: "Choisissez votre langue",
    logout: "Logout",
    voice: {
      headerName: "Voix",
      confirm: "Attention : Êtes-vous sûr de vouloir commencer par les tâches vocales ? ",
    },
    questionnaires: {
      headerName: "Questionnaires",
      confirm: "Attention : Êtes-vous sûr de vouloir commencer par les questionnaires ? ",
    },
    footer: {
      information: "Informations",
    },
  },
  project: {
    clickToStart: "Commencer",
    goToNextStep: "Continuer",
    questionnaireIDFootnote: "ID patient:",
    consent: {
      title: "Consentement",
      accept: "Je reconnais avoir lu ce document, j'en accepte les termes et je ne m'oppose donc pas à la Recherche.",
      date: "Date:",
      continue: "Continuer",
    },
    usertype: {
      newUser: "C’est ma première participation",
      existingUser: "J’ai déjà participé et je veux participer à nouveau",
    },
    newUser: {
      title: "Merci d'indiquer votre adresse email",
      explication:
        "Aucun message ne sera envoyé à l’adresse email que vous nous indiquerez. Elle ne sera utilisée que pour retrouver" +
        " vos données lors d’une prochaine participation à l’étude ou si vous souhaitez exercer vos droits.",
      //email: "Pour le recevoir, veuillez indiquer votre adresse email ci-après.",
      //userid: "Code",
      //privacy: "NB : votre adresse email ne sera pas conservée, elle ne sera utilisée que pour vous envoyer ce code.",
      //send: "Envoyer code",
      continue: "Continuer",
    },
    existingUser: {
      title: "Merci de vous authentifier en indiquant votre adresse email",
      explication:
        "Aucun message ne sera envoyé à l’adresse email que vous nous avez indiquée. Elle n'est utilisée que " +
        "pour retrouver vos données historiques et nous permettre d'appliquer vos souhaits éventuels quant à l'exercice de vos droits.",
      continue: "Confirmer",
      error: "L'utilisateur ayant cette adresse e-mail n'existe pas encore.",
      //forgot: "Je ne retrouve pas mon code",
      isVerifying: "L'adresse électronique est en cours de vérification...",
    },
    phoneNumber: {
      title: "Merci d'indiquer votre numéro de portable",
      explication:
        "Vous allez recevoir un SMS vous permettant de compléter cet échantillon dans la salle d'attente. Vous recevrez " +
        "un SMS similaire dans 1 semaine pour fournir un nouvel échantillon depuis votre domicile, et ainsi jusqu'à la fin de l'étude.",
      continue: "Confirmer",
    },
    recording: {
      title: "Tâches vocales",
      instructions: {
        title: "Instructions",
        first: "Trouvez un environnement calme",
        second:
          "Essayez de maintenir une distance constante entre votre bouche et votre appareil d'enregistrement pendant les tâches vocales",
        third: "Suivez les instructions données pour les tâches vocales",
        continue: "Continuer",
        O1: {
          warning:
            "Il est important que vous respectiez bien ces consignes pour garantir l'exploitabilité des données que" +
            " nous allons collecter.",
          first: "Trouvez un environnement calme et faites toujours l'exercice dans la même pièce.",
          second: "Installez le micro sur une table devant vous et positionnez-le comme indiqué sur l'image.",
          third:
            "Branchez le cable USB du micro dans votre appareil (ordinateur / tablette / portable) et vérifiez que le micro" +
            " est bien allumé (vous devez voir des lumières vertes sur le micro lorsque vous parlez)",
          fourth:
            "Derrière le micro, vérifiez que le voyant en forme de coeur est allumé (comme indiqué sur l'image). " +
            "Sinon cliquez sur le bouton jusqu'à ce que la forme du coeur apparaisse.",
          fifth: "Asseyez-vous sur une chaise à environ 30 centimètres du micro",
          sixth: "Cliquez sur 'Continuer' et suivez les instructions",
          imageMicroPattern: "blueX_micro_pattern.png",
          imageMicroPosition: "blueX_micro_position.png",
        },
      },
      tasks: "Tâches de l'étape :",
    },
    questionnaire: {
      title: "Questionnaire",
      description: "Répondez aux questions suivantes",
    },
    done: {
      questionnairesNext: {
        title: "Cette partie est terminée, merci !",
        continue: "Continuer avec les questionnaires",
      },
      recordingsNext: {
        title: "Cette partie est terminée, merci ! Veuillez maintenant mettre le casque sur votre tête.",
        titleProjectLongitudinal: "Cette partie est terminée, merci !",
        continue: "Continuer avec les tâches vocales",
      },
      information: "Pour toute question sur l’étude, un menu 'Informations' est disponible en bas de chaque page dès la page suivante.",
    },
    final: {
      title: "L’étude est terminée, merci pour votre participation!",
    },
    result: {
      continue: "Continuer",
    },
  },
  audioplayer: {
    pause: "Pause",
    stop: "Stop",
    listen: "Écouter",
    record: "Enregistrer",
    recordAgain: "Enregistrer à nouveau",
    instruction: "You can go to the next step once you have finished the task",
    rerecord: "If you like, you can re-record the audio or go to the next step",
  },
  admin: {
    title: "Questionnaire administration",
    select: "Sélectionnez un ID patient pour remplir le questionnaire",
    table: {
      idColumnName: "ID",
      questionnairesColumnName: "Quest.",
      recordingsColumnName: "Enregistr.",
      doctorsColumnName: "Médecin",
      completeButton: "compléter",
    },
  },
}

export default fr

import { Categorical, MedicalQuestion } from "../../../../@types/medicalQuestion"

const category: Categorical[] = [
  {
    label: "Jamais",
    value: "not-at-all",
  },
  {
    label: "Plusieurs jours",
    value: "several-days",
  },
  {
    label: "Plus de la moitié du temps",
    value: "more-than-half-the-days",
  },
  {
    label: "Presque tous les jours",
    value: "nearly-every-day",
  },
]

const phqFR: MedicalQuestion[] = [
  {
    id: "phq_intro",
    name: "intro",
    value: "Au cours des 2 dernières semaines, selon quelle fréquence avez-vous été gêné(e) par les problèmes suivants ?",
    type: "intro",
    lang: "fr",
    parent: "phq",
    required: false,
  },
  {
    id: "phq_1",
    name: "Patient Health Questionnaire",
    type: "categoricalShort",
    value: category,
    description: "Peu d’intérêt ou de plaisir à faire les choses",
    parent: "phq",
    order: 1,
    lang: "fr",
    required: true,
  },
  {
    id: "phq_2",
    name: "Patient Health Questionnaire",
    type: "categoricalShort",
    value: category,
    description: "Être triste, déprimé(e) ou désespéré(e)",
    parent: "phq",
    order: 2,
    lang: "fr",
    required: true,
  },
  {
    id: "phq_3",
    name: "Patient Health Questionnaire",
    type: "categoricalShort",
    value: category,
    description: "Difficultés à s’endormir ou à rester endormi(e), ou dormir trop",
    parent: "phq",
    order: 3,
    lang: "fr",
    required: true,
  },
  {
    id: "phq_4",
    name: "Patient Health Questionnaire",
    type: "categoricalShort",
    value: category,
    description: "Se sentir fatigué(e) ou manquer d’énergie",
    parent: "phq",
    order: 4,
    lang: "fr",
    required: true,
  },
  {
    id: "phq_5",
    name: "Patient Health Questionnaire",
    type: "categoricalShort",
    value: category,
    description: "Avoir peu d’appétit ou manger trop",
    parent: "phq",
    order: 5,
    lang: "fr",
    required: true,
  },
  {
    id: "phq_6",
    name: "Patient Health Questionnaire",
    type: "categoricalShort",
    value: category,
    description:
      "Avoir une mauvaise opinion de soi-même, ou avoir le sentiment d’être nul(le), ou d’avoir déçu sa " +
      "famille ou s’être déçu(e) soi-même",
    parent: "phq",
    order: 6,
    lang: "fr",
    required: true,
  },
  {
    id: "phq_7",
    name: "Patient Health Questionnaire",
    type: "categoricalShort",
    value: category,
    description: "Avoir du mal à se concentrer, par exemple, pour lire le journal ou regarder la télévision ",
    parent: "phq",
    order: 7,
    lang: "fr",
    required: true,
  },
  {
    id: "phq_8",
    name: "Patient Health Questionnaire",
    type: "categoricalShort",
    value: category,
    description:
      "Bouger ou parler si lentement que les autres auraient pu le remarquer. Ou au contraire, être si agité(e) " +
      "que vous avez eu du mal à tenir en place par rapport à d'habitude",
    parent: "phq",
    order: 8,
    lang: "fr",
    required: true,
  },
  {
    id: "phq_9",
    name: "Patient Health Questionnaire",
    type: "categoricalShort",
    value: category,
    description: "Penser qu’il vaudrait mieux mourir ou envisager de vous faire du mal d’une manière ou d’une autre",
    parent: "phq",
    order: 9,
    lang: "fr",
    required: true,
  },
]

export default phqFR
